import React, { useState, useEffect } from 'react';
import logo from '../assets/images/endless-logo.png';
import man from '../assets/images/dashboard/user.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import axios from 'axios'
import  {firebase_app, googleProvider, facebookProvider, twitterProvider, githubProvider,Jwt_token } from "../data/config";
import { handleResponse } from "../services/fack.backend";
import { useForm } from "react-hook-form";
import { apiEndpoint,Login,LOGIN,YourName,Password,RememberMe,LoginWithAuth0,LoginWithJWT } from '../constant';
import LoginImage from '../assets/images/signup_image.png'

const Signin = () => {

    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const onSubmit = (data) => {
      const loginJwt = async () => {
        const enter = await axios.post(`${apiEndpoint}/auth/login`)
        if(enter.auth) {
          localStorage.setItem('jwt', enter.token)
          window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`
        }
      }
      loginJwt()
    }

    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="card mt-4 cleaning-login-card">
                                            <div className="card-body">
                                                <div className="text-center">
                                                <div className="text-center">
                                                  <img src={LoginImage} alt="cleaning-image" className="login-image"/>
                                                </div>
                                                    <h4>{LOGIN}</h4>
                                                    <h6>{"Inserisci Username e Password"} </h6>
                                                </div>
                                                <form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0 cleaning-form-label">{"Username"}</label>
                                                        <input className="form-control cleaning-form" type="email" name="email"
                                                            placeholder="Il tuo indirizzo email" {...register("email")}/>

                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label cleaning-form-label">{Password}</label>
                                                        <input className="form-control cleaning-form" type="password" name="password"
                                                            placeholder="La tua password" {...register("password")}/>

                                                    </div>
                                                    <div className="checkbox p-0">
                                                        <input id="checkbox1 cleaning-form" type="checkbox" />
                                                        <label htmlFor="checkbox1">{RememberMe}</label>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <button className="btn btn-primary btn-block cleaning-btn-login" type="submit" >{Login}</button>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <span>Non sei registrato? <Link to={`${process.env.PUBLIC_URL}/pages/sign-up`}> Cicca qui</Link></span>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

export default withRouter(Signin);
