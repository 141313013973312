import React, { Fragment, useState, useEffect } from 'react';
import man from '../../../assets/images/dashboard/user.png';
import { User, Mail, Lock, Settings, LogOut, AlertTriangle, HelpCircle  } from 'react-feather';
import {firebase_app} from "../../../data/config";
import { Link, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import {EditProfile,Inbox,LockScreen} from '../../../constant'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import  Avatar  from '../../../assets/images/avtar/avatar-cleaning.png'

const UserMenu = ({ history }) => {

    const [isLoggedOut, setLoggedOut] = useState(false)
    const [modal, setModal] = useState();
    // auth0 profile
    const authenticated = JSON.parse(localStorage.getItem("authenticated"))

    const doLogout = () => {
        localStorage.clear()
        setLoggedOut(true)
    }

    const toggle = () => {
        setModal(!modal)
    }

    if(isLoggedOut) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/login`} />)
    }

    return (
        <Fragment>
            <li className="onhover-dropdown">
                <div className="media align-items-center">
                    <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={Avatar} alt="header-user" />
                    <div className="dotted-animation">
                        <span className="animate-circle"></span>
                        <span className="main-circle"></span>
                    </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li><Link to={`${process.env.PUBLIC_URL}/users/userEdit`}><User />{"Il mio profilo"}</Link></li>
                    {localStorage.getItem("role") === 'Admin' ? <li><Link to={`${process.env.PUBLIC_URL}/users/lista`}><Settings />{"Impostazioni"}</Link></li> : null}
                    <li><Link to={`${process.env.PUBLIC_URL}/users/help`}><HelpCircle />{"Aiuto"}</Link></li>
                    <li><a onClick={() => toggle()}><LogOut /> {"Esci"}</a></li>
                </ul>
            </li>


            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Attenzione! </ModalHeader>
                <ModalBody>
                    <p>Sei sicuro di voler <strong>uscire</strong> da Cleaning Academy? Premi <strong>Esci</strong> per effettuare il logout, o <strong>Annulla</strong> per annullare.</p>
                </ModalBody>
                <ModalFooter>
                    <Button className="cleaning-cancel-btn" onClick={toggle}>{"Annulla"}</Button>
                    <Button color="danger" className="btn-cleaning" onClick={() => doLogout()}>{"Esci"}</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};


export default withRouter(UserMenu);
