import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';



const PreventiviAmbienti = () => {

    const [data,setData] = useState([])
    const [allAreas, setAreas] = useState([{}])
    const [clientiPotenziali, setClientiPotenziali] = useState([])
    const [loading, setLoading] = useState(false)
    const [fileImage, setFile] = useState(null)
    const [isNum, setNum] = useState(null)
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { register: registerDel, handleSubmit: handleSubmitDel, formState: { errors: errorsDel } } = useForm();
    const { register: registerPwd, handleSubmit: handleSubmitPwd, formState: { errors: errorsPwd }, reset } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [customerCode, setCustomerCode] = useState('')
    const [isMoved, setMoved] = useState(false)
    let history = useHistory()

    //Generazione randomica di stringhe
    function makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() *
     charactersLength)));
       }
       return result.join('');
    }



    useEffect(() => {
        let mounted = true
        const getUser = async () => {
          const amb = await axios.get(`${apiEndpoint}/aree-ambienti/fetch-aree`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          const numero = await axios.get(`${apiEndpoint}/preventivi/fetch-num`, {
            params: {
              userID: localStorage.getItem('userID')
            },
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          if(mounted) {
              setAreas(amb.data)
              let array = numero.data
              if(array.length > 0) {
                const max = array.reduce(function(prev, current) {
                    return (prev.numero > current.numero) ? prev : current
                })
                setNum(max.numero + 1)
                setLoading(true)
              } else {
                setNum(1)
                setLoading(true)
              }


          }
        }
        getUser()
        return () => {
          mounted = false
        }
    }, [])

    const addPreventivo = (ambiente) => {
      const addPrev = async () => {
        var today = new Date();

        const add = await axios.post(`${apiEndpoint}/preventivi/create`, {
          idPreventivo: makeid(7),
          ambiente: ambiente,
          descrizione: makeid(4) + '-nuovo-preventivo-' + makeid(4),
          userID: localStorage.getItem("userID"),
          data: today,
          numero: isNum
        }, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        history.push(`${process.env.PUBLIC_URL}/preventivi/aggiungi/${isNum}`)
      }
      addPrev()
    }

    if(isMoved) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/clienti-potenziali/profilo/${customerCode}`} />)
    }


    return (
        <Fragment>
            <Breadcrumb title="Ambienti" parent="Aree e ambienti" />
            {loading ? <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                          <div className="card-body datatable-react">
                            <div className="row">

                              {allAreas.map((item, index) => {
                                return (
                                  <div className="col-xl-4 col-md-4 col-sm-6">
                                  <div className="card card-preventivo" onClick={() => addPreventivo(item.ambiente)}>
                                      <div className="card-body text-center">
                                          <center>
                                              <img src={fileServer + item.PercorsoImmagine} alt={item.ambiente} className="img-aree-ambienti"/>
                                              </center>
                                              <h5 className="title-cleaning-dashboard">{item.ambiente}</h5>
                                          </div>
                                      </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div> : null}
        </Fragment>
    );
};

export default PreventiviAmbienti;
