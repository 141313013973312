import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import Select from 'react-select';



const AttrezzaturaAdd = () => {

    const [data,setData] = useState([])
    const [userData, setUserData] = useState({})
    const [allCategories, setCategories] = useState([{}])
    const [allAmbience, setAmbience] = useState([{}])
    const [loadingAmbience, setLoadingAmbience] = useState(true)
    const [loading, setLoading] = useState(true)
    const [loadingAtt, setLoadingAtt] = useState(true)
    const [fileImage, setFile] = useState(null)
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [isEcolabel, setEcolabel] = useState(null)
    const [isMaterial, setMaterial] = useState('')
    const [isCodiceColore, setCodiceColore] = useState('')

    let history = useHistory()

    //Generazione randomica di stringhe
    function makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() *
     charactersLength)));
       }
       return result.join('');
    }

    const toggle = () => {
        setModal(!modal)
    }

    const onSubmit = (data) => {

      let formData = new FormData();
      let fileName = 'ATT' + (makeid(5)).toUpperCase()
      if(data.file) {
        formData.append('file', data.file[0])
        formData.append('fileName', fileName)
      }
      formData.append('descrizione', data.descrizione)
      formData.append('ecolabel', isEcolabel)
      formData.append('vita', data.vita)
      formData.append('prezzo', data.prezzo)
      formData.append('tipoResa', data.tipoResa)
      formData.append('plastica', data.plastica)
      formData.append('tecnica', data.scheda_tecnica)
      formData.append('sicurezza', data.scheda_sicurezza)
      formData.append('userID', localStorage.getItem("role") === 'Admin' ? null : localStorage.getItem("userID"))

      const updateData = async () => {
          const update = await axios.post(`${apiEndpoint}/attrezzature/create`, formData, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          toggle()
        }
        updateData()



    }

    const selectStyles = {
      control: styles => ({ ...styles, borderRadius: '10px'})
    }

    const resetForm = () => {
      reset()
    }

    const setFileUpload = (target) => {
      if(target) {
        let newImage = target.files[0]
        let imageSize = newImage.size
        let imageType = newImage.type
        if(imageType === 'image/png' || imageType === 'image/jpeg') {
          if(imageSize <= 2000000) {
            setFile(newImage)
            setDisabled(false)
          } else {
            window.alert('Dimensione massima consentita 2MB.')
          }
        } else {
          window.alert('Puoi caricare solo immagini in formato .png, .jpeg, .jpg')
        }
      }
    }

    if(isLoggedOut) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/login`} />)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Attrezzatura" title="Aggiungi" />
            {loading ? <><div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12 card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Nuova attrezzatura"}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">

                                    <div className="col-sm-12 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Immagine attrezzatura"} </label>
                                            <input className="form-control cleaning-form" name="file" type="file" placeholder="Inserisci descrizione es. Panno microfibra CLEANING ACADEMY" {...register("file")}/>
                                            {errors.file && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Descrizione"} </label>
                                            <input className="form-control cleaning-form" name="descrizione" type="text" placeholder="Inserisci descrizione es. Carrello CLEANING ACADEMY" {...register("descrizione")}/>
                                            {errors.descrizione && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Ecolabel"}</label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Il prodotto é EcoLabel?"
                                              onChange={e => setEcolabel(e.value)}
                                              options={[
                                                {
                                                  value: 1,
                                                  label: 'Si'
                                                },
                                                {
                                                  value: 0,
                                                  label: 'No'
                                                }
                                              ]}
                                              styles={selectStyles}
                                              required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Plastica riciclata"} </label>
                                            <input className="form-control cleaning-form" name="plastica" type="number" placeholder="Inserisci plastica riciclata" {...register("plastica")}/>
                                            {errors.plastica && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Vita utile"} </label>
                                            <input className="form-control cleaning-form" name="vita" type="number" placeholder="Inserisci vita utile" {...register("vita")}/>
                                            {errors.vita && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Tipo resa"} </label>
                                            <select className="form-control cleaning-form" name="tipoResa" {...register("tipoResa")}>
                                              <option value="mq">mq</option>
                                              <option value="punti">punti</option>
                                            </select>
                                            {errors.prezzo && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Importo ammortamento (singolo pezzo iva esclusa)"} </label>
                                            <input className="form-control cleaning-form" name="prezzo" step="0.01" type="number" placeholder="Inserisci prezzo es. 10.09" {...register("prezzo")}/>
                                            {errors.prezzo && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Scheda tecnica (es. https://sitoproduttore.com)"} </label>
                                            <input className="form-control cleaning-form" name="scheda_tecnica" type="url" placeholder="es. https://sitoproduttore.com" {...register("scheda_tecnica")}/>
                                            {errors.prezzo && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Scheda sicurezza (es. https://sitoproduttore.com)"} </label>
                                            <input className="form-control cleaning-form" name="scheda_sicurezza"  type="url" placeholder="es. https://sitoproduttore.com" {...register("scheda_sicurezza")}/>
                                            {errors.prezzo && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    {/*<div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Scegli categoria"}</label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Scegli la categoria del materiale"
                                              onChange={e => setMaterial(e.value)}
                                              options={[
                                                {
                                                  value: 'CAR',
                                                  label: 'Carrelli'
                                                },
                                                {
                                                  value: 'MAC',
                                                  label: 'Panni'
                                                }
                                              ]}
                                              styles={selectStyles}
                                              required
                                            />
                                        </div>
                                    </div>*/}

                                    </div>
                                    {/*{(() => {
                                      switch(isMaterial) {
                                        case 'DET':
                                          return (
                                            <>
                                            <hr />
                                            <div className="row">
                                              <div className="col-sm-6 col-md-6">
                                                  <div className="form-group">
                                                      <label className="form-label cleaning-form-label">{"Confezione tanica ml"} </label>
                                                      <input className="form-control cleaning-form" name="confezione" type="number" placeholder="Inserisci ml prodotto" {...register("confezione")}/>
                                                      {errors.confezione && <p className="err-msg-form">Campo richiesto</p>}
                                                  </div>
                                              </div>

                                              <div className="col-sm-6 col-md-6">
                                                  <div className="form-group">
                                                      <label className="form-label cleaning-form-label">{"Grammi plastica contenitore"} </label>
                                                      <input className="form-control cleaning-form" name="plastica" type="number" placeholder="Inserisci grammi plastica contenitore" {...register("contenitore")}/>
                                                      {errors.contenitore && <p className="err-msg-form">Campo richiesto</p>}
                                                  </div>
                                              </div>

                                              <div className="col-sm-6 col-md-6">
                                                  <div className="form-group">
                                                      <label className="form-label cleaning-form-label">{"Plastica riciclata %"} </label>
                                                      <input className="form-control cleaning-form" name="plastica" type="number" placeholder="Inserisci percentuale plastica riciclata es. 100%" {...register("plastica")}/>
                                                      {errors.plastica && <p className="err-msg-form">Campo richiesto</p>}
                                                  </div>
                                              </div>

                                              <div className="col-sm-6 col-md-6">
                                                  <div className="form-group">
                                                      <label className="form-label cleaning-form-label">{"Diluizione %"} </label>
                                                      <input className="form-control cleaning-form" name="diluizione" type="number" placeholder="Inserisci percentuale diluzione" {...register("diluizione")}/>
                                                      {errors.diluizione && <p className="err-msg-form">Campo richiesto</p>}
                                                  </div>
                                              </div>

                                            </div>
                                            </>
                                          )
                                         case 'PAN':
                                          return (
                                            <>
                                            <hr />
                                            <div className="row">

                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Composizione"} </label>
                                                    <input className="form-control cleaning-form" name="composizione" type="text" placeholder="Inserisci composizione panno es. 70% pes, 30% pa" {...register("composizione")}/>
                                                    {errors.composizione && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div> */}

                                            {/*<div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Dimensioni panno cm/pz"} </label>
                                                    <input className="form-control cleaning-form" name="dimensioni" type="text" placeholder="Inserisci dimensioni panno es. 40x40" {...register("dimensioni")}/>
                                                    {errors.dimensioni && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div>*/}
                                          {/*  <div className="col-sm-3 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Larghezza panno cm/pz"} </label>
                                                    <input className="form-control cleaning-form" name="larghezza" type="number" placeholder="Inserisci larghezza panno es. 40" {...register("larghezza")}/>
                                                    {errors.larghezza && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Altezza panno cm/pz"} </label>
                                                    <input className="form-control cleaning-form" name="altezza" type="number" placeholder="Inserisci altezza panno es. 40" {...register("altezza")}/>
                                                    {errors.altezza && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div>

                                            <div className="col-sm-3 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Peso gr/mq"} </label>
                                                    <input className="form-control cleaning-form" name="peso1" type="number" step="0.01" placeholder="Inserisci peso al metro quadro es. 30" {...register("peso1")}/>
                                                    {errors.peso1 && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div>

                                            <div className="col-sm-3 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Peso a pz gr"} </label>
                                                    <input className="form-control cleaning-form" name="peso2" type="number" step="0.01" placeholder="Inserisci peso al pezzo es. 4" {...register("peso2")}/>
                                                    {errors.peso2 && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div>

                                            <div className="col-sm-3 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Assorbimento massimo ml/pz"} </label>
                                                    <input className="form-control cleaning-form" name="assorbimento1" type="number" step="0.01" placeholder="Inserisci assorbimento massimo" {...register("assorbimento1")}/>
                                                    {errors.assorbimento1 && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div>

                                            <div className="col-sm-3 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Assorbimento dopo strizzaggio ml/pz"} </label>
                                                    <input className="form-control cleaning-form" name="assorbimento2" type="text" placeholder="Inserisci assorbimento dopo strizzaggio" {...register("assorbimento2")}/>
                                                    {errors.assorbimento2 && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div>

                                            <div className="col-sm-3 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Decitex"} </label>
                                                    <input className="form-control cleaning-form" name="decitex" type="number" step="0.01" placeholder="Inserisci valore decitex" {...register("decitex")}/>
                                                    {errors.decitex && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div>

                                            <div className="col-sm-3 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Cicli lavaggio / vita utile panno"} </label>
                                                    <input className="form-control cleaning-form" name="ciclo" type="number" placeholder="Inserisci cicli lavaggio / vita utile panno" {...register("ciclo")}/>
                                                    {errors.ciclo && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div>

                                            <div className="col-sm-3 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Temperatura massima lavaggio"} </label>
                                                    <input className="form-control cleaning-form" name="temperatura" type="text" placeholder="Inserisci temperatura massima lavaggio" {...register("temperatura")}/>
                                                    {errors.temperatura && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div>

                                            <div className="col-sm-3 col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Codice colore"} </label>
                                                    <Select
                                                      className="cleaning-form"
                                                      placeholder="Scegli la categoria del materiale"
                                                      onChange={e => setCodiceColore(e.value)}
                                                      options={[
                                                        {
                                                          value: 1,
                                                          label: 'Si'
                                                        },
                                                        {
                                                          value: 0,
                                                          label: 'No'
                                                        }
                                                      ]}
                                                      styles={selectStyles}
                                                      required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Certificazioni"} </label>
                                                    <input className="form-control cleaning-form" name="certificazione" type="text" placeholder="Inserisci certificazione es. Idoneo CAM" {...register("certificazione")}/>
                                                    {errors.certificazione && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div>

                                            </div>
                                            </>
                                          )
                                        default:
                                          return null
                                      }
                                    })()}*/}
                                    <span className="required-field">*</span>tutti i campi sono richiesti
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn cleaning-btn-login cleaning-cancel-btn" onClick={() => resetForm()}>{"Annulla"}</button>
                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Aggiungi"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div></> : null}

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Attrezzatura aggiunta con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => history.push("/attrezzature/lista")} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>


        </Fragment>
    );
};

export default AttrezzaturaAdd;
