import React, { useState, useEffect } from 'react';
import logo from '../assets/images/endless-logo.png';
import man from '../assets/images/dashboard/user.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter, useHistory } from "react-router";
import { Link } from "react-router-dom";
import axios from 'axios'
import  {firebase_app, googleProvider, facebookProvider, twitterProvider, githubProvider,Jwt_token } from "../data/config";
import { handleResponse } from "../services/fack.backend";
import { useForm, Controller  } from "react-hook-form";
import { apiEndpoint,Login,LOGIN,YourName,Password,RememberMe,LoginWithAuth0,LoginWithJWT } from '../constant';
import LoginImage from '../assets/images/signup_image.png'
import { TextField, Checkbox } from "@material-ui/core";

const CleaningLogin = () => {

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [isErr, setErr] = useState('')
    let history = useHistory()

    const onSubmit = (data) => {
      let email = data.email;
      let password = data.password;
      const loginJwt = async () => {
        const enter = await axios.post(`${apiEndpoint}/auth/login`, {
          email: email.toLowerCase().trim(),
          password: password.trim()
        })
        if(enter.data.auth === true) {
          localStorage.setItem('jwt', enter.data.token);
          localStorage.setItem("authenticated", enter.data.auth);
          localStorage.setItem("userID", enter.data.userID);
          localStorage.setItem("fname", enter.data.fname);
          localStorage.setItem("lname", enter.data.lname);
          localStorage.setItem("email", enter.data.email)
          localStorage.setItem("IDCartellaMedia", enter.data.IDCartellaMedia)
          localStorage.setItem("step", 0)
          localStorage.setItem("role", enter.data.role)
          //window.location.href = `${process.env.PUBLIC_URL}/dashboard`
          history.push(`${process.env.PUBLIC_URL}/dashboard`)

        } else if(enter.data.auth === false) {
          setErr(enter.data.message)
        }
      }
      loginJwt()
    }

    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="card mt-4 cleaning-login-card">
                                            <div className="card-body">
                                                <div className="text-center">
                                                <div className="text-center">
                                                  <img src={LoginImage} alt="cleaning-image" className="login-image"/>
                                                </div>
                                                    <h4>{LOGIN}</h4>
                                                    <h6>{"Inserisci Username e Password"} </h6>
                                                </div>
                                                <form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0 cleaning-form-label">{"Username"}</label>
                                                        <input className="form-control cleaning-form" type="email" name="email"
                                                            placeholder="Il tuo indirizzo email" {...register("email", { required: true})}/>
                                                        {errors.email && <p className="err-msg-form">Campo richiesto</p>}

                                                    </div>
                                                    <div className="form-group">
                                                      <label className="col-form-label cleaning-form-label">{Password}</label>
                                                      <input className="form-control cleaning-form" type="password" name="password" placeholder="La tua password" {...register("password", { required: true})}/>
                                                      {errors.password && <p className="err-msg-form">Campo richiesto</p>}

                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <button className="btn btn-primary btn-block cleaning-btn-login" type="submit" >{Login}</button>
                                                    </div>
                                                    <div className="form-group form-row mb-2">
                                                        <p className="text-left err-msg ml-1">{isErr}</p>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0">
                                                      <div className="col-md-12">
                                                          <span>Non sei registrato? <Link to={`${process.env.PUBLIC_URL}/pages/sign-up`}> Clicca qui</Link></span>
                                                      </div>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0">
                                                    <div className="col-md-12">
                                                        <span>Password dimenticata? <Link to={`${process.env.PUBLIC_URL}/forgot-password`}>Clicca qui</Link></span>
                                                    </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

export default  CleaningLogin;
