import React, { Fragment, useState, useEffect } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import Slider from 'react-slick';
import Ratings from 'react-ratings-declarative'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, ProductReview, Brand, Availability, AddToCart, BuyNow, ContinueShopping,OutOfStock, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import TreeMenu from 'react-simple-tree-menu';


const CapitoloProfilo = (props) => {

  const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();



      const [isMaterial, setMaterials] = useState({})
      const [allParagrafi, setParagrafi] = useState([{}])
      const [rating,setRating] = useState(0)
      const [loading, setLoading] = useState(false)
      const [viewTree, setTree] = useState([]);
      const [viewTree2, setTree2] = useState();
      const [isOpen1, setIsOpen1] = useState(true);
      const [isOpen2, setIsOpen2] = useState(false);
      const [expanded1, setexpanded1] = useState(true);
      const [expanded2, setexpanded2] = useState(false);
      const [isInitial, setInital] = useState('')
      const [errDel, setErrDel] = useState('')

      const [loadTree, setLoadTree] = useState(false)
      const [modalDel, setModalDel] = useState();
      const { register: registerDel, handleSubmit: handleSubmitDel, formState: { errors: errorsDel } } = useForm();


      let history = useHistory()

      useEffect(() => {
          let mounted = true
          const getMaterial = async () => {
            const material = await axios.get(`${apiEndpoint}/capitoli-progetto/fetch-capitolo/${props.match.params.id}/${localStorage.getItem('userID')}`, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            const paragrafi = await axios.get(`${apiEndpoint}/capitoli-progetto/fetch-paragrafo/${props.match.params.id}/${localStorage.getItem('userID')}`, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            if(mounted) {
              setMaterials(material.data)
              let tree = []
              let paragraph = paragrafi.data
              let lunghezza = paragraph.length
              paragraph.map((item, index) => {
                const getDocs = async () => {
                  const docs = await axios.get(`${apiEndpoint}/capitoli-progetto/fetch-documenti/${props.match.params.id}/${item.IDparagrafo}/${localStorage.getItem('userID')}`, {
                    headers: {
                      "x-access-token": localStorage.getItem('jwt')
                    }
                  })
                  if(index === 0) {
                    setInital(item.Descrizione)
                  }
                  let obj = {
                    key: index,
                    label: item.Descrizione,
                    nodes: docs.data
                  }
                  tree.push(obj)
                  tree.sort((a, b) => (a.key > b.key) ? 1 : -1)
                  let add = [...viewTree, tree]
                  setTree(add)
                }
                getDocs()
                if(index === lunghezza -1) {
                 console.log(viewTree)
                 setLoading(true)
                }
              })
            }
          }
          getMaterial()
          return () => {
            mounted = false
          }
      }, [])

      const viewDocument = (e) => {
          if(e.level !== 0) {
          history.push(`${process.env.PUBLIC_URL}/documenti/dettaglio/${e.id}`)
        }
      }


          const onSubmit = (data) => {
              let formData = new FormData()
              let titolo = data.titolo
              let userID = localStorage.getItem("userID")

              formData.append("titolo", titolo)
              formData.append("id", props.match.params.id)
              formData.append("userID", userID)
              formData.append("admin", localStorage.getItem("email"))

                  //Chiamata api
                  const updateData = async () => {
                      const update = await axios.patch(`${apiEndpoint}/capitoli-progetto/patch-capitolo`, formData, {
                        headers: {
                          "x-access-token": localStorage.getItem('jwt')
                        }
                      })
                      history.push(`${process.env.PUBLIC_URL}/capitoli/lista`)
                    }
                updateData()

          }

      const onDeleteAccount = data => {
           const deleteAccount = async () => {
            const delAcc = await axios.delete(`${apiEndpoint}/capitoli-progetto/delete-capitolo/${props.match.params.id}`, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            history.push(`${process.env.PUBLIC_URL}/capitoli/lista`)
          }
          deleteAccount()
      }


          const toggleDel = () => {
              setModalDel(!modalDel)
          }



      return (
          <Fragment>
              <Breadcrumb title="Dettagli capitolo" parent="Capitolo" />
              <div className="container-fluid">
              {loading ? <>{localStorage.getItem("role") !== "Admin" ? null : <div className="row">
                  <div className="col-lg-12 card">
                      <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="card-header">
                              <h4 className="card-title mb-0">{"Modifica capitolo"}</h4>
                          </div>
                          <div className="card-body">
                              <div className="row">
                              <div className="col-sm-6 col-md-6">
                                  <div className="form-group">
                                      <label className="form-label cleaning-form-label">{"Titolo"} </label>
                                      <input className="form-control cleaning-form" name="titolo" defaultValue={isMaterial.Descrizione} type="text" placeholder="Inserisci titolo es. Documento tecnico ambienti" {...register("titolo")} required/>
                                      {errors.titolo && <p className="err-msg-form">Campo richiesto</p>}
                                  </div>
                              </div>

                              </div>

                              <span className="required-field">*</span>tutti i campi sono richiesti
                          </div>
                          <div className="card-footer text-right">
                              <button className="btn btn-primary cleaning-btn-login" type="submit">{"Modifica"}</button>
                              <button className="btn btn-danger cleaning-btn-login" type="button" onClick={() => toggleDel()}>{"Elimina"}</button>
                          </div>
                      </form>
                  </div>
              </div>}
              {localStorage.getItem("role") === "UTENTI" && localStorage.getItem("userID") === isMaterial.IDutente ? <div className="row">
                  <div className="col-lg-12 card">
                      <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="card-header">
                              <h4 className="card-title mb-0">{"Modifica capitolo"}</h4>
                          </div>
                          <div className="card-body">
                              <div className="row">
                              <div className="col-sm-6 col-md-6">
                                  <div className="form-group">
                                      <label className="form-label cleaning-form-label">{"Titolo"} </label>
                                      <input className="form-control cleaning-form" name="titolo" defaultValue={isMaterial.Descrizione} type="text" placeholder="Inserisci titolo es. Documento tecnico ambienti" {...register("titolo")} required/>
                                      {errors.titolo && <p className="err-msg-form">Campo richiesto</p>}
                                  </div>
                              </div>

                              </div>

                              <span className="required-field">*</span>tutti i campi sono richiesti
                          </div>
                          <div className="card-footer text-right">
                              <button className="btn btn-primary cleaning-btn-login" type="submit">{"Modifica"}</button>
                              <button className="btn btn-danger cleaning-btn-login" type="button" onClick={() => toggleDel()}>{"Elimina"}</button>
                          </div>
                      </form>
                  </div>
              </div> : null}
                  <div className="col-lg-12 card">
                      <div className="row">
                        <div className="col ml-4 mb-4">
                        </div>
                      </div>
                      <div className="row">
                      <div className="col ml-4 cleaning-treeview">
                        <TreeMenu
                        data={viewTree[0]}
                        hasSearch={true}
                        initialOpenNodes={[isInitial]}
                        onClickItem={e => viewDocument(e)}
                        />
                       </div>
                      </div>
                  </div></> : <>{ loading || localStorage.getItem("role") === 'Admin' ? <div className="row">
                      <div className="col-lg-12 card">
                          <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="card-header">
                                  <h4 className="card-title mb-0">{"Modifica capitolo"}</h4>
                              </div>
                              <div className="card-body">
                                  <div className="row">
                                  <div className="col-sm-6 col-md-6">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Titolo"} </label>
                                          <input className="form-control cleaning-form" name="titolo" defaultValue={isMaterial.Descrizione} type="text" placeholder="Inserisci titolo es. Documento tecnico ambienti" {...register("titolo")} required/>
                                          {errors.titolo && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                  </div>

                                  </div>

                                  <span className="required-field">*</span>tutti i campi sono richiesti
                              </div>
                              <div className="card-footer text-right">
                              <button className="btn cleaning-btn-login cleaning-cancel-btn" type="button" onClick={() => history.push(`${process.env.PUBLIC_URL}/capitoli/lista`)}>{"Annulla"}</button>

                                  <button className="btn btn-primary cleaning-btn-login" type="submit">{"Modifica"}</button>
                                  <button className="btn btn-danger cleaning-btn-login" type="button" onClick={() => toggleDel()}>{"Elimina"}</button>
                              </div>
                          </form>
                      </div>
                  </div> : 'Non sono presenti paragrafi e documenti associati a questo capitolo...' }</>}
              </div>

              {/* Modale per eliminazione account*/}
              <Modal isOpen={modalDel} toggle={toggleDel} centered={true} modalClassName="cleaning-modal">
                <form onSubmit={handleSubmitDel(onDeleteAccount)}>
                  <ModalHeader toggle={toggleDel}>Attenzione!</ModalHeader>
                  <ModalBody>
                      <p>Stai per <strong>eliminare</strong> il capitolo, sei sicuro di voler procedere?</p>
                  </ModalBody>
                  <ModalFooter>
                  <Button onClick={toggleDel} className="cleaning-cancel-btn">{"Annulla"}</Button>

                      <Button color="danger" type="submit" className="btn-cleaning">{"Elimina"}</Button>
                  </ModalFooter>
                </form>
              </Modal>
              {/* Modale per eliminazione account*/}
          </Fragment>
      );
  }

export default CapitoloProfilo;
