import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import {FaCloudDownloadAlt, FaExternalLinkSquareAlt, FaEye, FaFileInvoice, FaTrashAlt} from "react-icons/fa"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ReactTooltip from "react-tooltip"



const UsersLista = () => {

    let history = useHistory()

    const [data,setData] = useState([])
    const [clientiPotenziali, setClientiPotenziali] = useState([])
    const [loading, setLoading] = useState(false)
    const [fileImage, setFile] = useState(null)
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { register: registerDel, handleSubmit: handleSubmitDel, formState: { errors: errorsDel } } = useForm();
    const { register: registerPwd, handleSubmit: handleSubmitPwd, formState: { errors: errorsPwd }, reset } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [customerCode, setCustomerCode] = useState('')
    const [isMoved, setMoved] = useState(false)


    function moveToProfile(code) {
      setCustomerCode(code)
      setMoved(true)
    }

    const { SearchBar } = Search;
    const columns = [{
      dataField: 'RagioneSociale1',
      text: 'Ragione Sociale'
    },
    {
      dataField: 'NomeUtente',
      text: 'Nome'
    },
    {
      dataField: 'CognomeUtente',
      text: 'Cognome'
    },
    {
      dataField: 'IDutente',
      text: 'Email'
    },
    {
      dataField: '',
      text: 'Opzioni',
      formatter: (value, row) => (
        <>
          <ReactTooltip place="top" effect="solid"/>
          <button className="btn btn-primary" style={{marginRight: '1vw', marginBottom: '1vw'}} data-tip="Vedi utente" onClick={() => toggleUser(row)}><FaEye /></button>
          <button className="btn btn-danger" style={{marginRight: '1vw', marginBottom: '1vw'}} data-tip="Elimina utente" onClick={() => toggleDel(row)}><FaTrashAlt /></button>
        </>
      ),
    }
  ];

  const [modalDel, setModalDel] = useState();
  const [isDeleted, setDeleted] = useState(null)
  const [modalUser, setModalUser] = useState();
  const [selectedUser, setSelectedUser] = useState(null)


  const toggleDel = (id) => {
      setModalDel(!modalDel)
      setDeleted(id)
  }

  const toggleUser = (id) => {
      setModalUser(!modalUser)
      setSelectedUser(id)
  }


    useEffect(() => {
        if(localStorage.getItem("role") !== 'Admin') {
          history.push(`${process.env.PUBLIC_URL}/`)
        }
        let mounted = true
        const getUser = async () => {
          const user = await axios.get(`${apiEndpoint}/user/fetch`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          if(mounted) {

              setClientiPotenziali(user.data)
              setLoading(true)
          }
        }
        getUser()
        return () => {
          mounted = false
        }
    }, [])

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: false,
      hideSelectColumn: true,
      hideSelectAll: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        moveToProfile(row.IDcliente)
      }
    }



      const onDeleteAccount = data => {
        if(isDeleted) {
          const deleteAccount = async () => {
           const delAcc = await axios.delete(`${apiEndpoint}/user/delete/${isDeleted.ID}/${isDeleted.IDCartellaMedia}`, {
             headers: {
               "x-access-token": localStorage.getItem('jwt')
             }
           })
           setDeleted(null)
           window.location.reload()
         }
         deleteAccount()
        }

      }






    if(isMoved) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/clienti-potenziali/profilo/${customerCode}`} />)
    }


    return (
        <Fragment>
            <Breadcrumb title="Lista" parent="Utenti" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                      {loading ?   <div className="card">

                            <div className="card-body datatable-react">
                            <ToolkitProvider
                            keyField='ID'
                            data={ clientiPotenziali }
                            columns={ columns }
                            search
                            >
                            {
                              props => (
                                <div>
                                  <div className="d-flex flex-row justify-content-between w-100 mb-2"><SearchBar
                                  { ...props.searchProps }
                                  placeholder="Cerca"
                                  className="cleaning-form"
                                  />
                                  <div className="text-left">
                                  </div>
                                  </div>
                                  <BootstrapTable
                                    { ...props.baseProps }
                                    striped
                                    hover
                                    condensed
                                    keyField="ID"
                                    pagination={ paginationFactory({
                                      showTotal: false,
                                      firstPageText: 'Inizio', // the text of first page button
                                      prePageText: 'Prec', // the text of previous page button
                                      nextPageText: 'Succ', // the text of next page button
                                      lastPageText: 'Fine', // the text of last page button
                                      nextPageTitle: 'Vai al successivo', // the title of next page button
                                      prePageTitle: 'Vai al precedente', // the title of previous page button
                                      firstPageTitle: 'Vai al primo', // the title of first page button
                                      lastPageTitle: 'Vai all\'ultmo', // the title of last page button
                                    }) }
                                    selectRow={ selectRow }
                                  />
                                </div>
                              )
                            }
                            </ToolkitProvider>
                            </div>
                        </div>: <div className="spinner-loading-cleaning"><div className="loader-box">
                          <div className="loader">
                            <div className="line bg-success"></div>
                            <div className="line bg-success"></div>
                            <div className="line bg-success"></div>
                            <div className="line bg-success"></div>
                          </div>
                        </div></div>}
                    </div>
                </div>
            </div>

            {/* Modale per eliminazione account*/}
            {isDeleted ? <Modal isOpen={modalDel} toggle={toggleDel} centered={true} modalClassName="cleaning-modal">
              <form onSubmit={handleSubmitDel(onDeleteAccount)}>
                <ModalHeader toggle={toggleDel}>Attenzione!</ModalHeader>
                <ModalBody>
                    <p>Stai per <strong>eliminare</strong> l'utente, sei sicuro di voler procedere?</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" type="submit" className="btn-cleaning">{"Elimina"}</Button>
                </ModalFooter>
              </form>
            </Modal> : null}
            {/* Modale per eliminazione account*/}
            {/* Modale per eliminazione account*/}
            {selectedUser ? <Modal isOpen={modalUser} toggle={() => {
              toggleUser()
              setSelectedUser(null)
            }} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={() => {
                  toggleUser()
                  setSelectedUser(null)
                }}>Dettagli</ModalHeader>
                <ModalBody>
                    <p>
                      <strong>Ragione sociale: </strong> {selectedUser.RagioneSociale1}<br/>
                      <strong>Nome: </strong> {selectedUser.NomeUtente}<br/>
                      <strong>Cognome: </strong> {selectedUser.CognomeUtente}<br/>
                      <strong>Email: </strong> {selectedUser.IDutente}<br/>
                      <strong>Telefono: </strong> {selectedUser.telefono}<br/>
                      <strong>indirizzo: </strong> {selectedUser.indirizzo + " " + selectedUser.cap + " " + selectedUser.città + " " + selectedUser.provincia}<br/>
                      <strong>PIVA: </strong> {selectedUser.IDfiscale}<br/>
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="button" onClick={() => {
                      toggleUser()
                      setSelectedUser(null)
                    }} className="btn-cleaning" >{"Ok"}</Button>
                </ModalFooter>
            </Modal> : null}
            {/* Modale per eliminazione account*/}

        </Fragment>
    );
};

export default UsersLista;
