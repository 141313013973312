import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';


const TemplatesLista = () => {

    const [data,setData] = useState([])
    const [allTemplates, setTemplates] = useState([{}])
    const [loading, setLoading] = useState(false)
    const history = useHistory()


    useEffect(() => {
        let mounted = true
        const getUser = async () => {
          const documenti = await axios.get(`${apiEndpoint}/templates/fetch/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          setTemplates(documenti.data)
          setLoading(true)
        }
        getUser()
        return () => {
          mounted = false
        }
    }, [])

    const { SearchBar } = Search;
    const columns = [
    {
      dataField: 'descrizione',
      text: 'Descrizione',
      formatter: (value, row) => (
          <span style={{cursor: 'pointer'}}>{row.descrizione}</span>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
        if(e.type === 'click' && row.IDutente == null) {
          history.push(`${process.env.PUBLIC_URL}/templates/dettaglio/${row.IDtemplate}`)
        } else if(e.type === 'click' && row.IDutente != null) {
          history.push(`${process.env.PUBLIC_URL}/templates/modifica/${row.IDtemplate}`)
        }
        }
      }
    }
  ];


    return (
        <Fragment>
            <Breadcrumb title="Tutti i templates" parent="Templates" />
            {loading ? <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                          <div className="card-body datatable-react">
                            <div className="row">

                            <div className="card-body datatable-react">
                            <ToolkitProvider
                            keyField='ID'
                            data={ allTemplates }
                            columns={ columns }
                            search
                            >
                            {
                              props => (
                                <div>
                                  <div className="d-flex flex-row justify-content-between w-100 mb-2"><SearchBar
                                  { ...props.searchProps }
                                  placeholder="Cerca"
                                  className="cleaning-form text-left"
                                  />
                                  <div className="text-right">
                                      <Link to={`${process.env.PUBLIC_URL}/templates/aggiungi`} className="btn btn-success cleaning-btn-login" type="submit">{"Aggiungi"}</Link>
                                  </div>
                                  </div>
                                  <BootstrapTable
                                    { ...props.baseProps }
                                    striped
                                    hover
                                    condensed
                                    keyField="ID"
                                    pagination={ paginationFactory({
                                      showTotal: false,
                                      firstPageText: 'Inizio', // the text of first page button
                                      prePageText: 'Prec', // the text of previous page button
                                      nextPageText: 'Succ', // the text of next page button
                                      lastPageText: 'Fine', // the text of last page button
                                      nextPageTitle: 'Vai al successivo', // the title of next page button
                                      prePageTitle: 'Vai al precedente', // the title of previous page button
                                      firstPageTitle: 'Vai al primo', // the title of first page button
                                      lastPageTitle: 'Vai all\'ultmo', // the title of last page button
                                    }) }
                                  />
                                </div>
                              )
                            }
                            </ToolkitProvider>
                            </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div> : null}
        </Fragment>
    );
};

export default TemplatesLista;
