import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import Select from 'react-select';



const OperazioniAggiungi = () => {

    const [data,setData] = useState([])
    const [userData, setUserData] = useState({})
    const [allAreas, setAreas] = useState([{}])
    const [allAmbience, setAmbience] = useState([{}])
    const [loadingAmbience, setLoadingAmbience] = useState(true)
    const [loading, setLoading] = useState(true)
    const [loadingAtt, setLoadingAtt] = useState(true)
    const [fileImage, setFile] = useState(null)
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [isArea, setArea] = useState('')
    const [isAmbiente, setAmbiente] = useState('')
    const [isMateriale, setMateriale] = useState([])
    const [isAttrezzatura, setValueAttrezzatura] = useState([])
    const [isDPI, setValueDPI] = useState([])
    const [isMacchinario, setValueMacchinari] = useState([])
    const [isFrequenza, setFrequenza] = useState('')
    const [allAttrezzatura, setAttrezzatura] = useState([{}])
    const [allMateriali, setMateriali] = useState([{}])
    const [allMacchinari, setMacchinari] = useState([{}])
    const [allDPI, setDPI] = useState([{}])
    const [isUnita, setUnita] = useState('')
    const [isSuperfici, setSuperfici] = useState([{}])
    const [isSup, setSuperficie] = useState('')
    useEffect(() => {
      const getAllData = async () => {
        const aree = axios.get(`${apiEndpoint}/aree-ambienti/fetch-aree-ambienti`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const materiali = axios.get(`${apiEndpoint}/operazioni/fetch-materiali`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const attrezzature = axios.get(`${apiEndpoint}/operazioni/fetch-attrezzature`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const macchinari = axios.get(`${apiEndpoint}/operazioni/fetch-macchinari`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const dpi = axios.get(`${apiEndpoint}/operazioni/fetch-dpi`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const superfici = axios.get(`${apiEndpoint}/operazioni/fetch-superfici`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })

        aree.then(res => {
          let are = res.data
          let areArray = []
          are.map((item, index) => {
            let obj = {
              value: item.ambiente,
              label: item.ambiente
            }
            areArray.push(obj)
          })
          setAreas(areArray)
        })

        superfici.then(res => {
          let sup = res.data
          let supArray = []
          sup.map((item, index) => {
            let obj = {
              value: item.IDsuperficie,
              label: item.superficie
            }
            supArray.push(obj)
          })
          setSuperfici(supArray)
        })

        materiali.then(res => {
          let mat = res.data
          let matArray = []
          mat.map((item, index) => {
            let obj = {
              value: item.IDarticolo,
              label: item.descrizione1
            }
            matArray.push(obj)
          })
          setMateriali(matArray)
        })
        dpi.then(res => {
          let dp = res.data
          let dpArray = []
          dp.map((item, index) => {
            let obj = {
              value: item.IDarticolo,
              label: item.descrizione1
            }
            dpArray.push(obj)
          })
          setDPI(dpArray)
        })
        attrezzature.then(res => {
          let att = res.data
          let attArray = []
          att.map((item, index) => {
            let obj = {
              value: item.IDattrezzatura,
              label: item.Descrizione
            }
            attArray.push(obj)
          })
          setAttrezzatura(attArray)
          setLoadingAtt(false)

        })
        macchinari.then(res => {
          let mac = res.data
          let macArray = []
          mac.map((item, index) => {
            let obj = {
              value: item.IDattrezzatura,
              label: item.Descrizione
            }
            macArray.push(obj)
          })
          setMacchinari(macArray)
        })
      }
      console.log(isArea)
      console.log(allAmbience)
      getAllData()
    }, [isArea, allAmbience])

    const toggle = () => {
        setModal(!modal)
    }

    const toggleDel = () => {
        setModalDel(!modalDel)
    }

    const toggleImg = () => {
        setModalImg(!modalImg)
    }

    const toggleImgSee = () => {
        setModalImgSee(!modalImgSee)
    }

    const frequenze = [
      {
        value: 2,
        label: '1 volta a settimana'
      },
      {
        value: 3,
        label: '2 volte a settimana'
      },
      {
        value: 4,
        label: '3 volte a settimana'
      },
      {
        value: 14,
        label: '4 volte a settimana'
      },
      {
        value: 1,
        label: '5 volte settimana'
      },
      {
        value: 15,
        label: '6 volte a settimana'
      },
      {
        value: 16,
        label: '7 volte a settimana'
      },
      {
        value: 5,
        label: 'Decadale'
      },
      {
        value: 6,
        label: 'Quindicinale'
      },
      {
        value: 7,
        label: 'Mensile'
      },
      {
        value: 8,
        label: 'Bimestrale'
      },
      {
        value: 9,
        label: 'Trimestrale'
      },
      {
        value: 10,
        label: 'Quadrimestrale'
      },
      {
        value: 11,
        label: 'Semestrale'
      },
      {
        value: 12,
        label: 'Annuale'
      },
      {
        value: 13,
        label: 'Spot'
      },

    ]


    const unity = [, {
      value: 'MQ',
      label: 'Metri quadri'
    },{
      value: 'PUNTI',
      label: 'Punti'
    }]

    const [isErrObb, setErrObb] = useState("")

    const toggleDD = () => setDropdownOpen(prevState => !prevState)

    const onSubmit = (data) => {
      let descrizione = data.operazione
      let userID = localStorage.getItem("userID")
      let area = isArea
      let ambiente = isAmbiente
      let frequenza = isFrequenza
      let materiale = isMateriale
      let attrezzatura = isAttrezzatura
      let dpi = isDPI
      let macchinario = isMacchinario
      let resa = data.resa

      if(isArea && isAmbiente && isFrequenza && isSup) {
        const updateData = async () => {
            const update = await axios.post(`${apiEndpoint}/operazioni/create`, {
              descrizione: descrizione.replace(/'/g, ' '),
              superficie: isSup,
              userID: userID,
              ambiente: area,
              area: ambiente,
              frequenza: frequenza,
              materiale: materiale,
              attrezzatura: attrezzatura,
              macchinario: macchinario,
              resa : resa,
              dpi: dpi
            }, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            console.log(update.data)
            reset()
            toggle()
          }
          updateData()
      } else {
        setErrObb('Campo obbligatorio')
      }

    }

    const selectStyles = {
      control: styles => ({ ...styles, borderRadius: '10px'})
    }

    const resetForm = () => {
      reset()
    }

    const handleChangeArea = e => {
      setArea(e.value)
      const ambiente = axios.get(`${apiEndpoint}/aree-ambienti/fetch-ambienti/${e.value}`, {
        headers: {
          "x-access-token": localStorage.getItem('jwt')
        }
      })
      ambiente.then(res => {
        let amb = res.data
        let ambArray = []
        amb.map((item, index) => {
          let obj = {
            value: item.area,
            label: item.area
          }
          ambArray.push(obj)
        })
        setAmbience(ambArray)
        setLoadingAmbience(false)
      })
    }

    const handleChangeAmbiente = e => {
      setAmbiente(e.value)
    }

    const handleChangeMateriali = e => {
      setMateriale(e)
    }

    const handleChangeFrequenza = e => {
      setFrequenza(e.value)
    }

    const handleChangeAttrezzature = e => {
      setValueAttrezzatura(e)
    }

    const handleChangeDPI = e => {
      setValueDPI(e)
    }

    const handleChangeMacchinari = e => {
      setValueMacchinari(e)
    }

    const handleChangeUnita = e => {
      setUnita(e.value)
    }

    const handleChangeSuperficie = e => {
      setSuperficie(e.value)
    }

    const setFileUpload = (target) => {
      if(target) {
        let newImage = target.files[0]
        let imageSize = newImage.size
        let imageType = newImage.type
        if(imageType === 'image/png' || imageType === 'image/jpeg') {
          if(imageSize <= 2000000) {
            setFile(newImage)
            setDisabled(false)
          } else {
            window.alert('Dimensione massima consentita 2MB.')
          }
        } else {
          window.alert('Puoi caricare solo immagini in formato .png, .jpeg, .jpg')
        }
      }
    }

    const cancelUpload = () => {
      toggleImg()
      setFile(null)
    }

    if(isLoggedOut) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/login`} />)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Operazioni" title="Aggiungi" />
            {loading ? <><div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12 card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Nuova operazione"}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Area"} <span className="required-field">*</span></label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci area"
                                              onChange={(e) => handleChangeArea(e)}
                                              options={allAreas}
                                              styles={selectStyles}
                                              required
                                            />
                                            {isErrObb ? <p className="err-msg-form">{isErrObb}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Ambiente"} <span className="required-field">*</span></label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci ambiente"
                                              onChange={(e) => handleChangeAmbiente(e)}
                                              options={allAmbience}
                                              styles={selectStyles}
                                              isLoading={loadingAmbience}
                                              required
                                            />
                                            {isErrObb ? <p className="err-msg-form">{isErrObb}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Operazione"}<span className="required-field">*</span></label>
                                            <input className="form-control cleaning-form" name="operazione" type="text" placeholder="es. La mia operazione" {...register("operazione", {required: true})}/>
                                            {errors.operazione && <p className="err-msg-form">Campo obbligatorio</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Frequenza"} <span className="required-field">*</span></label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci frequenza"
                                              onChange={(e) => handleChangeFrequenza(e)}
                                              options={frequenze}
                                              styles={selectStyles}
                                            />
                                            {isErrObb ? <p className="err-msg-form">{isErrObb}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Resa (mq/h)"} <span className="required-field">*</span></label>
                                            <input className="form-control cleaning-form" name="resa" type="text" placeholder="Inserisci resa" {...register("resa", {required: true})}/>
                                            {errors.resa && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label cleaning-form-label">{"Superificie"}<span className="required-field">*</span></label>
                                        <Select
                                          className="cleaning-form"
                                          placeholder="Inserisci superficie"
                                          options={isSuperfici}
                                          styles={selectStyles}
                                          onChange={e => handleChangeSuperficie(e)}
                                        />
                                        {isErrObb ? <p className="err-msg-form">{isErrObb}</p> : null}
                                    </div>
                                    </div>
                                    </div>

                                    <hr />
                                    <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Materiali"} </label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci materiali"
                                              onChange={e => handleChangeMateriali(e)}
                                              options={allMateriali}
                                              styles={selectStyles}
                                              isMulti
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Attrezzature"} </label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci attrezzature"
                                              isLoading={loadingAtt}
                                              options={allAttrezzatura}
                                              styles={selectStyles}
                                              onChange={e => handleChangeAttrezzature(e)}
                                              isMulti
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"D.P.I"} </label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci D.P.I"
                                              options={allDPI}
                                              styles={selectStyles}
                                              onChange={e => handleChangeDPI(e)}
                                              isMulti
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Macchinari"}</label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci macchinari"
                                              options={allMacchinari}
                                              styles={selectStyles}
                                              onChange={(e) => handleChangeMacchinari(e)}
                                              isMulti
                                            />
                                        </div>
                                    </div>

                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <Link to={'/operazioni/lista'} className="btn cleaning-btn-login cleaning-cancel-btn" onClick={() => resetForm()}>{"Annulla"}</Link>
                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Aggiungi"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div></> : null}

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Operazione aggiunta con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={toggle} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>


        </Fragment>
    );
};

export default OperazioniAggiungi;
