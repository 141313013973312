export const apitodoboard = {
    lanes: [
        {
            id: 1,
            title: 'Todo',
            cards: [
                {
                    id: 2,
                    title:"Test Sidebar",
                    date:"24/7/20",
                    priority:"Argent",
                    img:require("../assets/images/user/3.jpg"),
                    company:"Themeforest, australia",
                    rate:"+5",
                    customer_img1:require("../assets/images/user/3.jpg"),
                    customer_img2:require("../assets/images/user/1.jpg"),
                    customer_img3:require("../assets/images/user/5.jpg"),
                },
                {
                    id: 3,
                    title:"Test Sidebar",
                    date:"24/7/20",    
                    priority:"Argent",
                    img:require("../assets/images/user/3.jpg"),
                    company:"Pixelstrap, New york",
                    rate:"+5",
                    customer_img1:require("../assets/images/user/3.jpg"),
                    customer_img2:require("../assets/images/user/1.jpg"),
                    customer_img3:require("../assets/images/user/5.jpg"),
                }
            ]
        },
        {
            id: 4,
            title: 'Working',
            cards: [
                {
                    id: 5,
                    title:"Test Sidebar",
                    date:"24/7/20",
                    priority:"Argent",
                    backgroundImg:require("../assets/images/other-images/maintenance-bg.jpg"),
                    img:require("../assets/images/user/3.jpg"),
                    company:"Themeforest, australia",
                    rate:"+5",
                    customer_img1:require("../assets/images/user/3.jpg"),
                    customer_img2:require("../assets/images/user/1.jpg"),
                    customer_img3:require("../assets/images/user/5.jpg"),
                },
                {
                    id: 6,
                    title:"Test Sidebar",
                    date:"24/7/20",    
                    priority:"Argent",
                    img:require("../assets/images/user/3.jpg"),
                    company:"Pixelstrap, New york",
                    rate:"+5",
                    customer_img1:require("../assets/images/user/3.jpg"),
                    customer_img2:require("../assets/images/user/1.jpg"),
                    customer_img3:require("../assets/images/user/5.jpg"),
                }
            ]
        },
        {
            id: 7,
            title: 'Done',
            cards: [
                {
                    id: 8,
                    title:"Test Sidebar",
                    date:"24/7/20",
                    priority:"Argent",
                    img:require("../assets/images/user/3.jpg"),
                    company:"Themeforest, australia",
                    rate:"+5",
                    customer_img1:require("../assets/images/user/3.jpg"),
                    customer_img2:require("../assets/images/user/1.jpg"),
                    customer_img3:require("../assets/images/user/5.jpg"),
                },
                {
                    id: 9,
                    title:"Test Sidebar",
                    date:"24/7/20",    
                    priority:"Argent",
                    img:require("../assets/images/user/3.jpg"),
                    company:"Pixelstrap, New york",
                    rate:"+5",
                    customer_img1:require("../assets/images/user/3.jpg"),
                    customer_img2:require("../assets/images/user/1.jpg"),
                    customer_img3:require("../assets/images/user/5.jpg"),
                }
            ]
        }
    ]
}
