import React, { Fragment,useState,useEffect, useRef } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom'
import Breadcrumb from '../../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Table } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../../constant'
import  Avatar  from '../../../assets/images/avtar/avatar-cleaning.png'
import Word from '../../../assets/images/word-thumb.png'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import Select from 'react-select';



const RiepilogoFinale = (props) => {

    const [data,setData] = useState([])
    const [allTemplates, setTemplates] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [totMan, setTotMan] = useState(0)
    const [mesiTotale, setMesiTotale] = useState(0)
    const [isUtile, setUtile] = useState(0)
    const [totMat, setTotMat] = useState(0)
    const [totAtt, setTotAtt] = useState(0)
    const [isCosto, setCosto] = useState(0)
    const [isTotaleOre, setTotaleOre] = useState(0)
    const [isSpeseGenerali, setSpeseGenerali] = useState(0)
    const [isSpeseGeneraliPerc, setSpeseGeneraliPerc] = useState(0)
    const [isVerifichePerc, setVerifichePerc] = useState(0)
    const [isCostoVerifiche, setCostoVerifiche] = useState(0)
    const [isCostoSicurezza, setCostoSicurezza] = useState(0)
    const [isCostoSicurezzaPerc, setCostoSicurezzaPerc] = useState(0)
    const [isCostoAvviamento, setCostoAvviamento] = useState(0)
    const [isCostoAvviamentoPerc, setCostoAvviamentoPerc] = useState(0)
    const [isCostoFormazione, setCostoFormazione] = useState(0)
    const [isCostoFormazionePerc, setCostoFormazionePerc] = useState(0)
    const [isCostoIndiretti, setCostoIndiretti] = useState(0)
    const [isCostoIndirettiPerc, setCostoIndirettiPerc] = useState(0)
    const [isPrezzoDeciso, setPrezzoDeciso] = useState(0)
    const [isPrezzoConcordato, setPrezzoConcordato] = useState(0)
    const [isCanoneOra, setCanoneOra] = useState(0)
    const [isCanoneMQ, setCanoneMQ] = useState(0)
    const [isCanoneGiorno, setCanoneGiorno] = useState(0)
    const [isRibasso, setRibasso] = useState(0)
    const [isOreGiornaliere, setOreGiornaliere] = useState(0)
    const [isPercOreGiornaliere, setPercOreGiornaliere] = useState(0)
    const [isOrePeriodiche, setOrePeriodiche] = useState(0)
    const [isPercOrePeriodiche, setPercOrePeriodiche] = useState(0)
    const [isBaseAsta, setBaseAsta] = useState(0)
    const [isTotMQ, setTotMQ] = useState(0)
    const [isMesi, setMesi] = useState(0)
    const [isVivo, setVivo] = useState(0)
    const [isCostoMat, setCostoMat] = useState(0)
    const [isTotInterventi, setTotInterventi] = useState(0)
    const [isMaxGiorno, setMaxGiorno] = useState(0)


    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();

    const history = useHistory()


    useEffect(() => {
        console.log(props.current)
        let mounted = true
        const getUser = async () => {
          const documenti = await axios.get(`${apiEndpoint}/preventivi/fetch/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })

          const operazioni = await axios.get(`${apiEndpoint}/preventivi/fetch-preventivo-operazioni-finale`, {
            params: {
              id: props.current.ID
            },
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          const materiale = await axios.get(`${apiEndpoint}/preventivi/fetch-preventivo-prodotti-counter`, {
            params: {
              id: props.current.ID
            },
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          let opz = operazioni.data
          console.log(opz)
          let v = 0
          let tm = 0
          const giorni = opz.map(object => {
            return object.totInterventiContrattuali
          })
          const maxGiorno = Math.max(...giorni)
          setMaxGiorno(maxGiorno)
          opz.map(item => {
            console.log(item.totOreContrattuali)
            //setTotMan((totMan + Math.round(item.totOreContrattuali * item.costoOrario)).toFixed(2))
            tm = tm +  parseFloat(Math.round(item.totOreContrattuali * item.costoOrario).toFixed(2))
            setTotaleOre(isTotaleOre + parseFloat(item.totOreContrattuali))
            setTotInterventi(isTotInterventi + parseFloat(item.totInterventiContrattuali))
            setTotMQ(isTotMQ + parseFloat(item.mq))
            //setCosto(isCosto + parseFloat(totMan + (item.totOreContrattuali * item.costoOrario)))
            //setVivo(isVivo + parseFloat(totMan + (item.totOreContrattuali * item.costoOrario)))
            v = v + parseFloat(Math.round(item.totOreContrattuali * item.costoOrario).toFixed(2))
            if(parseFloat(item.codiceFrequenza) === 1 || parseFloat(item.codiceFrequenza) === 14 || parseFloat(item.codiceFrequenza) === 2 || parseFloat(item.codiceFrequenza) === 3 || parseFloat(item.codiceFrequenza) === 4 || parseFloat(item.codiceFrequenza) === 15 || parseFloat(item.codiceFrequenza) === 16) {
              setOreGiornaliere(isOreGiornaliere + parseFloat(item.totOreContrattuali))
            } else {
              setOrePeriodiche(isOrePeriodiche + parseFloat(item.totOreContrattuali))
            }
          })
          console.log(tm)

          setTotMan(tm)

          setTemplates(documenti.data)
          let start_costo = 0
          if(props.current.costoSpeseGenerali) {
            setSpeseGenerali(props.current.costoSpeseGenerali)
            setSpeseGeneraliPerc(props.current.percSpeseGenerali)
            start_costo = start_costo + parseFloat(props.current.costoSpeseGenerali)
          }
          if(props.current.costoVerifiche) {
            setCostoVerifiche(props.current.costoVerifiche)
            setVerifichePerc(props.current.percVerifiche)
            start_costo = start_costo + parseFloat(props.current.costoVerifiche)
          }
          if(props.current.costoSicurezza) {
            setCostoSicurezza(props.current.costoSicurezza)
            setCostoSicurezzaPerc(props.current.percSicurezza)
            start_costo = start_costo + parseFloat(props.current.costoSicurezza)
          }
          if(props.current.costoAvviamento) {
            setCostoAvviamento(props.current.costoAvviamento)
            setCostoAvviamentoPerc(props.current.percAvviamento)
            start_costo = start_costo + parseFloat(props.current.costoAvviamento)

          }
          if(props.current.costoFormazione) {
            setCostoFormazione(props.current.costoFormazione)
            setCostoFormazionePerc(props.current.percFormazione)
            start_costo = start_costo + parseFloat(props.current.costoFormazione)
          }
          if(props.current.costoAltriIndiretti) {
            setCostoIndiretti(props.current.costoAltriIndiretti)
            setCostoIndirettiPerc(props.current.percAltriIndiretti)
            start_costo = start_costo + parseFloat(props.current.costoAltriIndiretti)
          }

          setCosto(start_costo)
          setLoading(true)

          const getUser2 = async () => {
            const prodotti = await axios.get(`${apiEndpoint}/preventivi/fetch-preventivo-prodotti`, {
              params: {
                id: props.current.ID
              },
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            const operazioni = await axios.get(`${apiEndpoint}/preventivi/fetch-preventivo-prodotti-counter`, {
              params: {
                id: props.current.ID
              },
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            let prod = operazioni.data
            var value = 0
            var totm = 0
            var totat = 0
              prod.map(item => {
                if(item.IDarticolo) {
                  value = value + parseFloat(item.totInterventiContrattuali[1] * item.quantità * item.costoProdotto * ((100 - parseFloat(props.current.ScontoDET)) / 100))
                  totm = totm + parseFloat(item.quantità * item.costoProdotto * ((100 - parseFloat(props.current.ScontoDET)) / 100))
                  //setTotMat(parseFloat(totMat + parseFloat(item.totInterventiContrattuali[1] * item.quantità * item.costoProdotto * ((100 - parseFloat(props.current.ScontoDET)) / 100))))
                  //setCostoMat(isCostoMat + value)
                  v = v + value
                }
                if(item.IDattrezzatura) {
                  value = value + parseFloat(item.totInterventiContrattuali[1] * item.quantità * item.costoAttrezzatura * ((100 - parseFloat(props.current.ScontoATT)) / 100))
                  totat = totat + parseFloat(item.quantità * item.costoAttrezzatura * ((100 - parseFloat(props.current.ScontoATT)) / 100))
                  //setTotAtt(parseFloat(totAtt + parseFloat(item.totInterventiContrattuali[1] * item.quantità * item.costoAttrezzatura * ((100 - parseFloat(props.current.ScontoATT)) / 100))))
                  //setCostoMat(isCostoMat + value)

                  v = v + value
                }
                if(item.IDdpi) {
                  value = value + parseFloat(item.totInterventiContrattuali[1] * item.quantità * item.costoDPI * ((100 - parseFloat(props.current.ScontoDPI)) / 100))
                  totm = totm + parseFloat(item.quantità * item.costoDPI * ((100 - parseFloat(props.current.ScontoDPI)) / 100))
                  //setTotMat(parseFloat(totMat + parseFloat(item.totInterventiContrattuali[1] * item.quantità * item.costoDPI * ((100 - parseFloat(props.current.ScontoDPI)) / 100))))
                  //setCostoMat(isCostoMat + value)
                  v = v + value

                }
                if(item.IDmacchinario) {
                  value = value + parseFloat(item.totInterventiContrattuali[1] * item.quantità * item.costoMacchinario * ((100 - parseFloat(props.current.ScontoMAC)) / 100))
                  totat = totat + parseFloat(item.quantità * item.costoMacchinario * ((100 - parseFloat(props.current.ScontoMAC)) / 100))
                  //setTotAtt(parseFloat(totAtt + parseFloat(item.totInterventiContrattuali[1] * item.quantità * item.costoMacchinario * ((100 - parseFloat(props.current.ScontoMAC)) / 100))))
                  //setCostoMat(isCostoMat + value)
                  v = v + value
                }
              })
            //  console.log(tm)
            //  console.log(totat)
            //  console.log(totm)
              setVivo(tm + totat+ totm)
              setTotAtt(totat)
              setTotMat(totm)
              setCostoMat(totm + totat)
            //  console.log(v)

            setLoading2(true)
          }
          getUser2(start_costo)
        }
        getUser()

        return () => {
          mounted = false
        }
    }, [])

    useEffect(() => {
        let mounted = true

        return () => {
          mounted = false
        }

    }, [])


  const onSubmit = data => {
    let costo = (isUtile + isCosto + isCostoMat).toFixed(2)
    const finalize = async() => {
      const update = await axios.patch(`${apiEndpoint}/preventivi/finalize`, {
        id: props.current.ID,
        baseAsta: isBaseAsta,
        importoEsito: Number(isPrezzoConcordato) ? Number(isPrezzoConcordato) : Number(costo),
        ribassoEsito: Number(isRibasso),
        totaleMQ: Number(isTotMQ),
        totaleOre: Number(isTotaleOre),
        costoOra: Number(isCanoneOra),
        totaleManodopera: Number(totMan),
        totaleGiorni: Number(isMesi)*30.42,
        percManodopera: Number(Number(totMan) * 100 / Number(isCosto)).toFixed(1),
        percProdotti: Number(Number(totMat) * 100 / Number(isCosto)).toFixed(1),
        percMacchinari: Number(Number(totAtt) * 100 / Number(isCosto)).toFixed(1),
        percSpeseGenerali: Number(isSpeseGeneraliPerc),
        percVerifiche: Number(isVerifichePerc),
        percSicurezza: Number(isCostoSicurezzaPerc),
        percAvviamento: Number(isCostoAvviamentoPerc),
        percFormazione: Number(isCostoFormazionePerc),
        percAltriIndiretti: Number(isCostoIndirettiPerc),
        CostoManodopera: Number(totMan),
        costoProdotti:Number(totMat),
        costoMacchinari: Number(totAtt),
        costoSpeseGenerali: Number(isSpeseGenerali),
        costoVerifiche: Number(isCostoVerifiche),
        costoSicurezza: Number(isCostoSicurezza),
        costoAvviamento: Number(isCostoAvviamento),
        costoFormazione: Number(isCostoFormazione),
        costoAltriIndiretti: Number(isCostoIndiretti),
        prezzoFinale: Number(isTotale),
        prezzoConcordato: Number(isPrezzoConcordato),
        canoneMese: Number(Number(isTotale) / props.current.mesi).toFixed(2),
        canoneOra: Number(Number(isTotale) / Number(isTotaleOre)).toFixed(2),
        canoneMq: Number(Number(isTotale) / Number(isTotMQ)).toFixed(2),
        canoneGiorno: Number(Number(isTotale) / Number(isTotaleOre) * 8).toFixed(2),
        ribasso: isBaseAsta && (isPrezzoConcordato === 0) ? ((isCosto + isCostoMat) - isBaseAsta).toFixed(2) : (isPrezzoConcordato - isBaseAsta).toFixed(2),
        percRibasso: isBaseAsta && (isPrezzoConcordato === 0) ? ((100 - (100 * isBaseAsta / (isCosto + isCostoMat)))).toFixed(2) : ((100 - (100 * isPrezzoConcordato / (isCosto + isCostoMat)))).toFixed(2),
        oreGiornaliere: (Math.round(isOreGiornaliere)).toFixed(2),
        orePeriodiche: Math.round(isOrePeriodiche).toFixed(2),
        percGiornaliere: (Math.round(isOreGiornaliere)).toFixed(2),
        percPeriodiche: Math.round(100*isOrePeriodiche/(isOrePeriodiche+isOreGiornaliere)).toFixed(2)
      } ,{
        headers: {
          "x-access-token": localStorage.getItem('jwt')
        }
      })
      toggle()
    }
    finalize()
  }


  const [manodoperaDisabledEuro, setManodoperaDisabledEuro] = useState(false)
  const [manodoperaDisabledPerc, setManodoperaDisabledEuroPerc] = useState(true)

  const [prodottiDisabledEuro, setProdottiDisabledEuro] = useState(false)
  const [prodottiDisabledPerc, setProdottiDisabledEuroPerc] = useState(true)

  const [attDisabledEuro, setAttDisabledEuro] = useState(false)
  const [attDisabledPerc, setAttDisabledEuroPerc] = useState(true)

  const [sgDisabledEuro, setSGDisabledEuro] = useState(false)
  const [sgDisabledPerc, setSGDisabledEuroPerc] = useState(true)


  const onChangeManodoperaSelect = (value) => {
    if(value === "€") {
      setManodoperaDisabledEuro(false)
      setManodoperaDisabledEuroPerc(true)
    } else if(value === "%") {
      setManodoperaDisabledEuro(true)
      setManodoperaDisabledEuroPerc(false)
    }
  }

  const onChangeProdottiSelect = (value) => {
    if(value === "€") {
      setProdottiDisabledEuro(false)
      setProdottiDisabledEuroPerc(true)
    } else if(value === "%") {
      prodottiDisabledEuro(true)
      prodottiDisabledPerc(false)
    }
  }

  const onChangeAttSelect = (value) => {
    if(value === "€") {
      setAttDisabledEuro(false)
      setAttDisabledEuroPerc(true)
    } else if(value === "%") {
      setAttDisabledEuro(true)
      setAttDisabledEuroPerc(false)
    }
  }

  const onChangeSGSelect = (value) => {
    if(value === "€") {
      setSGDisabledEuro(false)
      setSGDisabledEuroPerc(true)
    } else if(value === "%") {
      setSGDisabledEuro(true)
      setAttDisabledEuroPerc(false)
    }
  }

  const changeTotMan = (value) => {
    setCosto((isCosto + isCostoMat) - totMan + value)
    setTotMan(value)
  }

  const changeTotMat = (value) => {
    setCosto(parseFloat(isCosto + isCostoMat) - parseFloat(totMat) + parseFloat(value))
    setTotMat(parseFloat(value))
  }

  const changeTotAtt = (value) => {
    setCosto(parseFloat(isCosto + isCostoMat) - parseFloat(totAtt) + parseFloat(value))
    setTotAtt(parseFloat(value))
  }

  //Spese generali

  const [disabledSGPerc, setDisabledSGPerc] = useState(false)
  const [disabledSG, setDisabledSG] = useState(true)

  const changeTotSG = (e) => {

    if(e.target.value) {
      var val = parseFloat(e.target.value)
      var old = parseFloat(isSpeseGenerali)
      setCosto(isCosto - old + val)
      setSpeseGenerali(+(Math.round(val + "e+2")  + "e-2"))
      var newPerc = Math.round(val / (isVivo) * 100)
      setSpeseGeneraliPerc(newPerc.toFixed(2))
      setTotale(isTotale - old)
    }

  }

  const changeTotSGPerc = (value) => {
    value = parseFloat(value)
    if(value && value !== 0) {
      if(value && value > 0) {
        value = parseFloat(value)
        let oldSpese = isSpeseGenerali
        setDisabledSGPerc(false)
        setDisabledSG(true)
        let newSpese = isVivo / 100 * value
        newSpese = parseFloat(newSpese)
        newSpese = +(Math.round(newSpese + "e+2")  + "e-2")
        setSpeseGenerali(newSpese)
        setSpeseGeneraliPerc(value)
        setCosto(isCosto + newSpese - oldSpese)
      }
    } else if(value === 0) {
      value = parseFloat(value)
      let oldSpese = isSpeseGenerali
      setDisabledSGPerc(false)
      setDisabledSG(true)
      setSpeseGenerali(0)
      setSpeseGeneraliPerc(0)
      setCosto(isCosto - oldSpese)
    }

  }

  const invertDisabledSG = () => {
    setDisabledSGPerc(!disabledSGPerc)
    setDisabledSG(disabledSGPerc)
  }

  //Verifiche

  const [disabledVerifichePerc, setDisabledVerifichePerc] = useState(false)
  const [disabledVerifiche, setDisabledVerifiche] = useState(true)

  const changeTotVerifichePerc = (value) => {
    value = parseFloat(value)
    if(value && value > 0) {
      let oldSpese = isCostoVerifiche
      let newSpese = isVivo / 100 * value
      newSpese = parseFloat(newSpese)
      newSpese = +(Math.round(newSpese + "e+2")  + "e-2")
      setCostoVerifiche(newSpese)
      setVerifichePerc(value)
      setCosto(isCosto + newSpese - oldSpese)
    } else if(value === 0) {
      let oldSpese = isCostoVerifiche
      setCostoVerifiche(0)
      setVerifichePerc(0)
      setCosto(isCosto - oldSpese)
    }

  }

  const changeTotCV = (value) => {
    if(value) {
      var val = parseFloat(value)
      var old = parseFloat(isCostoVerifiche)
      setCostoVerifiche(+(Math.round(val + "e+2")  + "e-2"))
      setCosto(isCosto - old + val)
      var newPerc = Math.round(value / (isVivo) * 100)
      newPerc = +(Math.round(newPerc + "e+2") + "e-2")
      setVerifichePerc(newPerc)
      setTotale(isTotale - old)
    }

  }

  const invertDisabledVerifiche = () => {
    setDisabledVerifichePerc(!disabledVerifichePerc)
    setDisabledVerifiche(disabledVerifichePerc)
  }

  //Costi sicurezza

  const changeTotCSPerc = (value) => {
    value = parseFloat(value)
    if(value && value > 0) {
      let oldSpese = isCostoSicurezza
      let newSpese = isVivo / 100 * value
      newSpese = parseFloat(newSpese)
      newSpese = +(Math.round(newSpese + "e+2")  + "e-2")
      setCostoSicurezza(newSpese)
      setCostoSicurezzaPerc(value)
      setCosto(isCosto + newSpese - oldSpese)
    } else if(value === 0) {
      let oldSpese = isCostoSicurezza
      setCostoSicurezza(0)
      setCostoSicurezzaPerc(0)
      setCosto(isCosto - oldSpese)
    }

  }

  const changeTotAvviamentoPerc = (value) => {
    value = parseFloat(value)
    if(value && value > 0) {
      let oldSpese = isCostoAvviamento
      let newSpese = isVivo / 100 * value
      newSpese = parseFloat(newSpese)
      newSpese = +(Math.round(newSpese + "e+2")  + "e-2")
      setCostoAvviamento(newSpese)
      setCostoAvviamentoPerc(value)
      setCosto(isCosto + newSpese - oldSpese)
    } else if(value === 0) {
      let oldSpese = isCostoAvviamento
      setCostoAvviamentoPerc(0)
      setCostoAvviamento(0)
      setCosto(isCosto - oldSpese)
    }

  }

  const changeTotCFPerc = (value) => {
    value = parseFloat(value)
    if(value && value > 0) {
      let oldSpese = isCostoFormazione
      let newSpese = isVivo / 100 * value
      newSpese = parseFloat(newSpese)
      newSpese = +(Math.round(newSpese + "e+2")  + "e-2")
      setCostoFormazione(newSpese)
      setCostoFormazionePerc(value)
      setCosto(isCosto + newSpese - oldSpese)
    } else if(value === 0) {
      let oldSpese = isCostoFormazione
      setCostoFormazione(0)
      setCostoFormazionePerc(0)
      setCosto(isCosto - oldSpese)
    }

  }

  const changeTotCIPerc = (value) => {
    value = parseFloat(value)
    if(value && value > 0) {
      let oldSpese = isCostoIndiretti
      let newSpese = isVivo / 100 * value
      newSpese = parseFloat(newSpese)
      newSpese = +(Math.round(newSpese + "e+2")  + "e-2")
      setCostoIndiretti(newSpese)
      setCostoIndirettiPerc(value)
      setCosto(isCosto + newSpese - oldSpese)
    } else if(value === 0) {
      let oldSpese = isCostoIndiretti
      setCostoIndiretti(0)
      setCostoIndirettiPerc(0)
      setCosto(isCosto - oldSpese)
    }

  }


  const changeTotCS = (value) => {
    if(value) {
      let old = isCostoSicurezza
      let val = parseFloat(value)
      setCosto(isCosto - old + val)
      setCostoSicurezza(+(Math.round(val + "e+2")  + "e-2"))
      var newPerc = Math.round(value / (isVivo) * 100)
      setCostoSicurezzaPerc(newPerc.toFixed(2))
      setTotale(isTotale - old)
    }

  }

  const changeTotCA = (value) => {
    if(value) {
      let old = isCostoAvviamento
      let val = parseFloat(value)
      setCosto(isCosto - old + val)
      setCostoAvviamento(+(Math.round(val + "e+2")  + "e-2"))
      var newPerc = Math.round(value / (isVivo) * 100)
      setCostoAvviamentoPerc(newPerc.toFixed(2))
      setTotale(isTotale - old)
    }

  }

  const changeTotCF = (value) => {
    if(value) {
      let old = isCostoFormazione
      let val = parseFloat(value)
      setCosto(isCosto - old + val)
      setCostoFormazione(+(Math.round(val + "e+2")  + "e-2"))
      var newPerc = Math.round(value / (isVivo) * 100)
      setCostoFormazionePerc(newPerc.toFixed(2))
      setTotale(isTotale - old)
    }

  }

  const changeTotCI = (value) => {
    if(value) {
      let old = isCostoIndiretti
      let val = parseFloat(value)
      setCosto(isCosto - old + val)
      setCostoIndiretti(+(Math.round(val + "e+2")  + "e-2"))
      var newPerc = Math.round(value / (isVivo) * 100)
      setCostoIndirettiPerc(newPerc.toFixed(2))
      setTotale(isTotale - old)
    }

  }

  const changeBaseAsta = (value) => {
    if(value) {
      setBaseAsta(value)
      setRibasso((isCosto + isCostoMat) - value)
    }

  }

  const changePrezzoConcordato = (value) => {
    if(value) {
      value = parseFloat(value)
      if(parseInt(value) > 0) {
        setPrezzoConcordato(value)
        setRibasso((isCosto + isCostoMat) - value)
      } else if(value === 0) {
        setPrezzoConcordato(0)
        setRibasso((isCosto + isCostoMat) - isBaseAsta)
      }
    }
  }


  const [modal, setModal] = useState(false)
  const toggle = () => {
      setModal(!modal)
  }


  const onClickSuccess = () => {
    toggle()
    history.push(`${process.env.PUBLIC_URL}/preventivi/lista`)
  }

  const onClickProject = () => {
    toggle()
    history.push(`${process.env.PUBLIC_URL}/preventivi/progetto/${props.current.ID}`)
  }

  const [isUtilePerc, setUtilePerc] = useState(0)

  const changeUtilePerc = (value) => {
    if(value) {
        let utile = parseFloat((totAtt + totMan + totMat) / 100 * parseFloat(value))
      setUtile(+(Math.round(utile + "e+2")  + "e-2"))
      setUtilePerc(value)
    }

  }

  const changeUtile = (value) => {
    if(value) {
      let old = isUtile
        let utile = Math.round(value / (totAtt + totMan + totMat) * 100)
      setUtile(parseInt(value))
      setTotale(isTotale - old)
      setUtilePerc(+(Math.round(utile + "e+2")  + "e-2"))
    }
  }

  const [isTotale, setTotale] = useState(isUtile + isCosto + isCostoMat + isVivo + isSpeseGenerali + isCostoVerifiche + isCostoSicurezza + isCostoAvviamento + isCostoFormazione + isCostoIndiretti)


    return (
        <Fragment>
            {loading && loading2 ? <div className="container-fluid" style={{marginTop: '2vh'}}>
                <div className="row" >
                    <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-6">
                            <fieldset>
                            <legend>Riepilogo costi preventivo</legend>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Manodopera:</label>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">%</label>
                                  <input type="number" id="fname" name="fname" className="form-control cleaning-form cleaning-disabled" value={(Math.round(totMan * 100 / (isVivo)))} disabled style={{textAlign: 'right'}}/>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">€</label>
                                  <input type="number" id="fname" name="fname" className="form-control cleaning-form cleaning-disabled" defaultValue={(Math.round(totMan))} onChange={e => changeTotMan(e.target.value)} disabled style={{textAlign: 'right'}}/>
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Prodotti:</label>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">%</label>
                                  <input type="number" id="fname" name="fname" className="form-control cleaning-form cleaning-disabled" value={totMat ? Math.round(totMat * 100 / (isVivo)) : 0} disabled style={{textAlign: 'right'}}/>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">€</label>
                                  <input type="number" id="fname" name="fname" className="form-control cleaning-form cleaning-disabled" defaultValue={totMat.toFixed(2)}  onChange={e => changeTotMat(e.target.value)} disabled style={{textAlign: 'right'}}/>
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Attrezz./Macc.:</label>
                                </div>

                                <div className="col-sm-4">
                                  <label htmlFor="fname">%</label>
                                  <input type="number" id="fname" name="fname" className="form-control cleaning-form cleaning-disabled" value={totAtt ? Math.round(totAtt * 100 / (isVivo)) : 0} disabled style={{textAlign: 'right'}}/>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">€</label>
                                  <input type="number" id="fname" name="fname" className="form-control cleaning-form cleaning-disabled" defaultValue={totAtt.toFixed(2)} onChange={e => changeTotAtt(e.target.value)} disabled style={{textAlign: 'right'}}/>
                                </div>
                              </div>


                              {/*ALTRI COSTI E PERCENTUALI*/}

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Spese generali:</label>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">%</label>
                                  <input type="number" className="form-control cleaning-form cleaning-disabled" min="0" value={isSpeseGeneraliPerc} onChange={e => changeTotSGPerc(e.target.value)} style={{textAlign: 'right'}}  />
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">€</label>
                                  <input type="number" id="fname" name="fname" className="form-control cleaning-form cleaning-disabled"  min="0" step="0.00" value={isSpeseGenerali} onChange={e => changeTotSG(e)} style={{textAlign: 'right'}} />
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Verifiche:</label>
                                </div>

                                <div className="col-sm-4">
                                  <label htmlFor="fname">%</label>
                                  <input type="number" className="form-control cleaning-form cleaning-disabled" min="0" value={isVerifichePerc} style={{textAlign: 'right'}} onChange={e => changeTotVerifichePerc(e.target.value)} />
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">€</label>
                                  <input type="number" className="form-control cleaning-form cleaning-disabled" min="0" value={isCostoVerifiche} onChange={e => changeTotCV(e.target.value)} style={{textAlign: 'right'}} />
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Sicurezza:</label>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">%</label>
                                  <input type="number" id="fname" name="fname" min="0" className="form-control cleaning-form cleaning-disabled" value={isCostoSicurezzaPerc} style={{textAlign: 'right'}} onChange={e => changeTotCSPerc(e.target.value)}/>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">€</label>
                                  <input type="number" id="fname" name="fname" min="0" className="form-control cleaning-form cleaning-disabled" value={isCostoSicurezza} onChange={e => changeTotCS(e.target.value)} style={{textAlign: 'right'}}/>
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Avviamento:</label>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">%</label>
                                  <input type="number" id="fname" name="fname" min="0" className="form-control cleaning-form cleaning-disabled" value={isCostoAvviamentoPerc} style={{textAlign: 'right'}} onChange={e => changeTotAvviamentoPerc(e.target.value)}/>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">€</label>
                                  <input type="number" id="fname" name="fname" min="0" className="form-control cleaning-form cleaning-disabled" value={isCostoAvviamento} onChange={e => changeTotCA(e.target.value)} style={{textAlign: 'right'}}/>
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Formazione:</label>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">%</label>
                                  <input type="number" min="0" className="form-control cleaning-form cleaning-disabled" value={isCostoFormazionePerc} style={{textAlign: 'right'}} onChange={e => changeTotCFPerc(e.target.value)}/>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">€</label>
                                  <input type="number" min="0" className="form-control cleaning-form cleaning-disabled" value={isCostoFormazione} onChange={e => changeTotCF(e.target.value)} style={{textAlign: 'right'}}/>
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Altri indiretti:</label>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">%</label>
                                  <input type="number" min="0" className="form-control cleaning-form cleaning-disabled" value={isCostoIndirettiPerc} style={{textAlign: 'right'}}  onChange={e => changeTotCIPerc(e.target.value)}/>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">€</label>
                                  <input type="number" min="0" className="form-control cleaning-form cleaning-disabled" value={isCostoIndiretti} onChange={e => changeTotCI(e.target.value)} style={{textAlign: 'right'}}/>
                                </div>
                              </div>
                              <br />
                              <br />

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Utile:</label>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">%</label>
                                  <input type="number" min="0" className="form-control cleaning-form cleaning-disabled" value={isUtilePerc} onChange={e => changeUtilePerc(e.target.value)} style={{textAlign: 'right'}}/>
                                </div>
                                <div className="col-sm-4">
                                  <label htmlFor="fname">€</label>
                                  <input type="number" min="0" className="form-control cleaning-form cleaning-disabled" value={isUtile} style={{textAlign: 'right'}} onChange={e => changeUtile(e.target.value)}/>
                                </div>
                              </div>


                            </fieldset>



                            </div>
                            <div className="col-sm-6" style={{
                              padding: '1vh',
                            }}>
                            <fieldset>
                            <legend>Totalizzatori</legend>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Prezzo finale:</label>
                                </div>
                                <div className="col-sm-4">
                                  <input type="number" className="form-control cleaning-form cleaning-disabled" value={(isUtile + isCosto + isVivo).toFixed(2)} onChange={e => setTotale(e.target.value)} style={{textAlign: 'right'}} disabled/>
                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Base d'asta:</label>
                                </div>
                                <div className="col-sm-4">
                                  <input type="number" className="form-control cleaning-form" value={isBaseAsta} onChange={e => {
                                    changeBaseAsta(e.target.value)
                                    if(e.target.value === 0) {

                                    }
                                  }} style={{textAlign: 'right'}} />
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Mesi:</label>
                                </div>
                                <div className="col-sm-4">
                                  <input type="number" className="form-control cleaning-form cleaning-disabled" value={props.current.mesi} style={{textAlign: 'left'}} disabled/>
                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Settimane:</label>
                                </div>
                                <div className="col-sm-4">
                                  <input type="number" className="form-control cleaning-form cleaning-disabled" value={props.current.mesi ? Math.ceil(props.current.mesi * 30.42 / 7) : 0} onChange={e => setMesi(e.target.value)} style={{textAlign: 'left'}} disabled/>
                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Prezzo concordato:</label>
                                </div>
                                <div className="col-sm-4">
                                  <input type="number" className="form-control cleaning-form" value={isPrezzoConcordato} style={{backgroundColor: 'rgba(0,128,0,0.1)', textAlign: 'right'}} onChange={e => changePrezzoConcordato(e.target.value)}/>
                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Canone mese:</label>
                                </div>
                                <div className="col-sm-4">
                                  <input type="number" disabled className="form-control cleaning-form cleaning-disabled" value={isPrezzoConcordato > 0 ? (isPrezzoConcordato / props.current.mesi).toFixed(2) : ((isCosto + isCostoMat) / props.current.mesi).toFixed(2)} style={{textAlign: 'right'}}/>
                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Canone ora:</label>
                                </div>
                                <div className="col-sm-4">
                                  <input type="number" disabled className="form-control cleaning-form cleaning-disabled" value={isPrezzoConcordato > 0 ? (isPrezzoConcordato / isTotaleOre).toFixed(2) : ((isCosto + isCostoMat) / isTotaleOre).toFixed(2)} style={{textAlign: 'right'}}/>
                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Canone MQ:</label>
                                </div>
                                <div className="col-sm-4">
                                  <input type="number" disabled className="form-control cleaning-form cleaning-disabled" value={isPrezzoConcordato > 0 ? (isPrezzoConcordato / isTotMQ).toFixed(2) : ((isCosto + isCostoMat) / isTotMQ).toFixed(2)} style={{textAlign: 'right'}}/>
                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Canone giorno:</label>
                                </div>
                                <div className="col-sm-4">
                                  <input type="number" disabled className="form-control cleaning-form cleaning-disabled" value={isPrezzoConcordato ? ((isPrezzoConcordato / (isOrePeriodiche+isOreGiornaliere)) * (isTotaleOre / isTotInterventi)).toFixed(2) : (((isCosto + isCostoMat) / (isOrePeriodiche+isOreGiornaliere)) * (isTotaleOre / isTotInterventi)).toFixed(2)} style={{textAlign: 'right'}}/>
                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Ribasso:</label>
                                </div>
                                <div className="col-sm-4">
                                  <input type="number" disabled className="form-control cleaning-form cleaning-disabled" value={((isBaseAsta === 0 || isBaseAsta === '0') && (isPrezzoConcordato === 0 || isPrezzoConcordato === '0')) ? 0 : (isBaseAsta && (isPrezzoConcordato === 0) ? ((isUtile + isCosto + isVivo) - isBaseAsta).toFixed(2) : (isPrezzoConcordato - isBaseAsta).toFixed(2))} style={{textAlign: 'right'}}/>
                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>

                              {/*(isPrezzoConcordato === 0 ? ((isUtile + isCosto + isVivo) - isBaseAsta).toFixed(2) : (isPrezzoConcordato - isBaseAsta).toFixed(2))*/}

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>% ribasso:</label>
                                </div>
                                <div className="col-sm-4">
                                  <input type="number" disabled className="form-control cleaning-form cleaning-disabled" value={((isBaseAsta === 0 || isBaseAsta === '0') && (isPrezzoConcordato === 0 || isPrezzoConcordato === '0')) ? 0 : (isBaseAsta  && (isPrezzoConcordato === 0) ? ((100 - (100 * isBaseAsta / (isUtile + isCosto + isVivo)))).toFixed(2) : ((100 - (100 * isBaseAsta / (isPrezzoConcordato)))).toFixed(2))} style={{textAlign: 'right'}}/>
                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>

                              <br/>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Ore giornaliere:</label>
                                </div>
                                <div className="col-sm-4">
                                  <input type="number" disabled className="form-control cleaning-form cleaning-disabled" value={(Math.round(isOreGiornaliere)).toFixed(2)} style={{textAlign: 'right'}}/>
                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>% Ore giornaliere:</label>
                                </div>
                                <div className="col-sm-4">
                                  <input type="number" disabled className="form-control cleaning-form cleaning-disabled" value={Math.round(100*isOreGiornaliere/(isOrePeriodiche+isOreGiornaliere)).toFixed(2)} style={{textAlign: 'right'}}/>
                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Ore periodiche:</label>
                                </div>
                                <div className="col-sm-4">
                                <input type="number" disabled className="form-control cleaning-form cleaning-disabled" value={Math.round(isOrePeriodiche).toFixed(2)} style={{textAlign: 'right'}}/>

                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>% Ore periodiche:</label>
                                </div>
                                <div className="col-sm-4">
                                <input type="number" disabled className="form-control cleaning-form cleaning-disabled" value={Math.round(100*isOrePeriodiche/(isOrePeriodiche+isOreGiornaliere)).toFixed(2)} style={{textAlign: 'right'}}/>

                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Totale giorni:</label>
                                </div>
                                <div className="col-sm-4">
                                <input type="number" disabled className="form-control cleaning-form cleaning-disabled" value={isMaxGiorno} style={{textAlign: 'right'}}/>

                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>

                              <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                <div className="col-sm-4">
                                  <label style={{fontSize: '1.2em', fontWeight: 'bold'}}>Totale metri quadri:</label>
                                </div>
                                <div className="col-sm-4">
                                <input type="number" disabled className="form-control cleaning-form cleaning-disabled" value={isTotMQ} style={{textAlign: 'right'}}/>

                                </div>
                                <div className="col-sm-4">
                                </div>
                              </div>


                            </fieldset>


                            </div>
                            <br />
                            <br />
                          </div>
                          <center><button className="btn btn-success cleaning-btn-login" style={{marginBottom: '3vh', marginTop: '5vh'}} onClick={() => onSubmit()}>CONCLUDI</button></center>
                    </div>
                </div>
            </div> : 'Caricamento riepilogo finale in corso...'}

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={onClickSuccess} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={onClickSuccess}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Concluso con successo, clicca su <strong>Crea progetto</strong> per proseguire e scegliere i documenti che faranno parte del tuo progetto, oppure su <strong>Chiudi</strong> per terminare. Abbiamo già salvato i tuoi dati, potrai tornare qui in qualsiasi momento.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onClickProject} className="btn-cleaning">{"Crea progetto"}</Button>
                    <Button color="danger" onClick={onClickSuccess} className="btn-cleaning">{"Chiudi"}</Button>
                </ModalFooter>
            </Modal>

        </Fragment>


    );
};

export default RiepilogoFinale;
