import React, { Fragment , useState , useEffect } from 'react';

const Loader = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(false)
          }, 500);

        return() => {
            clearTimeout(timeout)
        }

    },[show]);
    return (
        <Fragment>
            <div className={`loader-wrapper ${show ? '' : 'loderhide'}`} >
            <div className="spinner-loading-cleaning"><div className="loader-box">
              <div className="loader">
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
              </div>
            </div></div>
            </div>
        </Fragment>
    );
};

export default Loader;
