import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import Select from 'react-select';



const TariffeAdd = () => {

    const [userData, setUserData] = useState({})
    const [allCategories, setCategories] = useState([{}])
    const [allAmbience, setAmbience] = useState([{}])
    const [loadingAmbience, setLoadingAmbience] = useState(true)
    const [loading, setLoading] = useState(true)
    const [loadingAtt, setLoadingAtt] = useState(true)
    const [fileImage, setFile] = useState(null)
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [isEcolabel, setEcolabel] = useState(null)
    const [isMaterial, setMaterial] = useState('')
    const [isCodiceColore, setCodiceColore] = useState('')

    //Generazione randomica di stringhe
    function makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() *
     charactersLength)));
       }
       return result.join('');
    }

    const toggle = () => {
        setModal(!modal)
    }

    const onSubmit = (data) => {
        const updateData = async () => {
            const update = await axios.post(`${apiEndpoint}/tariffe/create`, {
              descrizione: data.descrizione,
              costo: data.costo,
              userID: localStorage.getItem("userID")
            }, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            reset()
            toggle()
          }
      updateData()
    }

    const selectStyles = {
      control: styles => ({ ...styles, borderRadius: '10px'})
    }

    const resetForm = () => {
      reset()
    }

    const setFileUpload = (target) => {
      if(target) {
        let newImage = target.files[0]
        let imageSize = newImage.size
        let imageType = newImage.type
        if(imageType === 'image/png' || imageType === 'image/jpeg') {
          if(imageSize <= 2000000) {
            setFile(newImage)
            setDisabled(false)
          } else {
            window.alert('Dimensione massima consentita 2MB.')
          }
        } else {
          window.alert('Puoi caricare solo immagini in formato .png, .jpeg, .jpg')
        }
      }
    }

    if(isLoggedOut) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/login`} />)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Tariffa" title="Aggiungi" />
            {loading ? <><div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12 card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Nuova tariffa"}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Descrizione"} </label>
                                            <input className="form-control cleaning-form" name="descrizione" type="text" placeholder="Inserisci descrizione es. MANODOPERA 5 LIVELLO" {...register("descrizione")} required/>
                                            {errors.descrizione && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Costo orario"}</label>
                                            <input className="form-control cleaning-form" name="costo" type="number" step="0.01" placeholder="Inserisci costo complessivo" {...register("costo")} required/>
                                            {errors.descrizione && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    </div>

                                    <span className="required-field">*</span>tutti i campi sono richiesti
                                </div>
                                <div className="card-footer text-right">
                                    <Link to={'/tariffe/lista'} className="btn cleaning-btn-login cleaning-cancel-btn">{"Annulla"}</Link>
                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Aggiungi"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div></> : null}

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Tariffa aggiunta con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={toggle} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>


        </Fragment>
    );
};

export default TariffeAdd;
