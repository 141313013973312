import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom'
import Breadcrumb from '../../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../../constant'
import  Avatar  from '../../../assets/images/avtar/avatar-cleaning.png'
import Word from '../../../assets/images/word-thumb.png'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';


const RiepilogoOperatori = (props) => {

  const frequenze = [
    {
      value: 2,
      label: '1 volta a settimana'
    },
    {
      value: 3,
      label: '2 volte a settimana'
    },
    {
      value: 4,
      label: '3 volte a settimana'
    },
    {
      value: 14,
      label: '4 volte a settimana'
    },
    {
      value: 1,
      label: '5 volte settimana'
    },
    {
      value: 15,
      label: '6 volte a settimana'
    },
    {
      value: 16,
      label: '7 volte a settimana'
    },
    {
      value: 5,
      label: 'Decadale'
    },
    {
      value: 6,
      label: 'Quindicinale'
    },
    {
      value: 7,
      label: 'Mensile'
    },
    {
      value: 8,
      label: 'Bimestrale'
    },
    {
      value: 9,
      label: 'Trimestrale'
    },
    {
      value: 10,
      label: 'Quadrimestrale'
    },
    {
      value: 11,
      label: 'Semestrale'
    },
    {
      value: 12,
      label: 'Annuale'
    },
    {
      value: 13,
      label: 'Spot'
    },

  ]

    const [data,setData] = useState([])
    const [allOperators, viewOperators] = useState([{}])
    const [loading, setLoading] = useState(false)
    const history = useHistory()


    useEffect(() => {
      const fetch = async () => {
        const operatori = await axios.get(`${apiEndpoint}/tariffe/fetch/${localStorage.getItem('userID')}`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        if(localStorage.getItem("back")) {
          localStorage.removeItem("back")
        }
        let array = []
        let operatores = operatori.data

        props.current.map((item, index) => {


          var array3 = operatores.findIndex(x => x.IDcausaleBusta === item.operatore.value)


          let obj = {
            operazione: item.descrizione ? item.descrizione : 'Nessuna operazione selezionata',
            operatore: item.operatore ? item.operatore.value : '',
            descrizione: item.operatore ? item.operatore.label : 'Nessun operatore selezionato',
            tempo: item.tempo ? item.tempo : 0,
            ricorrenza: item.frequenza ? item.frequenza.label : 0,
            consigliati: 1,
            tariffa: item.tariffa ? item.tariffa : 0
          }
          if(array3 >= 0) {
            obj.descrizione = operatores[array3].descrizione
          }
          var array2 = frequenze.findIndex(x => x.value === parseInt(item.frequenza.value))
          if(array2 >= 0) {
            obj.ricorrenza = frequenze[array2].label
          }
          array.push(obj)
        })
        let counts = array.reduce((c, { operatore: key }) => (c[key] = (c[key] || 0) + 1, c), {});
        let keys = Object.keys(counts)
        let defArray = []
        keys.map((k, i) => {
          let ok = array.filter(element => element.operatore === k)
          if(ok.length > 0) {
            let obj = {
              operatore: k,
              qty: ok.length,
              descrizione: ok[0].descrizione,
              tariffa: ok[0].tariffa,
              ricorrenza: ok[0].ricorrenza
            }
            defArray.push(obj)
          }
        })
        viewOperators(defArray)
        setLoading(true)
      }
      fetch()

    }, [])


    const { SearchBar } = Search;
    const columns = [
    {
      dataField: 'descrizione',
      text: 'Operatore',
      formatter: opFormatter
    },
    {
      dataField: 'tariffa',
      text: 'Costo orario (€)',
    },
    {
      dataField: 'qty',
      text: 'Quantità',
    },
    {
      dataField: 'ricorrenza',
      text: 'Ricorrenza'
    },

  ];
/*    {
      dataField: 'consigliati',
      text: 'Consigliati'
    }*/

      function opFormatter(cell, row) {
        return (
          <p>{row.descrizione} <br/>
          {/*<small className="text-muted">{row.operazione}</small>*/}</p>

        )
      }


    return (
        <Fragment>
            {loading ? <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                          <div className="card-body datatable-react">
                            <div className="row">

                            <ToolkitProvider
                            keyField='ID'
                            data={ allOperators }
                            columns={ columns }
                            search
                            >
                            {
                              props => (
                                <div>
                                  <BootstrapTable
                                    { ...props.baseProps }
                                    striped
                                    hover
                                    condensed
                                    keyField="ID"
                                  />
                                </div>
                              )
                            }
                            </ToolkitProvider>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div> : 'Caricamento riepilogo in corso...'}
        </Fragment>
    );
};

export default RiepilogoOperatori;
