import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Modal as Modal2 } from 'react-bootstrap'


const CantieriPotenzialiProfile = (props) => {

    const [data,setData] = useState([])
    const [show, setShow] = useState(false);
    const [userData, setClientData] = useState({})
    const [allCantieri, setCantieri] = useState([{}])
    const [loading, setLoading] = useState(true)
    const [fileImage, setFile] = useState(null)
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { register: registerDel, handleSubmit: handleSubmitDel, formState: { errors: errorsDel } } = useForm();
    const { register: registerEdit, handleSubmit: handleSubmiteEdit, formState: { errors: errorsEdit }, reset } = useForm();
    const { register: registerAdd, handleSubmit: handleSubmitAdd, formState: { errors: errorsAdd } } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalEdit, setModalEdit] = useState(false);
    const [modalAdd, setModalAdd] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [isCantierePotenziale, setCantierePotenziale] = useState({})
    const [isCantiere, setIsCantiere] = useState(0)
    const [isDeleted, setDeleted] = useState(false)
    const [nomeCantiere, setNomeCantiere] = useState('')

    useEffect(() => {
        let mounted = true
        const getUser = async () => {
          const cantieri = axios.get(`${apiEndpoint}/clienti-potenziali/fetch-cantiere-singolo/${props.match.params.id}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          if(mounted) {
            cantieri.then(res => {
              console.log(res.data)
              setClientData(res.data)
              setLoading(true)
            })
          }
        }
        getUser()
        return () => {
          mounted = false
        }
    }, [])

    const toggle = () => {
        setModal(!modal)
    }

    const toggleDel = () => {
        setModalDel(!modalDel)
    }

    const toggleImg = () => {
        setModalImg(!modalImg)
    }

    const toggleEdit = () => {
        setModalEdit(!modalEdit)
    }

    const toggleAdd = () => {
        setModalAdd(!modalAdd)
    }

    const toggleImgSee = () => {
        setModalImgSee(!modalImgSee)
    }

    const onSubmitAdd = data => {
      let cantiere = data.cantiere
      let address = data.address
      let city = data.city
      let postalCode = data.postalCode
      let region = data.region
      let cliID = props.match.params.id
      const insertData = async () => {
        const cantierePotenziale = await axios.post(`${apiEndpoint}/clienti-potenziali/create-cantiere`, {
          cantiere: cantiere,
          address: address,
          city: city,
          postalCode: postalCode,
          region: region,
          cliID: cliID
        }, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        toggleAdd()
        toggle()
      }
      insertData()
    }

    const onSubmitEdit = data => {
      let cantiere = data.cantiere
      let address = data.address
      let city = data.city
      let postalCode = data.postalCode
      let region = data.region
      let cliID = props.match.params.id
      const updateData = async () => {
        const cantUp = await axios.patch(`${apiEndpoint}/clienti-potenziali/patch-cantiere`, {
          cantiere: cantiere,
          address: address,
          city: city,
          postalCode: postalCode,
          region: region,
          cliID: cliID,
          cantiereID: data.cantiereID
        }, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        toggleEdit()
        toggle()
      }
      updateData()
    }

    const toggleDD = () => setDropdownOpen(prevState => !prevState)

    const onSubmitCantiere = data => {
      console.log(data)
    const updateData = async () => {
        const update = await axios.patch(`${apiEndpoint}/clienti-potenziali/patch-cantiere`, {
          address: data.address,
          city: data.city,
          postalCode: data.postalCode,
          region: data.region,
          cantiere: data.cantiere,
          cantiereID: props.match.params.id
        }, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        console.log(update.data)
        toggle()
      }
      updateData()
    }

    const setFileUpload = (target) => {
      if(target) {
        let newImage = target.files[0]
        let imageSize = newImage.size
        let imageType = newImage.type
        if(imageType === 'image/png' || imageType === 'image/jpeg') {
          if(imageSize <= 2000000) {
            setFile(newImage)
            setDisabled(false)
          } else {
            window.alert('Dimensione massima consentita 2MB.')
          }
        } else {
          window.alert('Puoi caricare solo immagini in formato .png, .jpeg, .jpg')
        }
      }
    }

    const onRecoverPassword = data => {
      const updateData = async () => {
          const update = await axios.patch(`${apiEndpoint}/user/change-password/${localStorage.getItem("userID")}`, {
            password: data.password,
            repassword: data.repassword
          }, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          if(update.data === 'Password uguali') {
            setErrPwd('La vecchia password e la nuova password devono essere differenti.')
          } else if(update.data === 'Password modificata') {
            reset()
            toggle()
          } else if(update.data === 'Password vecchia non combacia') {
            setErrPwd('La vecchia password é errata, inserisci la password corretta.')
          }
        }
        updateData()
    }



    const options = {
    filter: false,
    selectableRows: "multiple",
    filterType: "dropdown",
    rowsPerPage: 10,
    download: false,
    print: false,
    viewColumns: false,
    onRowsDelete: (rowData, rowState) => {
      console.log(rowData)
    },
    textLabels: {
      body: {
        noMatch: "Spiacenti, nessun record corrispondente trovato",
        toolTip: "Ordinare",
        columnHeaderTooltip: column => `Ordinare per ${column.label}`
      },
      pagination: {
          next: "Pagina successiva",
          previous: "Pagina Precedente",
          rowsPerPage: "Righe per pagina:",
          displayRows: "of",
        },
        toolbar: {
          search: "Cerca",
          print: "Stampa",
          viewColumns: "Vedi Colonne",
          filterTable: "Tabella dei filtri",
        },
        filter: {
          all: "Tutto",
          title: "FILTRI",
          reset: "RIPRISTINA",
        },
        viewColumns: {
          title: "Mostra colonne",
          titleAria: "Mostra / Nascondi colonne tabella",
        },
  }
  };

    const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          fontFamily: 'Nunito, sans-serif',
          cursor: 'pointer'
        }
      }
    }
  })

    const onDeleteAccount = data => {
      if(data.delete === 'ELIMINA ACCOUNT') {
         const deleteAccount = async () => {
          const delAcc = await axios.delete(`${apiEndpoint}/clienti-potenziali/delete/${props.match.params.id}/${localStorage.getItem("IDCartellaMedia")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          setDeleted(true)
        }
        deleteAccount()
      } else {
        setErrDel('Non hai inserito ELIMINA ACCOUNT come richiesto, riprova.')
      }
    }


    if(isDeleted) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/clienti-potenziali/lista`} />)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Cliente potenziale" title="Profilo cantiere" />
            {loading ? <><div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">

                        <div className="col-lg-12 card">
                        <Link to={`${process.env.PUBLIC_URL}/clienti-potenziali/profilo/${userData.IDcliente}`} className="mb-2 mt-2 ml-2">Torna al cliente</Link>

                            <form  onSubmit={handleSubmit(onSubmitCantiere)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Profilo cantiere"}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">

                                    <div className="row">

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Cantiere"}</label>
                                            <input className="form-control cleaning-form" defaultValue={userData.RagioneSociale1} name="cantiere" type="text" placeholder="es. Mario Rossi Srl" {...register("cantiere")}/>
                                        </div>
                                    </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{Address}</label>
                                                <input className="form-control cleaning-form"  defaultValue={userData.Indirizzo} name="address" type="text" placeholder="es. Via Roma, 1" {...register("address")}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{City}</label>
                                                <input className="form-control cleaning-form"  defaultValue={userData.IDcomune} name="city" type="text" placeholder="es. Roma" {...register("city")}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{PostalCode}</label>
                                                <input className="form-control cleaning-form" defaultValue={userData.CAP} name="postalCode" type="number" placeholder="es. 00100" {...register("postalCode")}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Provincia"}</label>
                                            <input className="form-control cleaning-form" defaultValue={userData.Provincia} name="region"maxLength="2" type="text" placeholder="es. RM" {...register("region")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Modifica"}</button>
                                    <a className="btn btn-danger cleaning-btn-delete" onClick={() => toggleDel()}>{"Elimina"}</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Modal isOpen={modalImgSee} toggle={toggleImgSee} centered={true} modalClassName="cleaning-modal remove-bg-cleaning">
                          <img className="light-shadow" alt={userData.RagioneSociale1} src={fileServer + '/' + localStorage.getItem("IDCartellaMedia") + "/clienti-potenziali/" + userData.percorsoImmagine} />
                </Modal>
                {/* Modale per cambio immagine profilo*/}
                <Modal isOpen={modalEdit} toggle={toggleEdit} centered={true} modalClassName="cleaning-modal">
                <form  onSubmit={handleSubmiteEdit(onSubmitEdit)}>

                    <ModalBody>

                            <div className="row">

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="form-label cleaning-form-label">{"Cantiere"}</label>
                                    <input className="form-control cleaning-form" defaultValue={isCantierePotenziale.RagioneSociale1} name="cantiere" type="text" placeholder="es. Mario Rossi Srl" {...registerEdit("cantiere")}/>
                                </div>
                            </div>


                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label cleaning-form-label">{Address}</label>
                                        <input className="form-control cleaning-form"  defaultValue={isCantierePotenziale.Indirizzo} name="address" type="text" placeholder="es. Via Roma, 1" {...registerEdit("address")}/>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label cleaning-form-label">{City}</label>
                                        <input className="form-control cleaning-form"  defaultValue={isCantierePotenziale[2]} name="city" type="text" placeholder="es. Roma" {...registerEdit("city")}/>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-3">
                                    <div className="form-group">
                                        <label className="form-label cleaning-form-label">{PostalCode}</label>
                                        <input className="form-control cleaning-form" defaultValue={isCantierePotenziale[4]} name="postalCode" type="number" placeholder="es. 00100" {...registerEdit("postalCode")}/>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-3">
                                <div className="form-group">
                                    <label className="form-label cleaning-form-label">{"Provincia"}</label>
                                    <input className="form-control cleaning-form" defaultValue={isCantierePotenziale[3]} name="region"maxLength="2" type="text" placeholder="es. RM" {...registerEdit("region")}/>
                                </div>

                                <input type="hidden" value={isCantierePotenziale[5]} {...registerEdit("cantiereID")} />
                              </div>
                            </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button onClick={toggleEdit} className="cleaning-cancel-btn">{"Annulla"}</Button>
                      <Button color="primary" type="submit" className="btn-cleaning">{"Aggiorna"}</Button>
                    </ModalFooter>
                    </form>

                </Modal>
            </div></> : null}

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Le modifiche sono state eseguite con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={toggle} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>

            {/* Modale per eliminazione account*/}
            <Modal isOpen={modalDel} toggle={toggleDel} centered={true} modalClassName="cleaning-modal">
              <form onSubmit={handleSubmitDel(onDeleteAccount)}>
                <ModalHeader toggle={toggleDel}>Attenzione!</ModalHeader>
                <ModalBody>
                    <p>Stai per <strong>eliminare</strong> il tuo account, per procedere scrivi <strong>ELIMINA ACCOUNT</strong> e clicca su <strong>Elimina</strong>.</p>
                      <input className="form-control cleaning-form" name="delete"  type="text" placeholder="" {...registerDel("delete")}/>
                      <p style={{color: 'red', marginTop: '1vh'}}>{errDel}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" type="submit" className="btn-cleaning">{"Elimina"}</Button>
                </ModalFooter>
              </form>
            </Modal>
            {/* Modale per eliminazione account*/}

            {/* Modale per cambio immagine profilo*/}


        </Fragment>
    );
};

export default CantieriPotenzialiProfile;
