import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import Select from 'react-select';


const AreaAdd = (props) => {

    const [data,setData] = useState([])
    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(false)
    const [fileImage, setFile] = useState(null)
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [allAmbienti, viewAmbienti] = useState([{}])
    const [selectedAmbiente, setSelectedAmbiente] = useState({})

    const [isArea, setArea] = useState({})
    const [defaultAmbiente, setDefaultAmbiente] = useState({})

    let history = useHistory()

    const selectStyles = {
      control: styles => ({ ...styles, borderRadius: '10px'})
    }

    useEffect(() => {
      const fetch = async () => {
        const aree = await axios.get(`${apiEndpoint}/aree-ambienti/fetch-aree-ambienti-2`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const response = await axios.get(`${apiEndpoint}/aree-ambienti/fetch-area`, {
          params: {
            ambiente: props.match.params.ambiente,
            area: props.match.params.area
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        console.log(response.data)
        setArea(response.data)
        let array = []
        aree.data.map(item => {
          let obj = {
            value: item.ambiente,
            label: item.ambiente,
            immagine: item.PercorsoImmagine
          }
          if(response.data.ambiente === item.ambiente) {
            let amb = {
              value: item.ambiente,
              label: item.ambiente
            }
            setDefaultAmbiente(amb)
          }
          array.push(obj)
        })
        viewAmbienti(array)
        setLoading(true)

      }
      fetch()
    }, [])

    const toggle = () => {
        setModal(!modal)
    }

    const toggleDel = () => {
        setModalDel(!modalDel)
    }

    const toggleImg = () => {
        setModalImg(!modalImg)
    }

    const toggleImgSee = () => {
        setModalImgSee(!modalImgSee)
    }

    const toggleDD = () => setDropdownOpen(prevState => !prevState)

    const onSubmit = (data) => {

    const updateData = async () => {
        const update = await axios.patch(`${apiEndpoint}/aree-ambienti/edit-area`, {
          ambiente: selectedAmbiente ? selectedAmbiente.value : isArea.ambiente,
          area: data.area,
          id: isArea.area,
          old_ambiente: isArea.ambiente,
          immagine: selectedAmbiente ? selectedAmbiente.immagine :  defaultAmbiente.immagine
        }, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        history.push("/aree-ambienti/aree")

      }
      updateData()
      console.log(data)
    }

    const setFileUpload = (target) => {
      if(target) {
        let newImage = target.files[0]
        let imageSize = newImage.size
        let imageType = newImage.type
        if(imageType === 'image/png' || imageType === 'image/jpeg') {
          if(imageSize <= 2000000) {
            setFile(newImage)
            setDisabled(false)
          } else {
            window.alert('Dimensione massima consentita 2MB.')
          }
        } else {
          window.alert('Puoi caricare solo immagini in formato .png, .jpeg, .jpg')
        }
      }
    }

    const onDeleteAccount = () => {
         const deleteAccount = async () => {
          const delAcc = await axios.delete(`${apiEndpoint}/aree-ambienti/delete-area/${props.match.params.ambiente}/${props.match.params.area}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          history.push("/aree-ambienti/aree")
        }
        deleteAccount()
    }




    const cancelUpload = () => {
      toggleImg()
      setFile(null)
    }

    if(isLoggedOut) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/login`} />)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Ambienti" title="Aggiungi" />
            {loading ? <><div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12 card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Nuovo ambiente e area"}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Ambiente"}</label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci area"
                                              onChange={(e) => setSelectedAmbiente(e)}
                                              options={allAmbienti}
                                              styles={selectStyles}
                                              defaultValue={defaultAmbiente}
                                              required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Area"}</label>
                                            <input className="form-control cleaning-form" name="area" type="text" placeholder="es. Bagno" defaultValue={isArea.area} {...register("area")} required/>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                <button className="btn cleaning-btn-login cleaning-cancel-btn" type="button" onClick={() => history.push(`${process.env.PUBLIC_URL}/aree-ambienti/aree`)}>{"Annulla"}</button>
                                <button className="btn btn-primary cleaning-btn-login" type="submit">{"Modifica"}</button>
                                <button type="button" className="btn btn-danger cleaning-btn-delete" onClick={() => toggleDel()}>{"Elimina"}</button>                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div></> : <div className="spinner-loading-cleaning"><div className="loader-box">
              <div className="loader">
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
              </div>
            </div></div>}

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Area aggiunta con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={toggle} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>


                        {/* Modale per eliminazione account*/}
                        <Modal isOpen={modalDel} toggle={toggleDel} centered={true} modalClassName="cleaning-modal">
                            <ModalHeader toggle={toggleDel}>Attenzione!</ModalHeader>
                            <ModalBody>
                                <p>Stai per <strong>eliminare</strong> l'area, sei sicuro di voler procedere?</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button type="button" className="cleaning-cancel-btn" onClick={() => toggleDel()}>{"Annulla"}</Button>
                                <Button color="danger" type="submit" className="btn-cleaning" onClick={() => onDeleteAccount()}>{"Elimina"}</Button>
                            </ModalFooter>
                        </Modal>


        </Fragment>
    );
};

export default AreaAdd;
