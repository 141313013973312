import React, { Fragment, useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import Slider from 'react-slick';
import Ratings from 'react-ratings-declarative'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, ProductReview, Brand, Availability, AddToCart, BuyNow, ContinueShopping,OutOfStock, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'



const AttrezzatureProfilo = (props) => {


      const [isMaterial, setMaterials] = useState({})
      const [rating,setRating] = useState(0)
      const [isLoading, setLoading] = useState(false)
      const [modalImgSee, setModalImgSee] = useState();


      useEffect(() => {
        //${localStorage.getItem("userID")}
          let mounted = true
          const getMaterial = async () => {
            const material = await axios.get(`${apiEndpoint}/attrezzature/fetch-profilo/${props.match.params.id}`, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            if(mounted) {
              setMaterials(material.data)
              setLoading(true)
            }
          }
          getMaterial()
          return () => {
            mounted = false
          }
      }, [])

      const changeRating =  (newRating) => {
          setRating(newRating)
      }

      function isEcolabel(ecolabel) {
        if(ecolabel === 1) {
          return (<span>&#9989;</span>)
        } else if(ecolabel === 0) {
          return (<span>&#10060;</span>)
        }
      }

      function isColore(colore) {
        if(colore === 1) {
          return 'Si'
        } else if(colore === 0) {
          return 'No'
        }
      }


      function calcoloDiluizione(valore) {
        if(valore === 0) {
          return 'Pronto all\'uso'
        } else {
          return valore + '%'
        }
      }

      const toggleImgSee = () => {
          setModalImgSee(!modalImgSee)
      }

      return (
          <Fragment>
              <Breadcrumb title="Dettagli attrezzatura" parent="Attrezzatura" />
              {isLoading ? <div className="container-fluid">
                  <div className="card">
                      <div className="row">
                        <div className="col">
                          <img src={fileServer + isMaterial.percorsoImmagine + isMaterial.IDattrezzatura + '.png'} alt={isMaterial.Descrizione} className="immagine-profilo-materiale" onClick={toggleImgSee}/>
                        </div>
                        <div className="col">
                          <h3 className="titolo-profilo-materiale">{isMaterial.Descrizione}</h3>
                          <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Codice articolo: </span><span>{isMaterial.IDattrezzatura}</span></p>
                          <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Ecolabel: </span><span>{isEcolabel(isMaterial.EcoLabel)}</span></p>
                          <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Vita utile (mq/punti): </span><span>{isMaterial.vitaUtile} /{isMaterial.tipoResa === null ? 'mq' : isMaterial.tipoResa}</span></p>
                          <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Plastica riciclata: </span><span>{isMaterial.PlasticaRiciclata + "%"}</span></p>
                          <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Prezzo listino ufficiale (singolo pezzo iva esclusa): </span><span>{isMaterial.ImportoAmmortamento}</span></p>
                          <a href={fileServer + isMaterial.percorsoScheda + isMaterial.IDattrezzatura + ".pdf"} className="btn btn-primary cleaning-btn-login" type="submit" target="_blank">{"Scheda"}</a>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col">
                        {/*(() => {
                          switch(isMaterial.IDsottogruppo) {
                            case 'DET':
                              return (
                                <>
                                <div className="col">
                                  <hr />
                                  <h4 className="titolo-dettaglio">Dettagli:</h4>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Confezione tanica ml: </span><span>{isMaterial.quantitàRiferimentoResa}</span></p>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Grammi plastica contenitore: </span><span>{isMaterial.DetGrammiplastica}</span></p>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Diluizione: </span><span>{calcoloDiluizione(isMaterial.diluizione1)}</span></p>
                                </div>
                                </>
                              )
                             case 'PAN':
                              return (
                                <>
                                <div className="col">
                                  <hr />
                                  <h4 className="titolo-dettaglio">Dettagli:</h4>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Composizione: </span><span>{isMaterial.descrizioneNomenclatura}</span></p>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Dimensioni panno cm/pz: </span><span>{isMaterial.nomenclaturaCombinata}</span></p>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Peso gr/mq: </span><span>{isMaterial.pesoNetto1}</span></p>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Peso a pz gr: </span><span>{isMaterial.pesoLordo1}</span></p>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Assorbimento massimo ml/pz: </span><span>{isMaterial.lunghezza1}</span></p>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Assorbimento dopo strizzaggio ml/pz: </span><span>{isMaterial.larghezza1}</span></p>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Decitex: </span><span>{isMaterial.PanDecitex}</span></p>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Cicli lavaggio / vita utile panno: </span><span>{isMaterial.PanVitaUtile}</span></p>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Temperatura massima lavaggio: </span><span>{isMaterial.PanTemperaturaLavaggio}</span></p>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Codice colore: </span><span>{isColore(isMaterial.PanCodiceColore)}</span></p>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Certificazioni: </span><span>{isMaterial.PanCertificazioni}</span></p>
                                </div>
                                </>
                              )
                            default:
                              return null
                          }
                        })() */}
                      </div>
                      </div>
                  </div>
                  <Modal isOpen={modalImgSee} toggle={toggleImgSee} centered={true} modalClassName="cleaning-modal remove-bg-cleaning">
                            <img className="light-shadow" alt={isMaterial.descrizione1} src={fileServer + isMaterial.percorsoImmagine + isMaterial.IDattrezzatura + '.png'} />
                  </Modal>
              </div> : null}
          </Fragment>
      );
  }

export default AttrezzatureProfilo;
