import React, { useEffect, useState, useHistory, Fragment } from 'react'
import {Link} from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import FileViewer from "react-file-viewer";
var mammoth = require("mammoth");




const CapitolatoProfile = (props) => {

  const [allCapitolati, setCapitolati] = useState([{}])
  const [clientiPotenziali, setClientiPotenziali] = useState([])
  const [loading, setLoading] = useState(true)
  const [viewDoc, setDoc] = useState(null)


  useEffect(() => {
      let mounted = true
      const getUser = async () => {
        const documenti = await axios.get(`${apiEndpoint}/capitoli-progetto/capitolati/fetch-profilo/${props.match.params.id}/${localStorage.getItem("userID")}`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        setCapitolati(documenti.data)
        mammoth.convertToHtml({path: allCapitolati.PercorsoDocumento})
      .then(function(result){
          var html = result.value; // The generated HTML
          var messages = result.messages; // Any messages, such as warnings during conversion
          setDoc(html)

      })
      .done();
      }
      getUser()
      return () => {
        mounted = false
      }
  }, [])

  const onError = e => {
  console.log(e, "error in file-viewer");
};


  return (
    <Fragment>
        <Breadcrumb title="I miei progetti" parent="Progetti" />
        {loading ? <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                    {viewDoc}
                    </div>
                </div>
            </div>
        </div> : null}
    </Fragment>
  )
}

export default CapitolatoProfile
