import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import Select from 'react-select';



const OperazioniEdit = (props) => {

    const [data,setData] = useState([])
    const [userData, setUserData] = useState({})
    const [allAreas, setAreas] = useState([{}])
    const [allAmbience, setAmbience] = useState([{}])
    const [loadingAmbience, setLoadingAmbience] = useState(true)
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [loading4, setLoading4] = useState(false)
    const [loading5, setLoading5] = useState(false)
    const [loading6, setLoading6] = useState(false)
    const [loadingAtt, setLoadingAtt] = useState(true)
    const [fileImage, setFile] = useState(null)
    const [allSuperfici, viewSuperfici] = useState([{}])
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [isArea, setArea] = useState('')
    const [isAmbiente, setAmbiente] = useState('')
    const [isMateriale, setMateriale] = useState([])
    const [isSuperficie, setSuperficie] = useState('')
    const [isAttrezzatura, setValueAttrezzatura] = useState([])
    const [isDPI, setValueDPI] = useState([])
    const [isMacchinario, setValueMacchinari] = useState([])
    const [isFrequenza, setFrequenza] = useState('')
    const [allAttrezzatura, setAttrezzatura] = useState([{}])
    const [allMateriali, setMateriali] = useState([{}])
    const [allMacchinari, setMacchinari] = useState([{}])
    const [allDPI, setDPI] = useState([{}])
    const [isUnita, setUnita] = useState('')
    const [isOperazione, setOperazione] = useState({})

    const [defaultMateriale, setDefaultMateriale] = useState([])
    const [defaultDPI, setDefaultDPI] = useState([])
    const [defaultAttrezzatura, setDefaultAttrezzatura] = useState([])
    const [defaultMacchinario, setDefaultMacchinario] = useState([])
    const [defaultSuperficie, setDefaultSuperficie] = useState({})
    const [defaultFrequenza, setDefaultFrequenza] = useState({})

    const history = useHistory()

    useEffect(() => {
      const getAllData = async () => {
        const aree =  axios.get(`${apiEndpoint}/aree-ambienti/fetch-aree-ambienti`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const materiali =  axios.get(`${apiEndpoint}/operazioni/fetch-materiali`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const attrezzature =  axios.get(`${apiEndpoint}/operazioni/fetch-attrezzature`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const macchinari =  axios.get(`${apiEndpoint}/operazioni/fetch-macchinari`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const dpi =  axios.get(`${apiEndpoint}/operazioni/fetch-dpi`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const superfici = await axios.get(`${apiEndpoint}/operazioni/fetch-superfici`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const operazione = await axios.get(`${apiEndpoint}/operazioni/fetch-pr/${props.match.params.id}`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const articoliOperazioni = await axios.get(`${apiEndpoint}/operazioni/fetch-operazioni-articoli`, {
          params: {
            id: props.match.params.id
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })

        setOperazione(operazione.data)

        aree.then(res => {
          let are = res.data
          let areArray = []
          are.map((item, index) => {
            let obj = {
              value: item.ambiente,
              label: item.ambiente
            }
            areArray.push(obj)
          })
          setAreas(areArray)
        })

        //Preparo il select dei materiali
        let m = operazione.data.IDmateriale
        let matSplit = articoliOperazioni.data
        let defMatArray = []
        materiali.then(res => {
          let mat = res.data
          let matArray = []
          mat.map((item, index) => {
            let obj = {
              value: item.IDarticolo,
              label: item.descrizione1
            }
            const found = matSplit.find(element => element.IDarticolo === item.IDarticolo)
            if(found) {
              let mm = {
                value: item.IDarticolo,
                label: item.descrizione1
              }
              defMatArray.push(mm)
            }
            matArray.push(obj)
          })
          setMateriali(matArray)
          setDefaultMateriale(defMatArray)
          setLoading(true)
        })


        //Preparo il select dei DPI
        let d = operazione.data.IDdpi
        let dpiSplt = articoliOperazioni.data
        let defDpiArray = []
        dpi.then(res => {
          let dp = res.data
          let dpArray = []
          dp.map((item, index) => {
            let obj = {
              value: item.IDarticolo,
              label: item.descrizione1
            }
            const found = dpiSplt.find(element => element.IDarticolo === item.IDarticolo)
            if(found) {
              let mm = {
                value: item.IDarticolo,
                label: item.descrizione1
              }
              defDpiArray.push(mm)
            }
            dpArray.push(obj)
          })
          setDPI(dpArray)
          setDefaultDPI(defDpiArray)
          setLoading2(true)
        })



        //Preparo i select per le attrezzature
        let a = operazione.data.IDattrezzatura
        let attSplit = articoliOperazioni.data
        let defAttArray = []
        attrezzature.then(res => {
          let att = res.data
          let attArray = []
          att.map((item, index) => {
            let obj = {
              value: item.IDattrezzatura,
              label: item.Descrizione
            }
            const found = attSplit.find(element => element.IDattrezzatura === item.IDattrezzatura)
            if(found) {
              let mm = {
                value: item.IDattrezzatura,
                label: item.Descrizione
              }
              defAttArray.push(mm)
            }
            attArray.push(obj)
          })
          setDefaultAttrezzatura(defAttArray)
          setAttrezzatura(attArray)
          setLoading3(true)
        })



        //Preparo i select per i macchinari
        let mc = operazione.data.IDmacchinario
        let macSplt = articoliOperazioni.data
        let defMacArray = []
        macchinari.then(res => {
          let mac = res.data
          let macArray = []
          mac.map((item, index) => {
            let obj = {
              value: item.IDattrezzatura,
              label: item.Descrizione
            }
            const found = macSplt.find(element => element.IDattrezzatura === item.IDattrezzatura)
            if(found) {
              let mm = {
                value: item.IDattrezzatura,
                label: item.Descrizione
              }
              defMacArray.push(mm)
            }
            macArray.push(obj)
          })
          setMacchinari(macArray)
          setDefaultMacchinario(defMacArray)
          setLoading4(true)
        })

        let supArray = []
        superfici.data.map(item => {
          let obj = {
            value: item.IDsuperficie,
            label: item.superficie
          }
          if(item.IDsuperficie === operazione.data.IDsuperficie) {
            setDefaultSuperficie({
              value: operazione.data.IDsuperficie,
              label: item.superficie
            })
          }
          supArray.push(obj)

        })
        frequenze.map(item => {
          if(parseInt(operazione.data.frequenza) === item.value) {
            setDefaultFrequenza({
              value: operazione.data.frequenza,
              label: item.label
            })
          }
          setLoading6(true)
        })
        viewSuperfici(supArray)
        setLoading5(true)
      }

      getAllData()
    }, [])

    const toggle = () => {
        setModal(!modal)
    }

    const toggleDel = () => {
        setModalDel(!modalDel)
    }

    const toggleImg = () => {
        setModalImg(!modalImg)
    }

    const toggleImgSee = () => {
        setModalImgSee(!modalImgSee)
    }

    const frequenze = [
      {
        value: 2,
        label: '1 volta a settimana'
      },
      {
        value: 3,
        label: '2 volte a settimana'
      },
      {
        value: 4,
        label: '3 volte a settimana'
      },
      {
        value: 14,
        label: '4 volte a settimana'
      },
      {
        value: 1,
        label: '5 volte settimana'
      },
      {
        value: 15,
        label: '6 volte a settimana'
      },
      {
        value: 16,
        label: '7 volte a settimana'
      },
      {
        value: 5,
        label: 'Decadale'
      },
      {
        value: 6,
        label: 'Quindicinale'
      },
      {
        value: 7,
        label: 'Mensile'
      },
      {
        value: 8,
        label: 'Bimestrale'
      },
      {
        value: 9,
        label: 'Trimestrale'
      },
      {
        value: 10,
        label: 'Quadrimestrale'
      },
      {
        value: 11,
        label: 'Semestrale'
      },
      {
        value: 12,
        label: 'Annuale'
      },
      {
        value: 13,
        label: 'Spot'
      },

    ]

    const unity = [, {
      value: 'MQ',
      label: 'Metri quadri'
    },{
      value: 'PUNTI',
      label: 'Punti'
    }]
    const [isErrObb, setErrObb] = useState("")

    const toggleDD = () => setDropdownOpen(prevState => !prevState)

    const onSubmit = (data) => {
      let descrizione = data.operazione ? data.operazione : isOperazione.descrizione
      let superficie = isSuperficie ? isSuperficie : defaultSuperficie.value
      let userID = localStorage.getItem("userID")
      let area = isArea ? isArea : isOperazione.area
      let ambiente = isAmbiente ? isAmbiente : isOperazione.ambiente
      let frequenza = isFrequenza ? isFrequenza : defaultFrequenza.value
      let materiale = isMateriale.length > 0 ? isMateriale : defaultMateriale
      let attrezzatura = isAttrezzatura.length > 0 ? isAttrezzatura : defaultAttrezzatura
      let dpi = isDPI.length > 0 ? isDPI : defaultDPI
      let macchinario = isMacchinario.length > 0 ? isMacchinario : defaultMacchinario
      let resa = data.resa
      if(isSuperficie || defaultSuperficie) {
      const updateData = async () => {

          const update = await axios.patch(`${apiEndpoint}/operazioni/patch`, {
            descrizione: descrizione.replace(/'/g, ' '),
            superficie: superficie,
            id: props.match.params.id,
            area: area,
            ambiente: ambiente,
            frequenza: frequenza,
            materiale: materiale,
            attrezzatura: attrezzatura,
            macchinario: macchinario,
            resa : resa,
            dpi: dpi
          }, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          history.push("/operazioni/lista")
        }
        updateData()
      } else {
        setErrObb('Campo obbligatorio')
      }
    }

    const selectStyles = {
      control: styles => ({ ...styles, borderRadius: '10px'})
    }

    const resetForm = () => {
      reset()
    }

    const onDeleteAccount = () => {

         const deleteAccount = async () => {
          const delAcc = await axios.delete(`${apiEndpoint}/operazioni/delete-op/${props.match.params.id}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          history.push("/operazioni/lista")
        }
        deleteAccount()
    }

    const handleChangeArea = e => {
      setArea(e.value)
      const ambiente = axios.get(`${apiEndpoint}/aree-ambienti/fetch-ambienti/${e.value}`, {
        headers: {
          "x-access-token": localStorage.getItem('jwt')
        }
      })
      ambiente.then(res => {
        let amb = res.data
        let ambArray = []
        amb.map((item, index) => {
          let obj = {
            value: item.area,
            label: item.area
          }
          ambArray.push(obj)
        })
        setAmbience(ambArray)
        setLoadingAmbience(false)
      })
    }

    const handleChangeAmbiente = e => {
      setAmbiente(e.value)
    }

    const handleChangeMateriali = e => {
      setMateriale(e)
    }

    const handleChangeFrequenza = e => {
      setFrequenza(e.value)
    }

    const handleChangeSuperificie = e => {
      setSuperficie(e.value)
    }

    const handleChangeAttrezzature = e => {
      setValueAttrezzatura(e)
    }

    const handleChangeDPI = e => {
      setValueDPI(e)
    }

    const handleChangeMacchinari = e => {
      setValueMacchinari(e)
    }

    const handleChangeUnita = e => {
      setUnita(e.value)
    }

    if(isLoggedOut) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/login`} />)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Operazioni" title="Modifica" />
            {loading && loading2 && loading3 && loading4 && loading5 &&loading6 ? <><div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12 card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{isOperazione.descrizione}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Area"}<span className="required-field">*</span></label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci area"
                                              onChange={(e) => handleChangeArea(e)}
                                              options={allAreas}
                                              styles={selectStyles}
                                              defaultValue={{
                                                value: isOperazione.area,
                                                label: isOperazione.area
                                              }}
                                              required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Ambiente"}<span className="required-field">*</span></label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci ambiente"
                                              onChange={(e) => handleChangeAmbiente(e)}
                                              options={allAmbience}
                                              styles={selectStyles}
                                              defaultValue={{
                                                value: isOperazione.ambiente,
                                                label: isOperazione.ambiente
                                              }}
                                              required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Operazione"}<span className="required-field">*</span></label>
                                            <input className="form-control cleaning-form" name="operazione" type="text" defaultValue={isOperazione.descrizione} placeholder="es. La mia operazione" {...register("operazione", {required: true})}/>
                                            {errors.operazione && <p className="err-msg-form">Campo obbligatorio</p>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Frequenza"}</label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci frequenza"
                                              onChange={(e) => handleChangeFrequenza(e)}
                                              options={frequenze}
                                              styles={selectStyles}
                                              defaultValue={defaultFrequenza}
                                            />
                                            {isErrObb ? <p className="err-msg-form">{isErrObb}</p> : null}

                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Resa (mq/h)"} </label>
                                            <input className="form-control cleaning-form" name="resa" type="text" placeholder="Inserisci resa" defaultValue={isOperazione.resaMediaOraria} {...register("resa")}/>
                                            {errors.resa && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label cleaning-form-label">{"Superificie"}<span className="required-field">*</span></label>
                                          <Select
                                            className="cleaning-form"
                                            placeholder="Inserisci frequenza"
                                            onChange={(e) => handleChangeSuperificie(e)}
                                            options={allSuperfici}
                                            defaultValue={defaultSuperficie}
                                            styles={selectStyles}
                                          />
                                          {isErrObb ? <p className="err-msg-form">{isErrObb}</p> : null}

                                    </div>
                                    </div>
                                    </div>
                                    <hr />

                                    <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Materiali"} </label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci materiali"
                                              onChange={e => handleChangeMateriali(e)}
                                              options={allMateriali}
                                              defaultValue={defaultMateriale}
                                              styles={selectStyles}
                                              isMulti
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Attrezzature"} </label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci attrezzature"
                                              options={allAttrezzatura}
                                              styles={selectStyles}
                                              defaultValue={defaultAttrezzatura}
                                              onChange={e => handleChangeAttrezzature(e)}
                                              isMulti
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"DPI"} </label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci D.P.I"
                                              options={allDPI}
                                              styles={selectStyles}
                                              onChange={e => handleChangeDPI(e)}
                                              defaultValue={defaultDPI}
                                              isMulti
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Macchinari"}</label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci macchinari"
                                              options={allMacchinari}
                                              styles={selectStyles}
                                              onChange={(e) => handleChangeMacchinari(e)}
                                              defaultValue={defaultMacchinario}
                                              isMulti
                                            />
                                        </div>
                                    </div>

                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                <button className="btn cleaning-btn-login cleaning-cancel-btn" type="button" onClick={() => history.push(`${process.env.PUBLIC_URL}/operazioni/lista`)}>{"Annulla"}</button>
                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Modifica"}</button>
                                    <button className="btn btn-danger cleaning-btn-login" type="button" onClick={toggleDel}>{"Elimina"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div></> : <div className="spinner-loading-cleaning"><div className="loader-box">
              <div className="loader">
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
              </div>
            </div></div>}

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Operazione aggiunta con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={toggle} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>

            {/* Modale per eliminazione account*/}
            <Modal isOpen={modalDel} toggle={toggleDel} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggleDel}>Attenzione!</ModalHeader>
                <ModalBody>
                    <p>Stai per <strong>eliminare</strong> l'operazione, sei sicuro di voler procedere?</p>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={toggleDel} className="cleaning-cancel-btn">{"Annulla"}</Button>
                    <Button color="danger" type="submit" className="btn-cleaning" onClick={() => onDeleteAccount()}>{"Elimina"}</Button>
                </ModalFooter>
            </Modal>
            {/* Modale per eliminazione account*/}


        </Fragment>
    );
};

export default OperazioniEdit;
