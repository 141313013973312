import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import Select from 'react-select';




const ParagrafoEdit = (props) => {

    const [userData, setUserData] = useState({})
    const [allCategories, setCategories] = useState([{}])
    const [allAmbience, setAmbience] = useState([{}])
    const [loadingAmbience, setLoadingAmbience] = useState(true)
    const [loading, setLoading] = useState(false)
    const [loadingAtt, setLoadingAtt] = useState(true)
    const [fileImage, setFile] = useState(null)
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const { register: registerDel, handleSubmit: handleSubmitDel, formState: { errors: errorsDel } } = useForm();
    const history = useHistory()
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [isEcolabel, setEcolabel] = useState(null)
    const [isMaterial, setMaterial] = useState('')
    const [isCodiceColore, setCodiceColore] = useState('')
    const [allProgetti, setProgetti] = useState([{}])
    const [isCapitolo, setCapitolo] = useState(null)
    const [isParagrafo, setParagrafo] = useState(null)
    const [allParagrafi, setParagrafi] = useState({})

    const [selectedDocumento, setSelectedDocumento] = useState({})
    const [chooseCapitoli, viewCapitoli] = useState([{}])


    const [capitoloSelected, setCapitoloSelected] = useState('')



    useEffect(() => {
        let mounted = true
        const getUser = async () => {
          const documenti = await axios.get(`${apiEndpoint}/capitoli-progetto/fetch-paragrafo-id/${props.match.params.id}/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          const capitoli = await axios.get(`${apiEndpoint}/capitoli-progetto/fetch/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          setParagrafi(documenti.data)
          let capArray = []
          capitoli.data.map(item => {
            let obj = {
              value: item.IDcapitolo,
              label: item.Descrizione
            }
            if(documenti.data.IDcapitolo === item.IDcapitolo) {
              let cap = {
                value: item.IDcapitolo,
                label: item.Descrizione
              }
              console.log(cap)
              setSelectedDocumento(cap)
            }
            capArray.push(obj)
          })
          viewCapitoli(capArray)
          setLoading(true)
        }
        getUser()
        return () => {
          mounted = false
        }
    }, [])

    //Generazione randomica di stringhe
    function makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() *
     charactersLength)));
       }
       return result.join('');
    }

    const toggle = () => {
        setModal(!modal)
    }

    const toggleClose = () => {
      setModal(!modal)
      //history.push(`${process.env.PUBLIC_URL}/macchinari/lista`)
    }

    const toggleDel = () => {
        setModalDel(!modalDel)
    }

    const onSubmit = (data) => {
        let formData = new FormData()
        let titolo = data.titolo
        let userID = localStorage.getItem("userID")

        formData.append("titolo", titolo)
        formData.append("id", props.match.params.id)
        formData.append("userID", userID)
        formData.append("capitolo", capitoloSelected ? Number(capitoloSelected) : allParagrafi.IDcapitolo)
        formData.append("old_capitolo", allParagrafi.IDcapitolo)
        formData.append("paragrafo", allParagrafi.IDparagrafo)
        formData.append("admin", localStorage.getItem("email"))

            //Chiamata api
            const updateData = async () => {
                const update = await axios.patch(`${apiEndpoint}/capitoli-progetto/patch-paragrafo`, formData, {
                  headers: {
                    "x-access-token": localStorage.getItem('jwt')
                  }
                })
                history.push(`${process.env.PUBLIC_URL}/paragrafi/lista`)
              }
          updateData()

    }


          const onDeleteAccount = data => {
               const deleteAccount = async () => {
                const delAcc = await axios.delete(`${apiEndpoint}/capitoli-progetto/delete-paragrafo/${props.match.params.id}`, {
                  headers: {
                    "x-access-token": localStorage.getItem('jwt')
                  }
                })
                if(delAcc.data.status === 404) {
                  toggleDel()
                  setErrPwd(delAcc.data.msg)
                  toggle()
                } else {
                  history.push(`${process.env.PUBLIC_URL}/paragrafi/lista`)
                }
              }
              deleteAccount()
          }

    const selectStyles = {
      control: styles => ({ ...styles, borderRadius: '10px'})
    }

    const resetForm = () => {
      reset()
    }


    if(isLoggedOut) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/login`} />)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Paragrafi" title="Aggiungi" />
            {loading ? <><div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12 card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Modifica paragrafo"}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Capitoli"}</label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Seleziona capitolo"
                                              onChange={e => setCapitoloSelected(e.value)}
                                              options={chooseCapitoli}
                                              styles={selectStyles}
                                              defaultValue={selectedDocumento}
                                              required
                                            />
                                        </div>
                                    </div>



                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Titolo"} </label>
                                            <input className="form-control cleaning-form" name="titolo" defaultValue={allParagrafi.Descrizione} type="text" placeholder="Inserisci titolo es. Documento tecnico ambienti" {...register("titolo")} required/>
                                            {errors.titolo && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    </div>

                                    <span className="required-field">*</span>tutti i campi sono richiesti
                                </div>
                                <div className="card-footer text-right">
                                <button className="btn cleaning-btn-login cleaning-cancel-btn" type="button" onClick={() => history.push(`${process.env.PUBLIC_URL}/paragrafi/lista`)}>{"Annulla"}</button>

                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Modifica"}</button>
                                    <button className="btn btn-danger cleaning-btn-login" type="button" onClick={() => toggleDel()}>{"Elimina"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div></> : <div className="spinner-loading-cleaning"><div className="loader-box">
              <div className="loader">
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
              </div>
            </div></div>}

            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Attenzione!</ModalHeader>
                <ModalBody>
                    <p>{errPwd}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => history.push(`${process.env.PUBLIC_URL}/paragrafi/lista`)} className="btn-cleaning">{"Ho capito"}</Button>
                </ModalFooter>
            </Modal>


                          {/* Modale per eliminazione account*/}
                          <Modal isOpen={modalDel} toggle={toggleDel} centered={true} modalClassName="cleaning-modal">
                            <form onSubmit={handleSubmitDel(onDeleteAccount)}>
                              <ModalHeader toggle={toggleDel}>Attenzione!</ModalHeader>
                              <ModalBody>
                                  <p>Stai per <strong>eliminare</strong> il tuo paragrafo, sei sicuro di voler procedere?</p>
                              </ModalBody>
                              <ModalFooter>
                              <Button onClick={toggleDel} className="cleaning-cancel-btn">{"Annulla"}</Button>
                                  <Button color="danger" type="submit" className="btn-cleaning">{"Elimina"}</Button>
                              </ModalFooter>
                            </form>
                          </Modal>
                          {/* Modale per eliminazione account*/}


        </Fragment>
    );
};

export default ParagrafoEdit;
