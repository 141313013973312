import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import CKEditors from "react-ckeditor-component";



const TemplatesEdit = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [modal, setModal] = useState();
    const [ allTemplates, setTemplates] = useState({})
    const [loading, setLoading] = useState(false)
    const [content,setContent] = useState('Inserisci il corpo del tuo template qui')
    const { register: registerDel, handleSubmit: handleSubmitDel, formState: { errors: errorsDel } } = useForm();

    const onChange = (evt) => {
        const newContent = evt.editor.getData();
        setContent(newContent)
    }
    let { id } = useParams()
    let history = useHistory()

    useEffect(() => {
      let mounted = true
      const fetch = async () => {
        const templates = await axios.get(`${apiEndpoint}/templates/fetch-profilo`, {
          params: {
            id: id,
            userID: localStorage.getItem("userID")
          },
          headers: {
            "x-access-token": localStorage.getItem("jwt")
          }
        })
        if(mounted) {
          console.log(templates.data)
          setTemplates(templates.data)
          setContent(templates.data.testoCorpo)
          setLoading(true)
        }
      }
      fetch()
      return () => {
        mounted = false
      }
    }, [])

    const onDeleteAccount = data1 => {
         const deleteAccount = async () => {
          const delAcc = await axios.delete(`${apiEndpoint}/templates/delete`, {
            params: {
              idIncidenza: props.match.params.id,
              userID: localStorage.getItem("userID")
            },
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          toggleDel()
          history.push(`${process.env.PUBLIC_URL}/templates/lista`)
        }
        deleteAccount()
    }

    //Generazione randomica di stringhe
    function makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() *
     charactersLength)));
       }
       return result.join('');
    }

    const toggle = () => {
        setModal(!modal)
    }

    const onSubmit = (data) => {
        const updateData = async () => {
            const update = await axios.patch(`${apiEndpoint}/templates/patch`, {
              descrizione: data.descrizione,
              corpo: content,
              userID: localStorage.getItem("userID"),
              id: id
            }, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            history.push(`${process.env.PUBLIC_URL}/templates/lista`)

          }
      updateData()
    }

    const resetForm = () => {
      reset()
    }

    const [modalDel, setModalDel] = useState();
    const [errDel, setErrDel] = useState('')


    const toggleDel = () => {
        setModalDel(!modalDel)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Templates" title="Modifica" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12 card">
                            {loading ? <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Modifica template"}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                      <div className="col-sm-12 col-md-12">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{"Descrizione"}<span className="required-field">*</span> </label>
                                              <input className="form-control cleaning-form" defaultValue={allTemplates.descrizione} name="descrizione" type="text" placeholder="Inserisci descrizione es. PIÉ DI PAGINA Cleaning Academy" {...register("descrizione")} required/>
                                              {errors.descrizione && <p className="err-msg-form">Campo richiesto</p>}
                                          </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 col-sm-12">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{"Corpo"}<span className="required-field">*</span></label>
                                              <CKEditors
                                                      activeclassName="p10"
                                                      content={content}
                                                      events={{
                                                          "change": onChange
                                                      }}
                                              />
                                          </div>
                                      </div>
                                    </div>
                                    <span className="required-field">*</span>tutti i campi sono richiesti
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Aggiungi"}</button>
                                    <button className="btn btn-danger cleaning-btn-login" type="button" onClick={() => toggleDel()}>{"Elimina"}</button>
                                </div>
                            </form> : null}
                        </div>
                    </div>
                </div>
            </div>

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Modifica effettuata con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={toggle} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>


                        {/* Modale per eliminazione account*/}
                        <Modal isOpen={modalDel} toggle={toggleDel} centered={true} modalClassName="cleaning-modal">
                          <form onSubmit={handleSubmitDel(onDeleteAccount)}>
                            <ModalHeader toggle={toggleDel}>Attenzione!</ModalHeader>
                            <ModalBody>
                                <p>Stai per <strong>eliminare</strong> il tuo template, sei sicuro di voler procedere?</p>
                            </ModalBody>
                            <ModalFooter>
                            <Button type="button" className="cleaning-cancel-btn" onClick={() => toggleDel()}>{"Annulla"}</Button>

                                <Button color="danger" type="submit" className="btn-cleaning">{"Elimina"}</Button>
                            </ModalFooter>
                          </form>
                        </Modal>


        </Fragment>
    );
};

export default TemplatesEdit;
