import React, { Fragment } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Home, Activity, Users } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import HomeComponent from './home-component';
import BudgetComponent from './budget-component';
import UserComponent from './user-component';
import { Homes,BudgetSummary,TeamMembers } from "../../../constant";

const Project = () => {
        return (
            <Fragment>
                <Breadcrumb title="Benvenuto" parent="Dashboard" />
                <div className="container-fluid">
                    <div className="row theme-tab">
                      <HomeComponent />
                    </div>
                </div>
            </Fragment>
        );
}

export default Project;
