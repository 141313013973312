import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';





const Unisci = () => {

    const [data,setData] = useState([])
    const [allProgetti, setProgetti] = useState([{}])
    const [clientiPotenziali, setClientiPotenziali] = useState([])
    const [loading, setLoading] = useState(true)
    const history = useHistory()




    return (
        <Fragment>
            <Breadcrumb title="Capitoli" parent="Progetto tecnico" />
            {loading ? <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                          <div className="card-body datatable-react">
                            <div className="row">

                              <button  className="btn btn-primary cleaning-btn-login" type="submit">{"Unisci"}</button>
                              <br />

                              <a href={fileServer + '/default/merged.docx'} className="btn btn-primary cleaning-btn-login" download>Clicca per scaricare il file</a>

                            <div className="card-body datatable-react">

                            </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div> : null}
        </Fragment>
    );
};

export default Unisci;
