import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import Select from 'react-select';




const DocumentoAdd = () => {

    const [userData, setUserData] = useState({})
    const [allCategories, setCategories] = useState([{}])
    const [allAmbience, setAmbience] = useState([{}])
    const [loadingAmbience, setLoadingAmbience] = useState(true)
    const [loading, setLoading] = useState(true)
    const [loadingAtt, setLoadingAtt] = useState(true)
    const [fileImage, setFile] = useState(null)
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [isEcolabel, setEcolabel] = useState(null)
    const [isMaterial, setMaterial] = useState('')
    const [isCodiceColore, setCodiceColore] = useState('')
    const [allProgetti, setProgetti] = useState([{}])
    const [isCapitolo, setCapitolo] = useState(null)
    const [isParagrafo, setParagrafo] = useState(null)
    const [allParagrafi, setParagrafi] = useState(null)
    const [chooseParagrafo, setChooseParagrafo] = useState([{}])
    const [loadingp, setLoadingP] = useState(true)

    useEffect(() => {
        let mounted = true
        const getUser = async () => {
          const documenti = await axios.get(`${apiEndpoint}/capitoli-progetto/fetch/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          const paragrafi = await axios.get(`${apiEndpoint}/capitoli-progetto/fetch-paragrafi/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          let array = []
          documenti.data.map(item => {
            let obj = {
              value: item.IDcapitolo,
              label: item.Descrizione
            }
            array.push(obj)
          })
          setProgetti(array)
          setParagrafi(paragrafi.data)
          setLoading(false)

        }
        getUser()
        return () => {
          mounted = false
        }
    }, [])

    //Generazione randomica di stringhe
    function makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() *
     charactersLength)));
       }
       return result.join('');
    }

    const toggle = () => {
        setModal(!modal)
    }

    const onSubmit = (data) => {
        let formData = new FormData()
        let file = data.file[0]
        let numeroPagine = data.numeroPagine
        let titolo = data.titolo.replace(/'/g, '');
        let userID = localStorage.getItem("role") === 'Admin' ? null : localStorage.getItem("userID")
        let newDoc = data.file[0]
        let docSize = newDoc.size
        let docType = newDoc.type

        formData.append("file", file)
        formData.append("titolo", titolo)
        formData.append("capitolo", isCapitolo)
        formData.append("paragrafo", isParagrafo)
        formData.append("numeroPagine", numeroPagine)
        formData.append("userID", userID)
        formData.append("tipo", docType)
        formData.append("admin", localStorage.getItem("email"))

        if(docType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || docType === 'application/msword') {
          if(docSize <= 5000000) {
            //Chiamata api
            const updateData = async () => {
                const update = await axios.post(`${apiEndpoint}/capitoli-progetto/upload/documenti`, formData, {
                  headers: {
                    "x-access-token": localStorage.getItem('jwt')
                  }
                })
                reset()
                toggle()
              }
          updateData()
          } else {
            window.alert('Dimensione massima consentita 5MB.')
          }
        } else {
          window.alert('Puoi caricare solo immagini in formato .doc, .docx')
        }
    }

    const selectStyles = {
      control: styles => ({ ...styles, borderRadius: '10px'})
    }

    const resetForm = () => {
      reset()
    }

    const changeCapitolo = capitolo => {
      setCapitolo(capitolo)
      const getPar = async () => {
        const paragrafi = await axios.get(`${apiEndpoint}/capitoli-progetto/fetch-paragrafi-select/${capitolo}/${localStorage.getItem("userID")}`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        setChooseParagrafo(paragrafi.data)
        setLoadingP(false)
      }
      getPar()
    }


    if(isLoggedOut) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/login`} />)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Documenti" title="Aggiungi" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12 card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Nuovo documento"}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Capitoli"}</label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Seleziona capitolo"
                                              onChange={e => changeCapitolo(e.value)}
                                              options={allProgetti}
                                              styles={selectStyles}
                                              isLoading={loading}
                                              required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Paragrafi"}</label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Seleziona il capitolo per vedere i paragrafi"
                                              onChange={e => setParagrafo(e.value)}
                                              options={chooseParagrafo}
                                              styles={selectStyles}
                                              isLoading={loadingp}
                                              required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Titolo"} </label>
                                            <input className="form-control cleaning-form" name="titolo" type="text" placeholder="Inserisci titolo es. Documento tecnico ambienti" {...register("titolo")} required/>
                                            {errors.titolo && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Documento"} <small>Dimensione massima consentita 5MB, estensioni consentite .doc, .docx</small></label>
                                            <input className="form-control cleaning-form" name="file" type="file" placeholder="Puoi caricare sono file .doc, .docx" {...register("file")} required/>
                                            {errors.descrizione && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-3 col-md-3">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Numero pagine"} </label>
                                            <input className="form-control cleaning-form" name="numeroPagine" type="number" placeholder="es. 20" {...register("numeroPagine")} required/>
                                            {errors.numeroPagine && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>



                                    </div>

                                    <span className="required-field">*</span>tutti i campi sono richiesti
                                </div>
                                <div className="card-footer text-right">
                                    <Link to="/documenti/lista" className="btn cleaning-btn-login cleaning-cancel-btn">{"Annulla"}</Link>
                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Aggiungi"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Documento aggiunto con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={toggle} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>


        </Fragment>
    );
};

export default DocumentoAdd;
