import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Prompt } from 'react-router-dom'
import Breadcrumb from '../../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image, EyeOff, Eye, BookOpen, Book } from "react-feather"
import { Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, ListGroup, ListGroupItem, Media, Pagination, PaginationItem, PaginationLink, Container, Row, Col  } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../../constant'
import CKEditors from "react-ckeditor-component";
import Select from 'react-select';
import parse from 'html-react-parser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReactTooltip from 'react-tooltip';




const PreventivoAreeOperazioni = (props) => {


  const frequenze = [
    {
      value: 2,
      label: '1 volta a settimana'
    },
    {
      value: 3,
      label: '2 volte a settimana'
    },
    {
      value: 4,
      label: '3 volte a settimana'
    },
    {
      value: 14,
      label: '4 volte a settimana'
    },
    {
      value: 1,
      label: '5 volte settimana'
    },
    {
      value: 15,
      label: '6 volte a settimana'
    },
    {
      value: 16,
      label: '7 volte a settimana'
    },
    {
      value: 5,
      label: 'Decadale'
    },
    {
      value: 6,
      label: 'Quindicinale'
    },
    {
      value: 7,
      label: 'Mensile'
    },
    {
      value: 8,
      label: 'Bimestrale'
    },
    {
      value: 9,
      label: 'Trimestrale'
    },
    {
      value: 10,
      label: 'Quadrimestrale'
    },
    {
      value: 11,
      label: 'Semestrale'
    },
    {
      value: 12,
      label: 'Annuale'
    },
    {
      value: 13,
      label: 'Spot'
    },

  ]

  function getLavorativi(value) {
    if(value === 1) {
      return 5
    } else if(value === 2) {
      return 1
    } else if(value === 3) {
      return 2
    } else if(value === 4) {
      return 3
    } else if(value === 5) {
      return 1
    } else if(value === 6) {
      return 1
    } else if(value === 7) {
      return 1
    } else if(value === 8) {
      return 1
    } else if(value === 9) {
      return 1
    } else if(value === 10) {
      return 1
    } else if(value === 11) {
      return 1
    } else if(value === 12) {
      return 1
    } else if(value === 13) {
      return 1
    } else if(value === 14) {
      return 4
    } else if(value === 15) {
      return 6
    } else if(value === 16) {
      return 7
    }
  }

  //Generazione randomica di stringhe
  function makeid(length) {
      var result           = [];
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result.push(characters.charAt(Math.floor(Math.random() *
   charactersLength)));
     }
     return result.join('');
  }

    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [modal, setModal] = useState();
    const [allAmbienti, viewAmbienti] = useState({})
    const [allOperazioni, viewOperazioni] = useState([{}])
    const [allOperazioniAll, viewOperazioniAll] = useState([{}])
    const [allOperatori, viewOperatori] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [toggleDiv, setToggleDiv] = useState(false)
    const [toggleDiv2, setToggleDiv2] = useState(false)
    const [toggleArrow, setToggleArrow] = useState(false)
    const [isChecked, setChecked] = useState(false)
    const [isHidden, setHidden] = useState(true)
    const [isHidden2, setHidden2] = useState(true)
    const [isHiddenAll, setHiddenAll] = useState(true)
    const [selectedRow, addSelectedRow] = useState([])
    const [isAll, setAll] = useState(true)
    const [tableAmbient, setTableAmbient] = useState([{
      IDoperazione: '',
      descrizione: '',
      tipoPulizie: ''
    }])


    const [isMateriale, setMateriale] = useState([{}])
    const [isAttrezzatura, setValueAttrezzatura] = useState([{}])
    const [isDPI, setValueDPI] = useState([{}])
    const [isMacchinario, setValueMacchinari] = useState([{}])
    const [isFrequenza, setFrequenza] = useState('')
    const [allAttrezzatura, setAttrezzatura] = useState([{}])
    const [allMateriali, setMateriali] = useState([{}])
    const [allMaterialiFull, setMaterialiFull] = useState([{}])
    const [allMacchinari, setMacchinari] = useState([{}])
    const [allMacchinariFull, setMacchinariFull] = useState([{}])
    const [allDPI, setDPI] = useState([{}])
    const [selectedOperations, setSelectedOperations] = useState([])
    const [selectedSingleOperation, setSelectedSingleOperations] = useState([{}])
    const [enableField, setEnableField] = useState(true)
    const [defaultEnabled, setDefaultEnabled] = useState(true)
    const [isResa, setResa] = useState([{
      id: 0,
      resa: 0
    }])
    const [checkedIndex, setCheckedIndex] = useState([])
    const [mqTotali, setMQTotali] = useState(null)
    const [alreadySelected, setAlreadySelected] = useState([{}])
    const [allPreventivo, setPreventivo] = useState({})

    const [allOperazioniArticoli, viewAllOperazioniArticoli] = useState([{}])


    useEffect(() => {
      let mounted = true
      const fetch = async () => {
        const aree = await axios.get(`${apiEndpoint}/preventivi/fetch-ambienti/${props.prev.ambiente}`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })

        const operazioni = await axios.get(`${apiEndpoint}/operazioni/fetch-for-preventivi`, {
          params: {
            ambiente: props.prev.ambiente,
            userID: localStorage.getItem("userID")
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const defSelected = await axios.get(`${apiEndpoint}/preventivi/fetch-selected`, {
          params: {
            id: props.prev.ID
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const operatori = await axios.get(`${apiEndpoint}/tariffe/fetch/${localStorage.getItem('userID')}`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })




        if(mounted) {
          let selezionate = defSelected.data
          setSelectedOperations(selezionate)
          viewOperazioni(operazioni.data)
          let opz = []

          operazioni.data.map(op => {


            let obj = {
              index: op.ID,
              ID: op.IDoperazione,
              ambiente: op.ambiente,
              area: op.area,
              descrizione: op.descrizione,
              metri: 0,
              operatore: {
                value: 0,
                label: 'Seleziona operatore'
              },
              tariffa: 0,
              frequenza: {
                label: 'Giornaliero (5 volte alla settimana)',
                value: 1
              },
              mesi: 12,
              giorni: 5,
              tempo: 0,
              resa: op.resaMediaOraria > 0 ? op.resaMediaOraria : 0,
              materiale: {
                value: '',
                label: ''
              },
              qtyMateriale: 1,
              prezzoMateriale: 0,
              dpi: {
                value: '',
                label: ''
              },
              qtyDPI: 1,
              prezzoDPI: 0,
              attrezzatura: {
                value: '',
                label: ''
              },
              qtyAttrezzatura: 1,
              prezzoAttrezzatura: 0,
              macchinario: {
                value: '',
                label: ''
              },
              qtyMacchinario: 1,
              prezzoMacchinario: 0,
              prezzoMacchinarioTipo: 'costo'
            }

            opz.push(obj)
          })
          let operatores = operatori.data
            if(defSelected.data.length > 0) {
                defSelected.data.map(item => {
                  if(item.operatore) {
                    var array = operatores.findIndex(x => x.IDcausaleBusta === item.operatore.value)
                    if(array >= 0) {
                      item.operatore.value = operatores[array].IDcausaleBusta
                      item.operatore.label = operatores[array].descrizione
                    }
                    var array2 = frequenze.findIndex(x => x.value === parseInt(item.frequenza.value))
                    if(array2 >= 0) {
                      item.frequenza.label = frequenze[array2].label
                      item.frequenza.value = parseInt(frequenze[array2].value)
                      let freq_val = parseInt(frequenze[array2].value)
                    }
                    let lav = getLavorativi(item.frequenza.value)
                    item.giorni = lav
                    let opz2 = opz.filter(obj => obj.ID === item.ID);
                    opz = opz.filter(obj => obj.ID !== item.ID);
                    item.index = opz2[0].index
                    onClick3(item.IDlotto - 1)
                    onClickHide2(item.IDlotto - 1)
                    opz.unshift(item)
                  }

                })
            }
          setAlreadySelected(defSelected.data)
          let selectedOp = defSelected.data
          let arrayIndex = []
          if(selectedOp.length > 0) {
            selectedOp.map(sop => {
              arrayIndex.push(sop.ID)
            })
          }
          setCheckedIndex(arrayIndex)
          let aaa = []
          setTimeout(() => {
                    aree.data.map((ar, idx) => {
                      let obj = {
                        ID: ar.ID,
                        ambiente: ar.ambiente,
                        name: ar.area,
                      }
                      let arrayopz = []

                      opz.map((oo, oid) => {
                        if(oo.area === ar.area && oo.ambiente === ar.ambiente) {
                          oo.IDlotto = idx + 1
                          oo.token = makeid(35)
                          let lav = getLavorativi(oo.frequenza.value)
                          oo.giorni = lav
                          arrayopz.push(oo)
                        }
                      })
                      obj.operazioni = arrayopz
                      aaa.push(obj)

                    })
                    viewAmbienti(aaa)
                    setLoading2(true)
          }, 1000)
        }
      }
      fetch()

    }, [])


    const [materialiHalf, setMaterialiHalf] = useState([{}])
    const [dpiHalf, setDpiHalf] = useState([{}])
    const [attrezzatureHalf, setAttrezzaturaHalf] = useState([{}])
    const [macchinariHalf, setMacchinariHalf] = useState([{}])

    useEffect(() => {
      let mounted = true
      const getAllData = async () => {
        const prev = await axios.get(`${apiEndpoint}/preventivi/fetch-profilo`, {
          params: {
            id: props.prev.ID,
            userID: localStorage.getItem("userID")
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        setPreventivo(prev.data)
        const materiali = await axios.get(`${apiEndpoint}/preventivi/fetch-materiali`, {
          params: {
            userID: localStorage.getItem('userID')
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const dpi = await axios.get(`${apiEndpoint}/preventivi/fetch-dpi`, {
          params: {
            userID: localStorage.getItem('userID')
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const attrezzature = await axios.get(`${apiEndpoint}/preventivi/fetch-attrezzature`, {
          params: {
            userID: localStorage.getItem('userID')
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const macchinari = await axios.get(`${apiEndpoint}/preventivi/fetch-macchinari`, {
          params: {
            userID: localStorage.getItem('userID')
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const macchinariFull = await axios.get(`${apiEndpoint}/preventivi/fetch-macchinari-full`, {
          params: {
            userID: localStorage.getItem('userID')
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const attrezzatureFull = await axios.get(`${apiEndpoint}/preventivi/fetch-attrezzature-full`, {
          params: {
            userID: localStorage.getItem('userID')
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const materialiFull = await axios.get(`${apiEndpoint}/preventivi/fetch-materiali-full`, {
          params: {
            userID: localStorage.getItem('userID')
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const operatori = await axios.get(`${apiEndpoint}/preventivi/fetch-operatori`, {
          params: {
            userID: localStorage.getItem('userID')
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const operazioniArticoli = await axios.get(`${apiEndpoint}/operazioni/fetch-articoli-operazioni-all`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })

        if(mounted) {

          viewOperatori(operatori.data)
          viewAllOperazioniArticoli(operazioniArticoli.data)
          setMateriali(materiali.data)
          setMaterialiFull(materialiFull.data)
          setDPI(dpi.data)
          setAttrezzatura(attrezzature.data)
          setMacchinari(macchinari.data)
          setMacchinariFull(macchinariFull.data)

          //Creo i parziali per mostrare nella tendina materiali, dpi, attrezzature, macchinari

          //Materiali
          let arrayMat = []
          operazioniArticoli.data.map(item => {
            let id = item.IDarticolo
            let filtered = materialiFull.data.filter(x => x.IDarticolo[0] === item.IDarticolo && x.IDgruppo === 'MAT')
            if(filtered.length > 0) {
              let obj = {
                value: filtered[0].IDarticolo[0],
                label: filtered[0].descrizione1,
                operazione: item.IDoperazione,
                PanVitaUtile: filtered[0].PanVitaUtile,
                Resa: filtered[0].Resa
              }
              arrayMat.push(obj)
            }
          })

          setMaterialiHalf(arrayMat)

          //DPI
          let arrayDPI = []
          operazioniArticoli.data.map(item => {
            let id = item.IDarticolo
            let filtered = materialiFull.data.filter(x => x.IDarticolo[0] === item.IDarticolo && x.IDgruppo === 'DPI')
            if(filtered.length > 0) {
              let obj = {
                value: filtered[0].IDarticolo[0],
                label: filtered[0].descrizione1,
                operazione: item.IDoperazione,
                PanVitaUtile: filtered[0].PanVitaUtile,
                Resa: filtered[0].Resa
              }
              arrayDPI.push(obj)
            }
          })

          setDpiHalf(arrayDPI)

          //Attrezzature
          let arrayAtt = []
          operazioniArticoli.data.map(item => {
            let filtered = attrezzatureFull.data.filter(x => x.IDattrezzatura === item.IDattrezzatura && x.IDtipologia === 'ATT')
            if(filtered.length > 0) {
              let obj = {
                value: filtered[0].IDattrezzatura,
                label: filtered[0].Descrizione,
                operazione: item.IDoperazione,
                VitaUtile: filtered[0].vitaUtile
              }
              console.log(obj)
              arrayAtt.push(obj)
            }
          })

          setAttrezzaturaHalf(arrayAtt)

          //Attrezzature
          let arrayMac = []
          operazioniArticoli.data.map(item => {
            let filtered = macchinariFull.data.filter(x => x.IDattrezzatura === item.IDattrezzatura && x.IDtipologia === 'MAC')
            if(filtered.length > 0) {
              let obj = {
                value: filtered[0].IDattrezzatura,
                label: filtered[0].Descrizione,
                operazione: item.IDoperazione,

              }
              arrayMac.push(obj)
            }
          })

          setMacchinariHalf(arrayMac)


          setLoading(true)


        }

      }
      getAllData()
      return(() => {
        mounted = false
      })
    }, [])




    //Generazione randomica di stringhe
    function makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() *
     charactersLength)));
       }
       return result.join('');
    }

    const toggle = () => {
        setModal(!modal)
    }


    const selectStyles = {
      control: styles => ({ ...styles, borderRadius: '10px'})
    }


    //Generazione randomica di stringhe
    function makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() *
     charactersLength)));
       }
       return result.join('');
    }

    const onSubmit = data => {
      console.log(data)
    }

    const onClick = (id) => {
      setHidden(prev => Boolean(!prev[id]) ? {...prev, [id]: true} : {...prev, [id]: false});
      setHidden2(false);
    }

    const onClick2 = (id) => {
      setHidden2(prev => Boolean(!prev[id]) ? {...prev, [id]: true} : {...prev, [id]: false});
      setHidden(false);
    }

    const onClick3 = (id) => {
      setHidden2(prev => Boolean(!prev[id]) ? {...prev, [id]: true} : {...prev, [id]: true});
      setHidden(false);
    }

    const onClick4 = (id) => {
      setHidden2(prev => Boolean(!prev[id]) ? {...prev, [id]: false} : {...prev, [id]: false});
      setHidden(prev => Boolean(!prev[id]) ? {...prev, [id]: false} : {...prev, [id]: false});
    }

    const onClickChecked = (id) => {
      setChecked(prev => Boolean(!prev[id]) ? {...prev, [id]: true} : {...prev, [id]: false});
    }

    const [checkedHide, setCheckedHide] = useState(true)

    const onClickHide = (id) => {
      setCheckedHide(prev => Boolean(!prev[id]) ? {...prev, [id]: true} : {...prev, [id]: false});
      console.log(checkedHide)
    }

    const onClickHide2 = (id) => {
      setCheckedHide(prev => Boolean(!prev[id]) ? {...prev, [id]: true} : {...prev, [id]: true});
    }

    const onClickMaterialiHalf = (id) => {
      let materiali_half = document.getElementById("mat_half_" + id)
      let materiali_full = document.getElementById("mat_full_" + id)
      materiali_half.style.display = "none"
      materiali_full.style.display = "flex"
    }

    const onClickMaterialiFull = (id) => {
      let materiali_half = document.getElementById("mat_half_" + id)
      let materiali_full = document.getElementById("mat_full_" + id)
      materiali_half.style.display = "flex"
      materiali_full.style.display = "none"
    }

    const onClickDPIHalf = (id) => {
      let materiali_half = document.getElementById("dpi_half_" + id)
      let materiali_full = document.getElementById("dpi_full_" + id)
      materiali_half.style.display = "none"
      materiali_full.style.display = "flex"
    }

    const onClickDPIFull = (id) => {
      let materiali_half = document.getElementById("dpi_half_" + id)
      let materiali_full = document.getElementById("dpi_full_" + id)
      materiali_half.style.display = "flex"
      materiali_full.style.display = "none"
    }

    const onClickMacHalf = (id) => {
      let materiali_half = document.getElementById("mac_half_" + id)
      let materiali_full = document.getElementById("mac_full_" + id)
      materiali_half.style.display = "none"
      materiali_full.style.display = "flex"
    }

    const onClickMacFull = (id) => {
      let materiali_half = document.getElementById("mac_half_" + id)
      let materiali_full = document.getElementById("mac_full_" + id)
      materiali_half.style.display = "flex"
      materiali_full.style.display = "none"
    }

    const onClickAttHalf = (id) => {
      let materiali_half = document.getElementById("att_half_" + id)
      let materiali_full = document.getElementById("att_full_" + id)
      materiali_half.style.display = "none"
      materiali_full.style.display = "flex"
    }

    const onClickAttFull = (id) => {
      let materiali_half = document.getElementById("att_half_" + id)
      let materiali_full = document.getElementById("att_full_" + id)
      materiali_half.style.display = "flex"
      materiali_full.style.display = "none"
    }


    const handleChangeMateriali = e => {
      setMateriale(e)
    }

    const handleChangeAttrezzature = e => {
      setValueAttrezzatura(e)
    }

    const handleChangeDPI = e => {
      setValueDPI(e)
    }

    const handleChangeMacchinari = e => {
      setValueMacchinari(e)
    }

    const resetForm = () => {
      reset()
    }



    const { SearchBar } = Search;
    const columns2 = [{
      dataField: 'descrizione',
      text: 'Descrizione',
      style: {
        position: 'sticky',
        left: '-1px',
        zIndex: 1,
        padding: '1vh',
        backgroundColor: 'white',
        borderLeft: '1px solid lightgrey'
      },
      headerStyle: {
        width: '20vw',
        position: 'sticky',
        left: '-1px',
        background: 'white',
        zIndex: 1,
      },
      classes: 'table-preventivo-td',
      editable: (cell, row, rowIndex, colIndex) => {
       return false;
     },
     formatter: stickyFormatter

    }, {
      dataField: 'metri',
      text: 'Metri quadri / Punti',
      headerStyle: {
        width: '10vw'
      },
      editable: (cell, row, rowIndex, colIndex) => {
       return false;
     },
     formatter: metriQuadriFormatter
    }, {
      dataField: 'operatore',
      text: 'Operatore',
      headerStyle: {
        width: '15vw'
      },
     formatter: operatoriFormatter,
     editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
    }, {
      dataField: 'tariffa',
      text: 'Tariffa',
      headerStyle: {
        width: '10vw'
      },
       editable: (cell, row, rowIndex, colIndex) => {
        return true;
      },
      formatter: (cell, row) => {
        return (
          <input className="form-control cleaning-form" type="number" id={`tariffa${row.ID}`} defaultValue={row.tariffa}/>
        )
      }
    }, {
      dataField: 'frequenza.label',
      text: 'Frequenza',
      headerStyle: {
        width: '10vw'
      },
      editable: (cell, row, rowIndex, colIndex) => {
       return false;
     },
     formatter: frequenzaFormatter
    }, {
      dataField: 'mesi',
      text: 'Mesi lavorativi',
      headerStyle: {
        width: '10vw'
      },
      editable: (cell, row, rowIndex, colIndex) => {
       return true;
     },
     type: 'number',
     validator: (newValue, row, column) => {
       if(newValue < 1) {
         return {
           valid: false,
           message: 'Inserire un valore maggore di 0'
         }
       }
     }
    }, {
      dataField: 'giorni',
      text: 'Giorni lavorativi',
      headerStyle: {
        width: '10vw'
      },
      editable: (cell, row, rowIndex, colIndex) => {
       return true;
     },
     type: 'number',
     formatter: giorniFormatter,
     validator: (newValue, row, column) => {
       if(newValue > 7 || newValue < 1) {
         return {
           valid: false,
           message: 'Inserire un valore compreso tra 1 e 7'
         }
       }
     }
    }, {
      dataField: 'tempo',
      text: 'Tempo stimato (min)',
      headerStyle: {
        width: '10vw'
      },
      editable: (cell, row, rowIndex, colIndex) => {
       return false;
     },
     formatter: tempoFormatter
    }, {
      dataField: 'resa',
      text: 'Resa (mq-punti/h)',
      headerStyle: {
        width: '10vw'
      },
      editable: (cell, row, rowIndex, colIndex) => {
       return false;
     },
     formatter: resaFormatter
    }, {
      dataField: '',
      text: 'Materiali',
      headerStyle: {
        width: '32vw'
      },
     formatter: materialiFormatter,
     editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
  },{
    dataField: 'qtyMateriale',
    text: 'Quantità',
    headerStyle: {
      width: '8vw'
    },
    type: 'number',
     editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
}, {
  dataField: 'prezzoMateriale',
  text: 'Prezzo',
  headerStyle: {
    width: '8vw'
  },
  type: 'number',
  formatter: prezzoMaterialeFormatter,
 editable: (cell, row, rowIndex, colIndex) => {
  return false;
},
} ,{
      dataField: '',
      text: 'DPI',
      headerStyle: {
        width: '32vw'
      },
     formatter: dpiFormatter,
     editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
    },{
      dataField: 'qtyDPI',
      text: 'Quantità',
      headerStyle: {
        width: '8vw'
      },
     editable: (cell, row, rowIndex, colIndex) => {
      return true;
    },
  }, {
    dataField: 'prezzoDPI',
    text: 'Prezzo',
    headerStyle: {
      width: '8vw'
    },
    type: 'number',
    formatter: prezzoDPIFormatter,
   editable: (cell, row, rowIndex, colIndex) => {
    return false;
  },
  }, {
      dataField: '',
      text: 'Attrezzature',
      headerStyle: {
        width: '32vw'
      },
     formatter: attrezzatureFormatter,
     editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
    },{
      dataField: 'qtyAttrezzatura',
      text: 'Quantità',
      headerStyle: {
        width: '8vw'
      },
     editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
  }, {
    dataField: 'prezzoAttrezzatura',
    text: 'Prezzo',
    type: 'number',
    headerStyle: {
      width: '8vw'
    },
    formatter: prezzoAttrezzaturaFormatter,
   editable: (cell, row, rowIndex, colIndex) => {
    return false;
  },
  } ,{
      dataField: '',
      text: 'Macchinari',
      headerStyle: {
        width: '32vw'
      },
     formatter: macchinariFormatter,
     editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
   },
   {
     dataField: 'qtyMacchinario',
     text: 'Quantità',
     headerStyle: {
       width: '8vw'
     },
    editable: (cell, row, rowIndex, colIndex) => {
     return false;
   },
   },
   {
     dataField: 'prezzoMacchinario',
     text: 'Prezzo',
     type: 'number',
     headerStyle: {
       width: '14vw'
     },
     formatter: prezzoMacchinariFormatter,
    editable: (cell, row, rowIndex, colIndex) => {
     return false;
   },
   }
];

    const columns4 = [{
      dataField: 'descrizione',
      text: 'Descrizione',
      style: {
        position: 'sticky',
        left: '-1px',
        zIndex: 1,
        padding: '1vh',
        backgroundColor: 'white',
        borderLeft: '1px solid lightgrey'
      },
      headerStyle: {
        width: '15vw',
        position: 'sticky',
        left: '-1px',
        background: 'white',
        zIndex: 1,
      },
      classes: 'table-preventivo-td',
      editable: (cell, row, rowIndex, colIndex) => {
       return false;
     },
     formatter: stickyFormatter

    }, {
      dataField: 'metri',
      text: 'Metri quadri / Punti',
      headerStyle: {
        width: '10vw'
      },
      editable: (cell, row, rowIndex, colIndex) => {
       return false;
     },
     formatter: metriQuadriFormatter
    }, {
      dataField: 'operatore',
      text: 'Operatore',
      headerStyle: {
        width: '15vw'
      },
     formatter: operatoriFormatter,
     editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
    }, {
      dataField: 'tariffa',
      text: 'Tariffa',
      headerStyle: {
        width: '10vw'
      },
     type: 'number',
     editable: (cell, row, rowIndex, colIndex) => {
      return true;
    },
    formatter: (cell, row) => {
      return (
        <input className="form-control cleaning-form" type="number" id={`tariffa${row.ID}`} defaultValue={row.tariffa}/>
      )
    }
    }, {
      dataField: 'frequenza.label',
      text: 'Frequenza',
      headerStyle: {
        width: '10vw'
      },
      editable: (cell, row, rowIndex, colIndex) => {
       return false;
     },
     formatter: frequenzaFormatter
    }, {
      dataField: 'mesi',
      text: 'Mesi lavorativi',
      headerStyle: {
        width: '10vw'
      },
      editable: (cell, row, rowIndex, colIndex) => {
       return true;
     },
     type: 'number',
     validator: (newValue, row, column) => {
       if(newValue < 1) {
         return {
           valid: false,
           message: 'Inserire un valore maggore di 0'
         }
       }
     }
    }, {
      dataField: 'giorni',
      text: 'Giorni lavorativi',
      headerStyle: {
        width: '10vw'
      },
      editable: (cell, row, rowIndex, colIndex) => {
       return true;
     },
     type: 'number',
     formatter: giorniFormatter,
     validator: (newValue, row, column) => {
       if(newValue > 7 || newValue < 1) {
         return {
           valid: false,
           message: 'Inserire un valore compreso tra 1 e 7'
         }
       }
     }
    }, {
      dataField: 'tempo',
      text: 'Inserisci tempo (min)',
      headerStyle: {
        width: '10vw'
      },
      editable: (cell, row, rowIndex, colIndex) => {
       return false;
     },
     formatter: tempoFormatter
    }, {
      dataField: 'resa',
      text: 'Resa stimata (mq-punti/h)',
      headerStyle: {
        width: '10vw'
      },
      editable: (cell, row, rowIndex, colIndex) => {
       return false;
     },
     formatter: resaFormatter
    }, {
      dataField: '',
      text: 'Materiali',
      headerStyle: {
        width: '32vw'
      },
     formatter: materialiFormatter,
     editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
    },{
      dataField: 'qtyMateriale',
      text: 'Quantità',
      headerStyle: {
        width: '8vw'
      },
      formatter: qtyMaterialeFormatter,
      type: 'number',
      editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
  }, {
    dataField: 'prezzoMateriale',
    text: 'Prezzo',
    headerStyle: {
      width: '8vw'
    },
    type: 'number',
     editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
    formatter: prezzoMaterialeFormatter
  } , {
      dataField: '',
      text: 'DPI',
      headerStyle: {
        width: '32vw'
      },
     formatter: dpiFormatter,
     editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
    },{
      dataField: 'qtyDPI',
      text: 'Quantità',
      headerStyle: {
        width: '8vw'
      },
      formatter: qtyDPIFormatter,
     editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
  }, {
    dataField: 'prezzoDPI',
    text: 'Prezzo',
    headerStyle: {
      width: '8vw'
    },
    type: 'number',
    formatter: prezzoDPIFormatter,
   editable: (cell, row, rowIndex, colIndex) => {
    return false;
  },
  }, {
      dataField: '',
      text: 'Attrezzature',
      headerStyle: {
        width: '32vw'
      },
     formatter: attrezzatureFormatter,
     editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
    },{
      dataField: 'qtyAttrezzatura',
      text: 'Quantità',
      formatter: qtyAttrezzaturaFormatter,
      headerStyle: {
        width: '8vw'
      },
     editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
  }, {
    dataField: 'prezzoAttrezzatura',
    text: 'Prezzo',
    type: 'number',
    headerStyle: {
      width: '8vw'
    },
    formatter: prezzoAttrezzaturaFormatter,
   editable: (cell, row, rowIndex, colIndex) => {
    return false;
  },
  } , {
      dataField: '',
      text: 'Macchinari',
      headerStyle: {
        width: '32vw'
      },
     formatter: macchinariFormatter,
     editable: (cell, row, rowIndex, colIndex) => {
      return false;
    },
   },
   {
     dataField: 'qtyMacchinario',
     text: 'Quantità',
     formatter: qtyMacchinariFormatter,
     headerStyle: {
       width: '8vw'
     },
    editable: (cell, row, rowIndex, colIndex) => {
     return false;
   },
   },
   {
     dataField: 'prezzoMacchinario',
     text: 'Prezzo',
     type: 'number',
     headerStyle: {
       width: '14vw'
     },
     formatter: prezzoMacchinariFormatter,
    editable: (cell, row, rowIndex, colIndex) => {
     return false;
   },
   }
];


    const columns3 = [{
      dataField: 'descrizione',
      text: 'Descrizione',

      editable: (cell, row, rowIndex, colIndex) => {
       return false;
     }
    }];

    function metriQuadriFormatter(cell, row) {
      return (
        <input
          className="form-control cleaning-form"
          placeholder="Inserisci mq"
          type="number"
          step="0.1"
          name="mq"
          id={`mq${row.ID}`}
          defaultValue={row.metri}
          onChange={e => changeMetriQuadri(row, e.target.value)}
        />
      )
    }

    function stickyFormatter(cell, row) {
      return (
        <span>{row.descrizione}</span>
      )
    }

    const cambiaTempo = (row) => {
      var resa = document.getElementById("resa" + row.ID)
      var tempo = document.getElementById("tempo" + row.ID)
      var mq = document.getElementById("mq" + row.ID)

      if(resa.value !== 0 && mq.value !== 0) {
        tempo.value = Math.round( mq.value*60/ resa.value )
        row.tempo = Math.round( mq.value*60/ resa.value )
      } else {
        tempo.value = row.tempo
      }
    }

    function tempoFormatter(cell, row) {



      return (
        <input
          className="form-control cleaning-form"
          placeholder="Inserisci tempo"
          type="number"
          name="tempo"
          id={`tempo${row.ID}`}
          defaultValue={row.tempo}
          onChange={() => inversoResa(row)}
        />
      )
    }


    const inversoResa = (row, e) => {
      var resa = document.getElementById("resa" + row.ID)
      var tempo = document.getElementById("tempo" + row.ID)
      var mq = document.getElementById("mq" + row.ID)

      if(tempo.value !== 0 && mq.value !== 0) {
        resa.value = Math.round(mq.value * 60 / tempo.value)
        row.resa = Math.round(mq.value * 60 / tempo.value)
        row.tempo = tempo.value
      } else {
        resa.value = row.resa
        row.tempo = tempo.value
      }
    }

    const onChangePrezzoMateriale = (e, row) => {

      let price = document.getElementById('prezzo_mat_' + row.ID)
      price.value = e.target.value
      row.prezzoMateriale = e.target.value
    }

    const onChangePrezzoAttrezzatura = (e, row) => {

      let price = document.getElementById('prezzo_att_' + row.ID)
      price.value = e.target.value
      row.prezzoAttrezzatura = e.target.value
    }

    const onChangePrezzoDPI = (e, row) => {

      let price = document.getElementById('prezzo_dpi_' + row.ID)
      price.value = e.target.value
      row.prezzoDPI = e.target.value
    }

    const onChangePrezzoMacchinari = (e, row) => {

      let price = document.getElementById('prezzo_mac_' + row.ID)
      price.value = e.target.value
      row.prezzoMacchinario = e.target.value
    }

    const onChangePrezzoMacchinariCanone = (e, row) => {

      let price = document.getElementById('prezzo_canone_mac_' + row.ID)
      price.value = e.target.value
      row.prezzoMacchinarioCanone = e.target.value
    }

    function prezzoMaterialeFormatter(cell, row) {

      return (
        <input className="form-control cleaning-form" step="0.01" type="number" id={`prezzo_mat_${row.ID}`} defaultValue={row.prezzoMateriale} onChange={e => onChangePrezzoMateriale(e, row)}/>
      )
    }

    function qtyMaterialeFormatter(cell, row) {
      return (
        <span id={`qty_mat_${row.ID}`}>{row.qtyMateriale}</span>
      )
    }

    function giorniFormatter(cell, row) {
      return (
        <span id={`giorni_${row.ID}`}>{row.giorni}</span>
      )
    }

    function prezzoAttrezzaturaFormatter(cell, row) {
      return (
        <input className="form-control cleaning-form" step="0.01" type="number" id={`prezzo_att_${row.ID}`} defaultValue={row.prezzoAttrezzatura} onChange={e => onChangePrezzoAttrezzatura(e, row)}/>
      )
    }

    function qtyAttrezzaturaFormatter(cell, row) {
      return (
        <span id={`qty_att_${row.ID}`}>{row.qtyAttrezzatura}</span>
      )
    }

    function prezzoDPIFormatter(cell, row) {
      return (
        <input className="form-control cleaning-form" step="0.01" type="number" id={`prezzo_dpi_${row.ID}`} defaultValue={row.prezzoDPI} onChange={e => onChangePrezzoDPI(e, row)}/>
      )
    }

    function qtyDPIFormatter(cell, row) {
      return (
        <span id={`qty_dpi_${row.ID}`}>{row.qtyDPI}</span>
      )
    }

    function prezzoMacchinariFormatter(cell, row) {
      return (
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <input className="form-control cleaning-form" step="0.01" type="number" id={`prezzo_mac_${row.ID}`} style={{display: row.prezzoMacchinarioTipo === 'costo' || row.prezzoMacchinarioTipo == null ? 'block' : 'none'}} defaultValue={row.prezzoMacchinario} onChange={e => onChangePrezzoMacchinari(e, row)}/>
          <input className="form-control cleaning-form" step="0.01" type="number" id={`prezzo_canone_mac_${row.ID}`} style={{display: row.prezzoMacchinarioTipo === 'canone' ? 'block' : 'none'}} defaultValue={row.prezzoMacchinarioCanone} onChange={e => onChangePrezzoMacchinariCanone(e, row)}/>
          <select className="form-control cleaning-form" style={{marginLeft: '1vw'}} defaultValue={row.prezzoMacchinarioTipo} id={`prezzoTipo_mac_${row.ID}`} onChange={e => onChangePrezzoTipoMacchinari(e, row)}>
            <option value="costo">costo</option>
            <option value="canone">canone</option>

          </select>

        </div>
      )
    }

    function qtyMacchinariFormatter(cell, row) {
      return (
        <span id={`qty_mac_${row.ID}`}>{row.qtyMacchinario}</span>
      )
    }

    function resaFormatter(cell, row) {


      return (
        <>
        <input className="form-control cleaning-form" type="number" id={`resa${row.ID}`} defaultValue={row.resa} onChange={() => cambiaTempo(row)}/>
        </>
      )
    }


    function deleteRowTrash(cell, row) {
      return (
        <>
        <center>
          <span style={{cursor: 'pointer'}}  onClick={() => removeRowFromArray(row)}>&#10060;</span>
        </center>
        </>
      )
    }

    const removeRowFromArray = (row) => {
      console.log(row)
    }

    function operatoriFormatter(cell, row) {



      return (
        <Select
          className="cleaning-form large-select"
          placeholder="Seleziona.."
          options={allOperatori}
          styles={selectStyles}
          onChange={e => selectOperatore(row, e)}
          defaultValue={[{
            value: row.operatore.value,
            label: row.operatore.label
          }]}
        />
      )
    }



    function frequenzaFormatter(cell, row) {

      return (
        <Select
          className="cleaning-form"
          placeholder="Seleziona.."
          options={[
            {
              value: 2,
              label: '1 volta a settimana'
            },
            {
              value: 3,
              label: '2 volte a settimana'
            },
            {
              value: 4,
              label: '3 volte a settimana'
            },
            {
              value: 14,
              label: '4 volte a settimana'
            },
            {
              value: 1,
              label: '5 volte settimana'
            },
            {
              value: 15,
              label: '6 volte a settimana'
            },
            {
              value: 16,
              label: '7 volte a settimana'
            },
            {
              value: 5,
              label: 'Decadale'
            },
            {
              value: 6,
              label: 'Quindicinale'
            },
            {
              value: 7,
              label: 'Mensile'
            },
            {
              value: 8,
              label: 'Bimestrale'
            },
            {
              value: 9,
              label: 'Trimestrale'
            },
            {
              value: 10,
              label: 'Quadrimestrale'
            },
            {
              value: 11,
              label: 'Semestrale'
            },
            {
              value: 12,
              label: 'Annuale'
            },
            {
              value: 13,
              label: 'Spot'
            },

          ]}
          styles={selectStyles}
          onChange={e => selectFrequenza(row, e)}
          defaultValue={[{
            value: row.frequenza.value,
            label: row.frequenza.label
          }]}
        />
      )
    }

    function macchinariFormatter(cell, row) {
      return (
        <>
        <div className="row" id={`mac_half_${row.ID}`} style={{display: 'none'}}>
          <div className="col-sm-9">
        <Select
          className="cleaning-form large-select"
          placeholder="Seleziona.."
          options={macchinariHalf.filter(x => x.operazione === row.ID)}
          styles={selectStyles}
          defaultValue={[{
            value: row.macchinario ? row.macchinario.value : null,
            label: row.macchinario ? row.macchinario.label : null
          }]}
          onChange={(e) => onChangeMacchinariOperazione(row, e)}
        />
        </div>
        <div className="col-sm-3">
          <ReactTooltip place="top" effect="solid"/>
          <button type="button" className="toggle-book" data-tip="Vedi tutte le opzioni" onClick={() => onClickMacHalf(row.ID)}><BookOpen /></button>
        </div>

      </div>
      <div className="row" id={`mac_full_${row.ID}`} >
        <div className="col-sm-9">

        <Select
          className="cleaning-form"
          placeholder="Seleziona.."
          options={allMacchinari}
          styles={selectStyles}
          defaultValue={[{
            value: row.macchinario ? row.macchinario.value : null,
            label: row.macchinario ? row.macchinario.label : null
          }]}
          onChange={(e) => onChangeMacchinariOperazione(row, e)}
        />

        </div>
        <div className="col-sm-3">
          <ReactTooltip place="top" effect="solid"/>
          <button type="button" className="toggle-book"  data-tip="Vedi le opzioni consigliate" onClick={() => onClickMacFull(row.ID)}><Book /></button>
        </div>
      </div>

      </>
      )
    }

    function attrezzatureFormatter(cell, row) {
      return (
        <>
        <div className="row" id={`att_half_${row.ID}`} style={{display: 'none'}}>
          <div className="col-sm-9">
          <Select
            className="cleaning-form"
            placeholder="Seleziona.."
            options={attrezzatureHalf.filter(x => x.operazione === row.ID)}
            styles={selectStyles}
            defaultValue={[{
              value: row.attrezzatura ? row.attrezzatura.value : null,
              label: row.attrezzatura ? row.attrezzatura.label : null
            }]}
            onChange={(e) => onChangeAttrezzatureOperazione(row, e)}
          />
          </div>
          <div className="col-sm-3">
            <ReactTooltip place="top" effect="solid"/>
            <button type="button" className="toggle-book" data-tip="Vedi tutte le opzioni" onClick={() => onClickAttHalf(row.ID)}><BookOpen /></button>
          </div>

        </div>
        <div className="row" id={`att_full_${row.ID}`} >
          <div className="col-sm-9">
          <Select
            className="cleaning-form"
            placeholder="Seleziona.."
            options={allAttrezzatura}
            styles={selectStyles}
            defaultValue={[{
              value: row.attrezzatura ? row.attrezzatura.value : null,
              label: row.attrezzatura ? row.attrezzatura.label : null
            }]}
            onChange={(e) => onChangeAttrezzatureOperazione(row, e)}
          />
          </div>
          <div className="col-sm-3">
            <ReactTooltip place="top" effect="solid"/>
            <button type="button" className="toggle-book"  data-tip="Vedi le opzioni consigliate" onClick={() => onClickAttFull(row.ID)}><Book /></button>
          </div>
        </div>

        </>
      )
    }

    function dpiFormatter(cell, row) {
      return (
        <>
        <div className="row" id={`dpi_half_${row.ID}`} style={{display: 'none'}}>
          <div className="col-sm-9">
          <Select
            className="cleaning-form"
            placeholder="Seleziona.."
            options={dpiHalf.filter(x => x.operazione === row.ID)}
            styles={selectStyles}
            defaultValue={[{
              value: row.dpi ? row.dpi.value : null,
              label: row.dpi ? row.dpi.label : null
            }]}
            onChange={e => onChangeDPIOperazione(row, e)}
          />
          </div>
          <div className="col-sm-3">
            <ReactTooltip place="top" effect="solid"/>
            <button type="button" className="toggle-book" data-tip="Vedi tutte le opzioni" onClick={() => onClickDPIHalf(row.ID)}><BookOpen /></button>
          </div>

        </div>
        <div className="row" id={`dpi_full_${row.ID}`} >
          <div className="col-sm-9">
          <Select
            className="cleaning-form"
            placeholder="Seleziona.."
            options={allDPI}
            styles={selectStyles}
            defaultValue={[{
              value: row.dpi ? row.dpi.value : null,
              label: row.dpi ? row.dpi.label : null
            }]}
            onChange={e => onChangeDPIOperazione(row, e)}
          />
          </div>
          <div className="col-sm-3">
            <ReactTooltip place="top" effect="solid"/>
            <button type="button" className="toggle-book"  data-tip="Vedi le opzioni consigliate" onClick={() => onClickDPIFull(row.ID)}><Book /></button>
          </div>
        </div>

        </>
      )
    }

    function materialiFormatter(cell, row) {


      return (
        <>

        <div className="row" id={`mat_half_${row.ID}`} style={{display: 'none'}}>
          <div className="col-sm-9">
            <Select
              className="cleaning-form"
              placeholder="Seleziona.."
              onChange={e => onChangeMaterialiOperazione(row, e)}
              options={materialiHalf.filter(x => x.operazione === row.ID)}
              defaultValue={[{
                value: row.materiale ? row.materiale.value : null,
                label: row.materiale ? row.materiale.label : null
              }]}
              styles={selectStyles}
            />

          </div>
          <div className="col-sm-3">
            <ReactTooltip place="top" effect="solid"/>
            <button type="button" className="toggle-book" data-tip="Vedi tutte le opzioni" onClick={() => onClickMaterialiHalf(row.ID)}><BookOpen /></button>
          </div>

        </div>
        <div className="row" id={`mat_full_${row.ID}`} >
          <div className="col-sm-9">
            <Select
              className="cleaning-form"
              placeholder="Seleziona.."
              onChange={e => onChangeMaterialiOperazione(row, e)}
              options={allMateriali}
              defaultValue={[{
                value: row.materiale ? row.materiale.value : null,
                label: row.materiale ? row.materiale.label : null
              }]}
              styles={selectStyles}
            />
          </div>
          <div className="col-sm-3">
            <ReactTooltip place="top" effect="solid"/>
            <button type="button" className="toggle-book"  data-tip="Vedi le opzioni consigliate" onClick={() => onClickMaterialiFull(row.ID)}><Book /></button>
          </div>
        </div>

        </>
      )
    }

    function beforeSaveCell(oldValue, newValue, row, column, done) {
      let array = selectedOperations
      let objIndex = array.findIndex((obj => obj.token === row.token));
      if(objIndex > 0) {
        let field = column.dataField
        array[objIndex].field = newValue
        return { async: true };
      }
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    bgColor: 'rgba(117, 185, 67, 0.15)',
    selected: checkedIndex,
    clickToSelect: false,
    clickToEdit: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if(e.target.checked === true) {
        var newObj = [...selectedOperations, row]
        var newIndex = [...checkedIndex, row.ID]
        setSelectedOperations(newObj)
        setCheckedIndex(newIndex)
      } else {
        var someArray = selectedOperations
        var otherArray = checkedIndex
        someArray = someArray.filter(obj => obj.ID != row.ID);
        otherArray = otherArray.filter(obj => obj != row.ID);
        setSelectedOperations(someArray)
        setCheckedIndex(otherArray)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if(e.target.checked === true) {
        rows.map((item, index) => {
          var newObj = [...selectedOperations, item]
          var newIndex = [...checkedIndex, item.ID]
          setSelectedOperations(newObj)
          setCheckedIndex(newIndex)
        })
      } else {
        var someArray = selectedOperations
        var otherArray = checkedIndex
        rows.map((row, index) => {
          someArray = someArray.filter(obj => obj.ID != row.ID);
          otherArray = otherArray.filter(obj => obj != row.ID);
          setSelectedOperations(someArray)
          setCheckedIndex(otherArray)
        })
      }
    }
  }

  const selectRowSingle = {
    mode: 'checkbox',
    clickToSelect: false,
    selected: checkedIndex,
    bgColor: 'rgba(117, 185, 67, 0.15)',
    onSelect: (row, isSelect, rowIndex, e) => {
      if(e.target.checked === true) {
        var newObj = [...selectedOperations, row]
        var newIndex = [...checkedIndex, row.ID]
        setSelectedOperations(newObj)
        setCheckedIndex(newIndex)
      } else {
        var someArray = selectedOperations
        var otherArray = checkedIndex
        someArray = someArray.filter(obj => obj.ID != row.ID);
        otherArray = otherArray.filter(obj => obj != row.ID);
        setSelectedOperations(someArray)
        setCheckedIndex(otherArray)
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if(e.target.checked === true) {
        rows.map((item, index) => {
          var newObj = [...selectedOperations, item]
          var newIndex = [...checkedIndex, item.ID]
          setSelectedOperations(newObj)
          setCheckedIndex(newIndex)
        })
      } else {
        var someArray = selectedOperations
        var otherArray = checkedIndex
        rows.map((row, index) => {
          someArray = someArray.filter(obj => obj.ID != row.ID);
          otherArray = otherArray.filter(obj => obj != row.ID);
          setSelectedOperations(someArray)
          setCheckedIndex(otherArray)
        })
      }
    }
  }

  const selectRow2 = {
    mode: 'checkbox',
    clickToSelect: false,
    selected: checkedIndex,
    onSelect: (row, isSelect, rowIndex, e) => {
      if(e.target.checked === true) {
        let obj = {
          IDoperazione: row.IDoperazione,
          descrizione: row.descrizione,
          tipoPulizie: row.tipoPulizie
        }
        let array = [...tableAmbient, obj]
        setTableAmbient(array)
      } else if(e.target.checked === false) {
        let obj = {
          IDoperazione: row.IDoperazione,
          descrizione: row.descrizione,
          tipoPulizie: row.tipoPulizie
        }
        const array = tableAmbient.filter(item => item.IDoperazione !== obj.IDoperazione)
        setTableAmbient(array)
      }
    }
  }

  const selectOperatore = (row, e) => {
    let splitted = e.value.split("%#$")
    let obj = {
      value: splitted[0],
      label: e.label,
    }
    row.operatore = obj
    row.tariffa = splitted[1]
    var tariffa = document.getElementById("tariffa" + row.ID)
    tariffa.value = splitted[1]

  }

  const selectFrequenza = (row, e) => {
    let obj = {
      value: e.value,
      label: e.label,
    }
    let value = parseInt(e.value)
    row.frequenza = obj
    row.giorni = getLavorativi(parseInt(e.value))

    let giorni = document.getElementById('giorni_' + row.ID)
    if ('textContent' in giorni) {
      giorni.textContent = getLavorativi(value);
    } else {
      giorni.innerText = 5;
    }
  }

  const changeMetriQuadri = (row, value) => {
    var mq = document.getElementById("mq" + row.ID)
    var resa = document.getElementById("resa" + row.ID)
    var tempo = document.getElementById("tempo" + row.ID)
    row.metri = value
    if(tempo.value !== '0' && tempo.value !== 0 && mq.value !== 0 && resa.value === 0) {
      resa.value = Math.round(value * 60 / tempo.value)
      row.resa = Math.round(mq.value * 60 / tempo.value)
    } else if(resa.value !== 0 && value !== 0) {
      console.log(Math.round(value / parseInt(resa.value) * 60))
      tempo.value = Math.round(value / parseInt(resa.value) * 60)
      row.tempo = Math.round(value / parseInt(resa.value) * 60)
    }
  }

  const changeTempo = (row, value) => {
    row.tempo = value
    if(row.metri > 0 && row.tempo) {
      row.resa = row.metri * 60 / row.tempo
    }
  }

  const onChangeMacchinariOperazione = (row, e) => {
    let splitted = e.value.split("%%%")
    let obj = {
      value: splitted[0],
      label: e.label
    }
    row.macchinario = obj
    row.qtyMacchinario = 1
    row.prezzoMacchinarioTipo = 'costo'
    row.prezzoMacchinarioCanone = isNaN(splitted[2]) || splitted[2] == null ? 0 : parseFloat(splitted[2])
    row.prezzoMacchinario = parseFloat(splitted[1])
    let price = document.getElementById('prezzo_mac_' + row.ID)
    price.value = parseFloat(splitted[1])


    let priceC = document.getElementById('prezzo_canone_mac_' + row.ID)
    priceC.value = isNaN(splitted[2]) || splitted[2] == null ? 0 : parseInt(splitted[2])


    let qty = document.getElementById('qty_mac_' + row.ID)
    if(qty) {
      if ('textContent' in qty) {
        qty.textContent = 1;
      } else {
        qty.innerText = 1;
      }
    }

    let prezzoTipo = document.getElementById('prezzoTipo_mac_' + row.ID)
    prezzoTipo.options.selectedIndex = 0

  }

  const onChangePrezzoTipoMacchinari = (e, row) => {
    row.prezzoMacchinarioTipo = e.target.value
    if(e.target.value === 'canone') {
      if(isNaN(row.prezzoMacchinarioCanone) || row.prezzoMacchinarioCanone === null) {
        let price = document.getElementById('prezzo_mac_' + row.ID)
        let priceC = document.getElementById('prezzo_canone_mac_' + row.ID)
        price.style.display = 'none'
        priceC.style.display = 'block'

        if(priceC) {
          if ('textContent' in priceC) {
            priceC.textContent = 0;
          } else {
            priceC.innerText = 0;
          }
        }
      } else {
        let price = document.getElementById('prezzo_mac_' + row.ID)
        let priceC = document.getElementById('prezzo_canone_mac_' + row.ID)
        price.style.display = 'none'
        priceC.style.display = 'block'

        if(priceC) {
          if ('textContent' in priceC) {
            priceC.textContent = row.prezzoMacchinarioCanone;
          } else {
            priceC.innerText = row.prezzoMacchinarioCanone;
          }
        }
      }
    } else {
      let price = document.getElementById('prezzo_mac_' + row.ID)
      let priceC = document.getElementById('prezzo_canone_mac_' + row.ID)
      price.style.display = 'block'
      priceC.style.display = 'none'
      if(price) {
        if ('textContent' in price) {
          price.textContent = row.prezzoMacchinario;
        } else {
          price.innerText = row.prezzoMacchinario;
        }
      }
    }
  }


  const onChangeAttrezzatureOperazione = (row, e) => {
    let splitted = e.value.split("%%%")
    let obj = {
      value: splitted[0],
      label: e.label
    }
    row.attrezzatura = obj
    row.qtyAttrezzatura = 1
    if(e.VitaUtile > 0) {
      let interventi = calcoloInterventi(props.current.mesi, props.current.giorni, row.frequenza.value, props.current.anni)
      let nb = interventi / e.VitaUtile
      nb = Math.ceil(nb)
      let qty = document.getElementById('qty_att_' + row.ID)
      let price = document.getElementById('prezzo_att_' + row.ID)
      if ('textContent' in qty) {
        qty.textContent = nb;
        row.qtyAttrezzatura = nb
      } else {
        qty.innerText = nb;
        row.qtyAttrezzatura = nb
      }

      price.value = (parseFloat(splitted[1]) / 12).toFixed(2);
      row.prezzoAttrezzatura = (parseFloat(splitted[1]) / 12).toFixed(2)

    } else {
      let interventi = calcoloInterventi(props.current.mesi, props.current.giorni, row.frequenza.value, props.current.anni)
      let nb = interventi / 1
      nb = Math.ceil(nb)
      let qty = document.getElementById('qty_att_' + row.ID)
      let price = document.getElementById('prezzo_att_' + row.ID)
      if ('textContent' in qty) {
        qty.textContent = nb;
        row.qtyAttrezzatura = nb
      } else {
        qty.innerText = nb;
        row.qtyAttrezzatura = nb
      }
      price.value = (parseFloat(splitted[1]) / 12).toFixed(2);
      row.prezzoAttrezzatura = (parseFloat(splitted[1]) / 12).toFixed(2)

    }

  }

  function calcoloInterventi(mesi, giorni, frequenza, anno) {
    if(frequenza === 1  || frequenza === '1') {
      let int = parseInt(anno) * parseInt(mesi) * 5 * 4.34
      return int.toFixed(2);
    } else if(frequenza === 4  || frequenza === '4') {
      let int = parseInt(anno) * parseInt(mesi) * 3 * 4.34
      return int.toFixed(2);
    } else if(frequenza === 3  || frequenza === '3') {
      let int = parseInt(anno) * parseInt(mesi) * 2 * 4.34
      return int.toFixed(2);
    } else if(frequenza === 2  || frequenza === '2') {
      let int = parseInt(anno) * parseInt(mesi) * 1 * 4.34
      return int.toFixed(2);
    } else if(frequenza === 5  || frequenza === '5') {
      let int = parseInt(anno) * parseInt(mesi) * 36.4
      return int.toFixed(2);
    } else if(frequenza === 6  || frequenza === '6') {
      let int = parseInt(anno) * parseInt(mesi) * 24.2
      return int.toFixed(2);
    } else if(frequenza === 7  || frequenza === '7') {
      return parseInt(anno) * parseInt(mesi).toFixed(2);
    } else if(frequenza === 8  || frequenza === '8') {
      return parseInt(anno) * parseInt(mesi / 2).toFixed(2);
    } else if(frequenza === 10  || frequenza === '10') {
      return parseInt(anno) * parseInt(mesi / 4).toFixed(2);
    } else if(frequenza === 9  || frequenza === '9') {
      return parseInt(anno) * parseInt(mesi / 3).toFixed(2);
    } else if(frequenza === 11  || frequenza === '11') {
      return parseInt(anno) * parseInt(mesi / 6).toFixed(2);
    } else if(frequenza === 12  || frequenza === '12') {
      return parseInt(anno) * parseInt(mesi / 12).toFixed(2);
    } else if(frequenza === 13  || frequenza === '13') {
      return 1
    } else if(frequenza === 14  || frequenza === '14') {
      let int = parseInt(anno) * parseInt(mesi) * 4 * 4.34
      return int.toFixed(2);
    } else if(frequenza === 15  || frequenza === '15') {
      let int = parseInt(anno) * parseInt(mesi) * 6 * 4.34
      return int.toFixed(2);
    } else if(frequenza === 16  || frequenza === '16') {
      let int = parseInt(anno) * parseInt(mesi) * 7 * 4.34
      return int.toFixed(2);
    }  else {
      return 0
    }
  }

  const onChangeMaterialiOperazione = (row, e) => {
    const fetch = async () => {
      const prezzo = await axios.get(`${apiEndpoint}/preventivi/fetch-materiali-prezzo`, {
        params: {
          userID: localStorage.getItem('userID'),
          articolo: e.value
        },
        headers: {
          "x-access-token": localStorage.getItem('jwt')
        }
      })
      row.prezzoMateriale = prezzo.data.prezzo
      console.log(e)
      if(e.PanVitaUtile > 0) {

        let interventi = calcoloInterventi(props.current.mesi, props.current.giorni, row.frequenza.value, props.current.anni)
        console.log(props.current)
        let nb = interventi / e.PanVitaUtile

        nb = Math.ceil(nb)
        let qty = document.getElementById('qty_mat_' + row.ID)
        if ('textContent' in qty) {
          qty.textContent = nb;
          row.qtyMateriale = nb
        } else {
          qty.innerText = nb;
          row.qtyMateriale = nb
        }
      } else {
        let interventi = calcoloInterventi(props.current.mesi, props.current.giorni, row.frequenza.value, props.current.anni)
        console.log(interventi)
        let nb = row.metri * interventi / e.Resa
        nb = Math.ceil(nb)
        let qty = document.getElementById('qty_mat_' + row.ID)
        if ('textContent' in qty) {
          qty.textContent = nb;
          row.qtyMateriale = nb
        } else {
          qty.innerText = nb;
          row.qtyMateriale = nb
        }
      }
      let price = document.getElementById('prezzo_mat_' + row.ID)
      price.value = prezzo.data.prezzo
      /*if ('textContent' in price) {
        price.textContent = prezzo.data.prezzo;
      } else {
        price.innerText = prezzo.data.prezzo;
      }

      let qty = document.getElementById('qty_mat_' + row.ID)
      if ('textContent' in qty) {
        qty.textContent = 1;
      } else {
        qty.innerText = 1;
      }*/

    }
    fetch()

    let obj = {
      value: e.value,
      label: e.label
    }
    row.materiale = obj
  }

  const onChangeDPIOperazione = (row, e) => {
    const fetch = async () => {
      const prezzo = await axios.get(`${apiEndpoint}/preventivi/fetch-dpi-prezzo`, {
        params: {
          userID: localStorage.getItem('userID'),
          articolo: e.value
        },
        headers: {
          "x-access-token": localStorage.getItem('jwt')
        }
      })
      row.prezzoDPI = prezzo.data.prezzo
      row.qtyDPI = 1
      let price = document.getElementById('prezzo_dpi_' + row.ID)
      if ('textContent' in price) {
        price.textContent = prezzo.data.prezzo;
      } else {
        price.innerText = prezzo.data.prezzo;
      }

      let qty = document.getElementById('qty_dpi_' + row.ID)
      if ('textContent' in qty) {
        qty.textContent = 1;
      } else {
        qty.innerText = 1;
      }
    }
    fetch()
    let obj = {
      value: e.value,
      label: e.label
    }
    row.dpi = obj
  }


  const [isFormIncomplete, setIsFormIncomplete] = useState(true);

  const onSubmitOperations = () => {
      localStorage.setItem("back", "back")
      console.log(selectedOperations)
      props.parentCallback(selectedOperations, alreadySelected)
  }


    return (
        <Fragment>
            <div className="container-fluid">
            <Prompt
                when={isFormIncomplete}
                message={'Vuoi davvero abbandonare la pagina? Tutti i dati non verranno salvati.'}
              />
                <div className="edit-profile">
                      {loading && loading2 ?   <div className="col-lg-12 card">

                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Operazioni"}</h4>
                                </div>
                                <div className="card-body">
                                  <form className="theme-form mt-3" onSubmit={handleSubmit(onSubmitOperations)}>
                                  <center><button type="submit" className="btn btn-success cleaning-btn-login" style={{marginBottom: '3vh', marginTop: '5vh'}}>SALVA</button></center>
                                  <ReactTooltip effect="solid"/>
                                  {allAmbienti.map((item, index) => {
                                    return (
                                      <>
                                        <div key={index}>
                                        <div className="row spacing" >
                                          <label className="col-sm-3 col-form-label cleaning-form-label-operazioni" htmlFor={item.name}>{isHidden[index] || isHidden2[index] ? <span onClick={() => onClick4(index)}>&#10134;{" "}</span> : <span>&#10133;{" "}</span>}{item.name}</label>
                                          <div className="col-sm-9">
                                          <input className="form-control cleaning-form" name={`mq${index}`} type="number" placeholder="es. 100 mq"/>
                                        </div>
                                        <div className="row" >
                                          <div className="col-sm-12" ><span onClick={() => onClick(index)} htmlFor={item.name} className="span-select-operazioni" data-tip="Clicca qui per espandere o comprimere le opzioni">Carica tutte</span>{" "}<span onClick={() => onClick2(index)} className="col-sm-3 span-select-operazioni" data-tip="Clicca qui per espandere o comprimere le opzioni" htmlFor={item.name}>Aggiungi singole</span></div>
                                        </div>
                                        </div>
                                        {isHidden[index] ? <div>

                                        <div className="div-tabella">
                                        <ToolkitProvider
                                        data={ item.operazioni }
                                        columns={ columns2 }
                                        keyField="ID"
                                        search
                                        >
                                        {
                                          props => (
                                            <div>
                                              <div className="d-flex flex-row justify-content-between w-100 mb-2"><SearchBar
                                              { ...props.searchProps }
                                              placeholder="Cerca"
                                              className="cleaning-form text-left"
                                              />
                                              </div>
                                              <BootstrapTable
                                                { ...props.baseProps }
                                                striped
                                                hover
                                                pagination={ paginationFactory({
                                                  showTotal: false,
                                                  firstPageText: 'Inizio', // the text of first page button
                                                  prePageText: 'Prec', // the text of previous page button
                                                  nextPageText: 'Succ', // the text of next page button
                                                  lastPageText: 'Fine', // the text of last page button
                                                  nextPageTitle: 'Vai al successivo', // the title of next page button
                                                  prePageTitle: 'Vai al precedente', // the title of previous page button
                                                  firstPageTitle: 'Vai al primo', // the title of first page button
                                                  lastPageTitle: 'Vai all\'ultmo', // the title of last page button
                                                }) }
                                                selectRow={ selectRow }
                                                cellEdit={ cellEditFactory({ mode: 'dbclick', blurToSave: true, beforeSaveCell: beforeSaveCell}) }
                                                classes="font-reduce large-select"
                                              />
                                            </div>
                                          )
                                        }
                                        </ToolkitProvider>
                                        </div>
                                        </div>
                                         : null}
                                        {isHidden2[index] ?

                                        <div>
                                          <Row style={{minHeight: "110%"}}>
                                            <Col md={!checkedHide[index] ? 8 : 12}>
                                            <div className="div-tabella">
                                              <ToolkitProvider
                                              data={ selectedOperations ? selectedOperations.filter(x => x.area === item.name) : selectedOperations }
                                              columns={ columns4 }
                                              search
                                              keyField="ID"
                                              >
                                              {
                                                props => (
                                                  <div>
                                                    <div className="d-flex flex-row justify-content-between w-100 mb-2"><SearchBar
                                                    { ...props.searchProps }
                                                    placeholder="Cerca"
                                                    className="cleaning-form text-left"
                                                    />
                                                    <ReactTooltip place="top" effect="solid"/>
                                                    <button type="button" className="toggle-eye" data-tip="Visualizza/Nascondi operazioni" onClick={() => onClickHide(index)}>{!checkedHide[index] ? <EyeOff /> : <Eye />}</button>
                                                    </div>
                                                    <BootstrapTable
                                                      { ...props.baseProps }
                                                      striped
                                                      hover
                                                      pagination={ paginationFactory({
                                                        showTotal: false,
                                                        firstPageText: 'Inizio', // the text of first page button
                                                        prePageText: 'Prec', // the text of previous page button
                                                        nextPageText: 'Succ', // the text of next page button
                                                        lastPageText: 'Fine', // the text of last page button
                                                        nextPageTitle: 'Vai al successivo', // the title of next page button
                                                        prePageTitle: 'Vai al precedente', // the title of previous page button
                                                        firstPageTitle: 'Vai al primo', // the title of first page button
                                                        lastPageTitle: 'Vai all\'ultmo', // the title of last page button
                                                      }) }
                                                      cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true, beforeSaveCell: beforeSaveCell }) }
                                                      classes="font-reduce large-select"
                                                    />
                                                  </div>
                                                )
                                              }
                                              </ToolkitProvider>
                                            </div>
                                            </Col>
                                            {!checkedHide[index] ? <Col md="4">
                                            <ToolkitProvider
                                            data={ item.operazioni }
                                            columns={ columns3 }
                                            search
                                            keyField="ID"
                                            >
                                            {
                                              props => (
                                                <div>
                                                  <div className="d-flex flex-row justify-content-between w-100 mb-2"><SearchBar
                                                  { ...props.searchProps }
                                                  placeholder="Cerca"
                                                  className="cleaning-form text-left"
                                                  />
                                                  </div>
                                                  <BootstrapTable
                                                    { ...props.baseProps }
                                                    striped
                                                    hover
                                                    pagination={ paginationFactory({
                                                      showTotal: false,
                                                      firstPageText: 'Inizio', // the text of first page button
                                                      prePageText: 'Prec', // the text of previous page button
                                                      nextPageText: 'Succ', // the text of next page button
                                                      lastPageText: 'Fine', // the text of last page button
                                                      nextPageTitle: 'Vai al successivo', // the title of next page button
                                                      prePageTitle: 'Vai al precedente', // the title of previous page button
                                                      firstPageTitle: 'Vai al primo', // the title of first page button
                                                      lastPageTitle: 'Vai all\'ultmo', // the title of last page button
                                                    }) }
                                                    selectRow={ selectRowSingle }
                                                    cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true, beforeSaveCell: beforeSaveCell }) }
                                                    classes="font-reduce large-select"
                                                  />
                                                </div>
                                              )
                                            }
                                            </ToolkitProvider>
                                            </Col> : null}
                                          </Row>
                                        </div>
                                         : null}
                                        </div>
                                      </>
                                    )
                                  })}
                                  <center><button type="submit" className="btn btn-success cleaning-btn-login" style={{marginBottom: '3vh', marginTop: '5vh'}}>SALVA</button></center>
                                  </form>
                                </div>
                        </div>:  <span style={{marginTop: '3vh', marginBottom: '3vh'}}>Caricamento operazioni in corso...</span>}
                </div>
            </div>

        </Fragment>
    );
};

export default PreventivoAreeOperazioni;
