import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import Select from 'react-select';



const MacchinariAdd = () => {

    const [data,setData] = useState([])
    const [userData, setUserData] = useState({})
    const [allCategories, setCategories] = useState([{}])
    const [allAmbience, setAmbience] = useState([{}])
    const [loadingAmbience, setLoadingAmbience] = useState(true)
    const [loading, setLoading] = useState(true)
    const [loadingAtt, setLoadingAtt] = useState(true)
    const [fileImage, setFile] = useState(null)
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [isEcolabel, setEcolabel] = useState(null)
    const [isMaterial, setMaterial] = useState('')
    const [isCodiceColore, setCodiceColore] = useState('')
    const [isAreeUtilizzo, setAreeUtilizzo] = useState([{}])
    const [isSuperfici, setSuperifici] = useState([{}])
    const [allFamiglia, viewFamiglia] = useState([{}])
    const [isFam, setFam] = useState([{}])

    let history = useHistory()

    useEffect(() => {
        let mounted = true
        const getFamily = async () => {
          const material = await axios.get(`${apiEndpoint}/macchinari/fetch-superfici`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })

          if(mounted) {
            viewFamiglia(material.data)
          }
        }
        getFamily()
        return () => {
          mounted = false
        }
    }, [])

    //Generazione randomica di stringhe
    function makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() *
     charactersLength)));
       }
       return result.join('');
    }

    const toggle = () => {
        setModal(!modal)
    }

    const onSubmit = (data) => {

      if(isFam === 'LAV') {

        let formData = new FormData();
        if(data.file){
          let fileName = 'MAC' + (makeid(5)).toUpperCase()
        formData.append('file', data.file[0])
        formData.append('fileName', fileName)
      }

        formData.append('descrizione', data.descrizione)
        formData.append('ecolabel', isEcolabel)
        formData.append('prezzo', data.prezzo)
        formData.append('canone', data.canone)
        formData.append('co2', data.co2)
        formData.append('kwh', data.kwh)
        formData.append('cesto', data.cesto)
        formData.append('carico', data.carico)
        formData.append('centrifuga', data.centrifuga)
        formData.append('fattoreg', data.fattoreg)
        formData.append('aree', isAreeUtilizzo)
        formData.append('famiglia', isFam)
        formData.append('userID', localStorage.getItem("role") === 'Admin' ? null : localStorage.getItem("userID"))

        const updateData = async () => {
            const update = await axios.post(`${apiEndpoint}/macchinari/create`, formData, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            toggle()
          }
          updateData()


      } else {

      let formData = new FormData();
      if(data.file) {
        let fileName = 'MAC' + (makeid(5)).toUpperCase()
        formData.append('file', data.file[0])
        formData.append('fileName', fileName)
      }

      formData.append('famiglia', isFam)

      formData.append('descrizione', data.descrizione)
      formData.append('ecolabel', isEcolabel)
      formData.append('pista', data.pista)
      formData.append('prezzo', data.prezzo)
      formData.append('canone', data.canone)
      formData.append('plastica', data.plastica)
      formData.append('capacita', data.capacita)
      formData.append('co2', data.co2)
      formData.append('kwh', data.kwh)
      formData.append('resa', data.resa)
      formData.append('aree', isAreeUtilizzo)
      formData.append('superfici', isSuperfici)
      formData.append('tecnica', data.tecnica)
      formData.append('sicurezza', data.sicurezza)
      formData.append('userID', localStorage.getItem("role") === 'Admin' ? null : localStorage.getItem("userID"))

      const updateData = async () => {
          const update = await axios.post(`${apiEndpoint}/macchinari/create`, formData, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          toggle()
        }
        updateData()
      }
    }

    const selectStyles = {
      control: styles => ({ ...styles, borderRadius: '10px'})
    }

    const resetForm = () => {
      reset()
    }

    const setFileUpload = (target) => {
      if(target) {
        let newImage = target.files[0]
        let imageSize = newImage.size
        let imageType = newImage.type
        if(imageType === 'image/png' || imageType === 'image/jpeg') {
          if(imageSize <= 2000000) {
            setFile(newImage)
            setDisabled(false)
          } else {
            window.alert('Dimensione massima consentita 2MB.')
          }
        } else {
          window.alert('Puoi caricare solo immagini in formato .png, .jpeg, .jpg')
        }
      }
    }

    const handleChangeAreeUtilizzo = e => {
      setAreeUtilizzo(e)
    }

    const handleChangeSuperfici = e => {
      setSuperifici(e)
    }

    if(isLoggedOut) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/login`} />)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Macchinari" title="Aggiungi" />
            {loading ? <><div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12 card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Nuovo macchinario"}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">

                                    <div className="col-sm-12 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Immagine macchinario"} </label>
                                            <input className="form-control cleaning-form" name="file" type="file" {...register("file")}/>
                                            {errors.file && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Descrizione"} </label>
                                            <input className="form-control cleaning-form" name="descrizione" type="text" placeholder="Inserisci descrizione es. Macchinario CLEANING ACADEMY" {...register("descrizione")}/>
                                            {errors.descrizione && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Ecolabel"}</label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Il prodotto é EcoLabel?"
                                              onChange={e => setEcolabel(e.value)}
                                              options={[
                                                {
                                                  value: 1,
                                                  label: 'Si'
                                                },
                                                {
                                                  value: 0,
                                                  label: 'No'
                                                }
                                              ]}
                                              styles={selectStyles}
                                              required
                                            />
                                        </div>
                                    </div>


                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Consumo elettrico kW/h"} </label>
                                            <input className="form-control cleaning-form" name="kwh" type="number" placeholder="Inserisci consumo in kWh" {...register("kwh")}/>
                                            {errors.kwh && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">Consumo CO<sub>2</sub> grammi/kWh</label>
                                            <input className="form-control cleaning-form" name="co2" type="number" placeholder="Inserisci consumo CO2/kWh" {...register("co2")}/>
                                            {errors.co2 && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>



                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Aree di utilizzo"}</label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Seleziona area di utilizzo"
                                              onChange={e => handleChangeAreeUtilizzo(e)}
                                              isMulti
                                              options={[
                                                {
                                                  value: 'OSP',
                                                  label: 'Ospedali'
                                                },
                                                {
                                                  value: 'SCU',
                                                  label: 'Scuole'
                                                },
                                                {
                                                  value: 'UFF',
                                                  label: 'Uffici'
                                                },
                                                {
                                                  value: 'STA',
                                                  label: 'Stabilimenti'
                                                },
                                                {
                                                  value: 'PIS',
                                                  label: 'Piscine'
                                                },
                                                {
                                                  value: 'CEN',
                                                  label: 'Centri commerciali'
                                                }
                                              ]}
                                              styles={selectStyles}
                                              required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Prezzo listino (iva esclusa)"} </label>
                                            <input className="form-control cleaning-form" name="prezzo" step="0.01" type="number" placeholder="Inserisci prezzo es. 10.09" {...register("prezzo")}/>
                                            {errors.prezzo && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Canone mensile (iva esclusa)"} </label>
                                            <input className="form-control cleaning-form" name="canone" step="0.01" type="number" placeholder="Inserisci canone es. 10.09" {...register("canone")}/>
                                            {errors.canone && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Famiglia"}</label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Inserisci famiglia"
                                              onChange={e => setFam(e.value)}
                                              options={allFamiglia}
                                              styles={selectStyles}
                                              required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12">
                                        <div className="form-group">
                                        <label className="form-label cleaning-form-label">{"Scheda tecnica (es. https://sitoproduttore.com)"} </label>
                                            <input className="form-control cleaning-form" name="tecnica"   type="url" placeholder="es. https://sitoproduttore.com"  {...register("tecnica")}/>
                                            {errors.prezzo && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12">
                                        <div className="form-group">
                                        <label className="form-label cleaning-form-label">{"Scheda sicurezza (es. https://sitoproduttore.com)"} </label>
                                            <input className="form-control cleaning-form" name="sicurezza"  type="url" placeholder="es. https://sitoproduttore.com"  {...register("sicurezza")}/>
                                            {errors.prezzo && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    </div>
                                    {(() => {
                                      switch(isFam) {
                                        case 'LAV':
                                          return (
                                            <>
                                            <hr />
                                            <div className="row">
                                              <div className="col-sm-6 col-md-6">
                                                  <div className="form-group">
                                                      <label className="form-label cleaning-form-label">{"Diametro cesto /lt"} </label>
                                                      <input className="form-control cleaning-form" name="cesto" type="number" placeholder="Inserisci lt diametro cesto" {...register("cesto")}/>
                                                      {errors.cesto && <p className="err-msg-form">Campo richiesto</p>}
                                                  </div>
                                              </div>

                                              <div className="col-sm-6 col-md-6">
                                                  <div className="form-group">
                                                      <label className="form-label cleaning-form-label">{"Carico /kg"} </label>
                                                      <input className="form-control cleaning-form" name="carico" type="number" placeholder="Inserisci kg carico" {...register("carico")}/>
                                                      {errors.carico && <p className="err-msg-form">Campo richiesto</p>}
                                                  </div>
                                              </div>

                                              <div className="col-sm-6 col-md-6">
                                                  <div className="form-group">
                                                      <label className="form-label cleaning-form-label">{"Centrifuga"} </label>
                                                      <input className="form-control cleaning-form" name="centrifuga" type="number" placeholder="Inserisci centrifuga" {...register("centrifuga")}/>
                                                      {errors.centrifuga && <p className="err-msg-form">Campo richiesto</p>}
                                                  </div>
                                              </div>

                                              <div className="col-sm-6 col-md-6">
                                                  <div className="form-group">
                                                      <label className="form-label cleaning-form-label">{"Fattore G"} </label>
                                                      <input className="form-control cleaning-form" name="fattoreg" type="number" placeholder="Inserisci fattore G" {...register("fattoreg")}/>
                                                      {errors.fattoreg && <p className="err-msg-form">Campo richiesto</p>}
                                                  </div>
                                              </div>

                                            </div>
                                            </>
                                          )
                                        default:
                                          return (
                                            <>
                                            <hr />
                                            <div className="row">
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Plastica riciclata %"} </label>
                                                    <input className="form-control cleaning-form" name="plastica" type="number" placeholder="Inserisci plastica riciclata" {...register("plastica")}/>
                                                    {errors.plastica && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Resa mq/punti"} </label>
                                                    <input className="form-control cleaning-form" name="resa" type="number" placeholder="Inserisci resa" {...register("resa")}/>
                                                    {errors.resa && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div>

                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Capacitá /l"} </label>
                                                    <input className="form-control cleaning-form" name="capacita" type="number" placeholder="Inserisci capacitá" {...register("capacita")}/>
                                                    {errors.capacita && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">Pista /cm</label>
                                                    <input className="form-control cleaning-form" name="pista" type="number" placeholder="Inserisci consumo CO2/kWh" {...register("pista")}/>
                                                    {errors.pista && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label cleaning-form-label">{"Superfici"}</label>
                                                    <Select
                                                      className="cleaning-form"
                                                      placeholder="Seleziona superifici"
                                                      onChange={e => handleChangeSuperfici(e)}
                                                      options={[
                                                        {
                                                          value: 'PAV',
                                                          label: 'Pavimenti'
                                                        },
                                                        {
                                                          value: 'ARR',
                                                          label: 'Arredi'
                                                        }
                                                      ]}
                                                      styles={selectStyles}
                                                      required
                                                    />
                                                </div>
                                            </div>
                                            </div>
                                            </>
                                          )
                                      }
                                    })()}
                                    <span className="required-field">*</span>tutti i campi sono richiesti
                                </div>
                                <div className="card-footer text-right">
                                    <Link className="btn cleaning-btn-login cleaning-cancel-btn" to={"/macchinari/lista"}>{"Annulla"}</Link>
                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Aggiungi"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div></> : null}

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Macchinario aggiunto con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => history.push("/macchinari/lista")} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>


        </Fragment>
    );
};

export default MacchinariAdd;
