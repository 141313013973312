import React, { useEffect, useState, Fragment } from 'react'
import {Link, useHistory} from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import FileViewer from "react-file-viewer";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import $ from 'jquery';
import Select from 'react-select';



const DocumentoProfile = (props) => {

  const [allCapitolati, setCapitolati] = useState({})
  const [clientiPotenziali, setClientiPotenziali] = useState([])
  const [isParagrafo, setParagrafo] = useState({})
  const [loading, setLoading] = useState(false)
  const [viewDoc, setDoc] = useState(null)
  const [allDoc, viewDocs] = useState({})
  const [allParagrafi, viewParagrafi] = useState([{}])
  const [defaultParagrafo, setDefaultParagrafo] = useState({})

  let history = useHistory()



  useEffect(() => {
      let mounted = true
      const getUser = async () => {
        const documenti = await axios.get(`${apiEndpoint}/capitoli-progetto/capitolati/fetch-profilo/${props.match.params.id}/${localStorage.getItem("userID")}`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const paragrafi = await axios.get(`${apiEndpoint}/capitoli-progetto/fetch-paragrafi/${localStorage.getItem("userID")}`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        let array = []
        paragrafi.data.map(item =>{
          let obj = {
            value: item.IDparagrafo,
            label: item.Descrizione[0],
            capitolo: item.IDcapitolo[0]
          }
          if(item.IDparagrafo === documenti.data.IDparagrafo && item.IDcapitolo[0] === documenti.data.IDcapitolo) {
            setDefaultParagrafo({
              value: item.IDparagrafo,
              label: item.Descrizione[0],
              capitolo: item.IDcapitolo[0]
            })
          }
          array.push(obj)
        })
        viewParagrafi(array)
        setDoc(documenti.data.PercorsoDocumento)
        viewDocs(documenti.data)
        setCapitolati(documenti.data)

        setLoading(true)


      }
      getUser()
      return () => {
        mounted = false
      }
  }, [])

  const onError = e => {
  console.log(e, "error in file-viewer");
};

  let docs=[{
    uri: viewDoc
  }]

  const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
  const onSubmit = (data) => {


          const updateData = async () => {
              const update = await axios.patch(`${apiEndpoint}/capitoli-progetto/patch-documento`, {
              titolo: data.titolo.replace(/'/g, ''),
              numeroPagine: data.numeroPagine,
              id: props.match.params.id,
              paragrafo: isParagrafo ? isParagrafo.value : defaultParagrafo.value,
              capitolo: isParagrafo ? isParagrafo.capitolo : defaultParagrafo.capitolo,
              percorso: viewDoc,
              IDutente: allDoc.IDutente,
              documentoSistema: allDoc.DocumentoSistema
            }, {
                headers: {
                  "x-access-token": localStorage.getItem('jwt')
                }
              })
              history.push("/documenti/lista")
            }
        updateData()


  }

  const toggleDel = () => {
      setModalDel(!modalDel)
  }
  const toggle = () => {
      setModal(!modal)
  }


  const [modalDel, setModalDel] = useState();
  const [modal, setModal] = useState();
  const [errDel, setErrDel] = useState('')
  const { register: registerDel, handleSubmit: handleSubmitDel, formState: { errors: errorsDel } } = useForm();


  const onDeleteAccount = data => {
       const deleteAccount = async () => {
        const delAcc = await axios.delete(`${apiEndpoint}/capitoli-progetto/delete-documento/${props.match.params.id}`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        history.push(`${process.env.PUBLIC_URL}/documenti/lista`)
      }
      deleteAccount()
  }

  const toggleClose = () => {
    setModal(!modal)
    window.location.reload()
    //history.push(`${process.env.PUBLIC_URL}/macchinari/lista`)
  }
  const selectStyles = {
    control: styles => ({ ...styles, borderRadius: '10px'})
  }

  return (
    <Fragment>
        <Breadcrumb title="I miei progetti" parent="Progetti" />
        {loading ? <div className="container-fluid mt-2">
        {localStorage.getItem("role") === 'Admin' ? <div className="row">
            <div className="col-lg-12 card">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="card-header">
                        <h4 className="card-title mb-0">{"Documento"}</h4>
                    </div>
                    <div className="card-body">
                        <div className="row">

                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label cleaning-form-label">{"Paragrafi"}</label>
                                <Select
                                  className="cleaning-form"
                                  placeholder="Seleziona il capitolo per vedere i paragrami"
                                  onChange={e => setParagrafo(e)}
                                  options={allParagrafi}
                                  styles={selectStyles}
                                  defaultValue={defaultParagrafo}
                                  required
                                />
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-6">
                            <div className="form-group">
                                <label className="form-label cleaning-form-label">{"Numero pagine"} </label>
                                <input className="form-control cleaning-form" name="numeroPagine" defaultValue={allDoc.numeroPagine} type="number" placeholder="es. 20" {...register("numeroPagine")} required/>
                                {errors.numeroPagine && <p className="err-msg-form">Campo richiesto</p>}
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12">
                            <div className="form-group">
                                <label className="form-label cleaning-form-label">{"Titolo"} </label>
                                <input className="form-control cleaning-form" name="titolo" defaultValue={allDoc.Descrizione} type="text" placeholder="Inserisci titolo es. Documento tecnico ambienti" {...register("titolo")} required/>
                                {errors.titolo && <p className="err-msg-form">Campo richiesto</p>}
                            </div>
                        </div>





                        </div>

                        <span className="required-field">*</span>tutti i campi sono richiesti
                    </div>
                    <div className="card-footer text-right">
                        <button className="btn btn-primary cleaning-btn-login" type="submit">{"Modifica"}</button>
                        <button type="button" className="btn btn-danger cleaning-btn-login"  onClick={() => toggleDel()}>{"Elimina"}</button>
                    </div>
                </form>
            </div>
        </div> : null}
            <div className="row" >
                <div className="col-md-12" >
                    <div className="documento-cleaning-a">
                    <iframe id="docframe" src={`https://docs.google.com/gview?rm=minimal&url=${viewDoc}&embedded=true`} style={{
                      height: '80vh',
                      width: '100%'
                    }} sandbox="allow-scripts allow-same-origin"></iframe>

                    </div>
                </div>
            </div>
        </div> : null}

        {/* Modale per eliminazione account*/}

        <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
            <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
            <ModalBody>
                <p>Dati modificati con successo, clicca su <strong>Continua</strong> per proseguire.</p>
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={toggleClose} className="btn-cleaning">{"Continua"}</Button>
            </ModalFooter>
        </Modal>


        <Modal isOpen={modalDel} toggle={toggleDel} centered={true} modalClassName="cleaning-modal">
          <form onSubmit={handleSubmitDel(onDeleteAccount)}>
            <ModalHeader toggle={toggleDel}>Attenzione!</ModalHeader>
            <ModalBody>
                <p>Stai per <strong>eliminare</strong> il documento, sei sicuro di voler procedere?</p>
            </ModalBody>
            <ModalFooter>
            <Button onClick={toggleDel} className="cleaning-cancel-btn">{"Annulla"}</Button>

                <Button color="danger" type="submit" className="btn-cleaning">{"Elimina"}</Button>
            </ModalFooter>
          </form>
        </Modal>
        {/* Modale per eliminazione account*/}
    </Fragment>
  )
}

export default DocumentoProfile
