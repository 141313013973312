import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import Select from 'react-select';




const ParagrafoAdd = () => {

    const [userData, setUserData] = useState({})
    const [allCategories, setCategories] = useState([{}])
    const [allAmbience, setAmbience] = useState([{}])
    const [loadingAmbience, setLoadingAmbience] = useState(true)
    const [loading, setLoading] = useState(true)
    const [loadingAtt, setLoadingAtt] = useState(true)
    const [fileImage, setFile] = useState(null)
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [isEcolabel, setEcolabel] = useState(null)
    const [isMaterial, setMaterial] = useState('')
    const [isCodiceColore, setCodiceColore] = useState('')
    const [allProgetti, setProgetti] = useState([{}])
    const [isCapitolo, setCapitolo] = useState(null)
    const [isParagrafo, setParagrafo] = useState(null)
    const [allParagrafi, setParagrafi] = useState(null)

    useEffect(() => {
        let mounted = true
        const getUser = async () => {
          const documenti = await axios.get(`${apiEndpoint}/capitoli-progetto/fetch-capitoli-select/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          console.log(documenti.data)
          setProgetti(documenti.data)
        }
        getUser()
        return () => {
          mounted = false
        }
    }, [])

    //Generazione randomica di stringhe
    function makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() *
     charactersLength)));
       }
       return result.join('');
    }

    const toggle = () => {
        setModal(!modal)
    }

    const onSubmit = (data) => {
        let formData = new FormData()
        let titolo = data.titolo
        let userID = localStorage.getItem("userID")

        formData.append("capitolo", isCapitolo)
        formData.append("userID", localStorage.getItem("role") === 'UTENTI' ? userID : null)
        formData.append("titolo", titolo)
        formData.append("paragrafo", isParagrafo)
        formData.append("admin", localStorage.getItem("email"))

            //Chiamata api
            const updateData = async () => {
                const update = await axios.post(`${apiEndpoint}/capitoli-progetto/create-paragrafo`, formData, {
                  headers: {
                    "x-access-token": localStorage.getItem('jwt')
                  }
                })
                reset()
                toggle()
              }
          updateData()

    }

    const selectStyles = {
      control: styles => ({ ...styles, borderRadius: '10px'})
    }

    const resetForm = () => {
      reset()
    }

    const changeCapitolo = capitolo => {
      setCapitolo(capitolo)
      let mounted = true
      const getUser = async () => {
        const documenti = await axios.get(`${apiEndpoint}/capitoli-progetto/fetch-paragrafo/${capitolo}/${localStorage.getItem("userID")}`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        let array = documenti.data
        let lunghezza = array.length
        setParagrafo(lunghezza + 1)
        console.log(lunghezza)
      }
      getUser()
      return () => {
        mounted = false
      }
    }


    if(isLoggedOut) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/login`} />)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Paragrafi" title="Aggiungi" />
            {loading ? <><div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12 card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Nuovo paragrafo"}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Capitoli"}</label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Seleziona capitolo"
                                              onChange={e => changeCapitolo(e.value)}
                                              options={allProgetti}
                                              styles={selectStyles}
                                              required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Titolo"} </label>
                                            <input className="form-control cleaning-form" name="titolo" type="text" placeholder="Inserisci titolo es. Documento tecnico ambienti" {...register("titolo")} required/>
                                            {errors.titolo && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    </div>

                                    <span className="required-field">*</span>tutti i campi sono richiesti
                                </div>
                                <div className="card-footer text-right">
                                    <Link to ="/paragrafi/lista" className="btn cleaning-btn-login cleaning-cancel-btn" >{"Annulla"}</Link>
                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Aggiungi"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div></> : null}

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Paragrafo aggiunto con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={toggle} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>


        </Fragment>
    );
};

export default ParagrafoAdd;
