import React, { Fragment, useState, useEffect } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import Slider from 'react-slick';
import Ratings from 'react-ratings-declarative'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, ProductReview, Brand, Availability, AddToCart, BuyNow, ContinueShopping,OutOfStock, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import Select from 'react-select';




const MaterialiEdit = (props) => {

      const history = useHistory();
      const [isMaterial, setMaterials] = useState({})
      const [isIdGruppo, setIDgruppo] = useState('')
      const [rating,setRating] = useState(0)
      const [isLoading, setLoading] = useState(false)
      const [modalImgSee, setModalImgSee] = useState();
      const [modal, setModal] = useState();
      const [valueEcolabel, setEcolabel] = useState('')
      const [ecolabel, changeEcolabel] = useState(null)
      const [codColore, setCodColore] = useState('')
      const [errDel, setErrDel] = useState('')
      const [isCodiceColore, setCodiceColore] = useState(null)
      const [modalDel, setModalDel] = useState();
      const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
      const { register: registerDel, handleSubmit: handleSubmitDel, formState: { errors: errorsDel } } = useForm();

      const selectStyles = {
        control: styles => ({ ...styles, borderRadius: '10px'})
      }

      useEffect(() => {
          let mounted = true
          const getMaterial = async () => {
            const material = await axios.get(`${apiEndpoint}/materiali/fetch-profilo/${props.match.params.id}`, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })

            if(mounted) {
              setMaterials(material.data)
              console.log(material.data)
              setIDgruppo(material.data.IDsottogruppo)
              setCodiceColore(material.data.PanCodiceColore)
              setEcolabel(material.data.EcoLabel)
              if(material.data.EcoLabel === 1) {
                changeEcolabel('Si')
              } else if(material.data.EcoLabel === 0) {
                changeEcolabel('No')
              }
              if(material.data.PanCodiceColore === 1) {
                setCodColore('Si')
              } else if(material.data.PanCodiceColore === 0) {
                setCodColore('No')
              }
              setLoading(true)
            }
          }
          getMaterial()
          return () => {
            mounted = false
          }
      }, [])

      const changeRating =  (newRating) => {
          setRating(newRating)
      }


      function isColore(colore) {
        if(colore === 1) {
          return 'Si'
        } else if(colore === 0) {
          return 'No'
        }
      }


      function calcoloDiluizione(valore) {
        if(valore === 0) {
          return 'Pronto all\'uso'
        } else {
          return valore + '%'
        }
      }

      const toggle = () => {
          setModal(!modal)
      }

      const toggleImgSee = () => {
          setModalImgSee(!modalImgSee)
      }

      const toggleClose = () => {
        setModal(!modal)
      }

      const toggleDel = () => {
          setModalDel(!modalDel)
      }

      const onSubmit = (data) => {

        var formData = new FormData
        formData.append('file', data.file[0])
        formData.append('percorsoImmagine', isMaterial.percorsoImmagine)
        formData.append('descrizione', data.descrizione)
        formData.append('resa', data.resa)
        formData.append('quantita', data.quantita)
        formData.append('prezzo', data.prezzo)
        formData.append('ecolabel', valueEcolabel)
        formData.append('IDarticolo', props.match.params.id)
        formData.append('IDsottogruppo', isIdGruppo)
        formData.append('tipoResa', data.tipoResa)

        if(isIdGruppo === 'DET') {
          console.log('qui')
          formData.append('confezione', data.confezione)
          formData.append('contenitore', data.contenitore)
          formData.append('plastica', data.plastica)
          formData.append('diluizione', data.diluizione)

          const updateData = async () => {
            const update = await axios.patch(`${apiEndpoint}/materiali/patch`, formData,{
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            history.push(`${process.env.PUBLIC_URL}/materiali/lista`)
          }
          updateData()

        } else if(isIdGruppo === 'PAN') {
          formData.append('composizione', data.composizione)
          formData.append('dimensioni', data.dimensioni)
          formData.append('peso1', data.peso1)
          formData.append('peso2', data.peso2)
          formData.append('assorbimento1', data.assorbimento1)
          formData.append('assorbimento2', data.assorbimento2)
          formData.append('decitex', data.decitex)
          formData.append('ciclo', data.ciclo)
          formData.append('temperatura', data.temperatura)
          formData.append('colore', isCodiceColore)
          formData.append('certificazione', data.certificazione)
          formData.append('tecnica', data.tecnica)
          formData.append('sicurezza', data.sicurezza)

          const updateData = async () => {
            const update = await axios.patch(`${apiEndpoint}/materiali/patch`, formData,{
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            history.push(`${process.env.PUBLIC_URL}/materiali/lista`)
          }
          updateData()
        }
      }

      const onDeleteAccount = data => {
           const deleteAccount = async () => {
            const delAcc = await axios.delete(`${apiEndpoint}/materiali/delete/${props.match.params.id}`, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            history.push(`${process.env.PUBLIC_URL}/materiali/lista`)
          }
          deleteAccount()
      }

      return (
          <Fragment>
              <Breadcrumb title="Dettagli prodotto" parent="Materiale" />
              {isLoading ? <div className="container-fluid">
                  <div className="card">
                  <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="card-header">
                          <h4 className="card-title mb-0">{"Modifica materiale"}</h4>
                      </div>
                      <div className="card-body">
                          <div className="row">

                          <div className="col-sm-6 col-md-6">
                              <img src={fileServer + isMaterial.percorsoImmagine} alt={isMaterial.descrizione1} className="immagine-profilo-materiale-edit"/>
                          </div>

                          <div className="col-sm-6 col-md-6">
                              <div className="form-group">
                                  <label className="form-label cleaning-form-label">{"Immagine materiale"} </label>
                                  <input className="form-control cleaning-form" name="file" type="file" placeholder="Inserisci descrizione es. Panno microfibra CLEANING ACADEMY" {...register("file")}/>
                                  {errors.file && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          <div className="col-sm-6 col-md-6">
                              <div className="form-group">
                                  <label className="form-label cleaning-form-label">{"Descrizione"} </label>
                                  <input className="form-control cleaning-form" name="descrizione" defaultValue={isMaterial.descrizione1} type="text" placeholder="Inserisci descrizione es. Panno microfibra CLEANING ACADEMY" {...register("descrizione")}/>
                                  {errors.descrizione && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          <div className="col-md-6">
                              <div className="form-group">
                                  <label className="form-label cleaning-form-label">{"Ecolabel"}</label>
                                  <Select
                                    className="cleaning-form"
                                    placeholder="Il prodotto é EcoLabel?"
                                    onChange={e => setEcolabel(e.value)}
                                    defaultValue={{value: isMaterial.EcoLabel, label: ecolabel}}
                                    options={[
                                      {
                                        value: 1,
                                        label: 'Si'
                                      },
                                      {
                                        value: 0,
                                        label: 'No'
                                      }
                                    ]}
                                    styles={selectStyles}
                                    required
                                  />
                              </div>
                          </div>

                          <div className="col-sm-6 col-md-6">
                              <div className="form-group">
                                  <label className="form-label cleaning-form-label">{"Resa prodotto mq/punti"} </label>
                                  <input className="form-control cleaning-form" name="resa" defaultValue={isMaterial.Resa} type="number" placeholder="Inserisci resa prodotto mq/punti" {...register("resa")}/>
                                  {errors.resa && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          <div className="col-sm-6 col-md-6">
                              <div className="form-group">
                                  <label className="form-label cleaning-form-label">{"Quantitá per cartone"} </label>
                                  <input className="form-control cleaning-form" name="quantita" defaultValue={isMaterial.confezionamento} type="number" placeholder="Inserisci quantitá per cartone" {...register("quantita")}/>
                                  {errors.quantita && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          <div className="col-sm-6 col-md-6">
                              <div className="form-group">
                                  <label className="form-label cleaning-form-label">{"Prezzo listino ufficiale (singolo pezzo iva esclusa)"} </label>
                                  <input className="form-control cleaning-form" name="prezzo" defaultValue={isMaterial.prezzo} step="0.01" type="number" placeholder="Inserisci prezzo es. 10.09" {...register("prezzo")}/>
                                  {errors.prezzo && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          <div className="col-sm-6 col-md-6">
                              <div className="form-group">
                                  <label className="form-label cleaning-form-label">{"Tipo resa"} </label>
                                  <select className="form-control cleaning-form" name="tipoResa" defaultValue={isMaterial.tipoResa === null ? 'mq' : isMaterial.tipoResa} {...register("tipoResa")}>
                                    <option value={isMaterial.tipoResa === null ? 'mq' : isMaterial.tipoResa}>{isMaterial.tipoResa === null ? 'mq' : isMaterial.tipoResa}</option>
                                    {isMaterial.tipoResa === 'punti' ? <option value="mq">mq</option> : <option value="punti">punti</option>}
                                  </select>
                                  {errors.prezzo && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          <div className="col-sm-12 col-md-12">
                              <div className="form-group">
                              <label className="form-label cleaning-form-label">{"Scheda tecnica (es. https://sitoproduttore.com)"} </label>
                                  <input className="form-control cleaning-form" name="tecnica" defaultValue={isMaterial.percorsoTecnica}  type="url" placeholder="es. https://sitoproduttore.com"  {...register("tecnica")}/>
                                  {errors.prezzo && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          <div className="col-sm-12 col-md-12">
                              <div className="form-group">
                              <label className="form-label cleaning-form-label">{"Scheda sicurezza (es. https://sitoproduttore.com)"} </label>
                                  <input className="form-control cleaning-form" name="sicurezza" defaultValue={isMaterial.percorsoSicurezza}  type="url" placeholder="es. https://sitoproduttore.com"  {...register("sicurezza")}/>
                                  {errors.prezzo && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          </div>
                          {(() => {
                            switch(isIdGruppo) {
                              case 'DET':
                                return (
                                  <>
                                  <hr />
                                  <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Confezione tanica ml"} </label>
                                            <input className="form-control cleaning-form" name="confezione" defaultValue={isMaterial.quantitàRiferimentoResa}  type="number" placeholder="Inserisci ml prodotto" {...register("confezione")}/>
                                            {errors.confezione && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Grammi plastica contenitore"} </label>
                                            <input className="form-control cleaning-form" name="contenitore" type="number" defaultValue={isMaterial.DetGrammiplastica} placeholder="Inserisci grammi plastica contenitore" {...register("contenitore")}/>
                                            {errors.contenitore && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Plastica riciclata %"} </label>
                                            <input className="form-control cleaning-form" name="plastica" type="number" defaultValue={isMaterial.DetPercPlasticaRiciclata} placeholder="Inserisci percentuale plastica riciclata es. 100%" {...register("plastica")}/>
                                            {errors.plastica && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Diluizione %"} </label>
                                            <input className="form-control cleaning-form" name="diluizione" type="number" defaultValue={isMaterial.diluizione1} placeholder="Inserisci percentuale diluzione" {...register("diluizione")}/>
                                            {errors.diluizione && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>



                                  </div>
                                  </>
                                )
                               case 'PAN':
                                return (
                                  <>
                                  <hr />
                                  <div className="row">

                                  <div className="col-sm-6 col-md-6">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Composizione"} </label>
                                          <input className="form-control cleaning-form" name="composizione" defaultValue={isMaterial.descrizioneNomenclatura} type="text" placeholder="Inserisci composizione panno es. 70% pes, 30% pa" {...register("composizione")}/>
                                          {errors.composizione && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                  </div>
                                  <div className="col-sm-6 col-md-6">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Dimensioni panno cm/pz"} </label>
                                          <input className="form-control cleaning-form" name="dimensioni" type="text" defaultValue={isMaterial.nomenclaturaCombinata} placeholder="Inserisci dimensioni panno es. 40x40" {...register("dimensioni")}/>
                                          {errors.dimensioni && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                  </div>
                                  <div className="col-sm-3 col-md-3">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Peso gr/mq"} </label>
                                          <input className="form-control cleaning-form" name="peso1" type="number" defaultValue={isMaterial.pesoNetto1} step="0.01" placeholder="Inserisci peso al metro quadro es. 30" {...register("peso1")}/>
                                          {errors.peso1 && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                  </div>

                                  <div className="col-sm-3 col-md-3">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Peso a pz gr"} </label>
                                          <input className="form-control cleaning-form" name="peso2" type="number" step="0.01" defaultValue={isMaterial.pesoLordo1} placeholder="Inserisci peso al pezzo es. 4" {...register("peso2")}/>
                                          {errors.peso2 && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                  </div>

                                  <div className="col-sm-3 col-md-3">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Assorbimento massimo ml/pz"} </label>
                                          <input className="form-control cleaning-form" name="assorbimento1" type="number" defaultValue={isMaterial.lunghezza1} step="0.01"  placeholder="Inserisci assorbimento massimo" {...register("assorbimento1")}/>
                                          {errors.assorbimento1 && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                  </div>

                                  <div className="col-sm-3 col-md-3">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Assorbimento dopo strizzaggio ml/pz"} </label>
                                          <input className="form-control cleaning-form" name="assorbimento2" type="number" defaultValue={isMaterial.larghezza1} placeholder="Inserisci assorbimento dopo strizzaggio" {...register("assorbimento2")}/>
                                          {errors.assorbimento2 && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                  </div>

                                  <div className="col-sm-3 col-md-3">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Decitex"} </label>
                                          <input className="form-control cleaning-form" name="decitex" type="number" defaultValue={isMaterial.PanDecitex}  step="0.01" placeholder="Inserisci valore decitex" {...register("decitex")}/>
                                          {errors.decitex && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                  </div>

                                  <div className="col-sm-3 col-md-3">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Cicli lavaggio / vita utile panno"} </label>
                                          <input className="form-control cleaning-form" name="ciclo" type="number" defaultValue={isMaterial.PanVitaUtile} placeholder="Inserisci cicli lavaggio / vita utile panno" {...register("ciclo")}/>
                                          {errors.ciclo && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                  </div>

                                  <div className="col-sm-3 col-md-3">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Temperatura massima lavaggio"} </label>
                                          <input className="form-control cleaning-form" name="temperatura" type="number" defaultValue={isMaterial.PanTemperaturaLavaggio} placeholder="Inserisci temperatura massima lavaggio" {...register("temperatura")}/>
                                          {errors.temperatura && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                  </div>

                                  <div className="col-sm-3 col-md-3">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Codice colore"} </label>
                                          <Select
                                            className="cleaning-form"
                                            placeholder="Scegli la categoria del materiale"
                                            onChange={e => setCodiceColore(e.value)}
                                            defaultValue={{value: isMaterial.PanCodiceColore, label: codColore}}
                                            options={[
                                              {
                                                value: 1,
                                                label: 'Si'
                                              },
                                              {
                                                value: 0,
                                                label: 'No'
                                              }
                                            ]}
                                            styles={selectStyles}
                                            required
                                          />
                                      </div>
                                  </div>

                                  <div className="col-sm-12 col-md-12">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Certificazioni"} </label>
                                          <input className="form-control cleaning-form" name="certificazione" defaultValue={isMaterial.PanCertificazioni} type="text" placeholder="Inserisci certificazione es. Idoneo CAM" {...register("certificazione")}/>
                                          {errors.certificazione && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                  </div>

                                  </div>
                                  </>
                                )
                              default:
                                return null
                            }
                          })()}
                          <span className="required-field">*</span>tutti i campi sono richiesti
                      </div>
                      <div className="card-footer text-right">
                      <button className="btn cleaning-btn-login cleaning-cancel-btn" type="button" onClick={() => history.push(`${process.env.PUBLIC_URL}/materiali/lista`)}>{"Annulla"}</button>
                          <a className="btn btn-danger cleaning-btn-login" href="javascript:void(0)" onClick={() => toggleDel()}>{"Elimina"}</a>
                          <button className="btn btn-primary cleaning-btn-login" type="submit">{"Modifica"}</button>
                      </div>
                  </form>
                  </div>
                  <Modal isOpen={modalImgSee} toggle={toggleImgSee} centered={true} modalClassName="cleaning-modal remove-bg-cleaning">
                            <img className="light-shadow" alt={isMaterial.descrizione1} src={fileServer + isMaterial.percorsoImmagine} />
                  </Modal>
              </div> : null}
              {/* Modale successo*/}
              <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                  <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                  <ModalBody>
                      <p>Dati modificati con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                  </ModalBody>
                  <ModalFooter>
                      <Button color="success" onClick={() => history.push("/materiali/lista")} className="btn-cleaning">{"Continua"}</Button>
                  </ModalFooter>
              </Modal>

              {/* Modale per eliminazione account*/}
              <Modal isOpen={modalDel} toggle={toggleDel} centered={true} modalClassName="cleaning-modal">
                <form onSubmit={handleSubmitDel(onDeleteAccount)}>
                  <ModalHeader toggle={toggleDel}>Attenzione!</ModalHeader>
                  <ModalBody>
                      <p>Stai per <strong>eliminare</strong> il tuo materiale, sei sicuro di voler procedere?</p>
                  </ModalBody>
                  <ModalFooter>
                      <Button onClick={toggleDel} className="cleaning-cancel-btn">{"Annulla"}</Button>
                      <Button color="danger" type="submit" className="btn-cleaning">{"Elimina"}</Button>
                  </ModalFooter>
                </form>
              </Modal>
              {/* Modale per eliminazione account*/}
          </Fragment>
      );
  }

export default MaterialiEdit;
