import React, { Fragment, useState, useEffect } from 'react';
import { Redirect, Link, useParams } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import Slider from 'react-slick';
import Ratings from 'react-ratings-declarative'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, ProductReview, Brand, Availability, AddToCart, BuyNow, ContinueShopping,OutOfStock, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import parse from 'html-react-parser';



const TemplatesProfilo = (props) => {


      const [isTemplate, setTemplates] = useState({})
      const [isLoading, setLoading] = useState(false)
      let { id } = useParams()



      useEffect(() => {
        //${localStorage.getItem("userID")}
          let mounted = true
          const getTemplate = async () => {
            const templates = await axios.get(`${apiEndpoint}/templates/fetch-profilo`, {
              params: {
                id: id,
                userID: null
              },
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            if(mounted) {
              setTemplates(templates.data)
              setLoading(true)
            }
          }
          getTemplate()
          return () => {
            mounted = false
          }
      }, [])

      return (
          <Fragment>
              <Breadcrumb title="Dettagli attrezzatura" parent="Attrezzatura" />
              {isLoading ? <div className="container-fluid">
                  <div className="card">
                      <div className="row">
                        <div className="col ml-4">
                          <h4 className="titolo-profilo-materiale">{isTemplate.descrizione}</h4>
                          <div>{parse(isTemplate.testoCorpo)}</div>
                        </div>
                      </div>
                  </div>
              </div> : null}
          </Fragment>
      );
  }

export default TemplatesProfilo;
