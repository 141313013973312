import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Word from '../../assets/images/word-thumb.png'

const CapitolatiLista = () => {

    const [allCapitolati,setCapitolati] = useState([])
    const [clientiPotenziali, setClientiPotenziali] = useState([])
    const [loading, setLoading] = useState(false)
    let history = useHistory()


    useEffect(() => {
        let mounted = true
        const getData = async () => {
          const documenti = await axios.get(`${apiEndpoint}/capitoli-progetto/capitolati/fetch/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          const paragrafi = await axios.get(`${apiEndpoint}/capitoli-progetto/fetch-paragrafi/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          const capitoli = await axios.get(`${apiEndpoint}/capitoli-progetto/fetch/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          let array = []
          documenti.data.map(item => {
            let obj = {
              ID: item.ID,
              numeroPagine: item.numeroPagine,
              Descrizione: item.Descrizione,
            }
            capitoli.data.map(cap => {
              if(item.IDcapitolo === cap.IDcapitolo) {
                obj.capitolo = cap.Descrizione
              }
              console.log(cap)
            })
            paragrafi.data.map(par => {
              console.log(par)
              if(item.IDcapitolo === par.IDcapitolo[0] && item.IDparagrafo === par.IDparagrafo) {
                obj.paragrafo = par.Descrizione[0]
              }
            })
            array.push(obj)
          })
          setCapitolati(array)
          setLoading(true)
        }
        getData()
        return () => {
          mounted = false
        }
    }, [])

    const { SearchBar } = Search;
    const columns = [
    {
      dataField: 'Descrizione',
      text: '',
      formatter: (cell,row) => {
        return (
          <center>
            <img src={Word} alt="word-thumbnail" className="cleaning-doc-thumbnail"></img>
          </center>
        )
      }
    },
    {
      dataField: 'capitolo',
      text: 'Capitolo',
    },
    {
      dataField: 'paragrafo',
      text: 'Paragrafo',
    },
    {
      dataField: 'Descrizione',
      text: 'Nome',
      formatter: (cell, row) => {
        return (
          <Link to={`/documenti/dettaglio/${row.ID}`}>{row.Descrizione}</Link>
        )
      }
    },
    {
      dataField: 'numeroPagine',
      text: 'Pagine'
    }
  ];

    return (
        <Fragment>
            <Breadcrumb title="Lista" parent="Capitolati" />
            <div className="container-fluid">
            {loading ?     <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                          <div className="card-body datatable-react">
                            <div className="row">

                            <ToolkitProvider
                            keyField='ID'
                            data={ allCapitolati }
                            columns={ columns }
                            search
                            >
                            {
                              props => (
                                <div>
                                  <div className="d-flex flex-row justify-content-between w-100 mb-2"><SearchBar
                                  { ...props.searchProps }
                                  placeholder="Cerca"
                                  className="cleaning-form text-left"
                                  />
                                  <div className="text-right">
                                      <Link to={`${process.env.PUBLIC_URL}/documenti/aggiungi`} className="btn btn-success cleaning-btn-login" type="submit">{"Aggiungi"}</Link>
                                  </div>
                                  </div>
                                  <BootstrapTable
                                    { ...props.baseProps }
                                    striped
                                    hover
                                    condensed
                                    keyField="ID"
                                    pagination={ paginationFactory({
                                      showTotal: false,
                                      firstPageText: 'Inizio', // the text of first page button
                                      prePageText: 'Prec', // the text of previous page button
                                      nextPageText: 'Succ', // the text of next page button
                                      lastPageText: 'Fine', // the text of last page button
                                      nextPageTitle: 'Vai al successivo', // the title of next page button
                                      prePageTitle: 'Vai al precedente', // the title of previous page button
                                      firstPageTitle: 'Vai al primo', // the title of first page button
                                      lastPageTitle: 'Vai all\'ultmo', // the title of last page button
                                    }) }
                                  />
                                </div>
                              )
                            }
                            </ToolkitProvider>

                              {/*allCapitolati.map((item, index) => {
                                return (
                                  <div className="col-xl-6 col-md-6 col-sm-6 h-25" key={index}>
                                  <div className="card">
                                      <div className="card-body text-center">
                                          <center>
                                            <Capitolato capitolato={item} />
                                          </center>
                                              <Link to={`${process.env.PUBLIC_URL}/capitolati/dettaglio/${item.ID}`}><h5 className="title-cleaning-dashboard">{item.Descrizione}</h5></Link>
                                          </div>
                                      </div>
                                  </div>
                                )
                              })*/}
                            </div>
                          </div>
                        </div>
                    </div>
                </div>: <div className="spinner-loading-cleaning"><div className="loader-box">
                  <div className="loader">
                    <div className="line bg-success"></div>
                    <div className="line bg-success"></div>
                    <div className="line bg-success"></div>
                    <div className="line bg-success"></div>
                  </div>
                </div></div>}
            </div>
            {/* Modale successo*/}

        </Fragment>
    );
};

export default CapitolatiLista;
