import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, ListGroup, ListGroupItem, FormGroup, Label, Input } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import Word from '../../assets/images/word-thumb.png'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';


const PreventivoProgetto = (props) => {

    const [data,setData] = useState([])
    const [allTemplates, setTemplates] = useState([{}])
    const [allParagrafi, setParagrafi] = useState([{}])
    const [allDocumenti, setDocumenti] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [selectedDocs, setSelectedDocs] = useState([])
    const [selectOptions, setSelect] = useState([])
    const [isTotPagine, setTotPagine] = useState(0)
    const [allOperations, viewOperations] = useState([{}])
    const [allProdotti, viewProdotti] = useState([{}])
    const [allAtt, viewAtt] = useState([{}])
    const [isPrev, setPrev] = useState({})
    const history = useHistory()


    useEffect(() => {
        let mounted = true
        const getUser = async () => {
          const prev = await axios.get(`${apiEndpoint}/preventivi/fetch-profilo-id`, {
            params: {
              id: props.match.params.id,
              userID: localStorage.getItem("userID")
            },
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          setPrev(prev.data)
          const capitoli = await axios.get(`${apiEndpoint}/preventivi/fetch-progetto/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          const paragrafi = await axios.get(`${apiEndpoint}/preventivi/fetch-paragrafo/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          const documenti = await axios.get(`${apiEndpoint}/capitoli-progetto/capitolati/fetch/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          const operazioni = await axios.get(`${apiEndpoint}/preventivi/fetch-preventivo-operazioni-finale`, {
            params: {
              id: props.match.params.id
            },
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          const prodotti = await axios.get(`${apiEndpoint}/preventivi/fetch-preventivo-documento-articoli`, {
            params: {
              id: props.match.params.id
            },
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          const att = await axios.get(`${apiEndpoint}/preventivi/fetch-preventivo-documento-att`, {
            params: {
              id: props.match.params.id
            },
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          console.log(paragrafi.data)
          viewOperations(operazioni.data)
          viewProdotti(prodotti.data)
          viewAtt(att.data)
          setDocumenti(documenti.data)
          let array = []
          capitoli.data.map(item => {

            let indice = item.Descrizione.slice(0, 8).split(".")
              if (parseInt(indice[0])) {
                  let obj = {
                      IDcapitolo: item.IDcapitolo,
                      capitolo: item.Descrizione,
                      n_capitolo: parseInt(indice[0])
                  }
                  let arrayP = []
                  paragrafi.data.map(par => {
                      let pg = {
                          IDparagrafo: par.IDparagrafo,
                          paragrafo: par.Descrizione
                      }
                      if (par.IDcapitolo === item.IDcapitolo) {
                          arrayP.push(pg)
                      }
                      let arrayD = []
                      documenti.data.map(doc => {
                          let dc = {
                              IDcapitolo: item.IDcapitolo,
                              DescrizioneCapitolo: item.Descrizione,
                              ParagrafoIDCapitolo: par.IDcapitolo,
                              IDparagrafo: par.IDparagrafo,
                              Descrizioneparagrafo: par.Descrizione,
                              DocumentoIDParagrafo: doc.IDparagrafo,
                              IDdocumento: doc.ID,
                              documento: doc.Descrizione,
                              url: doc.PercorsoDocumento,
                              pagine: doc.numeroPagine,
                              checked: false
                          }
                          if (doc.IDparagrafo === par.IDparagrafo && doc.IDcapitolo === item.IDcapitolo) {
                              arrayD.push(dc)
                          }
                      })
                      pg.documenti = arrayD
                  })
                  obj.paragrafi = arrayP
                  array.push(obj)
              }
            
          })
          var list = []
          for(let i = 1; i <= documenti.data.length; i++) {
            let o = {
              value: i,
              disabled: false
            }
            list.push(o)
          }
          setSelect(list)
          array = array.sort((a, b) => b.n_capitolo - a.n_capitolo)
          array = array.reverse()
          setTemplates(array)
          setLoading(true)
        }
        getUser()
        return () => {
          mounted = false
        }
    }, [])

    const onChangeDocs = (check, doc) => {
      if(check.target.checked === true) {
        var array = selectedDocs
        setTotPagine(isTotPagine + doc.pagine)
        var search =  array.findIndex(x => x.IDdocumento === doc.IDdocumento);
        if(search >= 0 && array[search].checked === false) {
          array[search].checked = true
        } else {
          const newDocs = [...selectedDocs, doc]
          setSelectedDocs(newDocs)
        }
      } else {
        setTotPagine(isTotPagine - doc.pagine)
          var array = selectedDocs
          array = array.filter(obj => obj.IDdocumento != doc.IDdocumento);
          setSelectedDocs(array)
      }
    }

    const onChangePosition = (value, doc) => {
      var array = selectedDocs
      var opt = selectOptions
      var search =  array.findIndex(x => x.IDdocumento === doc.IDdocumento);
      if(search >= 0) {
        array[search].posizione = parseInt(value)
      } else {
        doc.posizione = parseInt(value)
        const newDocs = [...selectedDocs, doc]
        setSelectedDocs(newDocs)
      }
    }


    const { SearchBar } = Search;
    const columns = [
    {
      dataField: 'ambiente',
      text: 'Ambiente',
      formatter: (value, row) => (
            <img src={Word} className="cleaning-doc-thumbnail"/>
      ),
    },
    {
      dataField: 'descrizione',
      text: 'Descrizione',
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push(`${process.env.PUBLIC_URL}/preventivi/aggiungi/${row.numero}`)
        }
      },
      formatter: (value, row) => (
          <span style={{cursor: 'pointer'}}>{row.descrizione}</span>
      ),
    }
  ];

  const [mergedSucces, setMerged] = useState(false)
  const [mergeData, setMergeData] = useState(null)

  function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

  const mergeDocs = () => {
    let modifica = selectedDocs
    modifica.sort(function(a, b) {
        return a.posizione - b.posizione;
    });
    let array = []
    let arrayPar = []
    let arrayCap = []
    modifica.map(capitolo => {
      let obj = {
        idCapitolo: capitolo.IDcapitolo,
        capitolo: capitolo.DescrizioneCapitolo
      }
      array.push(obj)
    })
    modifica.map(paragrafo => {
      let obj = {
        idCapitolo: paragrafo.IDcapitolo,
        idParagrafo: paragrafo.IDparagrafo,
        paragrafo: paragrafo.Descrizioneparagrafo
      }
      arrayPar.push(obj)
    })
    modifica.map(documento => {
      let obj = {
        idCapitolo: documento.IDcapitolo,
        idParagrafo: documento.IDparagrafo,
        paragrafo: documento.Descrizioneparagrafo,
        documento: documento.documento
      }
      arrayCap.push(obj)
    })
    var newArray = []
    var newArrayPar = []
    array.filter(function(item){
      var i = newArray.findIndex(x => (x.idCapitolo == item.idCapitolo && x.capitolo == item.capitolo));
      if(i <= -1){
            newArray.push(item);
      }
      return null;
    });
    arrayPar.filter(function(item){
      var i = newArrayPar.findIndex(x => (x.idCapitolo == item.idCapitolo && x.idParagrafo == item.idParagrafo && x.paragrafo == item.paragrafo));
      if(i <= -1){
            newArrayPar.push(item);
      }
      return null;
    });
    let indice = []
    newArray.map(item => {
      let obj = {
        capitolo: item.capitolo
      }
      let objPar = []
      newArrayPar.map(par => {
        if(parseInt(item.idCapitolo) === parseInt(par.idCapitolo)) {
          let pbj = {
            paragrafo: par.paragrafo
          }
          let objCap = []
          arrayCap.map(cap => {
            if(parseInt(cap.idParagrafo) === parseInt(par.idParagrafo) && parseInt(cap.idCapitolo) === parseInt(par.idCapitolo)) {
              let cbj = {
                documento: cap.documento
              }
              objCap.push(cbj)
            }
          })
          pbj.documento = objCap
          objPar.push(pbj)
        }
      })
      obj.paragrafi = objPar
      indice.push(obj)
    })
    console.log(indice)

    const onSubmit = async () => {
      const unisci = await axios.post(`${apiEndpoint}/capitoli-progetto/unisci`, {
        data: selectedDocs,
        id: props.match.params.id,
        indice: indice,
        operazioni: allOperations,
        preventivo: isPrev,
        prodotti: allProdotti,
        attrezzature: allAtt,
      }, {
        headers: {
          "x-access-token": localStorage.getItem('jwt')
        }
      })
      setMerged(true)
      setMergeData(unisci.data.message)
    }
    onSubmit()

  }


    return (
        <Fragment>
            {loading ? <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                          <div className="card-body datatable-react">
                            <div className="row">
                              <div className="card-body datatable-react">
                                <h3>Crea il tuo progetto</h3>
                                <p>Seleziona i documenti che intendi inserire nel tuo progetto. Capitoli e paragrafi verranno inseriti di conseguenza automaticamente.</p>
                                <br />
                                <p>Totale pagine progetto tecnico: <strong>{isTotPagine}</strong></p>
                                <br />
                                {mergedSucces ? <div>
                                  <h4>Documento creato con successo, clicca su <strong>Scarica</strong> per effettuare il download</h4>
                                  <center><a className="btn btn-success cleaning-btn-login" href={mergeData} style={{marginBottom: '3vh', marginTop: '5vh'}} download>SCARICA</a></center>
                                  </div> :<center><button className="btn btn-success cleaning-btn-login" style={{marginBottom: '3vh', marginTop: '5vh'}} onClick={() => mergeDocs()} disabled={selectedDocs.length > 0 ? false : true}>CREA</button></center>}


                                  {allTemplates ? <div><ListGroup>

                                    {allTemplates.map((item, index) => {
                                        console.log(item)
                                      return (
                                        <ListGroupItem index={index} className="div-progetto">
                                          <p className="progetto-titolo-capitolo">{item.capitolo}</p>
                                          {item.paragrafi ? item.paragrafi.map((paragrafo, idx) => {
                                            return (
                                              <ListGroupItem index={idx}>
                                                <p className="progetto-titolo-paragrafo">{paragrafo.paragrafo}</p>
                                                {paragrafo.documenti.map((doc, idc) => {
                                                  return (
                                                    <ListGroupItem index={idc}>
                                                      <div className="row" style={{marginTop: '1vh', marginBottom: '1vh'}}>
                                                        <div className="col-sm-10">
                                                          <FormGroup check>
                                                            <Input type="checkbox" onChange={e => onChangeDocs(e, doc)}/>
                                                            {' '}
                                                            <Label className="progetto-titolo-documento" check>
                                                              {doc.documento}{" "}<a href={`https://dev-cleaning.tribusadv.com/documenti/dettaglio/${doc.IDdocumento}`} target="_blank"><span>&#128279;</span></a><br />
                                                              <small>Numero pagine: <strong>{doc.pagine}</strong></small>
                                                            </Label>
                                                          </FormGroup>
                                                        </div>

                                                        <div className="col-sm-2">
                                                          <label htmlhtmlFor="fname">Posizione</label>
                                                          <select type="number" onChange={e => onChangePosition(e.target.value, doc)}  className="form-control cleaning-form">
                                                            <option disabled={false}></option>
                                                            {selectOptions.map((opt, ido) => {
                                                              return (
                                                                <option value={opt.value} >{opt.value}</option>
                                                              )
                                                            })}
                                                          </select>
                                                        </div>
                                                      </div>
                                                    </ListGroupItem>
                                                  )
                                                })}
                                              </ListGroupItem>
                                            )
                                          }) : null}
                                        </ListGroupItem>
                                      )
                                    })}
                                  </ListGroup>
                                  {mergedSucces ? <div>
                                    <h4>Documento creato con successo, clicca su <strong>Scarica</strong> per effettuare il download</h4>
                                    <center><a className="btn btn-success cleaning-btn-login" href={mergeData} style={{marginBottom: '3vh', marginTop: '5vh'}} download>SCARICA</a></center>
                                    </div> :<center><button className="btn btn-success cleaning-btn-login" style={{marginBottom: '3vh', marginTop: '5vh'}} onClick={() => mergeDocs()} disabled={selectedDocs.length > 0 ? false : true}>CREA</button></center>}
                                  </div>
                                   : 'Caricamento documenti...'}

                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div> : <div className="spinner-loading-cleaning"><div className="loader-box">
              <div className="loader">
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
              </div>
            </div></div>}
        </Fragment>
    );
};

export default PreventivoProgetto;
