import React, { useState, useEffect } from 'react';
import logo from '../assets/images/endless-logo.png';
import man from '../assets/images/dashboard/user.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import axios from 'axios'
import  {firebase_app, googleProvider, facebookProvider, twitterProvider, githubProvider,Jwt_token } from "../data/config";
import { handleResponse } from "../services/fack.backend";
import { useForm } from "react-hook-form";
import { apiEndpoint,Login,LOGIN,YourName,Password,RememberMe,LoginWithAuth0,LoginWithJWT } from '../constant';
import LoginImage from '../assets/images/signup_image.png'

const CleaningForgotPassword = () => {

    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [isErr, setErr] = useState('')
    const [isSucc, setSucc] = useState('')

    const onSubmit = (data) => {
      let email = data.email;
      const forgotPwd = async () => {
        const enter = await axios.post(`${apiEndpoint}/auth/forgot-password`, {
          email: email.toLowerCase().trim(),
        })
        reset()
        if(enter.status === 200) {
        if(enter.data.auth === true) {
          setErr('')
          setSucc('Riceverai a breve una mail per effettuare il recupero della tua password. Se questo non avvenisse controlla la tua casella di SPAM o effettua nuovamente la procedura.')
        } else if (enter.data.auth === false) {
          setErr(enter.data.message)
          setSucc('')
        } } else {
          console.log(enter.data.message)
        }
      }
      forgotPwd()
    }

    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="card mt-4 cleaning-login-card">
                                            <div className="card-body">
                                                <div className="text-center">
                                                <div className="text-center">
                                                  <img src={LoginImage} alt="cleaning-image" className="login-image"/>
                                                </div>
                                                    <h4>{LOGIN}</h4>
                                                    <h6>{"Inserisci la mail per effettuare il recupero password"} </h6>
                                                </div>
                                                <form className="theme-form" onSubmit={handleSubmit(onSubmit)}>
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0 cleaning-form-label">{"Email"}</label>
                                                        <input className="form-control cleaning-form" type="email" name="email"
                                                            placeholder="Il tuo indirizzo email" {...register("email", {required: true})}/>
                                                            {errors.email && <p className="err-msg-form">Campo richiesto</p>}

                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <button className="btn btn-primary btn-block cleaning-btn-login" type="submit" >{"Recupera password"}</button>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0">

                                                      <div className="col-md-12">
                                                          <span>Vuoi effettuare il login? <Link to={`${process.env.PUBLIC_URL}/login`}> Clicca qui</Link></span>
                                                      </div>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <p className="text-left err-msg">{isErr}</p>
                                                        <p className="text-left succ-msg">{isSucc}</p>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

export default  CleaningForgotPassword;
