import React, { Fragment,useState,useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Modal as Modal2 } from 'react-bootstrap'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';





const Prova = (props) => {
  return (
    <h1>{props.cantiere.RagioneSociale1}</h1>
  )
}


const ClientiPotenzialiProfile = (props) => {

    const [data,setData] = useState([])
    const [show, setShow] = useState(false);
    const [userData, setClientData] = useState({})
    const [allCantieri, setCantieri] = useState([{}])
    const [loading, setLoading] = useState(true)
    const [fileImage, setFile] = useState(null)
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { register: registerDel, handleSubmit: handleSubmitDel, formState: { errors: errorsDel } } = useForm();
    const { register: registerEdit, handleSubmit: handleSubmiteEdit, formState: { errors: errorsEdit }, reset } = useForm();
    const { register: registerAdd, handleSubmit: handleSubmitAdd, formState: { errors: errorsAdd } } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalEdit, setModalEdit] = useState(false);
    const [modalAdd, setModalAdd] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [isCantierePotenziale, setCantierePotenziale] = useState({})
    const [isCantiere, setIsCantiere] = useState(0)
    const [isDeleted, setDeleted] = useState(false)
    const [nomeCantiere, setNomeCantiere] = useState('')
    const [selectedRow, addSelectedRow] = useState([])
    const [isHidden, setHidden] = useState(true)
    const [isHiddenAll, setHiddenAll] = useState(true)


    useEffect(() => {
        let mounted = true
        const getUser = async () => {
          const user = axios.get(`${apiEndpoint}/clienti-potenziali/fetch/${localStorage.getItem("userID")}/${props.match.params.id}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          const cantieri = axios.get(`${apiEndpoint}/clienti-potenziali/fetch-cantieri/${props.match.params.id}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          if(mounted) {
            user.then(res => {
              setClientData(res.data[0])
              setLoading(true)
            })
            cantieri.then(cant => {
              let cants = cant.data
              setCantieri(cant.data)
              //let arrayCant = []
              //cants.map((item, index) => {
              //  let cantiere = [item.RagioneSociale1, item.Indirizzo, item.IDcomune, item.Provincia, item.CAP, item.IDcantiere]
              //  arrayCant.push(cantiere)
              //})
              //setCantieri(arrayCant)
            })
          }
        }
        getUser()
        return () => {
          mounted = false
        }
    }, [allCantieri])

    const toggle = () => {
        setModal(!modal)
    }

    const toggleDel = () => {
        setModalDel(!modalDel)
    }

    const toggleImg = () => {
        setModalImg(!modalImg)
    }

    const toggleEdit = () => {
        setModalEdit(!modalEdit)
    }

    const toggleAdd = () => {
        setModalAdd(!modalAdd)
    }

    const toggleImgSee = () => {
        setModalImgSee(!modalImgSee)
    }

    const onSubmitAdd = data => {
      let cantiere = data.cantiere
      let address = data.address
      let city = data.city
      let postalCode = data.postalCode
      let region = data.region
      let cliID = props.match.params.id
      const insertData = async () => {
        const cantierePotenziale = await axios.post(`${apiEndpoint}/clienti-potenziali/create-cantiere`, {
          cantiere: cantiere,
          address: address,
          city: city,
          postalCode: postalCode,
          region: region,
          cliID: cliID
        }, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        toggleAdd()
        toggle()
      }
      insertData()
    }

    const onSubmitEdit = data => {
      let cantiere = data.cantiere
      let address = data.address
      let city = data.city
      let postalCode = data.postalCode
      let region = data.region
      let cliID = props.match.params.id
      const updateData = async () => {
        const cantUp = await axios.patch(`${apiEndpoint}/clienti-potenziali/patch-cantiere`, {
          cantiere: cantiere,
          address: address,
          city: city,
          postalCode: postalCode,
          region: region,
          cliID: cliID,
          cantiereID: data.cantiereID
        }, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        toggleEdit()
        toggle()
      }
      updateData()
    }

    const toggleDD = () => setDropdownOpen(prevState => !prevState)

    const onSubmitCliente = data => {

    const updateData = async () => {
        const update = await axios.patch(`${apiEndpoint}/clienti-potenziali/patch`, {
          email: data.email ? data.email : userData.Email,
          phone: data.phone ? data.phone : userData.Telefono,
          address: data.address ? data.address : userData.Indirizzo,
          city: data.city ? data.city : userData.IDcomune,
          postalCode: data.postalCode ? data.postalCode : userData.Cap,
          region: data.region ? data.region : userData.Provincia,
          ragione_sociale: data.ragione_sociale ? data.ragione_sociale : userData.RagioneSociale1,
          userID: props.match.params.id
        }, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        toggle()
        setTimeout(() => {
          setDeleted(true)
        }, 2000)

      }
      updateData()
    }

    const setFileUpload = (target) => {
      if(target) {
        let newImage = target.files[0]
        let imageSize = newImage.size
        let imageType = newImage.type
        if(imageType === 'image/png' || imageType === 'image/jpeg') {
          if(imageSize <= 2000000) {
            setFile(newImage)
            setDisabled(false)
          } else {
            window.alert('Dimensione massima consentita 2MB.')
          }
        } else {
          window.alert('Puoi caricare solo immagini in formato .png, .jpeg, .jpg')
        }
      }
    }

    const onRecoverPassword = data => {
      const updateData = async () => {
          const update = await axios.patch(`${apiEndpoint}/user/change-password/${localStorage.getItem("userID")}`, {
            password: data.password,
            repassword: data.repassword
          }, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          if(update.data === 'Password uguali') {
            setErrPwd('La vecchia password e la nuova password devono essere differenti.')
          } else if(update.data === 'Password modificata') {
            reset()
            toggle()
          } else if(update.data === 'Password vecchia non combacia') {
            setErrPwd('La vecchia password é errata, inserisci la password corretta.')
          }
        }
        updateData()
    }

    const columns = ["Cantiere", "Indirizzo", "Cittá", "Provincia", "CAP", {
      name: 'Codice',
      options: {
        display: false
      }
    }];

    const { SearchBar } = Search;
    const columns2 = [{
      dataField: 'RagioneSociale1',
      text: 'Cantiere'
    }, {
      dataField: 'Indirizzo',
      text: 'Indirizzo'
    }, {
      dataField: 'IDcomune',
      text: 'Città'
    }, {
      dataField: 'Provincia',
      text: 'Provincia'
    }, {
      dataField: 'CAP',
      text: 'CAP'
    }];


    const [customerCode, setCustomerCode] = useState('')
    const [isMoved, setMoved] = useState(false)

    function moveToProfile(code, redirect) {
      setCustomerCode(code)
      setMoved(true)
    }




  function beforeSaveCell(oldValue, newValue, row, column, done) {
  console.log(row)
  console.log(oldValue)
  console.log(newValue)
  console.log(column)
  let id = props.match.params.id
  const updateData = async () => {
    const cantUp = await axios.patch(`${apiEndpoint}/clienti-potenziali/patch-cantiere`, {
      campo: column.dataField,
      valore: newValue,
      id: row.ID,
    }, {
      headers: {
        "x-access-token": localStorage.getItem('jwt')
      }
    })
    toggle()
  }
  updateData()
  return { async: true };
}

const selectRow = {
  mode: 'checkbox',
  clickToSelect: false,
  onSelect: (row, isSelect, rowIndex, e) => {
    if(e.target.checked) {
      setHidden(false)
    } else {
      setHidden(true)
    }
    let valore = row.ID
    if(selectedRow.includes(valore)) {
      let array = selectedRow.filter(item => item !== valore)
      addSelectedRow(array)
      setHiddenAll(true)

    } else {
      let array = [... selectedRow, row.ID]
      addSelectedRow(array)
      setHiddenAll(true)
    }
  },
  onSelectAll: (isSelect, rows, e) => {
    if(e.target.checked === true) {
      setHiddenAll(false)
    } else {
      setHiddenAll(true)
    }
    let array = []
    rows.map((item, index) => {
      let id = item.ID
      array.push(id)
    })
    addSelectedRow(array)
    setHidden(true)
  }
}

    const onDeleteAccount = () => {
         const deleteAccount = async () => {
          const delAcc = await axios.delete(`${apiEndpoint}/clienti-potenziali/delete/${props.match.params.id}/${localStorage.getItem("IDCartellaMedia")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          setDeleted(true)
        }
        deleteAccount()
    }

    const onDeleteCantiere = data => {
      selectedRow.map((item, index) => {
        const deleteAccount = async () => {
         const delAcc = await axios.delete(`${apiEndpoint}/clienti-potenziali/delete-cantiere/${item}`, {
           headers: {
             "x-access-token": localStorage.getItem('jwt')
           }
         })
         //setDeleted(true)
       }
       deleteAccount()
       setHiddenAll(true)
       setHidden(true)
      })
      toggle()
    }

    const onDeleteCantiereAll = data => {
      selectedRow.map((item, index) => {
        const deleteAccount = async () => {
         const delAcc = await axios.delete(`${apiEndpoint}/clienti-potenziali/delete-cantiere/${item}`, {
           headers: {
             "x-access-token": localStorage.getItem('jwt')
           }
         })
         //setDeleted(true)
       }
       deleteAccount()
       setHiddenAll(true)
       setHidden(true)
      })
      toggle()
    }


    const handleClose = () => setShow(false);
 const handleShow = () => setShow(true);


    const updateProfileImage = () => {
        var formData = new FormData();
        let name = Math.round((new Date()).getTime())
        formData.append('file', fileImage, name);
        formData.append('IDutente', props.match.params.id)
        formData.append('IDCartellaMedia', localStorage.getItem("IDCartellaMedia"))
        formData.append('imgPrecedente', userData.percorsoImmagine)
      const sendImage = async () => {
        const upload = await axios.post(`${apiEndpoint}/clienti-potenziali/change-profile-image`, formData, {
          headers: {
            "x-access-token": localStorage.getItem('jwt'),
          }
        })
        toggleImg()
      }
      sendImage()
    }


        const openModal = (id) => {
          const getData = async () => {
            const recuperoDati = axios.get(`${apiEndpoint}/clienti-potenziali/fetch-cantiere-singolo/${id}`, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            recuperoDati.then(res => {
              console.log(res.data)
              setCantierePotenziale(res.data)
              handleShow()
            })
          }
          getData()
        }

    if(isLoggedOut) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/login`} />)
    }
    if(isDeleted) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/clienti-potenziali/lista`} />)
    }

        if(isMoved) {
        return (<Redirect to={`${process.env.PUBLIC_URL}/clienti-potenziali/cantiere/${customerCode}`} />)
        }

    return (
        <Fragment>
            <Breadcrumb parent="Clienti potenziali" title="Profilo cliente" />
            {loading ? <><div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12 card">
                            <form  onSubmit={handleSubmit(onSubmitCliente)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Profilo cliente"}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                <div className="row mb-2">
                                  <Dropdown isOpen={dropdownOpen} toggle={toggleDD} className="dropdown-cleaning-image-profile">
                                    <DropdownToggle className="col-auto" color="link" >
                                    <img className="img-70 rounded-circle light-shadow" alt="" src={fileServer + '/' + localStorage.getItem("IDCartellaMedia") + '/clienti-potenziali/' + userData.percorsoImmagine} />
                                    <Badge  color="secondary" className="change-image-badge"><Camera className="change-image-badge-icon"/></Badge>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <DropdownItem onClick={toggleImgSee}>Visualizza immagine</DropdownItem>
                                      <DropdownItem onClick={toggleImg}>Aggiorna immagine</DropdownItem>
                                    </DropdownMenu>
                                    </Dropdown>
                                    <div className="col">
                                        <h3 className="mb-1">{userData.RagioneSociale1}</h3>
                                    </div>
                                </div>
                                    <div className="row">

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Ragione sociale"}</label>
                                            <input className="form-control cleaning-form"  defaultValue={userData.RagioneSociale1} name="ragione_sociale" type="text" placeholder="es. Mario Rossi Srl" {...register("ragione_sociale")}/>
                                        </div>
                                    </div>

                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{EmailAddress}</label>
                                                <input className="form-control cleaning-form" defaultValue={userData.Email} name="email" type="email" placeholder="Email" {...register("email")}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{PhoneNumber}</label>
                                                <input className="form-control cleaning-form" defaultValue={userData.Telefono} name="phone"  type="text" placeholder="es. +390123456789" {...register("phone")}/>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{Address}</label>
                                                <input className="form-control cleaning-form"  defaultValue={userData.Indirizzo} name="address" type="text" placeholder="es. Via Roma, 1" {...register("address")}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{City}</label>
                                                <input className="form-control cleaning-form"  defaultValue={userData.IDcomune} name="city" type="text" placeholder="es. Roma" {...register("city")}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{PostalCode}</label>
                                                <input className="form-control cleaning-form" defaultValue={userData.Cap} name="postalCode" type="number" placeholder="es. 00100" {...register("postalCode")}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Provincia"}</label>
                                            <input className="form-control cleaning-form" defaultValue={userData.Provincia} name="region"maxLength="2" type="text" placeholder="es. RM" {...register("region")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Modifica"}</button>
                                    <button type="button" className="btn btn-danger cleaning-btn-delete" onClick={() => toggleDel()}>{"Elimina"}</button>
                                </div>
                            </form>

                            <div className="card-footer text-left">
                            <h5>Cantieri potenziali</h5>
                            <p>Qui sotto trovi la lista dei clienti potenziali</p>

                            <ToolkitProvider
                            keyField='ID'
                            data={ allCantieri }
                            columns={ columns2 }
                            search
                            >
                            {
                              props => (
                                <div>
                                  <SearchBar
                                  { ...props.searchProps }
                                  placeholder="Cerca"
                                  className="cleaning-form"
                                  />
                                  <hr />
                                  <BootstrapTable
                                    { ...props.baseProps }
                                    cellEdit={ cellEditFactory({
                                      mode: 'click',
                                      beforeSaveCell
                                    }) }
                                    striped
                                    hover
                                    condensed
                                    selectRow = { selectRow }
                                  />
                                </div>
                              )
                            }
                            </ToolkitProvider>

                            </div>
                            <div className="text-right card-footer">
                                <button className="btn btn-success cleaning-btn-login" onClick={setModalAdd}>{"Aggiungi cantiere"}</button>
                                <button className="btn btn-danger cleaning-btn-login" hidden={isHidden} onClick={onDeleteCantiere}>{"Elimina"}</button>
                                <button className="btn btn-danger cleaning-btn-login" hidden={isHiddenAll} onClick={onDeleteCantiereAll}>{"Elimina tutto"}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={modalImgSee} toggle={toggleImgSee} centered={true} modalClassName="cleaning-modal remove-bg-cleaning">
                          <img className="light-shadow" alt={userData.RagioneSociale1} src={fileServer + '/' + localStorage.getItem("IDCartellaMedia") + "/clienti-potenziali/" + userData.percorsoImmagine} />
                </Modal>
                {/* Modale per cambio immagine profilo*/}
                <Modal isOpen={modalEdit} toggle={toggleEdit} centered={true} modalClassName="cleaning-modal">
                <form  onSubmit={handleSubmiteEdit(onSubmitEdit)}>

                    <ModalBody>

                            <div className="row">

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="form-label cleaning-form-label">{"Cantiere"}</label>
                                    <input className="form-control cleaning-form" defaultValue={isCantierePotenziale.RagioneSociale1} name="cantiere" type="text" placeholder="es. Mario Rossi Srl" {...registerEdit("cantiere")}/>
                                </div>
                            </div>


                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-label cleaning-form-label">{Address}</label>
                                        <input className="form-control cleaning-form"  defaultValue={isCantierePotenziale.Indirizzo} name="address" type="text" placeholder="es. Via Roma, 1" {...registerEdit("address")}/>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label cleaning-form-label">{City}</label>
                                        <input className="form-control cleaning-form"  defaultValue={isCantierePotenziale[2]} name="city" type="text" placeholder="es. Roma" {...registerEdit("city")}/>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-3">
                                    <div className="form-group">
                                        <label className="form-label cleaning-form-label">{PostalCode}</label>
                                        <input className="form-control cleaning-form" defaultValue={isCantierePotenziale[4]} name="postalCode" type="number" placeholder="es. 00100" {...registerEdit("postalCode")}/>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-3">
                                <div className="form-group">
                                    <label className="form-label cleaning-form-label">{"Provincia"}</label>
                                    <input className="form-control cleaning-form" defaultValue={isCantierePotenziale[3]} name="region"maxLength="2" type="text" placeholder="es. RM" {...registerEdit("region")}/>
                                </div>

                                <input type="hidden" value={isCantierePotenziale[5]} {...registerEdit("cantiereID")} />
                              </div>
                            </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button onClick={toggleEdit} className="cleaning-cancel-btn">{"Annulla"}</Button>
                      <Button color="primary" type="submit" className="btn-cleaning">{"Aggiorna"}</Button>
                    </ModalFooter>
                    </form>

                </Modal>
            </div></> : null}

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Le modifiche sono state eseguite con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={toggle} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>

            {/* Modale per eliminazione account*/}
            <Modal isOpen={modalDel} toggle={toggleDel} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggleDel}>Attenzione!</ModalHeader>
                <ModalBody>
                    <p>Stai per <strong>eliminare</strong> il cliente, sei sicuro di voler procedere?</p>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" className="cleaning-cancel-btn" onClick={() => toggleDel()}>{"Annulla"}</Button>
                    <Button color="danger" type="submit" className="btn-cleaning" onClick={() => onDeleteAccount()}>{"Elimina"}</Button>
                </ModalFooter>
            </Modal>
            {/* Modale per eliminazione account*/}

            {/* Modale per cambio immagine profilo*/}


            {/* Modale per cambio immagine profilo*/}
            <Modal isOpen={modalImg} toggle={toggleImg} centered={true} modalClassName="cleaning-modal">

                <ModalBody>
                        <p>Dimensione massima consentita <strong>2MB</strong>, risoluzione consigliata <strong>512x512px</strong></p>
                        <input className="form-control cleaning-form" name="delete" onChange={(e) => setFileUpload(e.target)} type="file" placeholder=""/>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={toggleImg} className="cleaning-cancel-btn">{"Annulla"}</Button>
                  <Button color="primary" type="submit" className="btn-cleaning" disabled={disabled} onClick={updateProfileImage}>{"Aggiorna"}</Button>
                </ModalFooter>
            </Modal>


            {/* Modale per cambio immagine profilo*/}
            <Modal isOpen={modalAdd} toggle={toggleAdd} centered={true} modalClassName="cleaning-modal">
            <form onSubmit={handleSubmitAdd(onSubmitAdd)}>
                <ModalBody>

                        <div className="row">

                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label cleaning-form-label">{"Cantiere"}</label>
                                <input className="form-control cleaning-form" name="cantiere" type="text" placeholder="es. Mario Rossi Srl" {...registerAdd("cantiere")}/>
                            </div>
                        </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="form-label cleaning-form-label">{Address}</label>
                                    <input className="form-control cleaning-form"   name="address" type="text" placeholder="es. Via Roma, 1" {...registerAdd("address")}/>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-6">
                                <div className="form-group">
                                    <label className="form-label cleaning-form-label">{City}</label>
                                    <input className="form-control cleaning-form"  name="city" type="text" placeholder="es. Roma" {...registerAdd("city")}/>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-3">
                                <div className="form-group">
                                    <label className="form-label cleaning-form-label">{PostalCode}</label>
                                    <input className="form-control cleaning-form"  name="postalCode" type="number" placeholder="es. 00100" {...registerAdd("postalCode")}/>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-3">
                            <div className="form-group">
                                <label className="form-label cleaning-form-label">{"Provincia"}</label>
                                <input className="form-control cleaning-form"  name="region"maxLength="2" type="text" placeholder="es. RM" {...registerAdd("region")}/>
                            </div>
                          </div>
                        </div>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={toggleAdd} className="cleaning-cancel-btn">{"Annulla"}</Button>
                  <Button color="success" type="submit" className="btn-cleaning">{"Aggiungi"}</Button>
                </ModalFooter>
                </form>

            </Modal>


        </Fragment>
    );
};

export default ClientiPotenzialiProfile;
