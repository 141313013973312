import React, { Fragment,useState,useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'


const UserEdit = () => {

    const [data,setData] = useState([])
    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(false)
    const [fileImage, setFile] = useState(null)
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { register: registerDel, handleSubmit: handleSubmitDel, formState: { errors: errorsDel } } = useForm();
    const { register: registerPwd, handleSubmit: handleSubmitPwd, formState: { errors: errorsPwd }, reset } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        let mounted = true
        axios.get(`${process.env.PUBLIC_URL}/api/user-edit-table.json`).then(res => setData(res.data))
        const getUser = async () => {
          const user = axios.get(`${apiEndpoint}/user/fetch/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          if(mounted) {
            user.then(res => {
              setUserData(res.data)
              setLoading(true)
            })
          }
        }
        getUser()
        return () => {
          mounted = false
        }
    }, [userData])

    const toggle = () => {
        setModal(!modal)
    }

    const toggleDel = () => {
        setModalDel(!modalDel)
    }

    const toggleImg = () => {
        setModalImg(!modalImg)
    }

    const toggleImgSee = () => {
        setModalImgSee(!modalImgSee)
    }

    const toggleDD = () => setDropdownOpen(prevState => !prevState)

    const onSubmit = (data) => {
    const updateData = async () => {
        const update = await axios.patch(`${apiEndpoint}/user/patch/${localStorage.getItem("userID")}`, {
          fname: data.fname,
          lname: data.lname,
          email: data.email,
          phone: data.phone,
          vat: data.vat,
          address: data.address,
          city: data.city,
          postalCode: data.postalCode,
          region: data.region,
          ragione_sociale: data.ragione_sociale
        }, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        toggle()
        localStorage.setItem('fname', data.fname)
        localStorage.setItem('lname', data.lname)
        localStorage.setItem('email', data.email)
      }
      updateData()
    }

    const setProfileImage = (percorso) => {
      if(percorso.includes('default')) {
        return fileServer + userData.PercorsoImmagine
      } else {
        return fileServer + '/' + userData.IDCartellaMedia + userData.PercorsoImmagine
      }
    }

    const setFileUpload = (target) => {
      if(target) {
        let newImage = target.files[0]
        let imageSize = newImage.size
        let imageType = newImage.type
        if(imageType === 'image/png' || imageType === 'image/jpeg') {
          if(imageSize <= 2000000) {
            setFile(newImage)
            setDisabled(false)
          } else {
            window.alert('Dimensione massima consentita 2MB.')
          }
        } else {
          window.alert('Puoi caricare solo immagini in formato .png, .jpeg, .jpg')
        }
      }
    }

    const onRecoverPassword = data => {
      const updateData = async () => {
          const update = await axios.patch(`${apiEndpoint}/user/change-password/${localStorage.getItem("userID")}`, {
            password: data.password,
            repassword: data.repassword
          }, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          if(update.data === 'Password uguali') {
            setErrPwd('La vecchia password e la nuova password devono essere differenti.')
          } else if(update.data === 'Password modificata') {
            reset()
            toggle()
          } else if(update.data === 'Password vecchia non combacia') {
            setErrPwd('La vecchia password é errata, inserisci la password corretta.')
          }
        }
        updateData()
    }

    const onDeleteAccount = data => {
      if(data.delete === 'ELIMINA ACCOUNT') {
         const deleteAccount = async () => {
          const delAcc = await axios.delete(`${apiEndpoint}/user/delete/${localStorage.getItem("userID")}/${userData.IDCartellaMedia}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          localStorage.clear()
          setLoggedOut(true)
        }
        deleteAccount()
      } else {
        setErrDel('Non hai inserito ELIMINA ACCOUNT come richiesto, riprova.')
      }
    }

    const updateProfileImage = () => {
        var formData = new FormData();
        let name = Math.round((new Date()).getTime())
        formData.append('file', fileImage, name);
        formData.append('IDutente', userData.IDutente)
        formData.append('IDCartellaMedia', userData.IDCartellaMedia)
        formData.append('imgPrecedente', userData.PercorsoImmagine)
      const sendImage = async () => {
        const upload = await axios.post(`${apiEndpoint}/user/change-profile-image`, formData, {
          headers: {
            "x-access-token": localStorage.getItem('jwt'),
          }
        })
        toggleImg()
      }
      sendImage()
    }

    if(isLoggedOut) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/login`} />)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Utente" title="Il mio profilo" />
            {loading ? <><div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12 card">
                            <form  onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Il mio profilo"}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                <div className="row mb-2">
                                  <Dropdown isOpen={dropdownOpen} toggle={toggleDD} className="dropdown-cleaning-image-profile">
                                    <DropdownToggle className="col-auto" color="link" >
                                    <img className="img-70 rounded-circle light-shadow" alt={userData.NomeUtente + "_" + userData.CognomeUtente} src={setProfileImage(userData.PercorsoImmagine)} />
                                    <Badge  color="secondary" className="change-image-badge"><Camera className="change-image-badge-icon"/></Badge>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <DropdownItem onClick={toggleImgSee}>Visualizza immagine</DropdownItem>
                                      <DropdownItem onClick={toggleImg}>Aggiorna immagine</DropdownItem>
                                    </DropdownMenu>
                                    </Dropdown>
                                    <div className="col">
                                        <h3 className="mb-1">{userData.NomeUtente + " " + userData.CognomeUtente}</h3>
                                        <p className="mb-4">{userData.email}</p>
                                    </div>
                                </div>
                                    <div className="row">

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Ragione sociale"}</label>
                                            <input className="form-control cleaning-form"  defaultValue={userData.RagioneSociale1} name="ragione_sociale" type="text" placeholder="es. Mario Rossi Srl" {...register("ragione_sociale")}/>
                                        </div>
                                    </div>

                                      <div className="col-sm-6 col-md-6">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{FirstName}</label>
                                              <input className="form-control cleaning-form" defaultValue={userData.NomeUtente} name="fname" type="text" placeholder="es. Mario" {...register("fname", { required: true})}/>
                                              {errors.fname && <p className="err-msg-form">Campo richiesto</p>}
                                          </div>
                                      </div>

                                      <div className="col-sm-6 col-md-6">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{LastName}</label>
                                              <input className="form-control cleaning-form" defaultValue={userData.CognomeUtente} name="lname" type="text" placeholder="es. Rossi" {...register("lname", { required: true})}/>
                                              {errors.lname && <p className="err-msg-form">Campo richiesto</p>}
                                          </div>
                                      </div>

                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{EmailAddress}</label>
                                                <input className="form-control cleaning-form" defaultValue={userData.email} name="email" type="email" placeholder="Email" {...register("email", { required: true})}/>
                                                {errors.email && <p className="err-msg-form">Campo richiesto</p>}
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{PhoneNumber}</label>
                                                <input className="form-control cleaning-form" defaultValue={userData.telefono} name="phone"  type="text" placeholder="es. +390123456789" {...register("phone")}/>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{"Partita IVA / Codice fiscale"}</label>
                                                <input className="form-control cleaning-form"  defaultValue={userData.IDfiscale} name="vat" type="text" placeholder="es. IT0123456789" {...register("vat")}/>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{Address}</label>
                                                <input className="form-control cleaning-form"  defaultValue={userData.indirizzo} name="address" type="text" placeholder="es. Via Roma, 1" {...register("address")}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{City}</label>
                                                <input className="form-control cleaning-form"  defaultValue={userData.città} name="city" type="text" placeholder="es. Roma" {...register("city")}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{PostalCode}</label>
                                                <input className="form-control cleaning-form" defaultValue={userData.cap} name="postalCode" type="number" placeholder="es. 00100" {...register("postalCode")}/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Provincia"}</label>
                                            <input className="form-control cleaning-form" defaultValue={userData.provincia} name="region"maxLength="2" type="text" placeholder="es. RM" {...register("region")}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Modifica"}</button>
                                    <a className="btn btn-danger cleaning-btn-delete" onClick={() => toggleDel()}>{"Elimina"}</a>
                                </div>
                            </form>
                            <div className="card-footer text-left">
                              <form onSubmit={handleSubmitPwd(onRecoverPassword)} >
                                <h5>Modifica password</h5>
                                <div className="row">
                                <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label cleaning-form-label">{"Vecchia password"}</label>
                                        <input className="form-control cleaning-form" name="password"  type="password" placeholder="**************" {...registerPwd("password", { required: "Campo richiesto", minLength: {
                                          value: 8,
                                          message: "La lunghezza della password deve essere di almeno 7 caratteri"
                                        }
                                      })}/>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label cleaning-form-label">{"Nuova password"}</label>
                                        <input className="form-control cleaning-form" name="repassword" type="password" placeholder="**************" {...registerPwd("repassword", { required: "Campo richiesto", minLength: {
                                          value: 8,
                                          message: "La lunghezza della password deve essere di almeno 7 caratteri"
                                        }
                                      })}/>
                                    </div>
                                </div>
                              </div>
                              <div className="text-right mt-2">
                                  <button className="btn btn-primary cleaning-btn-login" type="submit">{"Modifica"}</button>
                              </div>
                              <p className="err-msg ">{errPwd}</p>
                              </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={modalImgSee} toggle={toggleImgSee} centered={true} modalClassName="cleaning-modal remove-bg-cleaning">
                          <img className="light-shadow" alt={userData.NomeUtente + "_" + userData.CognomeUtente} src={setProfileImage(userData.PercorsoImmagine)} />
                </Modal>
            </div></> : null}

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span role="img">&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Le modifiche sono state eseguite con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={toggle} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>

            {/* Modale per eliminazione account*/}
            <Modal isOpen={modalDel} toggle={toggleDel} centered={true} modalClassName="cleaning-modal">
              <form onSubmit={handleSubmitDel(onDeleteAccount)}>
                <ModalHeader toggle={toggleDel}>Attenzione!</ModalHeader>
                <ModalBody>
                    <p>Stai per <strong>eliminare</strong> il tuo account, per procedere scrivi <strong>ELIMINA ACCOUNT</strong> e clicca su <strong>Elimina</strong>.</p>
                      <input className="form-control cleaning-form" name="delete"  type="text" placeholder="" {...registerDel("delete")}/>
                      <p style={{color: 'red', marginTop: '1vh'}}>{errDel}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" type="submit" className="btn-cleaning">{"Elimina"}</Button>
                </ModalFooter>
              </form>
            </Modal>
            {/* Modale per eliminazione account*/}

            {/* Modale per cambio immagine profilo*/}


            {/* Modale per cambio immagine profilo*/}
            <Modal isOpen={modalImg} toggle={toggleImg} centered={true} modalClassName="cleaning-modal">

                <ModalBody>
                        <p>Dimensione massima consentita <strong>2MB</strong>, risoluzione consigliata <strong>512x512px</strong></p>
                        <input className="form-control cleaning-form" name="delete" onChange={(e) => setFileUpload(e.target)} type="file" placeholder=""/>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={toggleImg} className="cleaning-cancel-btn">{"Annulla"}</Button>
                  <Button color="primary" type="submit" className="btn-cleaning" disabled={disabled} onClick={updateProfileImage}>{"Aggiorna"}</Button>
                </ModalFooter>
            </Modal>

        </Fragment>
    );
};

export default UserEdit;
