import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom'
import logo from '../assets/images/endless-logo.png';
import { apiEndpoint, FirstName, LastName, RetypePassword,Login,Password,SignUp, TownCity, Region,PostalCode, Address} from '../constant';
import axios from 'axios'
import { useForm, Controller } from "react-hook-form";
import SignupImage from '../assets/images/signup_image.png'
import { TextField, Checkbox } from "@material-ui/core";



const Signup = () => {
    const [fiscalCode, setFiscalCode] = useState(true)
    const [vat, setVat] = useState(false)
    const [isSucc, setSucc] = useState(false)
    const [isErr, setErr] = useState(false)
    const [succMsg, setSuccMsg] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [isChecked, setChecked] = useState(false)
    const [isRegistered, setRegistered] = useState(true)

    const { register, handleSubmit, watch, formState: { errors }, control, reset } = useForm();

    const onChange = (value) => {
      if(value === 'fiscal_code') {
        setFiscalCode(true)
        setVat(false)
      } else if(value === 'vat') {
        setFiscalCode(false)
        setVat(true)
      }
    }

    const partitaIvaCheck = async (piva) => {
      console.log(piva)
      const response = await axios.post("https://dagon.universalcloud.eu/CloudWebApi2/api/UdbCommonClientiService/ControlloPartitaIva", {
        "PartitaIva" : piva
      })
      if(response.data === 'OK') {
        const response2 = await axios.post(`${apiEndpoint}/auth/check-piva`, {
          piva: piva
        })
        if(response.data.error) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }

    const onSubmit = data => {
      // Dichiaro le variabile

      const controlla = async () => {
        const check = await partitaIvaCheck(data.vat)
        if(check === true) {

          let password = data.password;

              const postData = async () => {

              try {
                const registerData = await axios.post(`${apiEndpoint}/auth/sign-up`, {
                ragione_sociale: data.ragione_sociale,
                fname: data.fname,
                lname: data.lname,
                address: data.address,
                city: data.city,
                region: data.region,
                postalCode: data.postalCode,
                vat: data.vat,
                email: data.email,
                password: password,
                phone: data.phone,
                terms: data.terms
              })
              if(registerData.data.status === 200) {
                  setRegistered(false)
              } else if (registerData.data.status === 404){
                console.log(registerData.data.message.originalError.info.message)
                if(registerData.data.message.number === 2627) {
                  setErr(true)
                  setErrMsg('Indirizzo email giá utilizzato, inserisci un altro indirizzo email e riprova')
                } else {
                  setErr(true)
                  setErrMsg(registerData.data.message.originalError.info.message)
                }
              }

            } catch (e) {
              console.log(e.message)
            }
            }
            postData()
        } else {
          setErrMsg("Codice fiscale / partita IVA già registrata o invalida")
        }
      }
      controlla()

    }



    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="container-fluid">
                    {/* <!-- sign up page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-sm-12 p-0">
                                {isRegistered ? <><div className="auth-innerright">
                                    <div><center>
                                    <img src={SignupImage} alt="cleaning-academy" className="signup-image"/></center></div>
                                    <div>
                                        <div className="card mt-4 p-4 cleaning-auth-card">
                                            <h4 className="text-center">{"Registrati in Cleaning Academy"}</h4>
                                            <small className="text-center">{"Inserisci i dati nel form di registrazione"}</small>
                                            <form onSubmit={handleSubmit(onSubmit)} className="mt-2">
                                                  <div className="form-group">
                                                      <label className="col-form-label cleaning-form-label">{"Ragione sociale"}<span className="required-field">*</span></label>
                                                      <input className="form-control cleaning-form" type="text" placeholder="es. Mario Rossi Srl" name="address" {...register("ragione_sociale", { required: true })}/>
                                                  </div>
                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label cleaning-form-label">{FirstName} <span className="required-field">*</span></label>
                                                            <input className="form-control cleaning-form" type="text" name="fname" placeholder="es. Mario" {...register("fname", { required: true, pattern: /^[A-Za-z]+$/i })}/>
                                                            {errors.fname && <p className="err-msg-form">Campo richiesto</p>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="col-form-label cleaning-form-label">{LastName} <span className="required-field">*</span></label>
                                                            <input className="form-control cleaning-form" type="text" name="lname" placeholder="es. Rossi" {...register("lname", { required: true, pattern: /^[A-Za-z]+$/i })}/>
                                                            {errors.lname && <p className="err-msg-form">Campo richiesto</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-form-label cleaning-form-label">{Address}</label>
                                                    <input className="form-control cleaning-form" type="text" placeholder="es. Via Roma, 1" name="address" {...register("address")}/>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label cleaning-form-label">{TownCity}</label>
                                                            <input className="form-control cleaning-form" type="text" placeholder="es. Roma" name="city" {...register("city")}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label cleaning-form-label">{Region}</label>
                                                            <input className="form-control cleaning-form" type="text" placeholder="es. RM" maxLength="2" name="region" {...register("region")}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label cleaning-form-label">{PostalCode}</label>
                                                            <input className="form-control cleaning-form" type="number" placeholder="es. 00100" maxLength="5" name="postalCode" {...register("postalCode")}/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                  <div className="col-sm-9">
                                                    <div className="radio radio-primary ml-2">
                                                      <input id="radio11" type="radio" name="radio1" value="fiscal_code" defaultChecked onChange={e => onChange(e.target.value)}/>
                                                      <label htmlFor="radio11">Codice fiscale</label>
                                                    </div>
                                                    <div className="radio radio-primary ml-2">
                                                      <input id="radio22" type="radio" name="radio1" value="vat" onChange={e => onChange(e.target.value)}/>
                                                      <label htmlFor="radio22">Partita IVA</label>
                                                    </div>
                                                  </div>
                                                </div>
                                                {fiscalCode ? <div className="form-group">
                                                    <label className="col-form-label cleaning-form-label">Codice fiscale</label>
                                                    <input className="form-control cleaning-form" type="text" placeholder="es. CF0123456789" {...register("vat")}/>
                                                </div> : null}
                                                {vat ? <div className="form-group">
                                                    <label className="col-form-label cleaning-form-label">Partita IVA</label>
                                                    <input className="form-control cleaning-form" type="text" placeholder="es. IT0123456789" {...register("vat")}/>
                                                </div> : null}
                                                <div className="form-group">
                                                    <label className="col-form-label cleaning-form-label">{"Telefono"}</label>
                                                    <input className="form-control cleaning-form" type="tel" placeholder="+390123456789" {...register("phone")}/>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-form-label cleaning-form-label">Email <span className="required-field">*</span><small>Verrà utilizzato come username</small></label>
                                                    <input className="form-control cleaning-form" type="email" placeholder="es. mario.rossi@cleaningacademy.it" {...register("email", { required: true})}/>
                                                    {errors.password && <p className="err-msg-form">Campo richiesto</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-form-label cleaning-form-label">{Password} <span className="required-field">*</span></label>
                                                    <input className="form-control cleaning-form" type="password" placeholder="**********" {...register("password", { required: "Campo richiesto", minLength: {
                                                      value: 8,
                                                      message: "La lunghezza della password deve essere di almeno 7 caratteri"
                                                    }
                                                  })}/>
                                                  {errors.password && <p className="err-msg-form">{errors.password.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                <Controller
                                                  name="MyCheckbox"
                                                  control={control}
                                                  defaultValue={false}
                                                  className="form-control"
                                                  render={({ field }) => <Checkbox {...field} />}
                                                />
                                                    <label htmlFor="checkbox1">Accetto i <a href="https://loremipsum.io/" target="_blank">termini e condizioni</a></label>
                                                </div>
                                                <div className="form-group form-row">
                                                    <button className="btn btn-primary btn-block cleaning-btn-login" type="submit">Registrati</button>
                                                </div>
                                                <div className="form-row">
                                                    <div className="text-left mt-2 ml-2">{"Sei giá registrato?"}  <Link className="btn-link" to={`${process.env.PUBLIC_URL}/login`}>{"Clicca qui "}</Link>per effettuare il login</div>
                                                </div>
                                                <div className="form-row">
                                                  {isSucc ? <p className="text-left succ-msg ml-2">{succMsg}</p> : null}
                                                  {isErr ? <p className="text-left err-msg ml-2">{errMsg}</p> : null}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div></> : <><div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="card mt-4 cleaning-login-card">
                                            <div className="card-body">
                                                <div className="text-center">
                                                <div className="text-center">
                                                  <img src={SignupImage} alt="cleaning-image" className="confirm-image"/>
                                                </div>
                                                    <h4>{"Evviva ♻️"}</h4>
                                                    <h5>{"Ti sei registrato con successo!"} </h5>
                                                    <p className="mt-4">{"Riceverai a breve una mail di conferma, controlla nello SPAM nel caso non la trovassi."} </p>
                                                </div>
                                                <div className="text-center">
                                                    <div className=" mt-4 ml-2"><Link className="btn-link" to={`${process.env.PUBLIC_URL}/login`}>{"Clicca qui "}</Link>per effettuare il login</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div></>}
                            </div>
                        </div>
                    </div>
                    {/* <!-- sign up page ends--> */}
                </div>
            </div>
        </Fragment>
    );
};

export default Signup;
