import React, { Fragment, useState, useEffect } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import Slider from 'react-slick';
import Ratings from 'react-ratings-declarative'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, ProductReview, Brand, Availability, AddToCart, BuyNow, ContinueShopping,OutOfStock, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import Select from 'react-select';




const MacchinariEdit = (props) => {

      const history = useHistory();
      const [isMaterial, setMaterials] = useState({})
      const [isIdGruppo, setIDgruppo] = useState('')
      const [rating,setRating] = useState(0)
      const [isLoading, setLoading] = useState(false)
      const [modalImgSee, setModalImgSee] = useState();
      const [modal, setModal] = useState();
      const [valueEcolabel, setEcolabel] = useState('')
      const [ecolabel, changeEcolabel] = useState(null)
      const [codColore, setCodColore] = useState('')
      const [errDel, setErrDel] = useState('')
      const [isCodiceColore, setCodiceColore] = useState(null)
      const [modalDel, setModalDel] = useState();
      const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
      const { register: registerDel, handleSubmit: handleSubmitDel, formState: { errors: errorsDel } } = useForm();
      const [isFam, setFam] = useState('')

      const selectStyles = {
        control: styles => ({ ...styles, borderRadius: '10px'})
      }

      useEffect(() => {
          let mounted = true
          const getMaterial = async () => {
            const material = await axios.get(`${apiEndpoint}/macchinari/fetch-profilo/${props.match.params.id}`, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })

            if(mounted) {
              setMaterials(material.data)
              console.log(material.data)
              setFam(material.data.IDfamiglia)
              setIDgruppo(material.data.IDsottogruppo)
              setCodiceColore(material.data.PanCodiceColore)
              setEcolabel(material.data.EcoLabel)
              if(material.data.EcoLabel === 1) {
                changeEcolabel('Si')
              } else if(material.data.EcoLabel === 0) {
                changeEcolabel('No')
              }
              if(material.data.PanCodiceColore === 1) {
                setCodColore('Si')
              } else if(material.data.PanCodiceColore === 0) {
                setCodColore('No')
              }
              setLoading(true)
            }
          }
          getMaterial()
          return () => {
            mounted = false
          }
      }, [])

      const changeRating =  (newRating) => {
          setRating(newRating)
      }


      function isColore(colore) {
        if(colore === 1) {
          return 'Si'
        } else if(colore === 0) {
          return 'No'
        }
      }


      function calcoloDiluizione(valore) {
        if(valore === 0) {
          return 'Pronto all\'uso'
        } else {
          return valore + '%'
        }
      }

      const toggle = () => {
          setModal(!modal)
      }

      const toggleImgSee = () => {
          setModalImgSee(!modalImgSee)
      }

      const toggleClose = () => {
        setModal(!modal)
        //history.push(`${process.env.PUBLIC_URL}/macchinari/lista`)
      }

      const toggleDel = () => {
          setModalDel(!modalDel)
      }

      const onSubmit = (data) => {

        if(isFam === 'LAV') {

          let centrifuga = Number(data.centrifuga)
          console.log(typeof centrifuga)

          var formData = new FormData
          formData.append('file', data.file[0])
          formData.append('percorsoImmagine', isMaterial.percorsoImmagine)
          formData.append('descrizione', data.descrizione)
          formData.append('centrifuga', centrifuga)
          formData.append('prezzo', Number(data.prezzo))
          formData.append('canone', Number(data.canone))
          formData.append('cesto', Number(data.cesto))
          formData.append('carico', Number(data.carico))
          formData.append('fattoreg', Number(data.fattoreg))
          formData.append('kwh', Number(data.kwh))
          formData.append('co2', Number(data.co2))
          formData.append('ecolabel', Number(valueEcolabel))
          formData.append('IDattrezzatura', props.match.params.id)
          formData.append('famiglia', isFam)
          formData.append('sicurezza', data.sicurezza)
          formData.append('tecnica', data.tecnica)

            const updateData = async () => {
              const update = await axios.patch(`${apiEndpoint}/macchinari/patch`, formData,{
                headers: {
                  "x-access-token": localStorage.getItem('jwt')
                }
              })
              history.push(`${process.env.PUBLIC_URL}/macchinari/lista`)
            }
            updateData()

        } else {

          let centrifuga = Number(data.centrifuga)


          var formData = new FormData
          formData.append('file', data.file[0])
          formData.append('percorsoImmagine', isMaterial.percorsoImmagine)
          formData.append('descrizione', data.descrizione)
          formData.append('plastica', Number(data.plastica))
          formData.append('prezzo', Number(data.prezzo))
          formData.append('canone', Number(data.canone))
          formData.append('resa', Number(data.resa))
          formData.append('pista', Number(data.pista))
          formData.append('capacita', Number(data.capacita))
          formData.append('kwh', Number(data.kwh))
          formData.append('co2', Number(data.co2))
          formData.append('ecolabel', Number(valueEcolabel))
          formData.append('IDattrezzatura', props.match.params.id)
          formData.append('famiglia', isFam)
          formData.append('sicurezza', data.sicurezza)
          formData.append('tecnica', data.tecnica)


            const updateData = async () => {
              const update = await axios.patch(`${apiEndpoint}/macchinari/patch`, formData,{
                headers: {
                  "x-access-token": localStorage.getItem('jwt')
                }
              })
              history.push(`${process.env.PUBLIC_URL}/macchinari/lista`)
            }
            updateData()

        }
      }

      const onDeleteAccount = data => {
           const deleteAccount = async () => {
            const delAcc = await axios.delete(`${apiEndpoint}/macchinari/delete/${props.match.params.id}`, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            history.push(`${process.env.PUBLIC_URL}/macchinari/lista`)
          }
          deleteAccount()
      }

      return (
          <Fragment>
              <Breadcrumb title="Dettagli macchinario" parent="Macchinari" />
              {isLoading ? <div className="container-fluid">
                  <div className="card">
                  <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="card-header">
                          <h4 className="card-title mb-0">{"Modifica macchinario"}</h4>
                      </div>
                      <div className="card-body">
                          <div className="row">

                          <div className="col-sm-6 col-md-6">
                              <img src={fileServer + isMaterial.percorsoImmagine} alt={isMaterial.Descrizione} className="immagine-profilo-materiale-edit"/>
                          </div>

                          <div className="col-sm-6 col-md-6">
                              <div className="form-group">
                                  <label className="form-label cleaning-form-label">{"Immagine materiale (dimensioni 500x500px)"} </label>
                                  <input className="form-control cleaning-form" name="file" type="file" placeholder="Inserisci descrizione es. Carrello CLEANING ACADEMY" {...register("file")}/>
                                  {errors.file && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          <div className="col-sm-6 col-md-6">
                              <div className="form-group">
                                  <label className="form-label cleaning-form-label">{"Descrizione"} </label>
                                  <input className="form-control cleaning-form" name="descrizione" defaultValue={isMaterial.Descrizione} type="text" placeholder="Inserisci descrizione es. Carrello CLEANING ACADEMY" {...register("descrizione")}/>
                                  {errors.descrizione && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          <div className="col-md-6">
                              <div className="form-group">
                                  <label className="form-label cleaning-form-label">{"Ecolabel"}</label>
                                  <Select
                                    className="cleaning-form"
                                    placeholder="Il prodotto é EcoLabel?"
                                    onChange={e => setEcolabel(e.value)}
                                    defaultValue={{value: isMaterial.EcoLabel, label: ecolabel}}
                                    options={[
                                      {
                                        value: 1,
                                        label: 'Si'
                                      },
                                      {
                                        value: 0,
                                        label: 'No'
                                      }
                                    ]}
                                    styles={selectStyles}
                                    required
                                  />
                              </div>
                          </div>


                          <div className="col-sm-6 col-md-6">
                              <div className="form-group">
                                  <label className="form-label cleaning-form-label">{"Consumo elettrico /kWh"} </label>
                                  <input className="form-control cleaning-form" name="kwh" defaultValue={isMaterial.ConsumoKWh} type="number" placeholder="Inserisci consumo elettrico" {...register("kwh")}/>
                                  {errors.kwh && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          <div className="col-sm-6 col-md-6">
                              <div className="form-group">
                                  <label className="form-label cleaning-form-label">Consumo CO<sub>2</sub> g/kWh </label>
                                  <input className="form-control cleaning-form" name="co2" defaultValue={isMaterial.ConsumoCO2h} type="number" placeholder="Inserisci consumo CO2" {...register("co2")}/>
                                  {errors.co2 && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          <div className="col-sm-6 col-md-6">
                              <div className="form-group">
                                  <label className="form-label cleaning-form-label">{"Prezzo listino ufficiale (iva esclusa)"} </label>
                                  <input className="form-control cleaning-form" name="prezzo" defaultValue={isMaterial.CostoKm} step="0.01" type="number" placeholder="Inserisci prezzo es. 10.09" {...register("prezzo")}/>
                                  {errors.prezzo && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          <div className="col-sm-6 col-md-6">
                              <div className="form-group">
                                  <label className="form-label cleaning-form-label">{"Canone mensile (iva esclusa)"} </label>
                                  <input className="form-control cleaning-form" name="canone" defaultValue={isMaterial.Canone} step="0.01" type="number" placeholder="Inserisci canone es. 10.09" {...register("canone")}/>
                                  {errors.canone && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          <div className="col-sm-12 col-md-12">
                              <div className="form-group">
                              <label className="form-label cleaning-form-label">{"Scheda tecnica (es. https://sitoproduttore.com)"} </label>
                                  <input className="form-control cleaning-form" name="tecnica" defaultValue={isMaterial.percorsoTecnica}  type="url" placeholder="es. https://sitoproduttore.com"  {...register("tecnica")}/>
                                  {errors.prezzo && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          <div className="col-sm-12 col-md-12">
                              <div className="form-group">
                              <label className="form-label cleaning-form-label">{"Scheda sicurezza (es. https://sitoproduttore.com)"} </label>
                                  <input className="form-control cleaning-form" name="sicurezza" defaultValue={isMaterial.percorsoSicurezza}  type="url" placeholder="es. https://sitoproduttore.com"  {...register("sicurezza")}/>
                                  {errors.prezzo && <p className="err-msg-form">Campo richiesto</p>}
                              </div>
                          </div>

                          </div>
                          {(() => {
                            switch(isFam) {
                              case 'LAV':
                                return (
                                  <>
                                  <hr />
                                  <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Diametro cesto /lt"} </label>
                                            <input className="form-control cleaning-form" name="cesto" defaultValue={isMaterial.DiametroCesto}  type="number" placeholder="Inserisci diametro cesto" {...register("cesto")}/>
                                            {errors.cesto && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Carico /kg"} </label>
                                            <input className="form-control cleaning-form" name="carico" defaultValue={isMaterial.KgCarico}  type="number" placeholder="Inserisci carico" {...register("carico")}/>
                                            {errors.carico && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Centrifuga"} </label>
                                            <input className="form-control cleaning-form" name="centrifuga" defaultValue={isMaterial.Centrifuga}  type="number" placeholder="Inserisci centrifuga" {...register("centrifuga")}/>
                                            {errors.centrifuga && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Fattore G"} </label>
                                            <input className="form-control cleaning-form" name="fattoreg" defaultValue={isMaterial.FattoreG}  type="number" placeholder="Inserisci fattore G" {...register("fattoreg")}/>
                                            {errors.fattoreg && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                  </div>
                                  </>
                                )
                              default:
                                return (
                                  <>
                                  <hr />
                                  <div className="row">
                                  <div className="col-sm-6 col-md-6">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Pista /cm"} </label>
                                          <input className="form-control cleaning-form" name="pista" defaultValue={isMaterial.Pista} type="number" placeholder="Inserisci pista" {...register("pista")}/>
                                          {errors.pista && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                  </div>

                                  <div className="col-sm-6 col-md-6">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Resa mq/punti"} </label>
                                          <input className="form-control cleaning-form" name="resa" defaultValue={isMaterial.Resa} type="number" placeholder="Inserisci resa" {...register("resa")}/>
                                          {errors.resa && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                  </div>

                                  <div className="col-sm-6 col-md-6">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Capacitá /l"} </label>
                                          <input className="form-control cleaning-form" name="capacita" defaultValue={isMaterial.CapacitaLt} type="number" placeholder="Inserisci capacita" {...register("capacita")}/>
                                          {errors.capacita && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                  </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Plastica riciclata %"} </label>
                                            <input className="form-control cleaning-form" name="plastica" defaultValue={isMaterial.PlasticaRiciclata} type="number" placeholder="Inserisci quantitá plastica riciclata" {...register("plastica")}/>
                                            {errors.plastica && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>


                                  </div>
                                  </>
                                )
                            }
                          })()}
                          <span className="required-field">*</span>tutti i campi sono richiesti
                      </div>
                      <div className="card-footer text-right">
                      <button className="btn cleaning-btn-login cleaning-cancel-btn" type="button" onClick={() => history.push(`${process.env.PUBLIC_URL}/macchinari/lista`)}>{"Annulla"}</button>
                          <a className="btn btn-danger cleaning-btn-login" href="javascript:void(0)" onClick={() => toggleDel()}>{"Elimina"}</a>
                          <button className="btn btn-primary cleaning-btn-login" type="submit">{"Modifica"}</button>
                      </div>
                  </form>
                  </div>
                  <Modal isOpen={modalImgSee} toggle={toggleImgSee} centered={true} modalClassName="cleaning-modal remove-bg-cleaning">
                            <img className="light-shadow" alt={isMaterial.descrizione1} src={fileServer + isMaterial.percorsoImmagine} />
                  </Modal>
              </div> : null}
              {/* Modale successo*/}
              <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                  <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                  <ModalBody>
                      <p>Dati modificati con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                  </ModalBody>
                  <ModalFooter>
                      <Button color="success" onClick={() => history.push("/macchinari/lista")} className="btn-cleaning">{"Continua"}</Button>
                  </ModalFooter>
              </Modal>

              {/* Modale per eliminazione account*/}
              <Modal isOpen={modalDel} toggle={toggleDel} centered={true} modalClassName="cleaning-modal">
                <form onSubmit={handleSubmitDel(onDeleteAccount)}>
                  <ModalHeader toggle={toggleDel}>Attenzione!</ModalHeader>
                  <ModalBody>
                      <p>Stai per <strong>eliminare</strong> il tuo macchinario, sei sicuro di voler procedere?</p>
                  </ModalBody>
                  <ModalFooter>
                  <Button onClick={toggleDel} className="cleaning-cancel-btn">{"Annulla"}</Button>
                      <Button color="danger" type="submit" className="btn-cleaning">{"Elimina"}</Button>
                  </ModalFooter>
                </form>
              </Modal>
              {/* Modale per eliminazione account*/}
          </Fragment>
      );
  }

export default MacchinariEdit;
