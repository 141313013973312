import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import {FaCloudDownloadAlt, FaExternalLinkSquareAlt, FaFileInvoice, FaTrashAlt} from "react-icons/fa"
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import Word from '../../assets/images/word-thumb.png'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment"

import ReactTooltip from 'react-tooltip';



const PreventiviLista = () => {

    const [data,setData] = useState([])
    const [allTemplates, setTemplates] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [errDel, setErrDel] = useState('')
    const [isDeleted, setDeleted] = useState(null)

    const history = useHistory()



    useEffect(() => {
        let mounted = true
        const getUser = async () => {
          const documenti = await axios.get(`${apiEndpoint}/preventivi/fetch/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          console.log(documenti.data)
          setTemplates(documenti.data)
          setLoading(true)
        }
        getUser()
        return () => {
          mounted = false
        }
    }, [])

    function getImage(idAmbiente) {
      const getIm = async () => {
        const image = await axios.get(`${apiEndpoint}/preventivi/fetch-ambiente/${idAmbiente}`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        return image.data
      }
      getIm()
    }

    const { SearchBar } = Search;
    const columns = [
    {
      dataField: 'ambiente',
      text: '',
      formatter: (value, row) => (
            <img src={Word} className="cleaning-doc-thumbnail"/>
      ),
    },
    {
      dataField: 'data',
      text: 'Data',
      formatter: (value, row) => (
            <span>{moment(row.data).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      dataField: 'descrizione',
      text: 'Descrizione',
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          history.push(`${process.env.PUBLIC_URL}/preventivi/aggiungi/${row.numero}`)
        }
      },
      formatter: (value, row) => (
          <>
          <ReactTooltip place="top" effect="solid"/>
          <span style={{cursor: 'pointer'}} data-tip="Apri preventivo">{row.descrizione}</span>
          </>
      ),
    },
    {
      dataField: '',
      text: 'Opzioni',
      formatter: (value, row) => (
        <>
          <ReactTooltip place="top" effect="solid"/>
          <Link to={`/preventivi/progetto/${row.ID}`} className="btn btn-primary" style={{marginRight: '1vw', marginBottom: '1vw'}} data-tip="Vai a creazione progetto tecnico"><FaExternalLinkSquareAlt /></Link>
          {row.merged ? <a href={row.merged} className="btn btn-primary" download data-tip="Scarica progetto tecnico completo" style={{marginRight: '1vw', marginBottom: '1vw'}}><FaCloudDownloadAlt /></a> : null}
          {row.merged ? <a href={row.cartellaSchedeTecniche} className="btn btn-warning" download data-tip="Scarica schede tecniche" style={{marginRight: '1vw', marginBottom: '1vw'}}><FaFileInvoice /></a> : null}
          {row.merged ? <a href={row.cartellaSchedeSicurezza} className="btn btn-warning" download data-tip="Scarica schede sicurezza" style={{marginRight: '1vw', marginBottom: '1vw'}}><FaFileInvoice /></a> : null}
          <button className="btn btn-danger" style={{marginRight: '1vw', marginBottom: '1vw'}} data-tip="Elimina preventivo" onClick={() => toggleDel(row.ID)}><FaTrashAlt /></button>
        </>
      ),
    }
  ];

  const { register: registerDel, handleSubmit: handleSubmitDel, formState: { errors: errorsDel } } = useForm();


  const onDeleteAccount = data => {
       const deleteAccount = async () => {
        const delAcc = await axios.delete(`${apiEndpoint}/preventivi/delete-prev/${isDeleted}`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        window.location.reload()
      }
      deleteAccount()
  }

  const [modalDel, setModalDel] = useState();


  const toggleDel = (id) => {
      setModalDel(!modalDel)
      setDeleted(id)
  }


    return (
        <Fragment>
            <Breadcrumb title="Tutti i preventivi" parent="Preventivi" />
            <div className="container-fluid">
              {loading ?   <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                          <div className="card-body datatable-react">
                            <div className="row">

                            <div className="card-body datatable-react">
                            <ToolkitProvider
                            keyField='ID'
                            data={ allTemplates }
                            columns={ columns }
                            search
                            >
                            {
                              props => (
                                <div>
                                  <div className="d-flex flex-row justify-content-between w-100 mb-2"><SearchBar
                                  { ...props.searchProps }
                                  placeholder="Cerca"
                                  className="cleaning-form text-left"
                                  />
                                  <div className="text-right">
                                      <Link to={`${process.env.PUBLIC_URL}/preventivi/ambienti`} className="btn btn-success cleaning-btn-login" type="submit">{"Aggiungi"}</Link>
                                  </div>
                                  </div>
                                  <BootstrapTable
                                    { ...props.baseProps }
                                    striped
                                    hover
                                    condensed
                                    keyField="ID"
                                    pagination={ paginationFactory({
                                      showTotal: false,
                                      firstPageText: 'Inizio', // the text of first page button
                                      prePageText: 'Prec', // the text of previous page button
                                      nextPageText: 'Succ', // the text of next page button
                                      lastPageText: 'Fine', // the text of last page button
                                      nextPageTitle: 'Vai al successivo', // the title of next page button
                                      prePageTitle: 'Vai al precedente', // the title of previous page button
                                      firstPageTitle: 'Vai al primo', // the title of first page button
                                      lastPageTitle: 'Vai all\'ultmo', // the title of last page button
                                    }) }
                                  />
                                </div>
                              )
                            }
                            </ToolkitProvider>
                            </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>: <div className="spinner-loading-cleaning"><div className="loader-box">
                  <div className="loader">
                    <div className="line bg-success"></div>
                    <div className="line bg-success"></div>
                    <div className="line bg-success"></div>
                    <div className="line bg-success"></div>
                  </div>
                </div></div>}
            </div>

            {/* Modale per eliminazione account*/}
            <Modal isOpen={modalDel} toggle={toggleDel} centered={true} modalClassName="cleaning-modal">
              <form onSubmit={handleSubmitDel(onDeleteAccount)}>
                <ModalHeader toggle={toggleDel}>Attenzione!</ModalHeader>
                <ModalBody>
                    <p>Stai per <strong>eliminare</strong> il preventivo, sei sicuro di voler procedere?</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" type="submit" className="btn-cleaning">{"Elimina"}</Button>
                </ModalFooter>
              </form>
            </Modal>
            {/* Modale per eliminazione account*/}
        </Fragment>
    );
};

export default PreventiviLista;
