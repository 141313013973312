import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';




const MaterialiLista = () => {

    const history = useHistory();
    const [data,setData] = useState([])
    const [allMaterials, getMaterials] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [isErr, setErr] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [idArticle, setIdArticle] = useState(null)


    const { SearchBar } = Search;
    const columns = [
    {
      dataField: 'percorsoImmagine',
      text: 'Immagine',
      formatter: (value, row) => (
        <center><img src={fileServer + row.percorsoImmagine} className="immagine-attrezzi" style={{cursor: 'pointer'}}/></center>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
        if(e.type === 'click' && row.IDutente == null && localStorage.getItem("role") !== 'Admin') {
          history.push(`${process.env.PUBLIC_URL}/materiali/dettaglio/${row.IDarticolo}`)
        } else if((e.type === 'click' && row.IDutente != null) || localStorage.getItem("role") === 'Admin') {
          history.push(`${process.env.PUBLIC_URL}/materiali/modifica/${row.IDarticolo}`)
        }
        }
      }
    },
    {
      dataField: 'descrizione1',
      text: 'Nome',
      formatter: (value, row) => (
        <span style={{cursor: 'pointer'}}>{row.descrizione1}</span>
      ),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
        if(e.type === 'click' && !row.IDutente && localStorage.getItem("role") !== 'Admin') {
          history.push(`${process.env.PUBLIC_URL}/materiali/dettaglio/${row.IDarticolo}`)
        } else if((e.type === 'click' && row.IDutente) || localStorage.getItem("role") === 'Admin') {
          history.push(`${process.env.PUBLIC_URL}/materiali/modifica/${row.IDarticolo}`)
        }
        }
      }
    },
    {
      dataField: 'percorsoTecnica',
      text: 'Scheda',
      formatter: (value, row) => (
        <center>
            <a href={row.percorsoTecnica} className="btn btn-primary cleaning-btn-login" type="submit" target="_blank">{"Scheda"}</a>
        </center>
      )
    }
  ];


//Aggiungere bottone link http percorsoTecnica

    useEffect(() => {
        let mounted = true
        const getUser = async () => {
          const operazioni = await axios.get(`${apiEndpoint}/materiali/fetch/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          if(mounted) {
              getMaterials(operazioni.data)
              setLoading(true)
            }
          }
        getUser()
        return () => {
          mounted = false
        }
    }, [])


    return (
        <Fragment>
            <Breadcrumb title="Materiali" parent="Materiali" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                      {loading ?   <div className="card">

                            <div className="card-body datatable-react">
                            <ToolkitProvider
                            keyField='ID'
                            data={ allMaterials }
                            columns={ columns }
                            search
                            >
                            {
                              props => (
                                <div>
                                  <div className="d-flex flex-row justify-content-between w-100 mb-2"><SearchBar
                                  { ...props.searchProps }
                                  placeholder="Cerca"
                                  className="cleaning-form text-left"
                                  />
                                    <div className="text-right">
                                        <Link to={`${process.env.PUBLIC_URL}/materiali/aggiungi`} className="btn btn-success cleaning-btn-login" type="submit">{"Aggiungi"}</Link>
                                    </div>
                                  </div>
                                  <BootstrapTable
                                    { ...props.baseProps }
                                    striped
                                    hover
                                    condensed
                                    keyField="ID"
                                    pagination={ paginationFactory({
                                      showTotal: false,
                                      firstPageText: 'Inizio', // the text of first page button
                                      prePageText: 'Prec', // the text of previous page button
                                      nextPageText: 'Succ', // the text of next page button
                                      lastPageText: 'Fine', // the text of last page button
                                      nextPageTitle: 'Vai al successivo', // the title of next page button
                                      prePageTitle: 'Vai al precedente', // the title of previous page button
                                      firstPageTitle: 'Vai al primo', // the title of first page button
                                      lastPageTitle: 'Vai all\'ultmo', // the title of last page button
                                    }) }
                                  />
                                </div>
                              )
                            }
                            </ToolkitProvider>
                            </div>
                        </div> : <div className="spinner-loading-cleaning"><div className="loader-box">
                          <div className="loader">
                            <div className="line bg-success"></div>
                            <div className="line bg-success"></div>
                            <div className="line bg-success"></div>
                            <div className="line bg-success"></div>
                          </div>
                        </div></div>}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default MaterialiLista;
