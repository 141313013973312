import React, {Fragment, useState, useEffect } from 'react';
import Countup from 'react-countup';
import { Doughnut, Bar } from 'react-chartjs-2';
import ChartistGraph from 'react-chartist';
import { taskChartData, taskChartOptions, barChartData, barChartOptions, projectsmall, projectsmall1 } from '../../../data/default';
import { MoreHorizontal } from 'react-feather';
import EcoLabel from '../../../assets/images/cleaning-dashboard/Ecolabel_Logo-1.png'
import VideoLogo from '../../../assets/images/cleaning-dashboard/video.svg'
import LampLogo from '../../../assets/images/cleaning-dashboard/intellectual.svg'
import PenLogo from '../../../assets/images/cleaning-dashboard/pen.svg'
import configDB from '../../../data/customizer/config'
import { DueTasks,Features,Completed,Proposals,Implemented,Issues,Open,ReOpened,Overdue,Tasks,TaskSolved,TaskDistribution,Schedule,GroupMeeting,Project,Requirements,Discussion,Planning,PublicBetaRelease,Lunch,ClientsTiming,GithubIsuues,Created,ClosedToday,Fixed,WontFix,NeedTest,ClosedIssues,Closed } from "../../../constant";
const primary = localStorage.getItem('primary_color') || configDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || configDB.data.color.secondary_color;


const HomeComponent = () =>  {
    const [open,setOpen] = useState(false)
    const [sidebar, setSidebar] = useState(true);

   const openCloseSidebar = () => {
      if (sidebar) {
        setSidebar(!sidebar)
        document.querySelector(".page-main-header").classList.remove('open');
        document.querySelector(".page-sidebar").classList.remove('open');
        document.querySelector(".footer").classList.remove('open');
      } else {
        setSidebar(!sidebar)
        document.querySelector(".page-main-header").classList.add('open');
        document.querySelector(".page-sidebar").classList.add('open');
        document.querySelector(".footer").classList.add('open');
      }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
    },[])

    const handleClickOutside = () => {
        setOpen(false)
    };
    const handleButtonClick = () => {
        setOpen(!open)
    };

    return (
        <Fragment>
            <div className="tab-content active default" id="tab-1">
                <div className="row">
                    <div className="col-xl-4 col-sm-6">
                        <div className="card" onClick={() => openCloseSidebar()} style={{cursor: 'pointer'}}>
                            <div className="card-body text-center">
                                <center>
                                  <img src={EcoLabel} alt="ecolabel-alt" className="img-cleaning-dashboard"/>
                                </center>
                                <h4 className="title-cleaning-dashboard">Sviluppo progetto tecnico ed Ecolabel</h4>
                                <p className="subtext-cleaning-dashboard">Ecolabel UE è il marchio di qualità ecologica dell’Unione Europea (Ecolabel UE) che contraddistingue prodotti e
                                servizi che pur garantendo elevati standard prestazionali sono caratterizzati da un ridotto impatto ambientale durante l’intero ciclo di vita.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                      <div className="card">
                      <div className="card-body text-center">
                              <center>
                                <img src={VideoLogo} alt="ecolabel-alt" className="img-cleaning-dashboard-disabled "/>
                              </center>
                              <h4 className="title-cleaning-dashboard-disabled">Cam Studio</h4>
                              <p className="subtext-cleaning-dashboard-disabled">Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore
                              magna aliqua. Ut enim ad minim veniaLorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.</p>
                          </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                      <div className="card">
                      <div className="card-body text-center">
                              <center>
                                <img src={LampLogo} alt="ecolabel-alt" className="img-cleaning-dashboard-disabled"/>
                              </center>
                              <h4 className="title-cleaning-dashboard-disabled">Formazione</h4>
                              <p className="subtext-cleaning-dashboard-disabled">Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore
                              magna aliqua. Ut enim ad minim veniaLorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.</p>
                          </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                      <div className="card">
                      <div className="card-body text-center">
                              <center>
                                <img src={PenLogo} alt="ecolabel-alt" className="img-cleaning-dashboard-disabled"/>
                              </center>

                          <h4 className="title-cleaning-dashboard-disabled">Consulenza alle Aziende</h4>
                          <p className="subtext-cleaning-dashboard-disabled">Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore
                          magna aliqua. Ut enim ad minim veniaLorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.</p>
                          </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="card">
                        <div className="card-body text-center">
                                <center>
                                  <img src={VideoLogo} alt="ecolabel-alt" className="img-cleaning-dashboard-disabled"/>
                                </center>

                            <h4 className="title-cleaning-dashboard-disabled">Compro / Vendo</h4>
                            <p className="subtext-cleaning-dashboard-disabled">Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniaLorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                      <div className="card">
                      <div className="card-body text-center">
                              <center>
                                <img src={LampLogo} alt="ecolabel-alt" className="img-cleaning-dashboard-disabled"/>
                              </center>
                              <h4 className="title-cleaning-dashboard-disabled">Cleaning Community</h4>
                              <p className="subtext-cleaning-dashboard-disabled">Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore
                              magna aliqua. Ut enim ad minim veniaLorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua.</p>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default HomeComponent;
