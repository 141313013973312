import React, { Fragment,useState,useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'


const ClientiPotenzialiAggiungi = () => {

    const [data,setData] = useState([])
    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(true)
    const [fileImage, setFile] = useState(null)
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)

    const toggle = () => {
        setModal(!modal)
    }

    const toggleDel = () => {
        setModalDel(!modalDel)
    }

    const toggleImg = () => {
        setModalImg(!modalImg)
    }

    const toggleImgSee = () => {
        setModalImgSee(!modalImgSee)
    }

    const toggleDD = () => setDropdownOpen(prevState => !prevState)

    const onSubmit = (data) => {

     var formData = new FormData();
      let name = Math.round((new Date()).getTime())
      if(data.logo) {
        formData.append('file', data.logo[0])
        formData.append('fileName', name)
      }
      formData.append('address', data.address)
      formData.append('email', data.email)
      formData.append('phone', data.phone)
      formData.append('city', data.city)
      formData.append('region', data.region)
      formData.append('postalCode', data.postalCode)
      formData.append('ragione_sociale', data.ragione_sociale)
      formData.append('IDCartellaMedia', localStorage.getItem("IDCartellaMedia"))
      formData.append('userID', localStorage.getItem("userID"))

      console.log(formData)
    const updateData = async () => {
        const update = await axios.post(`${apiEndpoint}/clienti-potenziali/create`, formData, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        reset()
        toggle()
      }
      updateData()
      console.log(data)
    }

    const setFileUpload = (target) => {
      if(target) {
        let newImage = target.files[0]
        let imageSize = newImage.size
        let imageType = newImage.type
        if(imageType === 'image/png' || imageType === 'image/jpeg') {
          if(imageSize <= 2000000) {
            setFile(newImage)
            setDisabled(false)
          } else {
            window.alert('Dimensione massima consentita 2MB.')
          }
        } else {
          window.alert('Puoi caricare solo immagini in formato .png, .jpeg, .jpg')
        }
      }
    }

    const cancelUpload = () => {
      toggleImg()
      setFile(null)
    }

    if(isLoggedOut) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/login`} />)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Clienti potenziali" title="Aggiungi" />
            {loading ? <><div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12 card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Nuovo cliente potenziale"}</h4>
                                    <div className="card-options"><a className="card-options-collapse" href="javascript" data-toggle="card-collapse"><i className="fe fe-chevron-up"></i></a><a className="card-options-remove" href="javascript" data-toggle="card-remove"><i className="fe fe-x"></i></a></div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Logo cliente"}</label>
                                            <p>Dimensione massima consentita <strong>2MB</strong>, risoluzione consigliata <strong>512x512px</strong></p>
                                            <input className="form-control cleaning-form" name="logo" {...register("logo")} type="file" placeholder="" />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Ragione sociale"}</label>
                                            <input className="form-control cleaning-form" name="ragione_sociale" type="text" placeholder="es. Mario Rossi Srl" {...register("ragione_sociale")} required/>
                                        </div>
                                    </div>

                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{EmailAddress}</label>
                                                <input className="form-control cleaning-form" name="email" type="email" placeholder="Email" {...register("email", { required: true})} required/>
                                                {errors.email && <p className="err-msg-form">Campo richiesto</p>}
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{PhoneNumber}</label>
                                                <input className="form-control cleaning-form" name="phone"  type="text" placeholder="es. +390123456789" {...register("phone")} required/>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{Address}</label>
                                                <input className="form-control cleaning-form" name="address" type="text" placeholder="es. Via Roma, 1" {...register("address")} required/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{City}</label>
                                                <input className="form-control cleaning-form" name="city" type="text" placeholder="es. Roma" {...register("city")} required/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label cleaning-form-label">{PostalCode}</label>
                                                <input className="form-control cleaning-form" name="postalCode" type="number" placeholder="es. 00100" {...register("postalCode")} required/>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Provincia"}</label>
                                            <input className="form-control cleaning-form" name="region"maxLength="2" type="text" placeholder="es. RM" {...register("region")} required/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Aggiungi"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div></> : null}

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Cliente potenziale aggiunto con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={toggle} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>


        </Fragment>
    );
};

export default ClientiPotenzialiAggiungi;
