import React, { Fragment,useState,useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import CKEditors from "react-ckeditor-component";



const TemplatesAdd = () => {

    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [modal, setModal] = useState();
    const [content,setContent] = useState('Inserisci il corpo del tuo template qui')
    const onChange = (evt) => {
        const newContent = evt.editor.getData();
        setContent(newContent)
    }

    //Generazione randomica di stringhe
    function makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() *
     charactersLength)));
       }
       return result.join('');
    }

    const toggle = () => {
        setModal(!modal)
    }

    const onSubmit = (data) => {
        const updateData = async () => {
            const update = await axios.post(`${apiEndpoint}/templates/create`, {
              descrizione: data.descrizione,
              corpo: content,
              id: makeid(5),
              userID: localStorage.getItem("userID")
            }, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            reset()
            toggle()
          }
      updateData()
    }

    const resetForm = () => {
      reset()
    }

    return (
        <Fragment>
            <Breadcrumb parent="Templates" title="Aggiungi" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12 card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Nuovo template"}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                      <div className="col-sm-12 col-md-12">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{"Descrizione"}<span className="required-field">*</span> </label>
                                              <input className="form-control cleaning-form" name="descrizione" type="text" placeholder="Inserisci descrizione es. PIÉ DI PAGINA Cleaning Academy" {...register("descrizione")} required/>
                                              {errors.descrizione && <p className="err-msg-form">Campo richiesto</p>}
                                          </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 col-sm-12">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{"Corpo"}<span className="required-field">*</span></label>
                                              <CKEditors
                                                      activeclassName="p10"
                                                      content={content}
                                                      events={{
                                                          "change": onChange
                                                      }}
                                              />
                                          </div>
                                      </div>
                                    </div>
                                    <span className="required-field">*</span>tutti i campi sono richiesti
                                </div>
                                <div className="card-footer text-right">
                                    <button className="btn cleaning-btn-login cleaning-cancel-btn" onClick={() => resetForm()}>{"Annulla"}</button>
                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Aggiungi"}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Template aggiunto con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={toggle} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>


        </Fragment>
    );
};

export default TemplatesAdd;
