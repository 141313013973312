import React, { Fragment, useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import Slider from 'react-slick';
import Ratings from 'react-ratings-declarative'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, ProductReview, Brand, Availability, AddToCart, BuyNow, ContinueShopping,OutOfStock, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'



const MacchinariProfilo = (props) => {


      const [isMaterial, setMaterials] = useState({})
      const [rating,setRating] = useState(0)
      const [isLoading, setLoading] = useState(false)
      const [modalImgSee, setModalImgSee] = useState();


      useEffect(() => {
        //${localStorage.getItem("userID")}
          let mounted = true
          const getMaterial = async () => {
            const material = await axios.get(`${apiEndpoint}/macchinari/fetch-profilo/${props.match.params.id}`, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            if(mounted) {
              setMaterials(material.data)
              setLoading(true)
            }
          }
          getMaterial()
          return () => {
            mounted = false
          }
      }, [])

      const changeRating =  (newRating) => {
          setRating(newRating)
      }

      function isEcolabel(ecolabel) {
        if(ecolabel === 1) {
          return (<span>&#9989;</span>)
        } else if(ecolabel === 0) {
          return (<span>&#10060;</span>)
        }
      }

      function isColore(colore) {
        if(colore === 1) {
          return 'Si'
        } else if(colore === 0) {
          return 'No'
        }
      }


      function calcoloDiluizione(valore) {
        if(valore === 0) {
          return 'Pronto all\'uso'
        } else {
          return valore + '%'
        }
      }

      const toggleImgSee = () => {
          setModalImgSee(!modalImgSee)
      }

      return (
          <Fragment>
              <Breadcrumb title="Dettagli attrezzatura" parent="Attrezzatura" />
              {isLoading ? <div className="container-fluid">
                  <div className="card">
                      <div className="row">
                        <div className="col">
                          <img src={fileServer + isMaterial.percorsoImmagine} alt={isMaterial.Descrizione} className="immagine-profilo-materiale" onClick={toggleImgSee}/>
                        </div>
                        <div className="col">
                          <h3 className="titolo-profilo-materiale">{isMaterial.Descrizione}</h3>
                          <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Codice articolo: </span><span>{isMaterial.IDattrezzatura}</span></p>
                          <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Ecolabel: </span><span>{isEcolabel(isMaterial.EcoLabel)}</span></p>
                          <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Consumo elettrico: </span><span>{isMaterial.ConsumoKWh ? isMaterial.ConsumoKWh + " kW / h" : null}</span></p>
                          <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Consumo CO<sub>2</sub>/h: </span><span>{isMaterial.ConsumoCO2h ? isMaterial.ConsumoCO2h + " g / kWh" : null}</span></p>
                          <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Prezzo listino ufficiale (iva esclusa): </span><span>{isMaterial.CostoKm}</span></p>
                          <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Canone mensile (iva esclusa): </span><span>{isMaterial.Canone}</span></p>
                          <a href={isMaterial.percorsoTecnica && isMaterial.percorsoTecnica.includes("tribusadv") ? fileServer + isMaterial.percorsoTecnica : isMaterial.percorsoTecnica} className="btn btn-primary cleaning-btn-login" type="submit" target="_blank">{"Scheda tecnica"}</a>
                          <a href={isMaterial.percorsoSicurezza && isMaterial.percorsoSicurezza.includes("tribusadv") ? fileServer + isMaterial.percorsoSicurezza : isMaterial.percorsoSicurezza} className="btn btn-primary cleaning-btn-login" type="submit" target="_blank">{"Scheda sicurezza"}</a>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col">
                        {(() => {
                          switch(isMaterial.IDsottogruppo) {
                            case 'LAV':
                              return (
                                <>
                                <div className="col">
                                  <hr />
                                  <h4 className="titolo-dettaglio">Dettagli:</h4>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Diametro cesto: </span><span>{isMaterial.DiametroCesto} /lt</span></p>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Carico: </span><span>{isMaterial.KgCarico}</span></p>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Centrifuga: </span><span>{isMaterial.Centrifuga}</span></p>
                                  <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Fattore G: </span><span>{isMaterial.FattoreG}</span></p>
                                </div>
                                </>
                              )
                            default:
                              return (
                                <div className="col">
                                <hr />
                                <h4 className="titolo-dettaglio">Dettagli:</h4>
                                <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Pista : </span><span>{isMaterial.Pista ? isMaterial.Pista + " / cm" : null}</span></p>
                                <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Plastica riciclata: </span><span>{isMaterial.PlasticaRiciclata ? isMaterial.PlasticaRiciclata + "%" : null}</span></p>
                                <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Capacitá: </span><span>{isMaterial.CapacitaLt ? isMaterial.CapacitaLt + " / l" : null}</span></p>
                                <p className="dettaglio-profilo-materiale"><span className="strong-profilo-materiale">Resa: </span><span>{isMaterial.Resa ? isMaterial.Resa + " mq / punti" : null}</span></p>
                                </div>
                              )
                          }
                        })()}
                      </div>
                      </div>
                  </div>
                  <Modal isOpen={modalImgSee} toggle={toggleImgSee} centered={true} modalClassName="cleaning-modal remove-bg-cleaning">
                            <img className="light-shadow" alt={isMaterial.descrizione1} src={fileServer + isMaterial.percorsoImmagine} />
                  </Modal>
              </div> : null}
          </Fragment>
      );
  }

export default MacchinariProfilo;
