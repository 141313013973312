import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import {FaCloudDownloadAlt, FaExternalLinkSquareAlt, FaFileInvoice, FaTrashAlt} from "react-icons/fa"

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import ReactTooltip from 'react-tooltip';




const OperazioniLista = () => {

    const [data,setData] = useState([])
    const [allOperations, getOperations] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [isErr, setErr] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errDel, setErrDel] = useState('')
    const [isDeleted, setDeleted] = useState(null)

    const [modalDel, setModalDel] = useState();


    const toggleDel = (id) => {
        setModalDel(!modalDel)
        setDeleted(id)
    }

    const { SearchBar } = Search;
    const columns = [
      {
        dataField: 'ambiente',
        text: 'Ambiente'
      },
      {
        dataField: 'area',
        text: 'Area'
      },
      {
      dataField: 'descrizione',
      text: 'Operazioni',
      formatter: (value, row) => (
        <>
          {row.IDutente || localStorage.getItem("role") === "Admin" ? <Link to={`/operazioni/profilo/${row.IDoperazione}`}>{row.descrizione}</Link> : <span>{row.descrizione}</span>}
        </>
      ),
    }];


    useEffect(() => {
        let mounted = true
        const getUser = async () => {
          const operazioni = await axios.get(`${apiEndpoint}/operazioni/fetch`, {
            params: {
              id: localStorage.getItem('userID')
            },
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          if(mounted) {

              getOperations(operazioni.data)
              setLoading(true)
            }
          }
        getUser()
        return () => {
          mounted = false
        }
    }, [])

    const { register: registerDel, handleSubmit: handleSubmitDel, formState: { errors: errorsDel } } = useForm();


    const onDeleteAccount = data => {

      if(data.delete === 'ELIMINA OPERAZIONE') {
         const deleteAccount = async () => {
          const delAcc = await axios.delete(`${apiEndpoint}/operazioni/delete-op/${isDeleted}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          window.location.reload()
        }
        deleteAccount()
      } else {
        setErrDel('Non hai inserito ELIMINA OPERAZIONE come richiesto, riprova.')
      }
    }

    return (
        <Fragment>
            <Breadcrumb title="Vedi" parent="Operazioni" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                      {loading ?   <div className="card">

                            <div className="card-body datatable-react">
                            <ToolkitProvider
                            keyField='ID'
                            data={ allOperations }
                            columns={ columns }
                            search
                            >
                            {
                              props => (
                                <div>
                                  <div className="d-flex flex-row justify-content-between w-100 mb-2"><SearchBar
                                  { ...props.searchProps }
                                  placeholder="Cerca"
                                  className="cleaning-form text-left"
                                  />
                                  <div className="text-right">
                                      <Link to={`${process.env.PUBLIC_URL}/operazioni/aggiungi`} className="btn btn-success cleaning-btn-login" type="submit">{"Aggiungi"}</Link>
                                  </div></div>
                                  <BootstrapTable
                                    { ...props.baseProps }
                                    striped
                                    hover
                                    condensed
                                    keyField="ID"
                                    pagination={ paginationFactory({
                                      showTotal: false,
                                      firstPageText: 'Inizio', // the text of first page button
                                      prePageText: 'Prec', // the text of previous page button
                                      nextPageText: 'Succ', // the text of next page button
                                      lastPageText: 'Fine', // the text of last page button
                                      nextPageTitle: 'Vai al successivo', // the title of next page button
                                      prePageTitle: 'Vai al precedente', // the title of previous page button
                                      firstPageTitle: 'Vai al primo', // the title of first page button
                                      lastPageTitle: 'Vai all\'ultmo', // the title of last page button
                                    }) }
                                  />
                                </div>
                              )
                            }
                            </ToolkitProvider>
                            </div>
                        </div>: <div className="spinner-loading-cleaning"><div className="loader-box">
                          <div className="loader">
                            <div className="line bg-success"></div>
                            <div className="line bg-success"></div>
                            <div className="line bg-success"></div>
                            <div className="line bg-success"></div>
                          </div>
                        </div></div>}
                    </div>
                </div>
            </div>
            {/* Modale per eliminazione account*/}
            <Modal isOpen={modalDel} toggle={toggleDel} centered={true} modalClassName="cleaning-modal">
              <form onSubmit={handleSubmitDel(onDeleteAccount)}>
                <ModalHeader toggle={toggleDel}>Attenzione!</ModalHeader>
                <ModalBody>
                    <p>Stai per <strong>eliminare</strong> l'operazione, per procedere scrivi <strong>ELIMINA OPERAZIONE</strong> e clicca su <strong>Elimina</strong>.</p>
                      <input className="form-control cleaning-form" name="delete"  type="text" placeholder="" {...registerDel("delete")}/>
                      <p style={{color: 'red', marginTop: '1vh'}}>{errDel}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" type="submit" className="btn-cleaning">{"Elimina"}</Button>
                </ModalFooter>
              </form>
            </Modal>
            {/* Modale per eliminazione account*/}
        </Fragment>
    );
};

export default OperazioniLista;
