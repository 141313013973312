import React, { Fragment,useState,useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import Breadcrumb from '../../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../../constant'
import CKEditors from "react-ckeditor-component";
import Select from 'react-select';
import parse from 'html-react-parser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const PreventivoIntestazione = (props) => {

    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [modal, setModal] = useState();
    const [allPreventivi, viewPreventivi] = useState({})
    const [isMem, setMem] = useState(false)
    const [isNew, setNew] = useState(false)
    const [isMemIntro, setMemIntro] = useState(false)
    const [isNewIntro, setNewIntro] = useState(false)
    const [isMemCV, setMemCV] = useState(false)
    const [isNewCV, setNewCV] = useState(false)
    const [isMemPP, setMemPP] = useState(false)
    const [isNewPP, setNewPP] = useState(false)
    const [allClients, viewClients] = useState([{}])
    const [allIntros, viewIntros] = useState({})
    const [isCliente, setCliente] = useState(null)
    const [isIntro, setIntro] = useState(null)
    const [isIntroCorpo, setIntroCorpo] = useState(null)
    const [isVenditaCorpo, setVenditaCorpo] = useState(null)
    const [isPPCorpo, setPPCorpo] = useState(null)
    const [contentIntro,setContentIntro] = useState('Inserisci introduzione')
    const [contentVendita,setContentVendita] = useState('Inserisci condizioni di vendita')
    const [contentPP,setContentPP] = useState('Inserisci pié di pagina')
    const [isParseIntro, setParseIntro] = useState(false)
    const [isParseVendita, setParseVendita] = useState(false)
    const [isParsePP, setParsePP] = useState(false)
    const [isStatoPreventivo, setStatoPreventivo] = useState(null)
    const [selectedCliente, setSelectedCliente] = useState({})
    const [selectedStato, setSelectedStato] = useState({})
    const [loading, setLoading] = useState(false)
    const [allAmbiente, viewAmbienti] = useState([{}])
    const [selectedAmbiente, setSelectedAmbiente] = useState('')

    const [selectedTestoIntroduttivo, setSelectedTestoIntroduttivo] = useState({})
    const [selectedCondizioniVendita, setSelectedCondizioniVendita] = useState({})
    const [selectedPiePagina, setSelectedPiePagina] = useState({})

    //State cliente
    const [isRagioneSociale, setRagioneSociale] = useState(null)
    const [isEmail, setEmail] = useState(null)
    const [isTelefono, setTelefono] = useState(null)
    const [isIndirizzo, setIndirizzo] = useState(null)
    const [isCitta, setCitta] = useState(null)
    const [isCAP, setCAP] = useState(null)
    const [isProvincia, setProvincia] = useState(null)
    const [isVAT, setVAT] = useState(null)

    const [cliPotDefault, setCliPotDefault] = useState('')
    const [ambienteDefault, setAmbienteDefault] = useState({})

    //State Documenti
    const [docIntro, setDocIntro] = useState(null)
    const [docVendita, setDocVendita] = useState(null)
    const [docPP, setDocPP] = useState(null)

    useEffect(() => {
      let step = localStorage.getItem("back")
      if(step === 'back') {
        props.jumpToStep(2)
      }
      const getAllData = async () => {
        const clienti = await axios.get(`${apiEndpoint}/clienti-potenziali/fetch-all/${localStorage.getItem("userID")}`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const testi = await axios.get(`${apiEndpoint}/templates/fetch/${localStorage.getItem("userID")}`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const preventivi = await axios.get(`${apiEndpoint}/preventivi/fetch-profilo`, {
          params: {
            userID: localStorage.getItem("userID"),
            id: props.prev
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const amb = await axios.get(`${apiEndpoint}/aree-ambienti/fetch-aree`, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        let abt = {
          value: preventivi.data.ambiente,
          label: preventivi.data.ambiente
        }
        setAmbienteDefault(abt)
        let ambArray  = []
        amb.data.map(item => {
          let obj = {
            value: item.ambiente,
            label: item.ambiente
          }
          ambArray.push(obj)
        })
        viewAmbienti(ambArray)

        let pe = {
          value: preventivi.data.esito,
          label: preventivi.data.esito,
        }
        setSelectedStato(pe)
        viewPreventivi(preventivi.data)
        let cli = clienti.data
        let txt = testi.data
        let cliArray = []
        let txtArray = []
        cli.map((item, index) => {
            let obj = {
              value: item.IDcliente,
              label: item.RagioneSociale1
            }
            if(item.IDcliente === preventivi.data.IDclientePotenziale) {
              let cc = {
                value: item.IDcliente,
                label: item.RagioneSociale1
              }
              setSelectedCliente(cc)
            }
            cliArray.push(obj)
        })
        txt.map((item, index) => {
          let obj = {
            value: item.IDtemplate,
            label: item.descrizione
          }
          if(item.IDtemplate === preventivi.data.TestoIntroduttivo) {
            let cc = {
              value: item.IDtemplate,
              label: item.descrizione
            }
            setSelectedTestoIntroduttivo(cc)
          }
          if(item.IDtemplate === preventivi.data.CondizioniVendita) {
            let cc = {
              value: item.IDtemplate,
              label: item.descrizione
            }
            setSelectedCondizioniVendita(cc)
          }
          if(item.IDtemplate === preventivi.data.PieDiPagina) {
            let cc = {
              value: item.IDtemplate,
              label: item.descrizione
            }
            setSelectedPiePagina(cc)
          }
          txtArray.push(obj)
        })
        viewIntros(txtArray)
        viewClients(cliArray)
        setLoading(true)
      }
      getAllData()
    }, [])


    const onChangeIntro = (evt) => {
        const newContent = evt.editor.getData();
        setContentIntro(newContent)
    }

    const onChangeVendita = (evt) => {
        const newContent = evt.editor.getData();
        setContentVendita(newContent)
    }

    const onChangePP = (evt) => {
        const newContent = evt.editor.getData();
        setContentPP(newContent)
    }

    const setMemCust = () => {
      setMem(true)
      setNew(false)
    }

    const setNewCust = () => {
      setMem(false)
      setNew(true)
    }

    const changeMemIntro = () => {
      setMemIntro(true)
      setNewIntro(false)
    }

    const changeNewIntro = () => {
      setMemIntro(false)
      setNewIntro(true)
    }

    const changeMemCV = () => {
      setMemCV(true)
      setNewCV(false)
    }

    const changeNewCV = () => {
      setMemCV(false)
      setNewCV(true)
    }

    const changeMemPP = () => {
      setMemPP(true)
      setNewPP(false)
    }

    const changeNewPP = () => {
      setMemPP(false)
      setNewPP(true)
    }

    //Generazione randomica di stringhe
    function makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() *
     charactersLength)));
       }
       return result.join('');
    }

    const toggle = () => {
        setModal(!modal)
    }

    const onSubmit = (data) => {
        let obj = {
          dataPreventivo: data.dataPreventivo,
          statoPreventivo: isStatoPreventivo ? isStatoPreventivo : selectedStato,
          giorni: data.giorni,
          anni: data.anni,
          mesi: data.mesi,
          mq: data.mq,
          ambiente: selectedAmbiente ? selectedAmbiente : ambienteDefault.value,
          scontoATT: data.scontoATT,
          scontoDET: data.scontoDET,
          scontoDPI: data.scontoDPI,
          scontoMAC: data.scontoMAC,
          titolo: data.titolo,
          valida: data.valida,
          oggetto: data.oggetto,
          cliente: isCliente ? isCliente : selectedCliente.value,
          intro: docIntro ? docIntro : selectedTestoIntroduttivo,
          vendita: docVendita ? docVendita : selectedCondizioniVendita,
          pp: docPP ? docPP : selectedPiePagina

        }
        props.parentCallback(obj)
    }

    const selectStyles = {
      control: styles => ({ ...styles, borderRadius: '10px'})
    }

    const handleChangeClienti = e => {
      setCliente(e.value)
      const update = async () => {
        const response = await axios.patch(`${apiEndpoint}/preventivi/patch-cliente`, {
          esito: e.value,
          numero: props.current.ID,
          userID: localStorage.getItem("userID")
        }, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
      }
      update()
    }

    const handleChangeStatoPreventivo = e => {
      setStatoPreventivo(e.value)
      console.log(e.value)
      const update = async () => {
        const response = await axios.patch(`${apiEndpoint}/preventivi/patch-esito`, {
          esito: e.value,
          numero: props.current.ID,
          userID: localStorage.getItem("userID")
        }, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
      }
      update()
    }

    //Generazione randomica di stringhe
    function makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() *
     charactersLength)));
       }
       return result.join('');
    }

    const [isSucc, setSucc] = useState('')

    const addCostumerNew = () => {
      let descrizione = isRagioneSociale
      let email = isEmail
      let telefono = isTelefono
      let indirizzo = isIndirizzo
      let citta = isCitta
      let cap = isCAP
      let provincia = isProvincia
      let vat = isVAT
      let costumerID = makeid(5).toUpperCase()

      const insertCostumer = async () => {
        const insert = await axios.post(`${apiEndpoint}/clienti-potenziali/create-from-preventivo`, {
          ragione_sociale: descrizione,
          email: email,
          phone: telefono,
          address: indirizzo,
          userID: localStorage.getItem('userID'),
          vat: vat,
          postalCode: cap,
          region: provincia,
          city: citta,
          costumerCode: costumerID,
          mediaFolder: localStorage.getItem('IDCartellaMedia')
        }, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const response = await axios.patch(`${apiEndpoint}/preventivi/patch-cliente`, {
          esito: costumerID,
          numero: props.current.ID,
          userID: localStorage.getItem("userID")
        }, {
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        setCliente(costumerID)
        setSucc("Cliente inserito con successo, ricaricheremo la pagina per conferma l'inserimento")
      }
      insertCostumer()
    }

    const handleChangeIntro = e => {
      let id = e.value
      setDocIntro(id)
      const getText = async () => {
        const templates = await axios.get(`${apiEndpoint}/templates/fetch-profilo`, {
          params: {
            id: id,
            userID: localStorage.getItem("userID")
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const update = async () => {
          const response = await axios.patch(`${apiEndpoint}/preventivi/patch-intro`, {
            esito: e.value,
            numero: props.current.ID,
            userID: localStorage.getItem("userID")
          }, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
        }
        update()
        setIntroCorpo(templates.data.testoCorpo)
        setParseIntro(true)
      }
      getText()
    }

    const handleChangeCV = e => {
      let id = e.value
      setDocVendita(id)
      const getText = async () => {
        const templates = await axios.get(`${apiEndpoint}/templates/fetch-profilo`, {
          params: {
            id: id,
            userID: localStorage.getItem("userID")
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const update = async () => {
          const response = await axios.patch(`${apiEndpoint}/preventivi/patch-vendita`, {
            esito: e.value,
            numero: props.current.ID,
            userID: localStorage.getItem("userID")
          }, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
        }
        update()
        setVenditaCorpo(templates.data.testoCorpo)
        setParseVendita(true)
      }
      getText()
    }

    const handleChangePP = e => {
      let id = e.value
      setDocPP(id)
      const getText = async () => {
        const templates = await axios.get(`${apiEndpoint}/templates/fetch-profilo`, {
          params: {
            id: id,
            userID: localStorage.getItem("userID")
          },
          headers: {
            "x-access-token": localStorage.getItem('jwt')
          }
        })
        const update = async () => {
          const response = await axios.patch(`${apiEndpoint}/preventivi/patch-piepagina`, {
            esito: e.value,
            numero: props.current.ID,
            userID: localStorage.getItem("userID")
          }, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
        }
        update()
        setPPCorpo(templates.data.testoCorpo)
        setParsePP(true)
      }
      getText()
    }


    const addTestoIntro = () => {
      let id = makeid(5)
      setDocIntro(id)
      const updateData = async () => {
          const update = await axios.post(`${apiEndpoint}/templates/create`, {
            descrizione: makeid(5) + '-intro-prev-' + makeid(5),
            corpo: contentIntro,
            id: id,
            userID: localStorage.getItem("userID")
          }, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          const update2 = async () => {
            const response = await axios.patch(`${apiEndpoint}/preventivi/patch-intro`, {
              esito: id,
              numero: props.current.ID,
              userID: localStorage.getItem("userID")
            }, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
          }
          setIntroCorpo(contentIntro)
          setParseIntro(true)
          reset()
          toggle()
        }
        updateData()
    }

    const addTestoVendita = () => {
      let id = makeid(5)
      setDocVendita(id)
      const updateData = async () => {
          const update = await axios.post(`${apiEndpoint}/templates/create`, {
            descrizione: makeid(5) + '-condizioni-vendita-prev-' + makeid(5),
            corpo: contentVendita,
            id: id,
            userID: localStorage.getItem("userID")
          }, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          const update2 = async () => {
            const response = await axios.patch(`${apiEndpoint}/preventivi/patch-vendita`, {
              esito: id,
              numero: props.current.ID,
              userID: localStorage.getItem("userID")
            }, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
          }
          setVenditaCorpo(contentVendita)
          setParseVendita(true)
          reset()
          toggle()
        }
        updateData()
    }

    const addTestoPP = () => {
      let id = makeid(5)
      setDocPP(id)
      let name = makeid(5) + '-pie-pagina-prev-' + makeid(5)
      const updateData = async () => {
          const update = await axios.post(`${apiEndpoint}/templates/create`, {
            descrizione: name,
            corpo: contentPP,
            id: id,
            userID: localStorage.getItem("userID")
          }, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          const update2 = async () => {
            const response = await axios.patch(`${apiEndpoint}/preventivi/patch-piepagina`, {
              esito: id,
              numero: props.current.ID,
              userID: localStorage.getItem("userID")
            }, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
          }
          setIntroCorpo(contentPP)
          setParsePP(true)
          reset()
          toggle()
        }
        updateData()
    }


    const resetForm = () => {
      reset()
    }

    const getDefaultClient = () => {
      return cliPotDefault
    }


    return (
        <Fragment>
            <div className="container-fluid">
                <div className="edit-profile">
                    {loading ? <div className="row">
                        <div className="col-lg-12 card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                            <center><button type="submit" className="btn btn-success cleaning-btn-login" style={{marginBottom: '3vh', marginTop: '5vh'}}>SALVA</button></center>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Intestazione"}</h4>
                                </div>
                                <div className="card-body">

                                  <div className="row">
                                      <div className="col-sm-3 col-md-3">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{"Data"}<span className="required-field">*</span> </label>
                                              <input className="form-control cleaning-form" defaultValue={props.current.data ? new Date(props.current.data).toISOString().substr(0,10) : null} name="dataPreventivo" type="date" {...register("dataPreventivo")} required/>
                                              {errors.dataPreventivo && <p className="err-msg-form">Campo richiesto</p>}
                                          </div>
                                      </div>
                                      <div className="col-sm-3 col-md-3">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{"Stato preventivo"}<span className="required-field">*</span> </label>
                                              <Select
                                                className="cleaning-form"
                                                placeholder="Inserisci stato"
                                                defaultValue={selectedStato}
                                                options={[
                                                  {
                                                    value: 'Bozza',
                                                    label: 'Bozza'
                                                  },
                                                  {
                                                    value: 'In trattativa',
                                                    label: 'In trattativa'
                                                  },
                                                  {
                                                    value: 'In attesa',
                                                    label: 'In attesa'
                                                  },
                                                  {
                                                    value: 'Accettato',
                                                    label: 'Accettato'
                                                  },
                                                  {
                                                    value: 'Rifiutato',
                                                    label: 'Rifiutato'
                                                  }
                                                ]}
                                                styles={selectStyles}
                                                {... register("statoPreventivo")}
                                                onChange={(e) => handleChangeStatoPreventivo(e)}
                                              />
                                              {errors.descrizione && <p className="err-msg-form">Campo richiesto</p>}
                                          </div>
                                      </div>
                                      <div className="col-sm-6 col-md-6">
                                      <label className="form-label cleaning-form-label">{"Cliente"}<span className="required-field">*</span></label>
                                        <p>
                                          <button type="button" className="btn btn-primary cleaning-btn-login " style={{width: '18vw'}}  onClick={() => setMemCust()}>{"Seleziona memorizzati"}</button>
                                          <button type="button" className="btn btn-secondary cleaning-btn-login " style={{width: '18vw'}}   onClick={() => setNewCust()}>{"Aggiungi nuovo"}</button>
                                        </p>
                                      </div>
                                    </div>
                                    {isMem || selectedCliente ? <div className="row ">
                                      <div className="col-sm-12 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Seleziona cliente"}<span className="required-field">*</span> </label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Seleziona cliente"
                                              options={allClients}
                                              styles={selectStyles}
                                              defaultValue={selectedCliente}
                                              onChange={(e) => handleChangeClienti(e)}
                                            />
                                        </div>
                                      </div>
                                    </div> : null}
                                    {isNew ? <div className="row row-preventivo">
                                      <h5 className="titolo-row-preventivo">Nuovo cliente</h5>
                                      <div className="col-sm-12 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Ragione sociale"}<span className="required-field">*</span> </label>
                                            <input className="form-control cleaning-form" name="descrizione" type="text" placeholder="es. Mario Rossi Srl" onChange={e => setRagioneSociale(e.target.value)} required/>
                                            {errors.descrizione && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                      </div>
                                      <div className="col-sm-12 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Partita IVA"}<span className="required-field">*</span> </label>
                                            <input className="form-control cleaning-form" name="vat" type="text" placeholder="es. 0123456789" onChange={e => setVAT(e.target.value)} required/>
                                            {errors.descrizione && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                      </div>
                                      <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Indirizzo email"}<span className="required-field">*</span> </label>
                                            <input className="form-control cleaning-form" name="descrizione" type="email" placeholder="es. mario.rossi@cleaningacademy.it" onChange={e => setEmail(e.target.value)} required/>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Telefono"}<span className="required-field">*</span> </label>
                                            <input className="form-control cleaning-form" name="descrizione" type="text" placeholder="es. 0123456789" onChange={e => setTelefono(e.target.value)} required/>
                                        </div>
                                      </div>
                                      <div className="col-sm-12 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Indirizzo"}<span className="required-field">*</span> </label>
                                            <input className="form-control cleaning-form" name="descrizione" type="text" placeholder="es. Via Roma, 1" onChange={e => setIndirizzo(e.target.value)} required/>
                                        </div>
                                      </div>
                                      <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Cittá"}<span className="required-field">*</span> </label>
                                            <input className="form-control cleaning-form" name="descrizione" type="text" placeholder="es. Roma" onChange={e => setCitta(e.target.value)} required/>
                                        </div>
                                      </div>
                                      <div className="col-sm-3 col-md-3">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"CAP"}<span className="required-field">*</span> </label>
                                            <input className="form-control cleaning-form" name="descrizione" type="text" placeholder="es. 10100" onChange={e => setCAP(e.target.value)} required/>
                                        </div>
                                      </div>
                                      <div className="col-sm-3 col-md-3">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Provincia"}<span className="required-field">*</span> </label>
                                            <input className="form-control cleaning-form" name="provincia" type="text" placeholder="es. RM" onChange={e => setProvincia(e.target.value)} required/>
                                        </div>
                                      </div>
                                      <button type="button" className="btn btn-primary cleaning-btn-login ml-1"   onClick={() => addCostumerNew()}>{"Crea"}</button>
                                      <small style={{color: 'green', fontWeight: 'bold', marginLeft: '3vw'}} ><em>{isSucc}</em></small>

                                    </div> : null}
                                    <div className="row">
                                      <div className="col-sm-9 col-md-9">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{"Titolo preventivo"}<span className="required-field">*</span> </label>
                                              <input className="form-control cleaning-form" name="titolo" type="text" placeholder="Inserisci titolo es. PREVENTIVO Cleaning Academy" defaultValue={props.current.descrizione ? props.current.descrizione : null} {...register("titolo")} required/>
                                              {errors.titolo && <p className="err-msg-form">Campo richiesto</p>}
                                          </div>
                                      </div>
                                      <div className="col-sm-3 col-md-3">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Ambiente"}<span className="required-field">*</span> </label>
                                            <Select
                                              className="cleaning-form"
                                              placeholder="Seleziona cliente"
                                              options={allAmbiente}
                                              styles={selectStyles}
                                              defaultValue={ambienteDefault}
                                              onChange={(e) => setSelectedAmbiente(e.value)}
                                            />
                                        </div>
                                      </div>
                                      <div className="col-sm-12 col-md-12">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{"Oggetto"}</label>
                                              <input className="form-control cleaning-form" name="oggetto" type="text" placeholder="Inserisci oggetto es. OGGETTO Cleaning Academy" defaultValue={props.current.oggetto ? props.current.oggetto : null} {...register("oggetto")}/>
                                              {errors.oggetto && <p className="err-msg-form">Campo richiesto</p>}
                                          </div>
                                      </div>
                                      <div className="col-sm-6 col-md-6">
                                      <label className="form-label cleaning-form-label">{"Testo introduttivo"}<span className="required-field">*</span></label>
                                        <p>
                                          <button type="button" className="btn btn-primary cleaning-btn-login" style={{width: '18vw'}}   onClick={() => changeMemIntro()}>{"Seleziona memorizzati"}</button>
                                          <button type="button" className="btn btn-secondary cleaning-btn-login" style={{width: '18vw'}}    onClick={() => changeNewIntro()}>{"Aggiungi nuovo"}</button>
                                        </p>
                                      </div>
                                    </div>
                                    {isMemIntro || selectedTestoIntroduttivo ? <div className="row">
                                      <div className="col-md-12 col-sm-12">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{"Seleziona testo introduttivo"}<span className="required-field">*</span></label>
                                              <Select
                                                className="cleaning-form"
                                                placeholder="Seleziona testo"
                                                options={allIntros}
                                                styles={selectStyles}
                                                defaultValue={selectedTestoIntroduttivo}
                                                onChange={(e) => handleChangeIntro(e)}
                                              />
                                          </div>
                                      </div>
                                      {isParseIntro ? <div className="col-md-12 col-sm-12">
                                          <p className="paragraph-preventivo">{parse(isIntroCorpo)}</p>
                                      </div> : null}
                                    </div> : null}
                                    {isNewIntro ? <div className="row">
                                      <div className="col-md-12 col-sm-12">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{"Inserisci testo"}<span className="required-field">*</span></label>
                                              <CKEditors
                                                      activeclassName="p10"
                                                      content={contentIntro}
                                                      events={{
                                                          "change": onChangeIntro
                                                      }}
                                              />
                                              <button type="button" className="btn btn-primary cleaning-btn-login ml-1 mt-2"   onClick={() => addTestoIntro()}>{"Crea"}</button>
                                          </div>
                                      </div>
                                    </div> : null}
                                    <hr />
                                    <div className="row">
                                    <h5 className="titolo-sezione-preventivo">&#x270d; Condizioni di vendita</h5>
                                    </div>

                                    <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Offerta valida fino al"}<span className="required-field">*</span> </label>
                                          <input className="form-control cleaning-form" name="valida" defaultValue={props.current.offertaValida ? new Date(props.current.offertaValida).toISOString().substr(0,10) : null} type="date" {...register("valida")}/>
                                          {errors.valida && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"MQ Totali"}<span className="required-field">*</span> </label>
                                          <input className="form-control cleaning-form" name="mq" type="number" placeholder="es. 150" defaultValue={props.current.totaleMQ ? props.current.totaleMQ : null} {...register("mq")}/>
                                          {errors.mq && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                    </div>
                                    <div className="col-sm-4 col-md-4">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Anni di contratto"}<span className="required-field">*</span> </label>
                                          <input className="form-control cleaning-form" name="anni" type="number" placeholder="es. 2" defaultValue={props.current.anni ? props.current.anni : 1} {...register("anni")}/>
                                          {errors.anni && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                    </div>
                                    <div className="col-sm-4 col-md-4">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Mesi lavorativi in 1 anno"}<span className="required-field">*</span> </label>
                                          <input className="form-control cleaning-form" name="mesi" type="number" placeholder="es. 12" defaultValue={props.current.mesi ? props.current.mesi : 12} {...register("mesi")}/>
                                          {errors.mesi && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                    </div>
                                    <div className="col-sm-4 col-md-4">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Giorni lavorativi in 1 settimana"}<span className="required-field">*</span> </label>
                                          <input className="form-control cleaning-form" name="giorni" type="number" placeholder="es. 5" defaultValue={props.current.giorni ? props.current.giorni : 5} {...register("giorni")}/>
                                          {errors.giorni && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Sconto detergenti %"}<span className="required-field">*</span> </label>
                                          <input className="form-control cleaning-form" name="scontoDET" type="number" placeholder="es. 3" defaultValue={props.current.ScontoDET ? props.current.ScontoDET : 0} {...register("scontoDET")}/>
                                          {errors.scontoDet && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Sconto DPI %"}<span className="required-field">*</span> </label>
                                          <input className="form-control cleaning-form" name="scontoDPI" type="number" placeholder="es. 3" defaultValue={props.current.ScontoDPI ? props.current.ScontoDPI : 0} {...register("scontoDPI")}/>
                                          {errors.scontoDPI && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Sconto attrezzature %"}<span className="required-field">*</span> </label>
                                          <input className="form-control cleaning-form" name="scontoATT" type="number" placeholder="es. 3" defaultValue={props.current.ScontoATT ? props.current.ScontoATT : 0} {...register("scontoATT")}/>
                                          {errors.scontoATT && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3">
                                      <div className="form-group">
                                          <label className="form-label cleaning-form-label">{"Sconto macchinari %"}<span className="required-field">*</span> </label>
                                          <input className="form-control cleaning-form" name="scontoMAC" type="number" placeholder="es. 3" defaultValue={props.current.ScontoMAC ? props.current.ScontoMAC : 0} {...register("scontoMAC")} />
                                          {errors.scontoMAC && <p className="err-msg-form">Campo richiesto</p>}
                                      </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12">
                                    <label className="form-label cleaning-form-label">{"Seleziona testo"}<span className="required-field">*</span></label>
                                      <p>
                                        <button type="button" className="btn btn-primary cleaning-btn-login" style={{width: '18vw'}}   onClick={() => changeMemCV()}>{"Seleziona memorizzati"}</button>
                                        <button type="button" className="btn btn-secondary cleaning-btn-login" style={{width: '18vw'}}    onClick={() => changeNewCV()}>{"Aggiungi nuovo"}</button>
                                      </p>
                                    </div>
                                    {isMemCV || selectedCondizioniVendita ? <>
                                      <div className="col-md-12 col-sm-12">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{"Seleziona testo"}<span className="required-field">*</span></label>
                                              <Select
                                                className="cleaning-form"
                                                placeholder="Seleziona testo"
                                                options={allIntros}
                                                styles={selectStyles}
                                                defaultValue={selectedCondizioniVendita}
                                                onChange={(e) => handleChangeCV(e)}
                                              />
                                          </div>
                                      </div>
                                      {isParseVendita ? <div className="col-md-12 col-sm-12">
                                          <p className="paragraph-preventivo">{parse(isVenditaCorpo)}</p>
                                      </div> : null}
                                    </> : null}
                                    {isNewCV ?
                                      <div className="col-md-12 col-sm-12">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{"Inserisci testo"}<span className="required-field">*</span></label>
                                              <CKEditors
                                                      activeclassName="p10"
                                                      content={contentVendita}
                                                      events={{
                                                          "change": onChangeVendita
                                                      }}
                                              />
                                              <button type="button" className="btn btn-primary cleaning-btn-login ml-1 mt-2"   onClick={() => addTestoVendita()}>{"Crea"}</button>
                                          </div>
                                    </div> : null}
                                    </div>
                                    <hr/>
                                    <div className="row">
                                    <h5 className="titolo-sezione-preventivo">&#x1f5d2; Pié di pagina</h5>
                                    </div>
                                    <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                    <label className="form-label cleaning-form-label">{"Seleziona testo"}<span className="required-field">*</span></label>
                                      <p>
                                        <button type="button" className="btn btn-primary cleaning-btn-login" style={{width: '18vw'}}   onClick={() => changeMemPP()}>{"Seleziona memorizzati"}</button>
                                        <button type="button" className="btn btn-secondary cleaning-btn-login" style={{width: '18vw'}}    onClick={() => changeNewPP()}>{"Aggiungi nuovo"}</button>
                                      </p>
                                    </div>
                                    {isMemPP || selectedPiePagina ? <>
                                      <div className="col-md-12 col-sm-12">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{"Seleziona testo"}<span className="required-field">*</span></label>
                                              <Select
                                                className="cleaning-form"
                                                placeholder="Seleziona testo"
                                                options={allIntros}
                                                defaultValue={selectedPiePagina}
                                                styles={selectStyles}
                                                onChange={(e) => handleChangePP(e)}
                                              />
                                          </div>
                                      </div>
                                      {isParsePP ? <div className="col-md-12 col-sm-12">
                                          <p className="paragraph-preventivo">{parse(isPPCorpo)}</p>
                                      </div> : null}
                                    </> : null}
                                    {isNewPP ?
                                      <div className="col-md-12 col-sm-12">
                                          <div className="form-group">
                                              <label className="form-label cleaning-form-label">{"Inserisci testo"}<span className="required-field">*</span></label>
                                              <CKEditors
                                                      activeclassName="p10"
                                                      content={contentPP}
                                                      events={{
                                                          "change": onChangePP
                                                      }}
                                              />
                                              <button type="button" className="btn btn-primary cleaning-btn-login ml-1 mt-2"   onClick={() => addTestoPP()}>{"Crea"}</button>
                                          </div>
                                    </div> : null}
                                    </div>

                                    <span className="required-field">*</span>tutti i campi sono richiesti

                                </div>
                                <center><button type="submit" className="btn btn-success cleaning-btn-login" style={{marginBottom: '3vh', marginTop: '5vh'}}>SALVA</button></center>

                                {/*<div className="card-footer text-right">
                                    <button className="btn cleaning-btn-login cleaning-cancel-btn" onClick={() => resetForm()}>{"Annulla"}</button>
                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Aggiungi"}</button>
                                </div>*/
                              }
                            </form>
                        </div>
                    </div> : <span>Caricamento dati in corso...</span>}
                </div>
            </div>

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Template aggiunto con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={toggle} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>


        </Fragment>
    );
};

export default PreventivoIntestazione;
