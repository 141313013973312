import React, { Fragment,useState,useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import StepZilla from "react-stepzilla";
import Registration from './registration';
import Email from './email';
import Birthdate from './birthdate';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../../constant'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import Select from 'react-select';
import {FaTree } from 'react-icons/fa'

//Componenti wizard
import PreventivoIntestazione from './preventivoIntestazione'
import PreventivoAreeOperazioni from './preventivoAreeOperazioni'
import RiepilogoOperatori from './riepilogoOperatori'
import RiepilogoMacchinari from './riepilogoMacchinari'
import RiepilogoFinale from './riepilogoFinale'


const PreventivoAdd = (props) => {

  const [allPreventivo, viewPreventivo] = useState({})
  const [allOperations, viewOperations] = useState([{}])
  const [allProducts, viewProducts] = useState([{}])
  const [step2, setStep2] = useState([{}])
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [modal2, setModal2] = useState(false)

  const toggle = () => {
      setModal(!modal)
  }

  const toggle2 = () => {
      setModal2(!modal2)
  }


  useEffect(() => {
    let id = props.match.params.id
    let userID = localStorage.getItem('userID')
    const getAllData = async () => {
      const prev = await axios.get(`${apiEndpoint}/preventivi/fetch-profilo`, {
        params: {
          id: id,
          userID: userID
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          "x-access-token": localStorage.getItem('jwt')
        }
      })
      const defSelected = await axios.get(`${apiEndpoint}/preventivi/fetch-selected`, {
        params: {
          id: prev.data.ID
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          "x-access-token": localStorage.getItem('jwt')
        }
      })
      const defProd = await axios.get(`${apiEndpoint}/preventivi/fetch-selected-prod`, {
        params: {
          id: prev.data.ID
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
          "x-access-token": localStorage.getItem('jwt')
        }
      })
      viewProducts(defProd.data)
      viewOperations(defSelected.data)
      viewPreventivo(prev.data)
      setLoading(true)
    }
    getAllData()
  }, [])

  const [isStep1, setStep1] = useState({})

  const callbackFunction1 = (data) => {
    setStep1(data)
    const updateStep1 = async () => {
      const patch = await axios.patch(`${apiEndpoint}/preventivi/step1`, {
        data: data,
        id: props.match.params.id,
        utente: localStorage.getItem("userID")
      }, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "x-access-token": localStorage.getItem('jwt')
        }
      })
      toggle2()
    }
    updateStep1()
  }

  const callbackFunction2 = (data, old) => {
    setStep2(data)
    const updateStep2 = async () => {
      try {
        const patch = await axios.patch(`${apiEndpoint}/preventivi/step2`, {
          data: data,
          id: allPreventivo.ID,
          utente: localStorage.getItem("userID"),
          old: old,
          anni: allPreventivo.anni
        }, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            "x-access-token": localStorage.getItem('jwt'),
          }
        })
        if(patch.data) {
          const patch1 = await axios.patch(`${apiEndpoint}/preventivi/step2-1`, {
            data: data,
            id: allPreventivo.ID,
            utente: localStorage.getItem("userID"),
            old: old,
            anni: allPreventivo.anni
          }, {
            headers: {
              'Access-Control-Allow-Origin': '*',
              "x-access-token": localStorage.getItem('jwt')
            }
          })
            const patch2 = await axios.patch(`${apiEndpoint}/preventivi/step2-2`, {
              data: data,
              id: allPreventivo.ID,
              utente: localStorage.getItem("userID"),
              old: old,
              anni: allPreventivo.anni
            }, {
              headers: {
                'Access-Control-Allow-Origin': '*',
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            toggle()
        }

      } catch (e) {
        console.log(e)
      }

    }
    updateStep2()
  }

  const callbackFunction3 = (data) => {
    console.log(data)
    setStep1(data)
  }

  const callbackFunction4 = (data) => {
    console.log(data)
    setStep1(data)
  }

  const onClickSuccess = () => {
    toggle()
    window.location.reload()
  }

    const steps =
        [
            { name: 'Intestazione', component: <PreventivoIntestazione prev={props.match.params.id} parentCallback={callbackFunction1} current={allPreventivo}/> },
            { name: 'Operazioni', component: <PreventivoAreeOperazioni prev={allPreventivo} parentCallback={callbackFunction2} current={allPreventivo}/> },
            { name: 'Riepilogo operatori', component: <RiepilogoOperatori parentCallback={callbackFunction3} step2={step2} current={allOperations}/> },
            { name: 'Riepilogo materiali', component: <RiepilogoMacchinari parentCallback={callbackFunction4} step2={step2} current={allPreventivo}/> },
            { name: 'Concludi', component: <RiepilogoFinale parentCallback={callbackFunction4} current={allPreventivo}/> },
        ]

    return (
        <Fragment>
            <Breadcrumb title="Aggiungi" parent="Preventivi" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>{"Nuovo preventivo"}</h5>
                                <br/>
                                <em>Salva i dati prima di ogni avanzamento di step cliccando su <strong>SALVA</strong>, potrai interrompere e riprendere in un qualsiasi momento da dove eri rimasto.</em>
                            </div>
                            <div className="card-body">
                                {loading ? <StepZilla steps={steps}
                                    showSteps={true}
                                    showNavigation={true}
                                    showNavigationTop={true}
                                    stepsNavigation={true}
                                    prevBtnOnLastStep={true}
                                    dontValidate={true}
                                    showSteps={true}
                                    nextButtonText="Avanti"
                                    backButtonText="Indietro"
                                    nextButtonCls="btn btn-primary cleaning-btn-login float-right"
                                    backButtonCls="btn btn-primary cleaning-btn-login float-left"
                                    nextTextOnFinalActionStep="Avanti"
                                    /> : <div className="spinner-loading-cleaning"><div className="loader-box">
                                      <div className="loader">
                                        <div className="line bg-success"></div>
                                        <div className="line bg-success"></div>
                                        <div className="line bg-success"></div>
                                        <div className="line bg-success"></div>
                                      </div>
                                    </div></div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


                        {/* Modale successo*/}
                        <Modal isOpen={modal} toggle={onClickSuccess} centered={true} modalClassName="cleaning-modal">
                            <ModalHeader toggle={onClickSuccess}>Evviva <span>&#x1F389;</span></ModalHeader>
                            <ModalBody>
                                <p>Operazioni inserite con successo, clicca su <strong>Avanti</strong> per proseguire.</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="success" onClick={onClickSuccess} className="btn-cleaning">{"Ho capito"}</Button>
                            </ModalFooter>
                        </Modal>

                        {/* Modale successo*/}
                        <Modal isOpen={modal2} toggle={onClickSuccess} centered={true} modalClassName="cleaning-modal">
                            <ModalHeader toggle={onClickSuccess}>Evviva <span>&#x1F389;</span></ModalHeader>
                            <ModalBody>
                                <p>Dati salvati con successo, clicca su <strong>Avanti</strong> per proseguire.</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="success" onClick={onClickSuccess} className="btn-cleaning">{"Ho capito"}</Button>
                            </ModalFooter>
                        </Modal>



        </Fragment>
    );
};

export default PreventivoAdd;
