import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link, useHistory} from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';




const TariffeLista = () => {

    const [data,setData] = useState([])
    const [allTariffe, setTariffe] = useState([{}])
    const [clientiPotenziali, setClientiPotenziali] = useState([])
    const [loading, setLoading] = useState(false)
    const [fileImage, setFile] = useState(null)
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const { register: registerDel, handleSubmit: handleSubmitDel, formState: { errors: errorsDel } } = useForm();
    const { register: registerPwd, handleSubmit: handleSubmitPwd, formState: { errors: errorsPwd }, reset } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [customerCode, setCustomerCode] = useState('')
    const [isMoved, setMoved] = useState(false)
    const [show, setShow] = useState(true);

    let history = useHistory()



  const { SearchBar } = Search;
  const columns = [{
    dataField: 'descrizione',
    text: 'Descrizione',
    formatter: (value, row) => (
        <span style={{cursor: 'pointer'}}>{row.descrizione}</span>
    ),
  }, {
    dataField: 'costoComplessivo',
    text: 'Costo orario'
  }];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectColumn: true,
    hideSelectAll: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if(row.IDutente != null) {
        history.push(`${process.env.PUBLIC_URL}/tariffe/dettaglio/${row.IDcausaleBusta}`)
      }
    }
  }

    useEffect(() => {
        let mounted = true
        const getUser = async () => {
          const tariffe = await axios.get(`${apiEndpoint}/tariffe/fetch/${localStorage.getItem("userID")}`, {
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          if(mounted) {
            setTariffe(tariffe.data)
            setLoading(true)
          }
        }
        getUser()
        return () => {
          mounted = false
        }
    }, [])

    if(isMoved) {
    return (<Redirect to={`${process.env.PUBLIC_URL}/clienti-potenziali/profilo/${customerCode}`} />)
    }


    return (
        <Fragment>
            <Breadcrumb title="Aree" parent="Aree e ambienti" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        {loading ? <div className="card">
                            <div className="card-body datatable-react">
                            <ToolkitProvider
                            keyField='ID'
                            data={ allTariffe }
                            columns={ columns }
                            search
                            >
                            {
                              props => (
                                <div>
                                <div className="d-flex flex-row justify-content-between w-100 mb-2"><SearchBar
                                { ...props.searchProps }
                                placeholder="Cerca"
                                className="cleaning-form text-left"
                                />
                                <div className="text-right">
                                    <Link to={`${process.env.PUBLIC_URL}/tariffe/aggiungi`} className="btn btn-success cleaning-btn-login" type="submit">{"Aggiungi"}</Link>
                                </div>
                                </div>
                                  <hr />
                                  <BootstrapTable
                                    { ...props.baseProps }
                                    striped
                                    hover
                                    condensed
                                    keyField="ID"
                                    pagination={ paginationFactory({
                                      showTotal: false,
                                      firstPageText: 'Inizio', // the text of first page button
                                      prePageText: 'Prec', // the text of previous page button
                                      nextPageText: 'Succ', // the text of next page button
                                      lastPageText: 'Fine', // the text of last page button
                                      nextPageTitle: 'Vai al successivo', // the title of next page button
                                      prePageTitle: 'Vai al precedente', // the title of previous page button
                                      firstPageTitle: 'Vai al primo', // the title of first page button
                                      lastPageTitle: 'Vai all\'ultmo', // the title of last page button
                                    }) }
                                    selectRow={ selectRow }
                                  />
                                </div>
                              )
                            }
                            </ToolkitProvider>
                            </div>
                        </div> : <div className="spinner-loading-cleaning"><div className="loader-box">
                          <div className="loader">
                            <div className="line bg-success"></div>
                            <div className="line bg-success"></div>
                            <div className="line bg-success"></div>
                            <div className="line bg-success"></div>
                          </div>
                        </div></div>}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default TariffeLista;
