import React from "react";

const Footer = props => {
    return (
    <footer className="footer">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6 footer-copyright">
                    <p className="mb-0">{`Copyright ${new Date().getFullYear()} © Tutti i diritti riservati.`}</p>
                </div>
                <div className="col-md-6">
                    <p className="pull-right mb-0">Cleaning Academy é un progetto <a href="https://project-informatica.it" target="_blank">Project Informatica Srl</a></p>
                </div>
            </div>
        </div>
</footer>
)};

export default Footer;
