import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom'
import Breadcrumb from '../../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Table } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../../constant'
import  Avatar  from '../../../assets/images/avtar/avatar-cleaning.png'
import Word from '../../../assets/images/word-thumb.png'

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';


const RiepilogoMacchinari = (props) => {

  const [data,setData] = useState([])
  const [allOperators, viewOperators] = useState([{}])
  const [loading, setLoading] = useState(false)
  const history = useHistory()


  useEffect(() => {
    const fetch = async () => {
      const prodottiAAA = await axios.get(`${apiEndpoint}/preventivi/fetch-preventivo-prodotti`, {
        params: {
          id: props.current.ID
        },
        headers: {
          "x-access-token": localStorage.getItem('jwt')
        }
      })
      let p = prodottiAAA.data

      console.log(p)

      let countsArticolo = p.reduce((c, { IDarticolo: key }) => (c[key] = (c[key] || 0) + 1, c), {});
      let keysArticolo = Object.keys(countsArticolo)

      let countsAttrezzatura = p.reduce((c, { IDattrezzatura: key }) => (c[key] = (c[key] || 0) + 1, c), {});
      let keysAttrezzatura = Object.keys(countsAttrezzatura)
      console.log(keysAttrezzatura)

      let defArray = []

      keysArticolo.map((k, i) => {
        if(k !== 'null' && k !== 'undefined') {
          let ok = p.filter(element => element.IDarticolo === k)
          if(ok.length > 0) {
            let i = 0
            ok.map(e => {
              i = i + parseInt(e.quantità)
              console.log(i)
            })
            let obj = {
              descrizioneArticolo: ok[0].descrizioneArticolo,
              quantità: i,
              descrizione: ok[0].descrizione,
              costoProdotto: ok[0].costoProdotto,
            }
            defArray.push(obj)
          }
        }
      })

      keysAttrezzatura.map((k, i) => {
        if(k !== 'null' && k !== 'undefined') {
          let ok = p.filter(element => element.IDattrezzatura === k)
          if(ok.length > 0) {
            let i = 0
            ok.map(e => {
              i = i + parseInt(e.quantità)
              console.log(i)
            })
            let obj = {
              descrizioneAttrezzatura: ok[0].descrizioneAttrezzatura,
              quantità: i,
              descrizione: ok[0].descrizione,
              costoAttrezzatura: ok[0].costoAttrezzatura,
            }
            defArray.push(obj)
          }
        }
      })
      console.log(defArray)
      viewOperators(defArray)
      setLoading(true)
    }
    fetch()

  }, [])



      const { SearchBar } = Search;
      const columns = [
      {
        dataField: 'descrizione',
        text: 'Descrizione',
        formatter: opFormatter
      },
      {
        dataField: 'quantità',
        text: 'Quantità'
      },
      {
        dataField: 'costoProdotto',
        text: 'Costo',
        formatter: costoFormatter
      }
    ];


        function opFormatter(cell, row) {
          return (
            <p>{row.descrizioneArticolo !== 'NULL' ? row.descrizioneArticolo : ''} {row.descrizioneAttrezzatura !== 'NULL' ? row.descrizioneAttrezzatura : ''} {row.descrizioneMacchinario !== 'NULL' ? row.descrizioneMacchinario : ''} {row.descrizioneDPI !== 'NULL' ? row.descrizioneDPI : ''}<br/>
            {/*<small className="text-muted">{row.descrizione}</small>*/}</p>

          )
        }


        function costoFormatter(cell, row) {
          return (
            <span>{row.costoProdotto ? (row.costoProdotto).toFixed(2) : (row.costoAttrezzatura ? row.costoAttrezzatura : (row.costoUnitarioTipo === 'costo' ? row.costoMacchinario : row.costoUnitarioCanone)).toFixed(2)}</span>
          )
        }


  return (

      <Fragment>
          {loading ? <div className="container-fluid">
              <div className="row">
                  <div className="col-sm-12">
                      <div className="card">
                        <div className="card-body datatable-react">
                          <div className="row">
                              <div className="col-sm-12">
                              <ToolkitProvider
                              keyField='ID'
                              data={ allOperators }
                              columns={ columns }
                              search
                              >
                              {
                                props => (
                                  <div>
                                    <BootstrapTable
                                      { ...props.baseProps }
                                      striped
                                      hover
                                      condensed
                                      keyField="ID"
                                    />
                                  </div>
                                )
                              }
                              </ToolkitProvider>
                              </div>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div> : 'Caricamento riepilogo in corso...'}
      </Fragment>
  );
};

export default RiepilogoMacchinari;
