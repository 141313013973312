import React, { Fragment } from 'react';
import man from '../../../assets/images/dashboard/user.png'
import { Link } from 'react-router-dom';
import { Edit } from 'react-feather';
import {ELANA,GeneralManager} from '../../../constant'
import  Avatar  from '../../../assets/images/avtar/avatar-cleaning.png'


const UserPanel = () => {
    const url = '';
    return (
        <Fragment>
            <div className="sidebar-user text-center">
                <div>
                    <img className="img-60 rounded-circle lazyloaded blur-up" src={Avatar} alt={localStorage.getItem("fname") + " " + localStorage.getItem("lname")} />
                    <div className="profile-edit">
                        <Link to={`${process.env.PUBLIC_URL}/users/userEdit`}>
                            <Edit />
                        </Link>
                    </div>
                </div>
                <h6 className="mt-3 f-14">{localStorage.getItem("fname") + " " + localStorage.getItem("lname")}</h6>
                <p>{localStorage.getItem("email")}</p>
            </div>
        </Fragment>
    );
};

export default UserPanel;
