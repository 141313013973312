import React, { Fragment,useState,useEffect } from 'react';
import { Redirect, useHistory, Link } from 'react-router-dom'
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios'
import { useForm } from "react-hook-form";
import { Camera, Image } from "react-feather"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { apiEndpoint, fileServer, MyProfile,Bio,MarkJecno,Designer,Password,Website,Save,EditProfile,Company,Username,UsersCountryMenu,AboutMe,UpdateProfile,UsersTableTitle,FirstName,LastName,Address,EmailAddress,PostalCode,Country, UsersTableHeader,City,Edit,Update,Delete, PhoneNumber} from '../../constant'
import  Avatar  from '../../assets/images/avtar/avatar-cleaning.png'
import Select from 'react-select';



const TariffeEdit = (props) => {

    const history = useHistory()

    const [allTariffe, setTariffa] = useState({})
    const [allCategories, setCategories] = useState([{}])
    const [allAmbience, setAmbience] = useState([{}])
    const [loadingAmbience, setLoadingAmbience] = useState(true)
    const [loading, setLoading] = useState(false)
    const [loadingAtt, setLoadingAtt] = useState(true)
    const [fileImage, setFile] = useState(null)
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const { register: registerDel, handleSubmit: handleSubmitDel, formState: { errors: errorsDel } } = useForm();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isErr, setErr] = useState('')
    const [modal, setModal] = useState();
    const [modalDel, setModalDel] = useState();
    const [modalImg, setModalImg] = useState();
    const [modalImgSee, setModalImgSee] = useState();
    const [errDel, setErrDel] = useState('')
    const [isLoggedOut, setLoggedOut] = useState(false)
    const [errPwd, setErrPwd] = useState('')
    const [image, setImage] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [isEcolabel, setEcolabel] = useState(null)
    const [isMaterial, setMaterial] = useState('')
    const [isCodiceColore, setCodiceColore] = useState('')

    useEffect(() => {
      let mounted = true
      const fetch = async () => {
        const tariffe = await axios.get(`${apiEndpoint}/tariffe/fetch-profilo`, {
          params: {
            idCausaleBusta: props.match.params.id,
            userID: localStorage.getItem("userID")
          },
          headers: {
            "x-access-token": localStorage.getItem("jwt")
          }
        })
        if(mounted) {
          setTariffa(tariffe.data)
          setLoading(true)
        }
      }
      fetch()
      return () => {
        mounted = false
      }
    }, [])

    //Generazione randomica di stringhe
    function makeid(length) {
        var result           = [];
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() *
     charactersLength)));
       }
       return result.join('');
    }

    const toggle = () => {
        setModal(!modal)
    }


          const toggleDel = () => {
              setModalDel(!modalDel)
          }

    const onSubmit = (data1) => {
        const updateData = async () => {
            const update = await axios.patch(`${apiEndpoint}/tariffe/patch`, {
              id: props.match.params.id,
              descrizione: data1.descrizione ? data1.descrizione : allTariffe.descrizione,
              costo: data1.costo ? data1.costo : allTariffe.costoComplessivo,
              userID: localStorage.getItem("userID")
            }, {
              headers: {
                "x-access-token": localStorage.getItem('jwt')
              }
            })
            history.push(`${process.env.PUBLIC_URL}/tariffe/lista`)
          }
      updateData()
    }

    const onDeleteAccount = data1 => {
         const deleteAccount = async () => {
          const delAcc = await axios.delete(`${apiEndpoint}/tariffe/delete`, {
            params: {
              idCausaleBusta: props.match.params.id,
              userID: localStorage.getItem("userID")
            },
            headers: {
              "x-access-token": localStorage.getItem('jwt')
            }
          })
          toggleDel()
          history.push(`${process.env.PUBLIC_URL}/tariffe/lista`)
        }
        deleteAccount()
    }

    const selectStyles = {
      control: styles => ({ ...styles, borderRadius: '10px'})
    }

    const resetForm = () => {
      reset()
    }

    return (
        <Fragment>
            <Breadcrumb parent="Tariffa" title="Modifica" />
            {loading ? <><div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12 card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-header">
                                    <h4 className="card-title mb-0">{"Modifica tariffa"}</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">

                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Descrizione"} </label>
                                            <input className="form-control cleaning-form" name="descrizione" defaultValue={allTariffe.descrizione} type="text" placeholder="Inserisci descrizione es. MANODOPERA 5 LIVELLO" {...register("descrizione")} required/>
                                            {errors.descrizione && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label cleaning-form-label">{"Costo orario"}</label>
                                            <input className="form-control cleaning-form" name="costo" type="number" defaultValue={allTariffe.costoComplessivo} step="0.01" placeholder="Inserisci costo complessivo" {...register("costo")} required/>
                                            {errors.costo && <p className="err-msg-form">Campo richiesto</p>}
                                        </div>
                                    </div>

                                    </div>

                                    <span className="required-field">*</span>tutti i campi sono richiesti
                                </div>
                                <div className="card-footer text-right">
                                <button className="btn cleaning-btn-login cleaning-cancel-btn" type="button" onClick={() => history.push(`${process.env.PUBLIC_URL}/tariffe/lista`)}>{"Annulla"}</button>
                                    <button className="btn btn-primary cleaning-btn-login" type="submit">{"Modifica"}</button>
                                    <button className="btn btn-danger cleaning-btn-login" type="button" onClick={() => toggleDel()}>{"Elimina"}</button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div></> : <div className="spinner-loading-cleaning"><div className="loader-box">
              <div className="loader">
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
                <div className="line bg-success"></div>
              </div>
            </div></div>}

            {/* Modale successo*/}
            <Modal isOpen={modal} toggle={toggle} centered={true} modalClassName="cleaning-modal">
                <ModalHeader toggle={toggle}>Evviva <span>&#x1F389;</span></ModalHeader>
                <ModalBody>
                    <p>Modifica eseguita con successo, clicca su <strong>Continua</strong> per proseguire.</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={toggle} className="btn-cleaning">{"Continua"}</Button>
                </ModalFooter>
            </Modal>

            {/* Modale per eliminazione account*/}
            <Modal isOpen={modalDel} toggle={toggleDel} centered={true} modalClassName="cleaning-modal">
              <form onSubmit={handleSubmitDel(onDeleteAccount)}>
                <ModalHeader toggle={toggleDel}>Attenzione!</ModalHeader>
                <ModalBody>
                    <p>Stai per <strong>eliminare</strong> la tua tariffa, sei sicuro di voler procedere?</p>
                </ModalBody>
                <ModalFooter>
                <Button type="button" className="cleaning-cancel-btn" onClick={toggleDel}>{"Annulla"}</Button>
                    <Button color="danger" type="submit" className="btn-cleaning">{"Elimina"}</Button>
                </ModalFooter>
              </form>
            </Modal>


        </Fragment>
    );
};

export default TariffeEdit;
